import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ConfirmRegistration = (): ReactElement => {
 const navigate = useNavigate();

 const handleReturn = () => {
  navigate("/login");
 };

 return (
  <Stack>
   <Typography>
    <FormattedMessage id="login.confirmedRegistration" />
   </Typography>
   <Button variant="kxFilledActionButton" onClick={handleReturn} sx={{ py: 3 }}>
    Torna al login
   </Button>
  </Stack>
 );
};

export default ConfirmRegistration;
