import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";

import { domainOrdersTable } from "constants/tables";

import SkeletonDomains from "components/shared/skeletons/SkeletonDomains";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllCustomers } from "redux/handlers/customersHandler";
import { getAllDomainsOrders } from "redux/handlers/domainsHandler";

import {
 getDomainsOrdersList,
 getDomainsOrdersNumber
} from "redux/selectors/domainsOrdersSelector";

import { useAppDispatch } from "hooks/reduxHook";

const DomainsOrders = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();
 const domainsOrders = useSelector(getDomainsOrdersList);
 const domainsNumber = useSelector(getDomainsOrdersNumber);

 const searchRef = useRef<HTMLInputElement>(null);

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);

 useEffect(() => {
  (async () => {
   await dispatch(getAllDomainsOrders(0, 10, searchRef?.current?.value));
   await dispatch(getAllCustomers(0, 10));
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("domain_orders", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("domain_orders");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllDomainsOrders(currentPage, rowPerPage, searchRef?.current?.value));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const handleOnRowsPerPageChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  dispatch(getAllDomainsOrders(0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  dispatch(getAllDomainsOrders(startIndex, rowPerPage, searchRef?.current?.value));
 };

 return (
  <Stack>
   {isLoading ? (
    <SkeletonDomains id="app.domainsOrders" />
   ) : domainsOrders.length > 0 ? (
    <Stack mt={5}>
     <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="app.domainsOrders" /> {`(${domainsNumber})`}
      </Typography>
     </Stack>
     <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
      <Stack>
       <SortingTable
        tableData={domainOrdersTable}
        maxWidth={650}
        caller="domainOrders"
        listToShow={domainsOrders}
       />
       <TablePagination
        component="div"
        count={domainsNumber || 0}
        page={currentPage}
        rowsPerPage={rowPerPage}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleOnRowsPerPageChange}
       />
      </Stack>
     </Paper>
    </Stack>
   ) : (
    <></>
   )}
  </Stack>
 );
};

export default DomainsOrders;
