import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { mailboxContainerSteps } from "constants/apps";

import { getContainerMailboxes } from "redux/selectors/containerMailSelector";

const MailboxName = ({
 activeStep,
 firstValue,
 mail,
 handleNext,
 handleBack,
 handleReset,
 setPropMailbox
}: {
 activeStep: number;
 firstValue: string;
 mail: string;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropMailbox: (value: string) => void;
}): ReactElement => {
 const intl = useIntl();

 const mailboxes = useSelector(getContainerMailboxes);

 const [mailboxName, setMailboxName] = useState<string>(firstValue);

 const goNext = () => {
  setPropMailbox(mailboxName);
  handleNext();
 };

 const checkName = (): boolean => {
  return (
   mailboxName !== "" &&
   !isNil(mailboxes?.find((element) => element.username === `${mailboxName}@${mail}`))
  );
 };

 return (
  <Stack p={2} spacing={2}>
   <TextField
    autoComplete="off"
    value={mailboxName}
    autoFocus
    label={intl.formatMessage({ id: "container.maildomains.mailboxName" })}
    InputLabelProps={{ shrink: true }}
    helperText={<FormattedMessage id="container.maildomains.mailboxNameHelp" />}
    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
     e.stopPropagation();
    }}
    onChange={({ currentTarget }) => setMailboxName(currentTarget.value)}
    InputProps={{
     endAdornment: <InputAdornment position="end">{`@${mail}`}</InputAdornment>
    }}
   />
   {checkName() && (
    <Typography fontStyle="italic" variant="caption" color="error">
     <FormattedMessage id="container.maildomains.mailboxExists" />
    </Typography>
   )}
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={
      activeStep === mailboxContainerSteps.length - 1 ||
      mailboxName === "" ||
      mailboxName.includes(" ") ||
      mailboxName.includes("@") ||
      checkName()
     }
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default MailboxName;
