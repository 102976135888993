import React, { ReactElement, useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import SitesWizard from "../SitesWizard";

import WebAppContainerSelection from "./WebAppContainerSelection";

const WebAppWizardPage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [selectedSection, setSelectedSection] = useState<string>("");

 const query = new URLSearchParams(window.location.search);

 useEffect(() => {
  if (query.get("section") && query.get("section") === "webapp") {
   setSelectedSection("container");
  }
 }, []);

 const selectClassic = () => {
  setSelectedSection("classic");
 };

 const selectNew = () => {
  setSelectedSection("container");
 };

 const RenderSelectedSection = (): ReactElement => {
  switch (selectedSection) {
   case "classic":
    return <SitesWizard />;
   case "container":
    return <WebAppContainerSelection />;
   default:
    return <SitesWizard />;
  }
 };

 return selectedSection === "" ? (
  <Stack
   height={desktopViewPort ? "75vh" : "fit-content"}
   alignItems="center"
   justifyContent="center"
   p={4}>
   <Grid container spacing={10}>
    <Grid xs={desktopViewPort ? 6 : 12} sx={{ display: "flex", justifyContent: "center" }}>
     <Paper
      onClick={selectClassic}
      sx={{
       width: 400,
       height: 400,
       cursor: "pointer",
       "&:hover": {
        backgroundColor: "#c8e6c9"
       }
      }}>
      <Stack justifyContent="center" alignItems="center" height="100%">
       <IconSelector icon="WebIcon" props={{ sx: { fontSize: 120 }, color: "secondary" }} />
       <Typography variant="h3">Classic host</Typography>
       <Stack pt={2}>
        <Typography variant="subtitle2">
         Creazione di un sito web sfruttando i classici server host. Il livello di personalizzazione
         è limitato.
        </Typography>
       </Stack>
       <Stack pt={4}>
        <Typography variant="subtitle2">Supporta soltanto server del tipo "webserver"</Typography>
       </Stack>
      </Stack>
     </Paper>
    </Grid>
    <Grid xs={desktopViewPort ? 6 : 12} sx={{ display: "flex", justifyContent: "center" }}>
     <Paper
      onClick={selectNew}
      sx={{
       width: 400,
       height: 400,
       cursor: "pointer",
       "&:hover": {
        backgroundColor: "#c8e6c9"
       }
      }}>
      <Stack justifyContent="center" alignItems="center" height="100%">
       <IconSelector icon="DockerIcon" props={{ sx: { fontSize: 120 }, color: "secondary" }} />
       <Typography variant="h3">Container host</Typography>
       <Stack pt={2}>
        <Typography variant="subtitle2">
         Creazione di una Web App tramite la tecnologia container. Più completo e con una
         personalizzazione avanzata
        </Typography>
       </Stack>
       <Stack pt={4}>
        <Typography variant="subtitle2">
         Supporta soltanto server del tipo "container-website"
        </Typography>
       </Stack>
      </Stack>
     </Paper>
    </Grid>
   </Grid>
  </Stack>
 ) : (
  <RenderSelectedSection />
 );
};

export default WebAppWizardPage;
