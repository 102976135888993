import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getServerDetails } from "redux/selectors/serversSelector";

const SitePerformance = ({ name }: { name: string }): ReactElement => {
 const intl = useIntl();

 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onWebsitePerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === name) {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_tot_pc ? element?.mem_usage_tot_pc : 0));
     }
    }
   }
  }
  !isNil(socket) && socket.on("container_website_performance", onWebsitePerformance);

  return () => {
   !isNil(socket) && socket.off("container_website_performance", onWebsitePerformance);
  };
 }, [socket]);

 return (
  <Stack>
   <Stack direction="row" alignItems="center">
    <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
     {`${intl.formatMessage({ id: "server.cpu" })} ${cpu.toFixed(0)}%`}
    </Typography>
    <LinearProgress
     sx={{
      width: "100%",
      ml: 2,
      backgroundColor: "#F1F2F7",
      "& .MuiLinearProgress-bar": {
       backgroundColor: cpu < 50 ? "#41C58C" : cpu < 75 ? "#FF9800" : "#F44336"
      },
      height: 10,
      borderRadius: 0.8
     }}
     variant="determinate"
     value={cpu > 100 ? 100 : cpu}
    />
   </Stack>
   <Stack direction="row" alignItems="center">
    <Typography variant="body1" fontSize={12} textTransform="uppercase" minWidth={70}>
     {`${intl.formatMessage({ id: "server.ram" })} ${ram.toFixed(0)}%`}
    </Typography>
    <LinearProgress
     sx={{
      width: "100%",
      ml: 2,
      backgroundColor: "#F1F2F7",
      "& .MuiLinearProgress-bar": {
       backgroundColor: ram < 50 ? "#41C58C" : ram < 75 ? "#FF9800" : "#F44336"
      },
      height: 10,
      borderRadius: 0.8
     }}
     variant="determinate"
     value={ram > 100 ? 100 : ram}
    />
   </Stack>
  </Stack>
 );
};

export default SitePerformance;
