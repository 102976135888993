import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import CheckLogs from "../../actions/CheckLogs";
import InstallRedis from "../../actions/pluginActions/InstallRedis";
import RemovePlugin from "../../actions/pluginActions/RemovePlugin";

const RedisStatistics = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onRedisPerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === `redis_${siteData.site_name}`) {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
      setNetIo(element?.net_io);
      setBlockIo(element?.block_io);
     }
    }
   }
  }
  !isNil(socket) && socket.on("container_website_performance", onRedisPerformance);

  return () => {
   !isNil(socket) && socket.off("container_website_performance", onRedisPerformance);
  };
 }, [socket]);

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Stack direction="row" spacing={2} p={1}>
     <IconSelector icon="RedisIcon" />
     <Typography fontWeight="bold">{"Redis"}</Typography>
    </Stack>
    {!isNil(siteData.redis) ? (
     siteData.redis.redis_installed ? (
      <Stack>
       <Stack direction="row" alignItems="center" justifyContent="space-around">
        <Stack>
         <SemiCircularProgress value={cpu} maxValue={100} size={100} thickness={8} percent={true} />
         <Typography variant="subtitle2" fontWeight="bold">
          CPU
         </Typography>
        </Stack>
        <Stack>
         <SemiCircularProgress value={ram} maxValue={100} size={100} thickness={8} percent={true} />
         <Typography variant="subtitle2" fontWeight="bold">
          RAM
         </Typography>
        </Stack>
       </Stack>
       <Stack textAlign="left" p={1}>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          NET I/O:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {netIo}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          BLOCK I/O:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {blockIo}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Version:
         </Typography>
         {!isNil(siteData.redis.redis_version) ? (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           {siteData.redis.redis_version}
          </Typography>
         ) : (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           --
          </Typography>
         )}
        </Stack>
       </Stack>
       <Divider />
       <Stack direction="row" spacing={1} p={1}>
        <RemovePlugin id={siteData.id} plugin="redis" />
        <CheckLogs id={siteData.server_id_db} container={`redis_${siteData.site_name}`} />
       </Stack>
      </Stack>
     ) : (
      <Stack spacing={2}>
       <Alert severity="warning">
        <Typography>
         <FormattedMessage id="docker.website.redisIsOffline" />
        </Typography>
       </Alert>
       <Stack direction="row">redisOfflineActions</Stack>
      </Stack>
     )
    ) : (
     <Stack p={1} spacing={2}>
      <Alert severity="warning">
       <Typography>
        <FormattedMessage id="docker.website.noRedisStatistics" />
       </Typography>
      </Alert>
      <Divider />
      <Stack direction="row">
       <InstallRedis id={siteData.id} />
      </Stack>
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default RedisStatistics;
