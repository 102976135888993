import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import StyledBadge from "components/shared/badge/StyledBadge";
import IconSelector from "components/shared/images/IconSelector";

import { removeMessageId } from "redux/handlers/newMessageHandler";
import { getUsersList } from "redux/handlers/userHandler";

import { getNewMessagesList } from "redux/selectors/newMessageSelector";
import { getIsAdmin, getTelegramId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IUsersList } from "types/api/userApiInterface";

import ChatButton from "./components/ChatButton";
import ChatFAQ from "./components/ChatFAQ";
import AdminChatList from "./sections/AdminChatList";
import AdminTicketList from "./sections/AdminTicketList";
import UserChatList from "./sections/UserChatList";
import UserSendAMessage from "./sections/UserSendAMessage";
import UserTicketList from "./sections/UserTicketList";
import UserNotRegistered from "./UserNotRegistered";

const AppChat = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const isAdmin = useSelector(getIsAdmin);
 const tgId = useSelector(getTelegramId);
 const user = useSelector((state: any) => state.user);
 const messageIdList = useSelector(getNewMessagesList);

 const [open, setOpen] = useState<boolean>(false);
 const [usersList, setUsersList] = useState<Array<IUsersList>>([]);
 const [showTelegram, setShowTelegram] = useState<boolean>(false);
 const [section, setSection] = useState<string>("home");

 const handleOpen = async () => {
  setOpen(true);
  isAdmin && setUsersList(await dispatch(getUsersList()));
 };
 const handleClose = () => {
  setOpen(false);
 };

 const RenderPanel = (): ReactElement => {
  if (isAdmin) {
   switch (section) {
    case "home":
     return <ChatFAQ />;
    case "messages":
     return <UserSendAMessage />;
    case "chrono":
     return <AdminChatList userList={usersList} />;
    case "ticket":
     return <AdminTicketList userList={usersList} />;
    default:
     return <ChatFAQ />;
   }
  } else {
   switch (section) {
    case "home":
     return <ChatFAQ />;
    case "messages":
     return <UserSendAMessage />;
    case "chrono":
     return <UserChatList />;
    case "ticket":
     return <UserTicketList />;
    default:
     return <ChatFAQ />;
   }
  }
 };

 const handleOpenChat = (): void => {
  if (messageIdList?.includes(-20)) {
   setSection("messages");
   dispatch(removeMessageId(-20));
  }
 };

 return (
  <>
   <Stack
    sx={{
     position: "fixed",
     bottom: 12,
     right: 4,
     borderRadius: 2,
     alignItems: "flex-end",
     zIndex: "2000",
     boxShadow: 5
    }}
    spacing={2}
    mr={1}>
    {open && (
     <Box sx={{ width: 400, backgroundColor: "white", borderRadius: 2 }}>
      <Stack sx={{ maxHeight: "85vh", overflowY: "auto", scrollbarWidth: "thin" }}>
       <Stack
        sx={{
         borderTopLeftRadius: 8,
         borderTopRightRadius: 8,
         color: "white"
        }}>
        <Stack
         direction={"row"}
         justifyContent="space-between"
         sx={{
          backgroundColor: "#00278B",
          pl: 2,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8
         }}>
         <img
          width={60}
          height={60}
          src={`${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`}
          alt="konsolex"
         />
         <IconButton color="tertiary" className="close-button" onClick={handleClose}>
          <CloseIcon />
         </IconButton>
        </Stack>
        <Stack sx={{ backgroundImage: "linear-gradient(#00278B, #f9f9f9)" }}>
         <Stack pl={2} py={2}>
          <Typography textAlign="left" variant="subtitle1" fontWeight="bold">
           Ciao {`${user.firstname} ${user.lastname},`}
          </Typography>
          <Typography textAlign="left" variant="subtitle1" fontWeight="bold">
           come possiamo esserti d'aiuto?
          </Typography>
         </Stack>
         <Stack
          sx={{
           px: 3,
           py: 2,
           mx: 4,
           backgroundColor: "white",
           color: "black",
           border: "1px solid lightgrey",
           borderRadius: 2
          }}>
          <Stack direction="row" alignItems="center" spacing={2}>
           <IconSelector
            icon={tgId ? "CheckCircleIcon" : "WarningIcon"}
            props={{ color: tgId ? "success" : "error" }}
           />
           <Typography variant="subtitle2">
            {tgId ? "Account telegram connesso!" : "Account telegram non connesso"}
           </Typography>
           {!tgId && (
            <IconButton onClick={() => setShowTelegram(!showTelegram)}>
             <IconSelector icon={showTelegram ? "KeyboardArrowUpIcon" : "KeyboardArrowDownIcon"} />
            </IconButton>
           )}
          </Stack>
          {showTelegram && <UserNotRegistered />}
         </Stack>
        </Stack>
       </Stack>
       {!tgId ? <ChatFAQ /> : <RenderPanel />}
      </Stack>
      <Stack direction="row" justifyContent="space-between" px={5} py={2}>
       <Stack alignItems="center">
        <IconButton onClick={() => setSection("home")}>
         <IconSelector
          icon="HomeIcon"
          props={{ color: section === "home" ? "kxOrange" : "kxBlue" }}
         />
        </IconButton>
        <Typography variant="caption">Home</Typography>
       </Stack>
       <Stack alignItems="center">
        {messageIdList?.includes(-20) ? (
         <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot">
          <IconButton onClick={handleOpenChat}>
           <IconSelector
            icon="SpeakerNotesIcon"
            props={{ color: section === "messages" ? "kxOrange" : "kxBlue" }}
           />
          </IconButton>
         </StyledBadge>
        ) : (
         <IconButton onClick={() => setSection("messages")}>
          <IconSelector
           icon="SpeakerNotesIcon"
           props={{ color: section === "messages" ? "kxOrange" : "kxBlue" }}
          />
         </IconButton>
        )}
        <Typography variant="caption">Messaggi</Typography>
       </Stack>
       <Stack alignItems="center">
        <IconButton onClick={() => setSection("chrono")}>
         <IconSelector
          icon="ForumIcon"
          props={{ color: section === "chrono" ? "kxOrange" : "kxBlue" }}
         />
        </IconButton>
        <Typography variant="caption">Cronologia</Typography>
       </Stack>
       <Stack alignItems="center">
        {messageIdList && messageIdList?.length > 0 && !messageIdList?.includes(-20) ? (
         <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot">
          <IconButton onClick={() => setSection("ticket")}>
           <IconSelector
            icon="ConfirmationNumberIcon"
            props={{ color: section === "ticket" ? "kxOrange" : "kxBlue" }}
           />
          </IconButton>
         </StyledBadge>
        ) : (
         <IconButton onClick={() => setSection("ticket")}>
          <IconSelector
           icon="ConfirmationNumberIcon"
           props={{ color: section === "ticket" ? "kxOrange" : "kxBlue" }}
          />
         </IconButton>
        )}
        <Typography variant="caption">Tickets</Typography>
       </Stack>
      </Stack>
     </Box>
    )}
   </Stack>
   <Tooltip title={intl.formatMessage({ id: "app.support" })}>
    <Fab
     color="kxBlue"
     sx={{
      position: "fixed",
      right: "1%",
      bottom: "1%",
      width: "fit-content",
      px: 2,
      py: 1,
      borderRadius: 10,
      "&:hover": {
       backgroundColor: "#00278B"
      }
     }}
     aria-label="add"
     onClick={() => (open ? handleClose() : handleOpen())}>
     <Stack direction="row" spacing={1} alignItems="center">
      <ChatButton />
      <Typography color="white" textTransform="lowercase" fontWeight="bold">
       Chat
      </Typography>
     </Stack>
    </Fab>
   </Tooltip>
  </>
 );
};

export default AppChat;
