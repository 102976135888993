import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
 IContainerMailboxData,
 IContainerMailDomainAPI,
 IMailContainerAPI,
 IMailDomainSyncAPI
} from "types/api/containerMailDomainApiInterface";

const initialState: IMailContainerAPI = {
 mailDomains: null,
 mailboxes: [],
 domainSync: null,
 reload: true
};

const ContainerMailDetailsReducer = createSlice({
 name: "containerMailDomains",
 initialState,
 reducers: {
  setMailDomainsDetails: (state, { payload }: PayloadAction<IContainerMailDomainAPI>) => {
   return { ...state, mailDomains: payload };
  },
  resetMailDomainsDetails: (state) => {
   return { ...state, mailDomains: null };
  },
  setMailboxes: (state, { payload }: PayloadAction<Array<IContainerMailboxData>>) => {
   return { ...state, mailboxes: payload };
  },
  resetMailboxes: (state) => {
   return { ...state, mailboxes: [] };
  },
  setReload: (state, { payload }: PayloadAction<boolean>) => {
   return { ...state, reload: payload };
  },
  setSync: (state, { payload }: PayloadAction<IMailDomainSyncAPI>) => {
   return { ...state, domainSync: payload };
  },
  resetSync: (state) => {
   return { ...state, domainSync: null };
  },
  resetContainerData: () => {
   return initialState;
  }
 }
});

export default ContainerMailDetailsReducer;
