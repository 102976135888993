import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import SkeletonUtility from "components/shared/skeletons/SkeletonUtility";
import EmailMigration from "components/utility/mailMigration/EmailMigration";

import { getAllEmailMigrations } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

const MailMigrationPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllEmailMigrations(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Stack>{isLoading ? <SkeletonUtility id="utility.mailMigration" /> : <EmailMigration />}</Stack>
 );
};

export default MailMigrationPage;
