import React, { ReactElement } from "react";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import LitespeedBox from "./manageComponents/LitespeedBox";
import WebAppDbStatistics from "./manageSections/WebAppDbStatistics";
import WebAppEnvStatistics from "./manageSections/WebAppEnvStatistics";
import WebAppSiteStatistics from "./manageSections/WebAppSiteStatistics";

const WebAppMonitoring = ({ rowData }: { rowData: IDockerSiteData }): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Grid container spacing={2}>
   <Grid
    xs={
     desktopViewPort
      ? rowData.framework.type !== "react" && rowData.framework.type !== "vue"
        ? 6
        : 4
      : 12
    }>
    <WebAppSiteStatistics siteData={rowData} />
   </Grid>
   <Grid
    xs={
     desktopViewPort
      ? rowData.framework.type !== "react" && rowData.framework.type !== "vue"
        ? 6
        : 4
      : 12
    }>
    <WebAppDbStatistics siteData={rowData} />
   </Grid>
   {(rowData.framework.type === "react" || rowData.framework.type === "vue") && (
    <Grid xs={desktopViewPort ? 4 : 12}>
     <WebAppEnvStatistics siteData={rowData} />
    </Grid>
   )}
   {rowData.system_service.version.includes("litespeed") && (
    <Grid xs={12}>
     <LitespeedBox
      id={rowData.id}
      password={rowData.litespeed.litespeed_password || null}
      port={rowData.litespeed.litespeed_port || 0}
      user={rowData.litespeed.litespeed_user || ""}
     />
    </Grid>
   )}
  </Grid>
 );
};

export default WebAppMonitoring;
