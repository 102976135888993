import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postExecuteScript } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RunCustomScript = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [script, setScript] = useState<string>("");
 const [folder, setFolder] = useState<string>("");

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postExecuteScript(id, folder, script));
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return script === "" || folder === "";
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.runScript" />}>
    <IconButton onClick={() => handleOpen()} color="kxOrange">
     <IconSelector icon="TerminalIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.runScript" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="docker.website.runScriptMessage" />
      </Typography>
     </Alert>
     <TextField
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      label={intl.formatMessage({ id: "docker.website.folder" })}
      InputLabelProps={{ shrink: true }}
      value={folder}
      onChange={(e) => setFolder(e.target.value)}
     />
     <TextField
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      label={intl.formatMessage({ id: "docker.website.script" })}
      InputLabelProps={{ shrink: true }}
      value={script}
      onChange={(e) => setScript(e.target.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default RunCustomScript;
