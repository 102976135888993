import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { IContainerMailboxData } from "types/api/containerMailDomainApiInterface";

import DeleteMailbox from "./DeleteMailbox";
import EditMailbox from "./EditMailbox";
import EditMailboxLimits from "./EditMailboxLimits";

const MailboxActions = ({ rowData }: { rowData: IContainerMailboxData }): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <EditMailboxLimits mailboxData={rowData} closeMenu={handleClose} />
     <EditMailbox mailboxData={rowData} closeMenu={handleClose} />
     <DeleteMailbox id={rowData.id} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default MailboxActions;
