import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postCheckServerSsl } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CheckServerSsl = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);

 const handleCheckSsl = async () => {
  setLoading(true);
  await dispatch(postCheckServerSsl(id));
  setLoading(false);
 };
 return (
  <Stack spacing={2} textAlign="left" p={2}>
   <Typography variant="subtitle2">
    <FormattedMessage id="server.checkSslMessage" />
   </Typography>
   <Stack direction="row" justifyContent="center">
    {loading ? (
     <CircularProgress />
    ) : (
     <Button variant="kxFilledActionButton" onClick={handleCheckSsl}>
      <FormattedMessage id="server.checkSsl" />
     </Button>
    )}
   </Stack>
  </Stack>
 );
};

export default CheckServerSsl;
