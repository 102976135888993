import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface SemiCircularProgressProps {
 value: number;
 maxValue: number;
 size?: number;
 thickness?: number;
 percent?: boolean;
}

const SemiCircularProgress = ({
 value,
 maxValue,
 size = 120,
 thickness = 10,
 percent
}: SemiCircularProgressProps) => {
 const [animatedValue, setAnimatedValue] = useState(0);
 const [color, setColor] = useState("green");

 useEffect(() => {
  if (maxValue === 0) {
   setAnimatedValue(0);
   return;
  }

  const animationDuration = 1000;
  const step = (value - animatedValue) / (animationDuration / 16);

  let current = animatedValue;
  const interval = setInterval(() => {
   current += step;
   if ((step > 0 && current >= value) || (step < 0 && current <= value)) {
    current = value;
    clearInterval(interval);
   }
   setAnimatedValue(Math.round(current));
  }, 16);

  return () => clearInterval(interval);
 }, [value, maxValue]);

 useEffect(() => {
  if (maxValue === 0) {
   setColor("gray");
   return;
  }

  const percentage = (animatedValue / maxValue) * 100;
  if (percent) {
   if (percentage < 50) setColor("green");
   else if (percentage >= 50 && percentage < 80) setColor("orange");
   else setColor("red");
  } else {
   if (percentage <= 50) setColor("red");
   else if (percentage < 100) setColor("orange");
   else setColor("green");
  }
 }, [animatedValue, maxValue]);

 if (maxValue === 0) {
  return (
   <Box
    sx={{
     position: "relative",
     width: size,
     height: size / 2,
     overflow: "hidden"
    }}>
    <svg width={size} height={size}>
     <path
      d={`M ${thickness} ${size / 2}
                  A ${size / 2 - thickness} ${size / 2 - thickness} 0 0 1 ${size - thickness} ${
                   size / 2
                  }`}
      stroke="#e0e0e0"
      strokeWidth={thickness}
      fill="none"
     />
    </svg>

    <Box
     sx={{
      position: "absolute",
      top: "80%",
      left: "50%",
      transform: "translate(-50%, -50%)"
     }}>
     <Typography variant="h6" fontWeight="bold" fontSize="small">
      0/0
     </Typography>
    </Box>
   </Box>
  );
 }

 const percentage = (animatedValue / maxValue) * 100;
 const radius = size / 2 - thickness;
 const circumference = Math.PI * radius;
 const progress = (percentage / 100) * circumference;

 return (
  <Box
   sx={{
    position: "relative",
    width: size,
    height: size / 2,
    overflow: "hidden"
   }}>
   <svg width={size} height={size}>
    <path
     d={`M ${thickness} ${size / 2}
                A ${radius} ${radius} 0 0 1 ${size - thickness} ${size / 2}`}
     stroke="#e0e0e0"
     strokeWidth={thickness}
     fill="none"
    />
   </svg>

   <svg
    width={size}
    height={size}
    style={{
     position: "absolute",
     top: 0,
     left: 0
    }}>
    <path
     d={`M ${thickness} ${size / 2}
                A ${radius} ${radius} 0 0 1 ${size - thickness} ${size / 2}`}
     stroke={color}
     strokeWidth={thickness}
     fill="none"
     strokeDasharray={`${progress} ${circumference}`}
     strokeDashoffset={0}
     style={{
      transition: "stroke-dasharray 1s linear, stroke 1s linear"
     }}
    />
   </svg>

   <Box
    sx={{
     position: "absolute",
     top: "80%",
     left: "50%",
     transform: "translate(-50%, -50%)"
    }}>
    {percent ? (
     <Typography variant="h6" fontWeight="bold" fontSize="medium">
      {`${value}%`}
     </Typography>
    ) : (
     <Typography variant="h6" fontWeight="bold" fontSize="medium">
      {animatedValue}/{maxValue}
     </Typography>
    )}
   </Box>
  </Box>
 );
};

export default SemiCircularProgress;
