import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";

import { getBillingIsSet } from "redux/handlers/userHandler";

import { getUserProfileData } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const MissingProfileData = (): ReactElement => {
 const dispatch = useAppDispatch();

 const profileData = useSelector(getUserProfileData);

 const [dataAvailable, setDataAvailable] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setDataAvailable(await dispatch(getBillingIsSet()));
  })();
 }, [profileData]);

 return dataAvailable ? (
  <></>
 ) : (
  <Alert severity="error" variant="filled">
   <FormattedMessage id="app.dataMissing" />
  </Alert>
 );
};

export default MissingProfileData;
