import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getAllBackups } from "redux/handlers/webAppHandler";

import { getBackups, getReload } from "redux/selectors/webAppSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IBackupSchedule } from "types/api/webAppApiInterface";

import CreateBackup from "../actions/backupActions/CreateBackup";
import DeleteBackup from "../actions/backupActions/DeleteBackup";
import RestoreFromBackup from "../actions/backupActions/RestoreFromBackup";
import ScheduleBackup from "../actions/backupActions/ScheduleBackup";
import BackupNotFound from "../manageComponents/BackupNotFound";

const WebAppBackups = ({
 id,
 hasDb,
 backupData
}: {
 id: number;
 hasDb: boolean;
 backupData: IBackupSchedule;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const searchRef = useRef<HTMLInputElement>(null);
 const backups = useSelector(getBackups);
 const reload = useSelector(getReload);

 const [loading, setLoading] = useState<boolean>(false);
 const [searchValue, setSearchValue] = useState<string>("");

 useEffect(() => {
  if (reload) {
   (async () => {
    setLoading(true);
    if (id) {
     await dispatch(getAllBackups(id));
    }
    setLoading(false);
   })();
  }
 }, [reload]);

 useDebounce(
  async () => {
   if (!loading && searchValue !== "") {
    await dispatch(getAllBackups(id, searchValue));
   }
  },
  1000,
  [searchValue]
 );

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="BackupIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="docker.website.backup" />
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : backups && backups?.length > 0 ? (
    <Stack width="stretch" p={2}>
     <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TextField
       size="small"
       autoComplete="new-password"
       label={<FormattedMessage id="docker.website.searchBackup" />}
       inputRef={searchRef}
       InputLabelProps={{ shrink: true }}
       InputProps={{
        startAdornment: <IconSelector icon="SearchIcon" props={{ color: "disabled" }} />,
        autoComplete: "off"
       }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <Stack direction="row" spacing={1}>
       <ScheduleBackup id={id} hasDb={hasDb} backupData={backupData} />
       <CreateBackup id={id} hasDb={hasDb} />
      </Stack>
     </Stack>
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableBody>
        {backups?.map((element, index) => {
         return (
          <TableRow key={`cron-table-${index}`}>
           <TableCell component="th" scope="row">
            <Chip
             size="small"
             label={element.status}
             color={element.status === "active" ? "success" : "error"}
            />
           </TableCell>
           <TableCell component="th" scope="row">
            <Stack>
             <Typography fontWeight="bold" variant="subtitle2">
              {element.name}
             </Typography>
             <Stack direction="row" alignItems="center" spacing={1}>
              <IconSelector icon={element.type === "site" ? "WebIcon" : "DatabaseIcon"} />
              <Typography variant="subtitle2">{element.type}</Typography>
             </Stack>
            </Stack>
           </TableCell>
           <TableCell component="th" scope="row">
            <Typography variant="subtitle2">{`${element.size} MB`}</Typography>
           </TableCell>
           <TableCell component="th" scope="row">
            <Typography variant="subtitle2">
             <FormattedDate value={new Date(element.createdAt)} />
            </Typography>
           </TableCell>
           <TableCell align="right">
            <RestoreFromBackup id={element.id} />
            <DeleteBackup id={element.id} />
           </TableCell>
          </TableRow>
         );
        })}
       </TableBody>
      </Table>
     </TableContainer>
    </Stack>
   ) : (
    <BackupNotFound id={id} hasDb={hasDb} />
   )}
  </Paper>
 );
};

export default WebAppBackups;
