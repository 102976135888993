import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { get2FA } from "redux/selectors/userSelector";

const TwoFANotActive = (): ReactElement => {
 const faOn = useSelector(get2FA);

 return faOn ? (
  <></>
 ) : (
  <Alert severity="warning" color="error" variant="filled">
   <Stack textAlign="left">
    <Typography fontWeight="bold" variant="subtitle2">
     <FormattedMessage id="app.2faOfflineTitle" />
    </Typography>
    <Typography variant="caption">
     <FormattedMessage id="app.2faOffline" />
    </Typography>
   </Stack>
  </Alert>
 );
};

export default TwoFANotActive;
