import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import ReportIcon from "@mui/icons-material/Report";
import RestoreIcon from "@mui/icons-material/Restore";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChangeDomainLock from "components/domains/actions/ChangeDomainLock";
import SendVerificationInList from "components/domains/actions/SendVerificationInList";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";
import RegistarImage from "components/shared/images/RegistarImage";

import { postChangeDomainAutomigration } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { domainTableData } from "types/global/table";

const DomainsTable = ({
 rowData,
 index
}: {
 rowData: domainTableData;
 index: number;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const [automigration, setAutomigration] = useState<boolean>(false);

 const RenderStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   case "in renew":
    return <RestoreIcon style={{ color: "#FFB600" }} />;
   case "expiring":
    return <RestoreIcon style={{ color: "#FFB600" }} />;
   case "expired":
    return <ReportIcon style={{ color: "#FF1800" }} />;
   case "deleted":
    return <ReportIcon style={{ color: "#FF1800" }} />;
   case "restore":
    return <SettingsBackupRestoreIcon style={{ color: "blue" }} />;
   case "pending transfer":
    return <SettingsBackupRestoreIcon style={{ color: "blue" }} />;
   case "on hold":
    return <RestoreIcon style={{ color: "#FFB600" }} />;
   case "Transfer_Lock, Pending_Verification":
    return <SettingsBackupRestoreIcon style={{ color: "blue" }} />;
   default:
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
  }
 };

 const RenderStatusMessage = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return <FormattedMessage id="domain.status.active" />;
   case "expiring":
    return <FormattedMessage id="domain.status.expiring" />;
   case "pending transfer":
    return <FormattedMessage id="domain.status.transfer" />;
   case "on hold":
    return <FormattedMessage id="domain.status.onHold" />;
   case "expired":
    return <FormattedMessage id="domain.status.expired" />;
   case "in renew":
    return <FormattedMessage id="domain.status.inRenew" />;
   case "Transfer_Lock, Pending_Verification":
    return <FormattedMessage id="domain.status.inVerify" />;
   default:
    return <FormattedMessage id="domain.status.active" />;
  }
 };

 const formatProviderName = (provider: string): string => {
  switch (provider) {
   case "internetbs":
    return "IBS";
   case "ascio":
    return "Ascio";
   default:
    return "";
  }
 };

 const colorDateFilter = (status: string | null) => {
  switch (status) {
   case "active":
    return "black";
   case "expiring":
    return "#FFB600";
   case "in renew":
    return "#FFB600";
   default:
    return "#FF1800";
  }
 };

 const emailVerificationMessage = (emailVerified: number): ReactElement => {
  switch (emailVerified) {
   case 0:
    return (
     <Typography variant="caption" color="error">
      <FormattedMessage id="domain.emailVerificationFailed" />
     </Typography>
    );
   case 1:
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.emailVerificationNeeded" />
     </Typography>
    );
   case 2:
    return (
     <Typography variant="caption">
      <FormattedMessage id="domain.emailVerificationPending" />
     </Typography>
    );
   default:
    return <></>;
  }
 };

 useEffect(() => {
  setAutomigration(rowData.automigration);
 }, [rowData]);

 const handleChangeAutomigration = async (event: ChangeEvent<HTMLInputElement>) => {
  setAutomigration(event.target.checked);
  await dispatch(postChangeDomainAutomigration(rowData.id, event.target.checked));
 };

 const handleRedirectToDomainDetails = (id: number): void => {
  navigate(`/domains/manage/${id}/details`);
 };

 return (
  <TableRow
   key={`domains-list-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => handleRedirectToDomainDetails(rowData.id)}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
     {/*<RegistarImage provider={row.registrar} />*/}
     <Typography variant="small" fontWeight="bold">
      {rowData.name}
     </Typography>
    </Stack>
    {rowData.provider && (
     <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
      <RegistarImage provider={rowData.provider} />
      <Typography variant="small">{formatProviderName(rowData.provider)}</Typography>
     </Stack>
    )}
    <Stack
     direction="row"
     alignItems="center"
     justifyContent="left"
     spacing={0.5}
     py={1}
     onClick={(event) => event.stopPropagation()}
     maxWidth="fit-content">
     <AdminLogAsUser userid={rowData.userid} owner={rowData.personalName || ""} />
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack spacing={1}>
     <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       <FormattedMessage id="domain.service" />
      </Typography>
      <Typography variant="extraSmall">
       <RenderStatus status={rowData.status} />
      </Typography>
      <Typography variant="caption">
       <RenderStatusMessage status={rowData.status} />
      </Typography>
     </Stack>
     {rowData.domainStatus !== "success" && rowData.domainStatus && (
      <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
       <Typography variant="caption" fontWeight="bold">
        <FormattedMessage id="domain.domain" />
       </Typography>
       <Typography variant="extraSmall">
        <RenderStatus status={rowData.domainStatus} />
       </Typography>
       <Typography variant="caption">
        <RenderStatusMessage status={rowData.domainStatus} />
       </Typography>
      </Stack>
     )}
     {emailVerificationMessage(rowData.emailVerified)}
     {!isNil(rowData.emailVerified) && rowData.emailVerified !== 3 && (
      <Typography variant="caption">{rowData.email}</Typography>
     )}
     {!isNil(rowData.emailVerified) && rowData.emailVerified !== 3 && (
      <Stack onClick={(event) => event.stopPropagation()}>
       <SendVerificationInList id={rowData.id} />
      </Stack>
     )}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="column" spacing={1}>
     <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      onClick={(event) => event.stopPropagation()}>
      <ChangeDomainLock id={rowData.id} lock={rowData.registrarlock} />
     </Stack>
     {rowData.warningicanndomainlocked && (
      <Typography variant="caption" textAlign="left">
       {rowData.warningicanndomainlocked}
      </Typography>
     )}
     {rowData.domainstatusreason && (
      <Stack direction="row">
       <Typography variant="caption" textAlign="left">
        {rowData.domainstatusreason}
       </Typography>
      </Stack>
     )}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack spacing={0.5} alignItems="flex-start">
     <FormControlLabel
      onClick={(event) => event.stopPropagation()}
      label={
       <Typography variant="subtitle2">
        <FormattedMessage id="domain.automigrate" />
       </Typography>
      }
      disabled={!isNil(rowData.domainStatus) && rowData.domainStatus.includes("Transfer_Lock")}
      control={<Switch checked={automigration} onChange={handleChangeAutomigration} />}
      labelPlacement="start"
     />
     {!isNil(rowData.domainStatus) && rowData.domainStatus.includes("Transfer_Lock") && (
      <Typography variant="extraSmall">
       <FormattedMessage id="domain.automigrate.locked" />
      </Typography>
     )}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
     {/*rowData.autoRenew === 1 ? (
       <FormControlLabel
        label={<FormattedMessage id="domain.autorenew" />}
        control={
         <Switch
          checked={true}
          onChange={(event) => handleChangeAutorenew(rowData.id, event.target.checked)}
         />
        }
        labelPlacement="start"
       />
      ) : (
       <FormControlLabel
        control={
         <Switch
          checked={false}
          onChange={(event) => handleChangeAutorenew(rowData.id, event.target.checked)}
         />
        }
        label={<FormattedMessage id="domain.autorenew" />}
        labelPlacement="start"
       />
    )*/}
    </Stack>
    <Stack direction="row" justifyContent="left" spacing={0.5}>
     <Typography variant="small">
      <FormattedMessage id="domain.expiration" />
     </Typography>
     <Typography variant="small" sx={{ color: colorDateFilter(rowData.status) }}>
      {rowData?.expirationdate === null ? (
       <FormattedMessage id="domain.notavailable" />
      ) : (
       <FormattedDate
        value={new Date(rowData.expirationdate)}
        year="numeric"
        month="long"
        day="2-digit"
       />
      )}
     </Typography>
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default DomainsTable;
