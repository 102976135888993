import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

const TypeBox = ({
 selectedType,
 typeList,
 selectType
}: {
 selectedType: string;
 typeList: Array<string>;
 selectType: (type: string) => void;
}): ReactElement => {
 const RenderIcon = ({ version }: { version: string }): ReactElement => {
  if (version.includes("php")) {
   return <IconSelector icon={"PhpIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("tomcat")) {
   return <IconSelector icon={"TomcatIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("odoo")) {
   return <IconSelector icon={"OdooIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("nodejs")) {
   return <IconSelector icon={"NodejsIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("nextjs")) {
   return <IconSelector icon={"NextjsIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("litespeed")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/litespeed.svg`}
     alt="litespeed"
    />
   );
  } else if (version.includes("apache")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/apache2.svg`}
     alt="apache"
    />
   );
  } else if (version.includes("nginx")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/nginx.svg`}
     alt="nginx"
    />
   );
  } else {
   return <></>;
  }
 };

 return (
  <Stack direction="row" flexWrap="wrap">
   {typeList.map((element, index) => {
    return selectedType === element ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2,
       m: 1,
       display: "flex",
       alignItems: "center",
       minWidth: 150
      }}
      onClick={() => selectType(element)}>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
       <RenderIcon version={element} />
       <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" textTransform="uppercase">
         {element}
        </Typography>
       </Stack>
      </Stack>
     </Paper>
    ) : (
     <Tooltip
      key={`country-paper-${index}`}
      title={<FormattedMessage id="docker.website.add.selectType" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer",
        m: 1,
        display: "flex",
        alignItems: "center",
        minWidth: 150
       }}
       onClick={() => selectType(element)}>
       <Stack direction="row" spacing={1}>
        <RenderIcon version={element} />
        <Stack direction="row" justifyContent="center" alignItems="center">
         <Typography fontWeight="bold" textTransform="uppercase">
          {element}
         </Typography>
        </Stack>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default TypeBox;
