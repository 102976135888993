import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditContainerAlias } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainsAlias } from "types/api/containerMailDomainApiInterface";

type aliasType = {
 address: string;
 goto: string;
 active: boolean;
 private_comment: string;
};

const defaultAlias = {
 address: "",
 goto: "",
 active: false,
 private_comment: ""
};

const EditAlias = ({ aliasData }: { aliasData: IContainerMailDomainsAlias }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [newAlias, setNewAlias] = useState<aliasType>(defaultAlias);

 const handleOpen = async () => {
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postEditContainerAlias(
    aliasData.id,
    `${newAlias.address}@${aliasData.domain}`,
    newAlias.goto,
    newAlias.active,
    newAlias.private_comment
   )
  );
  setLoading(false);
  handleClose();
 };

 const handleReset = () => {
  setNewAlias({
   address: aliasData.address.split("@")[0],
   goto: aliasData.goto,
   active: aliasData.active,
   private_comment: aliasData.private_comment || ""
  });
 };

 const handleChange = (key: keyof aliasType, value: string | boolean) => {
  setNewAlias({ ...newAlias, [key]: value });
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="container.maildomains.editAlias" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="EditIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.editAlias" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <FormControlLabel
      control={
       <Checkbox
        checked={newAlias.active}
        onChange={(e) => {
         handleChange("active", e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "container.maildomains.aliasEnabled" })}
     />
     <TextField
      autoComplete="off"
      value={newAlias.address}
      autoFocus
      label={intl.formatMessage({ id: "container.maildomains.aliasAddress" })}
      InputLabelProps={{ shrink: true }}
      helperText={<FormattedMessage id="container.maildomains.aliasAddressHelp" />}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => handleChange("address", currentTarget.value)}
      InputProps={{
       endAdornment: <InputAdornment position="end">{`@${aliasData.domain}`}</InputAdornment>
      }}
     />
     <TextField
      autoComplete="off"
      value={newAlias.goto}
      autoFocus
      label={intl.formatMessage({ id: "container.maildomains.aliasGoto" })}
      InputLabelProps={{ shrink: true }}
      helperText={<FormattedMessage id="container.maildomains.aliasGotoHelp" />}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => handleChange("goto", currentTarget.value)}
     />
     <TextField
      autoComplete="off"
      value={newAlias.private_comment}
      autoFocus
      label={intl.formatMessage({ id: "container.maildomains.aliasPrivateComment" })}
      InputLabelProps={{ shrink: true }}
      helperText={<FormattedMessage id="container.maildomains.aliasPrivateCommentHelp" />}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => handleChange("private_comment", currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditAlias;
