import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { ApiService } from "service/ApiService";

import { getMe, getProviderPermissions } from "./userHandler";

export const postLoginViaGoogle =
 (jwt: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/google-auth", "POST", {
    idToken: jwt
   });
   const token = data?.token;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save token in local storage
   localStorage.setItem("jwt", token);
   // Call user info
   await dispatch(getMe());
   await dispatch(getProviderPermissions());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get user data with google - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postLoginViaGithub =
 (code: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/callback", "POST", {
    code
   });
   const token = data?.token;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save token in local storage
   localStorage.setItem("jwt", token);
   // Call user info
   await dispatch(getMe());
   await dispatch(getProviderPermissions());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get user data with github - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postLoginViaLinkedin =
 (code: string, state: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/linkedin/callback", "POST", {
    code,
    state
   });
   const token = data?.token;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save token in local storage
   localStorage.setItem("jwt", token);
   // Call user info
   await dispatch(getMe());
   await dispatch(getProviderPermissions());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get user data with linkedin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const saveLinkedinStateCode =
 (linkedin_state_code: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/auth/savelogincode", "POST", { linkedin_state_code });
  } catch (error) {
   console.warn("FAILED TO GET SAVE LOGIN CODE");
  }
 };

export const postConnectGoogle =
 (jwt: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/link/google", "POST", {
    idToken: jwt
   });
   const token = data;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Call user info
   await dispatch(getMe());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to connect with google - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postConnectGithub =
 (code: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/link/github", "POST", {
    code
   });
   const token = data;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Call user info
   await dispatch(getMe());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to connect with github - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postConnectLinkedin =
 (code: string, state: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/link/linkedin", "POST", {
    code,
    state
   });
   const token = data;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Call user info
   await dispatch(getMe());
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to connect with linkedin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postRevokeId =
 (type: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/auth/revokeid", "POST", {
    type
   });
   const token = data;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Call user info
   await dispatch(getMe());
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Succesfully removed ${type} access`,
     timestamp: Date.now(),
     type: "success"
    })
   );
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to revoke ${type} token - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };
