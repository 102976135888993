import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DockerDeleteWebsite from "components/docker/website/actions/DockerDeleteWebsite";
import DockerWebsiteSsl from "components/docker/website/actions/DockerWebsiteSsl";
import EmptyList from "components/shared/emptyList/EmptyList";

import { getDockerProxy, getServerSites } from "redux/handlers/dockerHandler";

import {
 getDockerWebsiteNumber,
 getDockerWebsitesList
} from "redux/selectors/dockerWebsiteSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import CreateProxy from "../actions/CreateProxy";
import PowerOnSite from "../actions/PowerOnSite";
import RecreateSite from "../actions/RecreateSite";
import SiteFramework from "../SiteFramework";
import SitePerformance from "../SitePerformance";
import SiteVersion from "../SiteVersion";

const ContainerWebsites = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const { socket } = useContext(AppContext);

 const searchRef = useRef<HTMLInputElement>(null);
 const websites = useSelector(getDockerWebsitesList);
 const websiteNumber = useSelector(getDockerWebsiteNumber);

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [noProxyFound, setNoProxyFound] = useState<boolean>(false);

 useEffect(() => {
  function onContainerWebsite(value: any) {
   if (value?.completed && value?.section === "websites") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onContainerWebsite);

  return () => {
   !isNil(socket) && socket.off("docker_data", onContainerWebsite);
  };
 }, [socket]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    await dispatch(getServerSites(id, currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const containerList = await dispatch(getDockerProxy(id, 0, 10));
    if (containerList.totalCount === 0) setNoProxyFound(true);
    await dispatch(getServerSites(id, currentPage, rowPerPage, searchRef?.current?.value));
    if (websiteNumber === 0) setListWasEmpty(true);
    if (isLoading) setIsLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(getServerSites(id, 0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(getServerSites(id, startIndex, rowPerPage, searchRef?.current?.value));
 };

 const RenderActions = ({ rowData }: { rowData: IDockerSiteData }): ReactElement => {
  switch (rowData.status) {
   case "offline":
    return (
     <RecreateSite id={id} siteName={rowData.site_name} siteVers={rowData.system_service.service} />
    );
   case "stop":
    return <PowerOnSite id={rowData.id} type="site" />;
   default:
    return (
     <Typography variant="caption">
      <FormattedMessage id="docker.website.noAction" />
     </Typography>
    );
  }
 };

 const handleGoToManage = (id: number, server_id: number) => {
  navigate(`/app/sites/container/${server_id}/${id}/details`);
 };

 const handleGoToCreate = () => {
  navigate("/app/sites/add?section=webapp");
 };

 const RenderTable = ({ rowData }: { rowData: IDockerSiteData }): ReactElement => {
  if (rowData.status === "online") {
   return (
    <>
     <TableRow
      sx={{ cursor: "pointer" }}
      onClick={() => handleGoToManage(rowData.id, rowData.server_id_db)}>
      <TableCell component="th" scope="row">
       <Chip
        size="small"
        color={rowData.status === "online" ? "success" : "error"}
        label={<Typography variant="caption">{rowData.status}</Typography>}
       />
      </TableCell>
      <TableCell component="th" scope="row">
       <Typography variant="subtitle2" fontWeight="bold">
        {rowData.site_name}
       </Typography>
      </TableCell>
      <TableCell component="th" scope="row" onClick={(e) => e.stopPropagation()}>
       <DockerWebsiteSsl id={rowData.id} check={rowData.ssl} />
      </TableCell>
      <TableCell component="th" scope="row">
       {!isNil(rowData.framework.type) && rowData.framework.type !== "custom" ? (
        <SiteFramework framework={rowData.framework.type} />
       ) : (
        <SiteVersion version={rowData.system_service.version} />
       )}
      </TableCell>
      <TableCell component="th" scope="row">
       <SitePerformance name={rowData.site_name} />
      </TableCell>
      <TableCell align="right" onClick={(e) => e.stopPropagation()}>
       <Stack direction="row" justifyContent="flex-end">
        <DockerDeleteWebsite id={rowData.id} />
       </Stack>
      </TableCell>
     </TableRow>
    </>
   );
  } else {
   return (
    <TableRow>
     <TableCell component="th" scope="row">
      <Stack direction="row" spacing={2} alignItems="center">
       <Chip
        size="small"
        color={rowData.status === "offline" || rowData.status === "stop" ? "error" : "warning"}
        label={<Typography variant="caption">{rowData.status}</Typography>}
       />
      </Stack>
     </TableCell>
     <TableCell component="th" scope="row">
      <Typography variant="subtitle2" fontWeight="bold">
       {rowData.site_name}
      </Typography>
     </TableCell>
     <TableCell component="th" scope="row">
      <DockerWebsiteSsl id={rowData.id} check={rowData.ssl} />
     </TableCell>
     <TableCell component="th" scope="row"></TableCell>
     <TableCell component="th" scope="row"></TableCell>
     <TableCell align="right">
      <RenderActions rowData={rowData} />
     </TableCell>
    </TableRow>
   );
  }
 };

 return isLoading ? (
  <></>
 ) : websites.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="docker.website.searchWebsite" />}
      inputRef={searchRef}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>
     {noProxyFound ? (
      <Stack spacing={1}>
       <Alert severity="warning">
        <FormattedMessage id="docker.website.noProxyFound" />
       </Alert>
       <CreateProxy id={id} />
      </Stack>
     ) : (
      <Button onClick={handleGoToCreate} variant="kxActionButton">
       <FormattedMessage id="docker.website.createApp" />
      </Button>
     )}
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="docker.website.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.ssl" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="docker.website.version" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.website.performance" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {websites.map((row, index) => (
        <RenderTable key={`docker-sites-list-${index}`} rowData={row} />
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={websiteNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : noProxyFound ? (
  <Stack p={2} spacing={1}>
   <Alert severity="warning">
    <FormattedMessage id="docker.website.noProxyFound" />
   </Alert>
   <CreateProxy id={id} />
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
   <Button onClick={handleGoToCreate} variant="kxActionButton">
    <FormattedMessage id="docker.website.createApp" />
   </Button>
  </Stack>
 );
};

export default ContainerWebsites;
