import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import ServerDns from "components/servers/managePages/ServerDns";

import ServerCredential from "./managePages/credentials/ServerCredentials";

const ServerManage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Stack>
   <Grid container spacing={2}>
    <Grid xs={desktopViewPort ? 7 : 12}>
     <ServerCredential />
    </Grid>
    <Grid xs={desktopViewPort ? 5 : 12}>
     <ServerDns />
    </Grid>
   </Grid>
  </Stack>
 );
};

export default ServerManage;
