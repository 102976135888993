import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { getAllServices, postRenewService } from "redux/handlers/servicesHandler";

import { getServicesList } from "redux/selectors/servicesSelector";
import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { EServiceEndpoints } from "types/redux/userInterfaces";

const ManualRenewServer = ({ server }: { server: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const servicesList = useSelector(getServicesList);
 const userBalance = useSelector(getUserBalance);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  dispatch(getAllServices(0, 10, server));
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postRenewService(
    servicesList?.find((element) => element.name === server && element.type === "server")?.id || 0,
    EServiceEndpoints.SERVER
   )
  );
  setLoading(false);

  handleClose();
 };

 const checkBalance = (): boolean => {
  return (
   userBalance <
   Number(
    servicesList?.find((element) => element.name === server && element.type === "server")?.price ||
     0
   )
  );
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="app.renew" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.services.manualRenew" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkBalance()}>
    <Stack>
     {checkBalance() ? (
      <Alert severity="error">
       <FormattedMessage id="app.balanceInsufficient" />
      </Alert>
     ) : (
      <Typography>
       <FormattedMessage id="profile.services.manualRenewMessage" />
      </Typography>
     )}
     <Stack direction="row">
      <Typography fontWeight="bold">
       <FormattedMessage id="app.balance" />
       {`: ${userBalance} €`}
      </Typography>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold">
       <FormattedMessage id="app.price" />
       {`: ${
        servicesList?.find((element) => element.name === server && element.type === "server")
         ?.price || 0
       } €`}
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default ManualRenewServer;
