import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";

import { getServerDetails } from "redux/selectors/serversSelector";

import CheckServerSsl from "./actions/CheckServerSsl";
import ContainerMailList from "./sections/ContainerMailList";

const ContainerMailPage = (): ReactElement => {
 const params = useParams<{ id: string; section: string }>();
 const server = useSelector(getServerDetails);

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   {server.ssl_checked ? (
    <ContainerMailList id={parseInt(params?.id || "0")} />
   ) : (
    <CheckServerSsl id={server.id || 0} />
   )}
  </Paper>
 );
};

export default ContainerMailPage;
