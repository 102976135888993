import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getServerDetails } from "redux/selectors/serversSelector";

import ResetLitespeedPassword from "../actions/litespeedActions/ResetLitespeedPassword";

const LitespeedBox = ({
 id,
 password,
 port,
 user
}: {
 id: number;
 password: string | null;
 port: number;
 user: string;
}): ReactElement => {
 const server = useSelector(getServerDetails);

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, minHeight: 170, borderRadius: "10px", boxShadow: 0 }}>
   <Stack>
    <img
     width={300}
     height={75}
     src={`${process.env.PUBLIC_URL}/svg/providers/litespeed_full_logo.svg`}
     alt="litespeed"
    />
    <Stack p={1}>
     <Stack direction="row" textAlign="left">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={80}>
       <FormattedMessage id="docker.website.databaseUser" />
       {":"}
      </Typography>
      <Typography variant="subtitle2">{user || "Not available"}</Typography>
     </Stack>
     <Stack direction="row" textAlign="left">
      <Typography variant="subtitle2" fontWeight="bold" minWidth={80}>
       <FormattedMessage id="docker.website.databasePassword" />
       {":"}
      </Typography>
      <Typography variant="subtitle2">{!password ? "Not available" : password}</Typography>
     </Stack>
    </Stack>
    <Stack>
     <Divider />
     <Stack direction="row" spacing={1} p={1}>
      <ResetLitespeedPassword id={id} />
      <Tooltip title={<FormattedMessage id="docker.website.openLitespeed" />}>
       <IconButton
        onClick={() =>
         window.open(`http://${server.ipv4}:${port}`, "Litespeed server", "width=1000, height=800")
        }>
        <IconSelector icon="OpenInNewIcon" props={{ color: "secondary" }} />
       </IconButton>
      </Tooltip>
     </Stack>
    </Stack>
   </Stack>
  </Paper>
 );
};

export default LitespeedBox;
