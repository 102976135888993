import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { generatePassword } from "redux/handlers/userHandler";
import {
 getAllServerMailbox,
 getServerByType,
 postStartMigration
} from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerByTypeAPI } from "types/api/serversApiInterface";

type migrationData = {
 source_host: string;
 source_user: string;
 source_password: string;
 server: number;
 destination_user: string;
 destination_password: string;
 mailbox: number;
};

const EmailMigrationStart = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [loading, setLoading] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [showPassword, setShowPassword] = useState<string>("password");
 const [showSourcePassword, setShowSourcePassword] = useState<string>("password");
 const [mailboxes, setMailboxes] = useState<Array<{ username: string; id: number }>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getServerByType("container-mail")));
   setIsLoading(false);
  })();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, setValue, getValues, formState, watch } = useForm({
  defaultValues: {
   source_host: "",
   source_user: "",
   source_password: "",
   server: 0,
   destination_user: "",
   destination_password: "",
   mailbox: 0
  }
 });

 const onSubmit: SubmitHandler<migrationData> = async (data: migrationData) => {
  setLoading(true);
  await dispatch(
   postStartMigration(
    data.mailbox,
    data.server,
    data.source_host,
    data.source_user,
    data.source_password,
    servers.find((element) => element.id === data.server)?.server_name || "",
    data.destination_user,
    data.destination_password
   )
  );
  handleClose();
  setLoading(false);
  navigate("/utility/migration");
 };

 const handleChangeServer = async (id: number) => {
  setMailboxes(await dispatch(getAllServerMailbox(id)));
  setValue("server", id);
 };

 const checkForData = (): boolean => {
  return (
   watch("source_host") === "" ||
   watch("source_user") === "" ||
   watch("source_password") === "" ||
   watch("server") === 0 ||
   watch("mailbox") === 0 ||
   watch("destination_password") === "" ||
   watch("destination_user") === ""
  );
 };

 const handleGeneratePassword = async () => {
  setValue("destination_password", await generatePassword());
 };

 return (
  <Stack spacing={2} px={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <IconSelector icon="MultipleStopIcon" />
    <Typography>
     <FormattedMessage id={"utility.mailMigration.addMigration"} />
    </Typography>
   </Stack>
   {isLoading ? (
    <Stack direction="row" alignItems="center" justifyContent="center">
     <CircularProgress />
    </Stack>
   ) : (
    <Grid container spacing={2}>
     <Grid xs={desktopViewPort ? 5 : 12}>
      <Stack spacing={2}>
       <Stack direction="row" spacing={1}>
        <IconSelector icon="FileUploadIcon" props={{ color: "kxBlue" }} />
        <Typography>
         <FormattedMessage id="utility.mailMigration.sourceBox" />
        </Typography>
       </Stack>
       <Stack spacing={2}>
        <Alert severity="info">
         <Typography>
          <FormattedMessage id="utility.mailMigration.sourceExplain" />
         </Typography>
        </Alert>
        <Controller
         name="source_host"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourceHost" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.source_host}
           helperText={
            formState.isDirty &&
            !!formState?.errors?.source_host &&
            intl.formatMessage({ id: "utility.mailMigration.invalidHost" })
           }
          />
         )}
        />
        <Controller
         name="source_user"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourceUser" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.source_user}
           helperText={
            formState.isDirty &&
            !!formState?.errors?.source_user &&
            intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
           }
          />
         )}
        />
        <Controller
         name="source_password"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourcePassword" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.source_password}
           type="password"
           autoComplete="new-password"
           helperText={
            formState.isDirty &&
            !!formState?.errors?.source_password &&
            intl.formatMessage({ id: "utility.mailMigration.invalidPassword" })
           }
           InputProps={{
            endAdornment: (
             <InputAdornment position="end">
              <IconButton
               aria-label="toggle password visibility"
               onClick={() =>
                setShowSourcePassword(showSourcePassword === "password" ? "text" : "password")
               }
               edge="end">
               <IconSelector
                icon={showSourcePassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
                props={{ fontSize: "small" }}
               />
              </IconButton>
             </InputAdornment>
            )
           }}
          />
         )}
        />
       </Stack>
      </Stack>
     </Grid>
     {desktopViewPort && (
      <Grid xs={2}>
       <Stack justifyContent="center" alignItems="center" height="100%">
        <IconSelector
         icon="ArrowForwardIcon"
         props={{ sx: { fontSize: 100 }, color: "kxOrange" }}
        />
       </Stack>
      </Grid>
     )}
     <Grid xs={desktopViewPort ? 5 : 12}>
      <Stack spacing={2}>
       <Stack direction="row" spacing={1}>
        <IconSelector icon="DownloadIcon" props={{ color: "kxBlue" }} />
        <Typography>
         <FormattedMessage id="utility.mailMigration.destinationBox" />
        </Typography>
       </Stack>
       <Stack spacing={2}>
        <Alert severity="info">
         <Typography>
          <FormattedMessage id="utility.mailMigration.destinationExplain" />
         </Typography>
        </Alert>
        <Controller
         name="server"
         control={control}
         render={({ field }) => (
          <Autocomplete
           fullWidth={true}
           autoHighlight
           sx={{ my: 2 }}
           onChange={(e, value) => {
            handleChangeServer(value?.id || 0);
           }}
           options={servers.map((server) => {
            return { label: server.server_name, id: server.id };
           })}
           value={servers
            .map((element) => {
             return { label: element.server_name, id: element.id };
            })
            .find((server) => server.id === getValues("server"))}
           renderInput={(params) => (
            <TextField
             {...params}
             {...field}
             label={<FormattedMessage id="container.maildomains.serverHost" />}
             error={formState.isDirty && !!formState?.errors?.server}
             InputLabelProps={{ shrink: true }}
            />
           )}
          />
         )}
        />
        <Controller
         name="mailbox"
         control={control}
         render={({ field }) => (
          <Autocomplete
           fullWidth={true}
           autoHighlight
           disabled={watch("server") === 0}
           sx={{ my: 2 }}
           onChange={(e, value) => {
            setValue("mailbox", value?.id || 0);
           }}
           options={mailboxes.map((mail) => {
            return { label: mail.username, id: mail.id };
           })}
           value={mailboxes
            .map((element) => {
             return { label: element.username, id: element.id };
            })
            .find((mail) => mail.id === getValues("mailbox"))}
           renderInput={(params) => (
            <TextField
             {...params}
             {...field}
             label={<FormattedMessage id="utility.mailMigration.mailbox" />}
             error={formState.isDirty && !!formState?.errors?.server}
             InputLabelProps={{ shrink: true }}
            />
           )}
          />
         )}
        />
        <Controller
         name="destination_user"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.destinationUser" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.destination_user}
           helperText={
            formState.isDirty &&
            !!formState?.errors?.destination_user &&
            intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
           }
          />
         )}
        />
        <Stack direction="row" alignItems="center" spacing={2}>
         <Controller
          name="destination_password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
           <TextField
            {...field}
            fullWidth
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
             e.stopPropagation();
            }}
            label={intl.formatMessage({ id: "utility.mailMigration.destinationPassword" })}
            InputLabelProps={{ shrink: true }}
            type="password"
            autoComplete="new-password"
            error={formState.isDirty && !!formState?.errors?.destination_password}
            helperText={
             formState.isDirty &&
             !!formState?.errors?.destination_password &&
             intl.formatMessage({ id: "utility.mailMigration.invalidPassword" })
            }
            InputProps={{
             endAdornment: (
              <InputAdornment position="end">
               <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(showPassword === "password" ? "text" : "password")}
                edge="end">
                <IconSelector
                 icon={showPassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
                 props={{ fontSize: "small" }}
                />
               </IconButton>
              </InputAdornment>
             )
            }}
           />
          )}
         />
         <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
          <FormattedMessage id="app.generatePassword" />
         </Button>
        </Stack>
       </Stack>
      </Stack>
     </Grid>
    </Grid>
   )}
   <Stack direction="row" alignItems="center" justifyContent="center">
    <Button variant="kxFilledActionButton" disabled={checkForData()} onClick={handleOpen}>
     <FormattedMessage id="app.confirm" />
    </Button>
    <AppModal
     open={open}
     close={handleClose}
     title={intl.formatMessage({ id: "utility.mailMigration.startMigration" })}
     handleClose={handleClose}
     disabled={loading || checkForData()}
     handleConfirm={handleSubmit(onSubmit)}>
     <Typography>
      <FormattedMessage id="utility.mailMigration.startMigrationMessage" />
     </Typography>
    </AppModal>
   </Stack>
  </Stack>
 );
};

export default EmailMigrationStart;
