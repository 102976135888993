/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getUserBalance } from "redux/selectors/userSelector";

import { UpgradeTypesAPI } from "types/api/serversApiInterface";

const UpgradeBox = ({
 upgradeElement,
 serverPrice,
 showTag,
 selected,
 showTag2,
 selectUpgrade
}: {
 upgradeElement: UpgradeTypesAPI;
 serverPrice: number;
 showTag: boolean;
 selected: boolean;
 showTag2: boolean;
 selectUpgrade: (value: UpgradeTypesAPI) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const boxStyle = {
  height: 20,
  width: 130,
  backgroundColor: showTag2 ? "#ff4900" : "green",
  color: "white",
  fontWeight: "bold",
  position: "relative",
  transform: "rotate(0.90turn)",
  top: 20,
  left: -20
 };

 const onSelect = () => {
  if (userBalance >= upgradeElement.price - serverPrice) selectUpgrade(upgradeElement);
 };

 return (
  <Paper
   sx={{
    overflow: "hidden",
    minHeight: 60,
    cursor: selected ? "default" : "pointer",
    backgroundColor: selected ? "#c8e6c9" : "white"
   }}
   onClick={onSelect}>
   {showTag && (
    <Stack sx={boxStyle}>
     <Typography variant="caption">
      <FormattedMessage id="server.upgrade.machineConvenient" />
     </Typography>
    </Stack>
   )}
   {showTag2 && (
    <Stack sx={boxStyle}>
     <Typography variant="caption">
      <FormattedMessage id="server.upgrade.machinePerformance" />
     </Typography>
    </Stack>
   )}
   <Stack
    direction={desktopViewPort ? "row" : "column"}
    sx={{ mx: 9, height: showTag || showTag2 ? "auto" : desktopViewPort ? 60 : "auto" }}
    alignItems="center"
    justifyContent="space-between">
    <Stack direction="row" minWidth={80}>
     <Typography variant="h5">{upgradeElement.name}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>{upgradeElement.cores}</Typography>
     <Typography>
      <FormattedMessage id="server.upgrade.machineCores" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Stack direction={"row"}>
      <Typography>{upgradeElement.memory}</Typography>
      <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
       GB
      </Typography>
     </Stack>
     <Typography>
      <FormattedMessage id="server.upgrade.machineRam" />
     </Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Stack direction={"row"}>
      <Typography>{upgradeElement.disk}</Typography>
      <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
       GB
      </Typography>
     </Stack>
     <Typography>{upgradeElement.disk_type.toUpperCase()}</Typography>
    </Stack>
    <Stack direction="row" spacing={1} alignContent="center">
     <Typography
      fontStyle="italic"
      fontWeight="bold"
      color={userBalance < upgradeElement.price - serverPrice ? "#FF1800" : "green"}>
      <FormattedNumber value={upgradeElement.price} style="currency" currency="EUR" />
     </Typography>
     {userBalance < upgradeElement.price - serverPrice && (
      <Tooltip
       title={intl.formatMessage({
        id: "server.upgrade.balanceInsufficient"
       })}>
       <ReportProblemIcon color="warning" />
      </Tooltip>
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default UpgradeBox;
