import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const AddonsIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <path d="M9.24.75v7.65c0,.42-.34.76-.76.76H.76C.34,9.16,0,8.82,0,8.4V.75C0,.34.34,0,.76,0h7.72c.42,0,.76.34.76.75Z" />
  <path d="M9.24,11.6v7.65c0,.41-.34.75-.76.75H.76C.34,20,0,19.66,0,19.25v-7.65c0-.42.34-.76.76-.76h7.72c.42,0,.76.34.76.76Z" />
  <path d="M20,11.6v7.65c0,.41-.34.75-.75.75h-7.73c-.42,0-.75-.34-.75-.75v-7.65c0-.42.33-.76.75-.76h7.73c.41,0,.75.34.75.76Z" />
  <path d="M19.99,4.58c0,.14-.12.25-.25.25h-4.08v4.08c0,.13-.12.25-.25.25-.14,0-.25-.12-.25-.25v-4.08h-4.08c-.14,0-.25-.11-.25-.25s.11-.25.25-.25h4.08V.25c0-.14.11-.25.25-.25.13,0,.25.11.25.25v4.08h4.08c.13,0,.25.11.25.25Z" />
 </svg>,
 "Addons"
);
