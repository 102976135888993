import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IActiveSessions } from "types/api/userApiInterface";

const initialState: IActiveSessions = {
 phpActiveSession: 0,
 filemanagerActiveSession: 0
};

const SessionReducer = createSlice({
 name: "sessions",
 initialState,
 reducers: {
  setSession: (state, { payload }: PayloadAction<{ name: string; value: number }>) => {
   switch (payload.name) {
    case "php":
     state.phpActiveSession = payload.value;
     break;
    case "filemanager":
     state.filemanagerActiveSession = payload.value;
     break;
    default:
     break;
   }
  },
  resetSession: () => {
   return initialState;
  }
 }
});

export default SessionReducer;
