import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { getServerNameFromId } from "helpers/utility";

import DvrIcon from "@mui/icons-material/Dvr";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ExternalDatabasesMenu from "components/utility/externalDatabase/ExternalDatabasesMenu";

import { IAllServers } from "types/api/serversApiInterface";
import { externalDatabasesTableData } from "types/global/table";

const ExternalDatabasesTable = ({
 rowData,
 index,
 servers
}: {
 rowData: externalDatabasesTableData;
 index: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 return (
  <TableRow key={`snapshot-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" spacing={1}>
      <Typography variant="inherit" fontWeight="bold">
       {rowData.db_name_phpmyadmin}
      </Typography>
     </Stack>
     <Stack direction="row" alignItems="center">
      <DvrIcon fontSize="small" sx={{ marginRight: 1 }} />
      <Typography variant="inherit">
       {rowData.server_id_db ? (
        getServerNameFromId(rowData.server_id_db, servers)
       ) : (
        <FormattedMessage id="utility.externalDatabases.serverNotAvailable" />
       )}
      </Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="inherit">{rowData.db_host_phpmyadmin}</Typography>
   </TableCell>
   <TableCell>
    <Typography variant="inherit">{rowData.db_port_phpmyadmin}</Typography>
   </TableCell>
   <TableCell>
    <Typography variant="inherit">{rowData.db_upload_limit_phpmyadmin}</Typography>
   </TableCell>
   <TableCell align="center">
    <ExternalDatabasesMenu rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default ExternalDatabasesTable;
