import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ManualRenew from "components/profile/services/actions/ManualRenew";

import { getAllServices } from "redux/handlers/servicesHandler";

import { getServicesList } from "redux/selectors/servicesSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { serviceTableData } from "types/global/table";

const ManualRestoreServer = ({ server }: { server: string }): ReactElement => {
 const dispatch = useAppDispatch();

 const servicesList = useSelector(getServicesList);

 const [serviceServer, setServiceServer] = useState<serviceTableData | undefined>(undefined);

 useEffect(() => {
  (async () => {
   await dispatch(getAllServices(0, 10, server));
  })();
 }, []);

 useEffect(() => {
  setServiceServer(
   servicesList?.find((element) => element.name === server && element.type === "server")
  );
 }, [servicesList]);

 return serviceServer ? (
  <ManualRenew
   rowData={serviceServer}
   status={serviceServer.status}
   restore_price={serviceServer.restore_price}
  />
 ) : (
  <></>
 );
};

export default ManualRestoreServer;
