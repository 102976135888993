import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const getMails = (state: AppState) => state.containerMailDomains;

export const getContainerMailsList = createSelector(getMails, (mails) => {
 return mails?.mailDomains?.dataset || [];
});

export const getContainerMailsNumber = createSelector(getMails, (mails) => {
 return mails?.mailDomains?.totalCount || 0;
});

export const getContainerReload = createSelector(getMails, (mails) => {
 return mails?.reload;
});

export const getContainerMailboxes = createSelector(getMails, (mails) => {
 return mails?.mailboxes;
});

export const getMailDomainsSync = createSelector(getMails, (mails) => {
 return mails?.domainSync?.dataset || [];
});

export const getMailDomainsSyncNumber = createSelector(getMails, (mails) => {
 return mails?.domainSync?.totalCount || 0;
});
