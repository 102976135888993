import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { websiteContainerCronjobSteps } from "constants/server";

type cronType = {
 week: string;
 minutes: string;
 hours: string;
 days: string;
 month: string;
 type: string;
};

const CronTiming = ({
 activeStep,
 firstValue,
 handleNext,
 handleBack,
 handleReset,
 setPropsValue
}: {
 activeStep: number;
 firstValue: cronType;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropsValue: (value: cronType) => void;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [timingWeek, setTimingWeek] = useState<string>(firstValue.week);
 const [timingMin, setTimingMin] = useState<string>(firstValue.minutes);
 const [timingHour, setTimingHour] = useState<string>(firstValue.hours);
 const [timingDay, setTimingDay] = useState<string>(firstValue.days);
 const [timingMonth, setTimingMonth] = useState<string>(firstValue.month);
 const [selectedType, setSelectedType] = useState<string>(firstValue.type);

 const goNext = () => {
  setPropsValue({
   minutes: timingMin,
   week: timingWeek,
   hours: timingHour,
   days: timingDay,
   month: timingMonth,
   type: selectedType
  });
  handleNext();
 };

 const changeSelection = (selection: string) => {
  setSelectedType(selection);
  switch (selection) {
   case "5min": {
    setTimingDay("*");
    setTimingHour("*");
    setTimingMin("*/5");
    setTimingWeek("*");
    setTimingMonth("*");
    break;
   }
   case "hourly": {
    setTimingDay("*");
    setTimingHour("*");
    setTimingMin("0");
    setTimingWeek("*");
    setTimingMonth("*");
    break;
   }
   case "daily": {
    setTimingDay("*");
    setTimingHour("0");
    setTimingMin("0");
    setTimingWeek("*");
    setTimingMonth("*");
    break;
   }
   case "weekly": {
    setTimingDay("*");
    setTimingHour("0");
    setTimingMin("0");
    setTimingWeek("0");
    setTimingMonth("*");
    break;
   }
   case "monthly": {
    setTimingDay("1");
    setTimingHour("0");
    setTimingMin("0");
    setTimingWeek("*");
    setTimingMonth("*");
    break;
   }
   case "custom": {
    setTimingDay("*");
    setTimingHour("*");
    setTimingMin("*");
    setTimingWeek("*");
    setTimingMonth("*");
    break;
   }
  }
 };

 const RenderCronInputs = (): ReactElement => {
  switch (selectedType) {
   case "5min":
    return (
     <Stack direction="row" alignItems="center">
      <Typography variant="subtitle2" fontStyle="italic">
       <FormattedMessage id="sites.cronjob.5MinutesExplain" />
      </Typography>
     </Stack>
    );
   case "hourly":
    return (
     <TextField
      size="small"
      value={timingMin}
      sx={{ width: desktopViewPort ? "auto" : "100%", maxWidth: desktopViewPort ? 120 : "stretch" }}
      label={<FormattedMessage id="sites.cronjob.run_min" />}
      onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
      InputLabelProps={{ shrink: true }}
      autoComplete="off"
     />
    );
   case "daily":
    return (
     <Stack
      direction={desktopViewPort ? "row" : "column"}
      alignItems="center"
      spacing={desktopViewPort ? 0 : 2}>
      <TextField
       size="small"
       value={timingMin}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_min" />}
       onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingHour}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_hour" />}
       onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
     </Stack>
    );
   case "weekly":
    return (
     <Stack
      direction={desktopViewPort ? "row" : "column"}
      alignItems="center"
      spacing={desktopViewPort ? 0 : 2}>
      <TextField
       size="small"
       value={timingMin}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_min" />}
       onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingHour}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_hour" />}
       onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingWeek}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_wday" />}
       onChange={({ currentTarget }) => setTimingWeek(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
     </Stack>
    );
   case "monthly":
    return (
     <Stack
      direction={desktopViewPort ? "row" : "column"}
      alignItems="center"
      spacing={desktopViewPort ? 0 : 2}>
      <TextField
       size="small"
       value={timingMin}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_min" />}
       onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingHour}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_hour" />}
       onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingDay}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_mday" />}
       onChange={({ currentTarget }) => setTimingDay(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
     </Stack>
    );
   case "custom":
    return (
     <Stack
      direction={desktopViewPort ? "row" : "column"}
      alignItems="center"
      spacing={desktopViewPort ? 0 : 2}>
      <TextField
       size="small"
       value={timingMin}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_min" />}
       onChange={({ currentTarget }) => setTimingMin(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingHour}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_hour" />}
       onChange={({ currentTarget }) => setTimingHour(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingDay}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_mday" />}
       onChange={({ currentTarget }) => setTimingDay(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingMonth}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_month" />}
       onChange={({ currentTarget }) => setTimingMonth(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
      {desktopViewPort && <Typography>{"-"}</Typography>}
      <TextField
       size="small"
       value={timingWeek}
       sx={{
        width: desktopViewPort ? "auto" : "100%",
        maxWidth: desktopViewPort ? 120 : "stretch"
       }}
       label={<FormattedMessage id="sites.cronjob.run_wday" />}
       onChange={({ currentTarget }) => setTimingWeek(currentTarget.value)}
       InputLabelProps={{ shrink: true }}
       autoComplete="off"
      />
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return (
  <Stack p={2} pt={1} spacing={2}>
   <Stack spacing={3}>
    <Alert severity="info">
     <Typography variant="subtitle2">
      <FormattedMessage id="sites.cronjob.timingExplain" />
     </Typography>
    </Alert>
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="sites.cronjob.timing" />
      </InputLabel>
      <Select
       value={selectedType}
       size="small"
       sx={{ width: desktopViewPort ? "fit-content" : "stretch" }}
       label={<FormattedMessage id="sites.cronjob.timing" />}
       onChange={(e) => changeSelection(e.target.value)}>
       <MenuItem value={"5min"}>Ogni 5 minuti</MenuItem>
       <MenuItem value={"hourly"}>Orario</MenuItem>
       <MenuItem value={"daily"}>Giornaliero</MenuItem>
       <MenuItem value={"weekly"}>Settimanale</MenuItem>
       <MenuItem value={"monthly"}>Mensile</MenuItem>
       <MenuItem value={"custom"}>Custom</MenuItem>
      </Select>
     </FormControl>
     <RenderCronInputs />
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={
      activeStep === websiteContainerCronjobSteps.length - 1 ||
      timingWeek === "" ||
      timingMin === "" ||
      timingHour === "" ||
      timingDay === "" ||
      timingMonth === ""
     }
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default CronTiming;
