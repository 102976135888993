import React, { ReactElement, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";
import ResetPasswordComponent from "components/shared/modal/ResetPasswordComponent";

import { postResetAdminPassword } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ResetPassword = ({ id, oldPassword }: { id: number; oldPassword: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, setValue, formState, watch, reset } = useForm({
  defaultValues: {
   password: oldPassword
  }
 });

 const onSubmit: SubmitHandler<{
  password: string;
 }> = async (data: { password: string }) => {
  setLoading(true);
  await dispatch(postResetAdminPassword(id, data.password));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.resetAdminPassword" />}>
    <IconButton onClick={handleOpen} color="kxOrange">
     <IconSelector icon="PasswordIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.resetAdminPassword" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("password") === "" || watch("password") === oldPassword}>
    <Stack spacing={2}>
     <ResetPasswordComponent
      control={control}
      error={formState.isDirty && !!formState?.errors?.password}
      setPassword={(password) => setValue("password", password)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ResetPassword;
