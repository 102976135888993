import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { mailboxContainerSteps } from "constants/apps";

import RangeSlider from "components/shared/sliders/RangeSlider";

const MailboxSpam = ({
 activeStep,
 threshold1,
 threshold2,
 handleNext,
 handleBack,
 handleReset,
 setScore
}: {
 activeStep: number;
 threshold1: number;
 threshold2: number;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setScore: (value: Array<number>) => void;
}): ReactElement => {
 const [cThreshold, setCThreshold] = useState<Array<number>>([threshold1, threshold2]);

 const goNext = () => {
  setScore(cThreshold);
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <Stack>
    <Stack direction="row" spacing={1}>
     <Typography variant="subtitle2" fontWeight="bold">
      <FormattedMessage id="container.maildomains.threshold" />
      {":"}
     </Typography>
     <Typography
      variant="kxColored"
      fontWeight="bold">{`${cThreshold[0]} - ${cThreshold[1]}`}</Typography>
    </Stack>
    <RangeSlider
     max={15}
     min={0}
     defaultValue={cThreshold}
     changeValue={(value) => setCThreshold(value)}
    />
    <Typography variant="extraSmall">
     <FormattedMessage id="container.maildomains.thresholdHelp" />
    </Typography>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={
      activeStep === mailboxContainerSteps.length - 1 || cThreshold[0] < 0 || cThreshold[1] > 15
     }
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default MailboxSpam;
