import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const AppIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <path d="M9.24.75v7.65c0,.42-.34.76-.76.76H.76c-.42,0-.76-.34-.76-.76V.75c0-.41.34-.75.76-.75h7.72c.42,0,.76.34.76.75Z" />
  <path d="M20,.75v7.65c0,.42-.34.76-.76.76h-7.72c-.42,0-.76-.34-.76-.76V.75c0-.41.34-.75.76-.75h7.72c.42,0,.76.34.76.75Z" />
  <path d="M9.24,11.6v7.65c0,.41-.34.75-.76.75H.76c-.42,0-.76-.34-.76-.75v-7.65c0-.42.34-.76.76-.76h7.72c.42,0,.76.34.76.76Z" />
  <path d="M20,11.6v7.65c0,.41-.34.75-.75.75h-7.73c-.42,0-.75-.34-.75-.75v-7.65c0-.42.33-.76.75-.76h7.73c.41,0,.75.34.75.76Z" />
 </svg>,
 "App"
);
