export const utilityList = [
 {
  name: "utility.externalDatabases",
  link: "/utility/databases",
  icon: "DatabaseIcon",
  add: "/utility/databases/add",
  internElement: "utility.externalDatabases.totalDatabases",
  sectionMessage: "utility.externalDatabases.description",
  section: "databases",
  addTitle: "utility.externalDatabases.addDatabase"
 },
 {
  name: "utility.mailMigration",
  link: "/utility/migration",
  icon: "MultipleStopIcon",
  add: "/utility/migration/add",
  internElement: "utility.mailMigration.totalMigrations",
  sectionMessage: "utility.mailMigration.description",
  section: "migrations",
  addTitle: "utility.mailMigration.addMigration"
 }
];

export const externalDatabaseSteps = [
 {
  label: "utility.externalDatabases.add.selectServer",
  description: "utility.externalDatabases.add.selectServerMessage"
 },
 {
  label: "utility.externalDatabases.add.uploadLimit",
  description: "utility.externalDatabases.add.uploadLimitMessage"
 },
 {
  label: "utility.externalDatabases.add.databaseHost",
  description: "utility.externalDatabases.add.databaseHostMessage"
 },
 {
  label: "utility.externalDatabases.add.port",
  description: "utility.externalDatabases.add.portMessage"
 },
 {
  label: "utility.externalDatabases.add.databaseName",
  description: "utility.externalDatabases.add.databaseNameMessage"
 }
];
