import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
 postDisinstallPhpAdmin,
 postUninstallAdminer,
 postUninstallPgadmin
} from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

import IconSelector from "../images/IconSelector";

const DatabaseFrame = ({
 id,
 database,
 link,
 navigator_type
}: {
 id: number;
 database: IDatabaseData;
 link: string;
 navigator_type: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const handleRemove = async () => {
  if (navigator_type === "adminer") await dispatch(postUninstallAdminer(id));
  else if (navigator_type === "phpmyadmin") await dispatch(postDisinstallPhpAdmin(id));
  else if (navigator_type === "pgadmin") await dispatch(postUninstallPgadmin(id));
 };

 const RenderDatabaseIcon = (): ReactElement => {
  switch (database.db_type) {
   case "mysql":
    return <IconSelector icon="MysqlIcon" />;
   case "mariadb":
    return <IconSelector icon="MariadbIcon" />;
   case "postgres":
    return <IconSelector icon="PostgresqlIcon" />;
   default:
    return <></>;
  }
 };

 return (
  <Stack spacing={2} p={2} textAlign="left">
   <Stack>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
      Database
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databaseName" />
     </Typography>
     <Typography variant="subtitle2">{`${database.db_name}`}</Typography>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databaseHost" />
     </Typography>
     <Typography variant="subtitle2">{`${database.db_host}`}</Typography>
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="subtitle2" textTransform="uppercase">
      Access
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databaseUser" />
     </Typography>
     <Typography variant="subtitle2">{`${database.db_user}`}</Typography>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databasePassword" />
     </Typography>
     <Typography variant="subtitle2">{`${database.db_password}`}</Typography>
    </Stack>
    {database?.db_type === "postgres" ? (
     <>
      <Stack direction="row" alignItems="center" spacing={1} pt={2}>
       <Typography fontWeight="bold" variant="subtitle2" textTransform="uppercase">
        Postgres
       </Typography>
       <Stack width="stretch">
        <Divider />
       </Stack>
      </Stack>
      <Stack direction="row">
       <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
        <FormattedMessage id="docker.website.databasePasswordPostgres" />
       </Typography>
       <Typography variant="subtitle2">{`${database.db_password}`}</Typography>
      </Stack>
      {navigator_type === "pgadmin" && (
       <Stack direction="row">
        <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
         <FormattedMessage id="docker.website.databaseUserPgadmin" />
        </Typography>
        <Typography variant="subtitle2">{`${database.db_root_user}`}</Typography>
       </Stack>
      )}
      {navigator_type === "pgadmin" && (
       <Stack direction="row">
        <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
         <FormattedMessage id="docker.website.databasePasswordPgadmin" />
        </Typography>
        <Typography variant="subtitle2">{`${database?.db_root_password}`}</Typography>
       </Stack>
      )}
     </>
    ) : (
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
       <FormattedMessage id="docker.website.databasePasswordRoot" />
      </Typography>
      <Typography variant="subtitle2">{`${database?.db_root_password}`}</Typography>
     </Stack>
    )}
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="subtitle2" textTransform="uppercase">
      Type
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databaseType" />
     </Typography>
     <Stack direction="row" spacing={1} alignItems="center">
      <RenderDatabaseIcon />
      <Typography variant="subtitle2">{`${database.db_type || "Not available"}`}</Typography>
     </Stack>
    </Stack>
   </Stack>
   <Stack direction="row">
    <Button onClick={handleRemove}>
     <FormattedMessage id="docker.website.removeService" />
    </Button>
    <Button onClick={() => window.open(link, "Database", "width=1000, height=800")}>
     <FormattedMessage id="docker.website.openDatabase" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DatabaseFrame;
