import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { filterAppsInfo } from "helpers/app";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { appsList } from "constants/apps";

import IconSelector from "components/shared/images/IconSelector";

import { postObtainAppsRecap } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAppsInfo } from "types/api/appsApiInterface";

const AppsDashboard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [appsInfo, setAppsInfo] = useState<IAppsInfo | null>(null);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setAppsInfo(await dispatch(postObtainAppsRecap()));
   setLoading(false);
  })();
 }, []);

 const handleNavigate = (link: string) => {
  navigate(link);
 };

 const RenderManageSection = ({ section }: { section: string }) => {
  switch (section) {
   case "website":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="apps.manageSection" />
      </Typography>
      <Typography variant="caption">3</Typography>
     </Stack>
    );
   case "maildomain":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="apps.manageSection" />
      </Typography>
      <Typography variant="caption">2</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderMoreSection = ({ section }: { section: string }) => {
  switch (section) {
   case "maildomain":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="apps.totalMailboxes" />
      </Typography>
      <Typography variant="caption">{appsInfo?.mail.totalMailboxes || "--"}</Typography>
     </Stack>
    );
   case "zone":
    return (
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="caption">
       <FormattedMessage id="apps.totalRecords" />
      </Typography>
      <Typography variant="caption">{appsInfo?.zone.totalRecords || "--"}</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderServerType = ({ section }: { section: string }): ReactElement => {
  switch (section) {
   case "website":
    return (
     <Stack direction="row" spacing={1}>
      <Tooltip title="container-website">
       <Stack>
        <IconSelector icon="DockerIcon" />
       </Stack>
      </Tooltip>
      <Tooltip title="web-server">
       <Stack>
        <IconSelector icon="WebIcon" />
       </Stack>
      </Tooltip>
     </Stack>
    );
   case "maildomain":
    return (
     <Stack direction="row" spacing={1}>
      <Tooltip title="container-mail">
       <Stack>
        <IconSelector icon="DockerIcon" />
       </Stack>
      </Tooltip>
      <Tooltip title="mail-server">
       <Stack>
        <IconSelector icon="AlternateEmailIcon" />
       </Stack>
      </Tooltip>
     </Stack>
    );
   case "zone":
    return (
     <Stack direction="row" spacing={1}>
      <Tooltip title="name-server">
       <Stack>
        <IconSelector icon="LanIcon" />
       </Stack>
      </Tooltip>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return loading ? (
  <Stack px={desktopViewPort ? 20 : 5} pt={10}>
   <LinearProgress color="kxOrange" />
  </Stack>
 ) : (
  <Stack px={desktopViewPort ? 20 : 5} pt={10}>
   <Grid container spacing={2}>
    {appsList.map((element, index) => {
     return (
      <Grid key={`addons-dash-${index}`} xs={desktopViewPort ? "auto" : 12}>
       <Card sx={{ minWidth: desktopViewPort ? 500 : "auto", minHeight: 300 }}>
        <CardHeader
         avatar={
          <IconSelector icon={element.icon} props={{ sx: { fontSize: 40 }, color: "kxOrange" }} />
         }
         action={
          <Stack direction="row" spacing={1}>
           <Tooltip title={<FormattedMessage id={element.addTitle} />}>
            <IconButton aria-label="settings" onClick={() => handleNavigate(element.add)}>
             <IconSelector icon="AddIcon" props={{ color: "kxOrange" }} />
            </IconButton>
           </Tooltip>
           <Tooltip title={<FormattedMessage id="addons.goToSection" />}>
            <IconButton aria-label="settings" onClick={() => handleNavigate(element.link)}>
             <IconSelector icon="ArrowOutwardIcon" props={{ color: "kxOrange" }} />
            </IconButton>
           </Tooltip>
          </Stack>
         }
         title={
          <Typography textTransform="uppercase" fontWeight="bold">
           <FormattedMessage id={element.name} />
          </Typography>
         }
         subheader={`${
          appsInfo ? filterAppsInfo(appsInfo, element.section)?.totalCount : "0"
         } ${intl.formatMessage({ id: element.internElement })}`}
         sx={{ textAlign: "left" }}
        />
        <Divider sx={{ borderColor: "#ff4900", mx: 1 }} />
        <Stack
         px={2}
         py={1}
         maxWidth={desktopViewPort ? 470 : "auto"}
         textAlign="left"
         sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
         <Typography variant="caption">
          <FormattedMessage id={element.sectionMessage} />
         </Typography>
         <Divider />
         {appsInfo && filterAppsInfo(appsInfo, element.section)?.totalCount === 0 ? (
          <Stack justifyContent="center" alignItems="center" mt={4}>
           <IconSelector icon="WarningIcon" props={{ color: "warning" }} />
           <Typography textAlign="center" variant="caption" fontStyle="italic">
            <FormattedMessage id="apps.elementNotFound" />
           </Typography>
          </Stack>
         ) : (
          <Stack pt={1} color="rgba(0, 0, 0, 0.6)">
           <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start">
             <IconSelector icon="WarningIcon" props={{ color: "error", sx: { fontSize: 15 } }} />
             <Typography variant="caption">
              <FormattedMessage id="apps.offlineElement" />
             </Typography>
            </Stack>
            <Typography
             variant="caption"
             color={
              appsInfo && filterAppsInfo(appsInfo, element.section)?.offlineElement === 0
               ? "green"
               : "#FF1800"
             }>
             {appsInfo ? (
              filterAppsInfo(appsInfo, element.section)?.offlineElement === 0 ? (
               <FormattedMessage id="apps.noOfflineElement" />
              ) : (
               filterAppsInfo(appsInfo, element.section)?.offlineElement
              )
             ) : (
              "0"
             )}
            </Typography>
           </Stack>
           <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="flex-start">
             <IconSelector icon="StatusIcon" props={{ color: "success", sx: { fontSize: 15 } }} />
             <Typography variant="caption">
              <FormattedMessage id="apps.onlineElement" />
             </Typography>
            </Stack>
            <Typography variant="caption" color="green">
             {appsInfo ? filterAppsInfo(appsInfo, element.section)?.onlineElement : "--"}
            </Typography>
           </Stack>
           <RenderManageSection section={element.section} />
           <RenderMoreSection section={element.section} />
           <Divider />
           <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
            <Typography variant="caption">
             <FormattedMessage id="apps.availableServers" />
            </Typography>
            <RenderServerType section={element.section} />
           </Stack>
          </Stack>
         )}
        </Stack>
       </Card>
      </Grid>
     );
    })}
   </Grid>
  </Stack>
 );
};

export default AppsDashboard;
