import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postAddNewMailDomainsSync } from "redux/handlers/mailDomainContainerHandler";
import { getAllServerMailbox, getServerByType } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerByTypeAPI } from "types/api/serversApiInterface";

type migrationData = {
 server: number;
 username: string;
 host1: string;
 port1: number;
 user1: string;
 password1: string;
 enc1: string;
 subfolder2: string;
 mins_interval: number;
 maxage: number;
 maxbytespersecond: number;
 timeout1: number;
 timeout2: number;
 exclude: string;
 delete2duplicates: boolean;
 delete1: boolean;
 delete2: boolean;
 automap: boolean;
 skipcrossduplicates: boolean;
 subscribeall: boolean;
};

const CreateNewSync = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [loading, setLoading] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [showSourcePassword, setShowSourcePassword] = useState<string>("password");
 const [mailboxes, setMailboxes] = useState<Array<{ username: string; id: number }>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getServerByType("container-mail")));
   setIsLoading(false);
  })();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, setValue, getValues, formState, watch } = useForm({
  defaultValues: {
   server: 0,
   username: "",
   host1: "",
   port1: 143,
   user1: "",
   password1: "",
   enc1: "SSL",
   subfolder2: "",
   mins_interval: 20,
   maxage: 0,
   maxbytespersecond: 0,
   timeout1: 600,
   timeout2: 600,
   exclude: "",
   delete2duplicates: true,
   delete1: false,
   delete2: false,
   automap: true,
   skipcrossduplicates: false,
   subscribeall: true
  }
 });

 const onSubmit: SubmitHandler<migrationData> = async (data: migrationData) => {
  setLoading(true);
  await dispatch(postAddNewMailDomainsSync(data));
  handleClose();
  setLoading(false);
  navigate("/utility/migration");
 };

 const handleChangeServer = async (id: number) => {
  setMailboxes(await dispatch(getAllServerMailbox(id)));
  setValue("server", id);
 };

 const checkForData = (): boolean => {
  return (
   watch("username") === "" ||
   watch("host1") === "" ||
   watch("password1") === "" ||
   watch("server") === 0 ||
   watch("port1") === 0 ||
   watch("mins_interval") < 1 ||
   watch("mins_interval") > 43800 ||
   watch("maxage") < 0 ||
   watch("maxage") > 32000 ||
   watch("maxbytespersecond") < 0 ||
   watch("maxbytespersecond") > 125000000 ||
   watch("timeout1") > 32000 ||
   watch("timeout1") < 1 ||
   watch("user1") === "" ||
   watch("enc1") === ""
  );
 };

 return (
  <Stack spacing={2} px={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <IconSelector icon="MultipleStopIcon" />
    <Typography>
     <FormattedMessage id={"container.maildomains.createNewSync"} />
    </Typography>
   </Stack>
   {isLoading ? (
    <Stack direction="row" alignItems="center" justifyContent="center">
     <CircularProgress />
    </Stack>
   ) : (
    <Grid container spacing={2}>
     <Grid xs={desktopViewPort ? 5 : 12}>
      <Stack spacing={2}>
       <Stack direction="row" spacing={1}>
        <IconSelector icon="FileUploadIcon" props={{ color: "kxBlue" }} />
        <Typography>
         <FormattedMessage id="utility.mailMigration.sourceBox" />
        </Typography>
       </Stack>
       <Stack spacing={2}>
        <Alert severity="info">
         <Typography>
          <FormattedMessage id="utility.mailMigration.sourceExplain" />
         </Typography>
        </Alert>
        <Controller
         name="host1"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourceHost" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.host1}
           helperText={
            formState.isDirty &&
            !!formState?.errors?.host1 &&
            intl.formatMessage({ id: "utility.mailMigration.invalidHost" })
           }
          />
         )}
        />
        <Controller
         name="port1"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.port" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.port1}
           helperText={
            formState.isDirty && !!formState?.errors?.port1
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.portHelp" })
           }
          />
         )}
        />
        <Controller
         name="user1"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourceUser" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.user1}
           helperText={
            formState.isDirty &&
            !!formState?.errors?.user1 &&
            intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
           }
          />
         )}
        />
        <Controller
         name="password1"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.sourcePassword" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.password1}
           type="password"
           autoComplete="new-password"
           helperText={
            formState.isDirty &&
            !!formState?.errors?.password1 &&
            intl.formatMessage({ id: "utility.mailMigration.invalidPassword" })
           }
           InputProps={{
            endAdornment: (
             <InputAdornment position="end">
              <IconButton
               aria-label="toggle password visibility"
               onClick={() =>
                setShowSourcePassword(showSourcePassword === "password" ? "text" : "password")
               }
               edge="end">
               <IconSelector
                icon={showSourcePassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
                props={{ fontSize: "small" }}
               />
              </IconButton>
             </InputAdornment>
            )
           }}
          />
         )}
        />
        <Controller
         name="enc1"
         control={control}
         rules={{
          required: true
         }}
         render={({ field }) => (
          <FormControl>
           <InputLabel>
            <FormattedMessage id="utility.mailMigration.enc1" />
           </InputLabel>
           <Select
            {...field}
            label={<FormattedMessage id="utility.mailMigration.enc1" />}
            onChange={(e) => setValue("enc1", e.target.value)}>
            <MenuItem key="ssl-key" value="SSL">
             SSL
            </MenuItem>
            <MenuItem key="starttls-key" value="STARTTLS">
             STARTTLS
            </MenuItem>
            <MenuItem key="plain-key" value="PLAIN">
             PLAIN
            </MenuItem>
           </Select>
          </FormControl>
         )}
        />
        <Controller
         name="subfolder2"
         control={control}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           label={intl.formatMessage({ id: "utility.mailMigration.subfolder2" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.subfolder2}
           helperText={
            formState.isDirty && !!formState?.errors?.subfolder2
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.subfolder2Help" })
           }
          />
         )}
        />
        <Controller
         name="delete2duplicates"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("delete2duplicates")}
             onChange={(e) => {
              setValue("delete2duplicates", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.delete2duplicates" })}
          />
         )}
        />
        <Controller
         name="delete1"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("delete1")}
             onChange={(e) => {
              setValue("delete1", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.delete1" })}
          />
         )}
        />
        <Controller
         name="delete2"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("delete2")}
             onChange={(e) => {
              setValue("delete2", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.delete2" })}
          />
         )}
        />
       </Stack>
      </Stack>
     </Grid>
     {desktopViewPort && (
      <Grid xs={2}>
       <Stack justifyContent="center" alignItems="center" height="100%">
        <IconSelector
         icon="ArrowForwardIcon"
         props={{ sx: { fontSize: 100 }, color: "kxOrange" }}
        />
       </Stack>
      </Grid>
     )}
     <Grid xs={desktopViewPort ? 5 : 12}>
      <Stack spacing={2}>
       <Stack direction="row" spacing={1}>
        <IconSelector icon="DownloadIcon" props={{ color: "kxBlue" }} />
        <Typography>
         <FormattedMessage id="utility.mailMigration.destinationBox" />
        </Typography>
       </Stack>
       <Stack spacing={2}>
        <Alert severity="info">
         <Typography>
          <FormattedMessage id="utility.mailMigration.destinationExplain" />
         </Typography>
        </Alert>
        <Controller
         name="server"
         control={control}
         render={({ field }) => (
          <Autocomplete
           fullWidth={true}
           autoHighlight
           sx={{ my: 2 }}
           onChange={(e, value) => {
            handleChangeServer(value?.id || 0);
           }}
           options={servers.map((server) => {
            return { label: server.server_name, id: server.id };
           })}
           value={servers
            .map((element) => {
             return { label: element.server_name, id: element.id };
            })
            .find((server) => server.id === getValues("server"))}
           renderInput={(params) => (
            <TextField
             {...params}
             {...field}
             label={<FormattedMessage id="container.maildomains.serverHost" />}
             error={formState.isDirty && !!formState?.errors?.server}
             InputLabelProps={{ shrink: true }}
            />
           )}
          />
         )}
        />
        <Controller
         name="username"
         control={control}
         render={({ field }) => (
          <Autocomplete
           fullWidth={true}
           autoHighlight
           disabled={watch("server") === 0}
           sx={{ my: 2 }}
           onChange={(e, value) => {
            setValue("username", value?.label || "");
           }}
           options={mailboxes.map((mail) => {
            return { label: mail.username, id: mail.id };
           })}
           value={mailboxes
            .map((element) => {
             return { label: element.username, id: element.id };
            })
            .find((mail) => mail.label === getValues("username"))}
           renderInput={(params) => (
            <TextField
             {...params}
             {...field}
             label={<FormattedMessage id="utility.mailMigration.mailbox" />}
             error={formState.isDirty && !!formState?.errors?.server}
             InputLabelProps={{ shrink: true }}
            />
           )}
          />
         )}
        />
        <Controller
         name="mins_interval"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.minsInterval" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.mins_interval}
           helperText={
            formState.isDirty && !!formState?.errors?.mins_interval
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.minsIntervalHelp" })
           }
          />
         )}
        />
        <Controller
         name="maxage"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.maxage" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.maxage}
           helperText={
            formState.isDirty && !!formState?.errors?.maxage
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.maxageHelp" })
           }
          />
         )}
        />
        <Controller
         name="maxbytespersecond"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.maxBytesPerSecond" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.maxbytespersecond}
           helperText={
            formState.isDirty && !!formState?.errors?.maxbytespersecond
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.maxBytesPerSecondHelp" })
           }
          />
         )}
        />
        <Controller
         name="timeout1"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.timeout1" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.timeout1}
           helperText={
            formState.isDirty && !!formState?.errors?.timeout1
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.timeout1Help" })
           }
          />
         )}
        />
        <Controller
         name="timeout2"
         control={control}
         rules={{ required: true }}
         render={({ field }) => (
          <TextField
           {...field}
           onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            e.stopPropagation();
           }}
           type="number"
           label={intl.formatMessage({ id: "utility.mailMigration.timeout2" })}
           InputLabelProps={{ shrink: true }}
           error={formState.isDirty && !!formState?.errors?.timeout2}
           helperText={
            formState.isDirty && !!formState?.errors?.timeout2
             ? intl.formatMessage({ id: "utility.mailMigration.invalidUser" })
             : intl.formatMessage({ id: "utility.mailMigration.timeout2Help" })
           }
          />
         )}
        />
        <Controller
         name="automap"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("automap")}
             onChange={(e) => {
              setValue("automap", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.automap" })}
          />
         )}
        />
        <Controller
         name="skipcrossduplicates"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("skipcrossduplicates")}
             onChange={(e) => {
              setValue("skipcrossduplicates", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.skipcrossduplicates" })}
          />
         )}
        />
        <Controller
         name="subscribeall"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             checked={watch("subscribeall")}
             onChange={(e) => {
              setValue("subscribeall", e.target.checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "utility.mailMigration.subscribeall" })}
          />
         )}
        />
       </Stack>
      </Stack>
     </Grid>
    </Grid>
   )}
   <Stack direction="row" alignItems="center" justifyContent="center">
    <Button variant="kxFilledActionButton" disabled={checkForData()} onClick={handleOpen}>
     <FormattedMessage id="app.confirm" />
    </Button>
    <AppModal
     open={open}
     close={handleClose}
     title={intl.formatMessage({ id: "utility.mailMigration.startMigration" })}
     handleClose={handleClose}
     disabled={loading || checkForData()}
     handleConfirm={handleSubmit(onSubmit)}>
     <Typography>
      <FormattedMessage id="utility.mailMigration.startMigrationMessage" />
     </Typography>
    </AppModal>
   </Stack>
  </Stack>
 );
};

export default CreateNewSync;
