import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const getUtilities = (state: AppState) => state.utilities;

export const getExternalDatabasesNumber = createSelector(getUtilities, (utility) => {
 return utility?.external_databases.totalCount || 0;
});

export const getExternalDatabases = createSelector(getUtilities, (utility) => {
 return utility?.external_databases.dataset || [];
});

export const getEmailMigrations = createSelector(getUtilities, (utility) => {
 return utility?.mail_migrations.dataset || [];
});

export const getEmailMigrationsNumber = createSelector(getUtilities, (utility) => {
 return utility?.mail_migrations.totalCount || 0;
});
