import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { serverOrders } from "constants/tables";

import SortingTable from "components/shared/tables/SortingTable";

import { getAllServerOrders, getAllServers } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { OrdersAPI } from "types/api/serversApiInterface";

const ServersOrders = (): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const [currentList, setCurrentList] = useState<OrdersAPI>({ dataset: [], totalCount: 0 });
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_orders", async (value): Promise<void> => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_orders");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setCurrentList(await dispatch(getAllServerOrders(0, 10, searchRef?.current?.value)));
    await dispatch(getAllServers(0, 10));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  (async () => {
   setCurrentList(await dispatch(getAllServerOrders(0, 10)));
  })();
 }, []);

 return currentList.dataset.length > 0 ? (
  <Stack mt={5}>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="app.servers.orders" />
     {` (${currentList.totalCount})`}
    </Typography>
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Divider sx={{ borderColor: "#ff4900" }} />
    <Stack>
     <SortingTable
      tableData={serverOrders}
      maxWidth={650}
      caller="serverOrders"
      listToShow={currentList.dataset}
     />
    </Stack>
   </Paper>
  </Stack>
 ) : (
  <></>
 );
};

export default ServersOrders;
