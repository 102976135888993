import React, { ReactElement, useEffect } from "react";

interface GoogleLoginProps {
 onSuccess: (response: any) => void;
 onError?: () => void;
 method: "signin_with" | "signup_with";
}

const GoogleLogin = ({ onSuccess, onError, method }: GoogleLoginProps): ReactElement => {
 useEffect(() => {
  // Ensure Google SDK is available
  if (!window.google) return;

  // Initialize Google Sign-In
  window.google.accounts.id.initialize({
   client_id: "3430589632-ca8vh6sl31fd7b7t8628hsqsesu3phbj.apps.googleusercontent.com", // Use an environment variable
   callback: onSuccess,
   use_fedcm_for_prompt: true
  });

  // Render Google Sign-In button
  window.google.accounts.id.renderButton(document.getElementById("google-login-btn"), {
   size: "large",
   text: method,
   shape: "pill",
   type: "icon"
  });

  // Prompt one-tap login
  window.google.accounts.id.prompt();
 }, [onSuccess]);

 return <div id="google-login-btn"></div>;
};

export default GoogleLogin;
