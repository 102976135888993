import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { ipRegex } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postUpdateFail2ban } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

type fail2banType = {
 ban_time: number;
 ban_time_increment: boolean;
 max_bantime: number;
 max_attempts: number;
 retry_window: number;
 netban_ipv4: number;
 netban_ipv6: number;
 whitelist: Array<string>;
 blacklist: Array<string>;
};

const EditFail2ban = ({
 mailData,
 closeUpdate
}: {
 mailData: IContainerMailDomainData;
 closeUpdate: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);
 const [error, setError] = useState<string>("");
 const [newIp, setNewIp] = useState<Array<string>>([]);

 useEffect(() => {
  reset();
 }, []);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, reset, watch, setValue } = useForm({
  defaultValues: {
   ban_time: mailData.fail2ban.ban_time,
   ban_time_increment: mailData.fail2ban.ban_time_increment,
   max_bantime: mailData.fail2ban.max_ban_time,
   max_attempts: mailData.fail2ban.max_attempts,
   retry_window: mailData.fail2ban.retry_window,
   netban_ipv4: mailData.fail2ban.netban_ipv4,
   netban_ipv6: mailData.fail2ban.netban_ipv6,
   whitelist: mailData.fail2ban.whitelist,
   blacklist: mailData.fail2ban.blacklist
  }
 });

 const onSubmit: SubmitHandler<fail2banType> = async (data: fail2banType) => {
  setLoading(true);
  await dispatch(
   postUpdateFail2ban(
    mailData.id,
    Number(data.ban_time),
    data.ban_time_increment,
    Number(data.max_bantime),
    Number(data.netban_ipv4),
    Number(data.netban_ipv6),
    Number(data.max_attempts),
    Number(data.retry_window),
    data.whitelist,
    data.blacklist
   )
  );
  setLoading(false);
  closeUpdate();
 };

 const renderError = () => {
  switch (error) {
   case "length":
    return "addons.firewall.ipLengthError";
   case "format":
    return "addons.firewall.ipFormatError";
   case "void":
    return "addons.firewall.ipVoidError";
   case "existing":
    return "addons.firewall.ipExistingError";
   default:
    return "";
  }
 };

 const handleDeleteIp = (ipToDelete: string, list: "whitelist" | "blacklist") => {
  if (watch(list).length > 1) {
   const updatedList = watch(list).filter((item: string) => item !== ipToDelete);
   setError("");
   setValue(list, updatedList);
  }
 };

 const handleKeyDown = (event: any, list: "whitelist" | "blacklist") => {
  if (event.key === "Enter") {
   const index = list === "whitelist" ? 0 : 1;
   if (
    newIp[index] !== "" &&
    !watch(list).includes(
     `${newIp[index]}/${newIp[index] === "0.0.0.0" || newIp[index] === "::" ? "0" : "32"}`
    )
   ) {
    if (ipRegex.test(newIp[index])) {
     let newData = watch(list);
     newData.push(
      `${newIp[index]}/${newIp[index] === "0.0.0.0" || newIp[index] === "::" ? "0" : "32"}`
     );
     setValue(list, newData);
     setNewIp(["", ""]);
     setError("");
    } else {
     setError("format");
    }
   } else {
    if (newIp[index] === "") {
     setError("void");
    } else {
     setError("existing");
    }
   }
  }
 };

 return (
  <Stack textAlign="left" p={2} pt={0}>
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
     Ban time
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Controller
    name="ban_time"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      label={intl.formatMessage({ id: "container.maildomains.banTime" })}
      error={watch("ban_time") < 0 || !watch("ban_time")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("ban_time") < 0 || !watch("ban_time")
        ? intl.formatMessage({ id: "container.maildomains.banTimeError" })
        : intl.formatMessage({ id: "container.maildomains.banTimeExplain" })
      }
     />
    )}
   />
   <Controller
    name="ban_time_increment"
    control={control}
    render={({ field }) => (
     <FormControlLabel
      {...field}
      control={
       <Checkbox
        checked={watch("ban_time_increment")}
        onChange={(e) => {
         setValue("ban_time_increment", e.target.checked);
        }}
       />
      }
      label={intl.formatMessage({ id: "container.maildomains.banTimeIncrement" })}
     />
    )}
   />
   <Controller
    name="max_bantime"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      label={intl.formatMessage({ id: "container.maildomains.maxBanTime" })}
      error={watch("max_bantime") < 0 || !watch("max_bantime")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("max_bantime") < 0 || !watch("max_bantime")
        ? intl.formatMessage({ id: "container.maildomains.maxBanTimeError" })
        : intl.formatMessage({ id: "container.maildomains.maxBanTimeExplain" })
      }
     />
    )}
   />
   <Controller
    name="max_attempts"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      label={intl.formatMessage({ id: "container.maildomains.maxAttempts" })}
      error={watch("max_attempts") < 0 || !watch("max_attempts")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("max_attempts") < 0 || !watch("max_attempts")
        ? intl.formatMessage({ id: "container.maildomains.maxAttemptsError" })
        : intl.formatMessage({ id: "container.maildomains.maxAttemtsExplain" })
      }
     />
    )}
   />
   <Controller
    name="retry_window"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      label={intl.formatMessage({ id: "container.maildomains.retryWindow" })}
      error={watch("retry_window") < 0 || !watch("retry_window")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("retry_window") < 0 || !watch("retry_window")
        ? intl.formatMessage({ id: "container.maildomains.retryWindowError" })
        : intl.formatMessage({ id: "container.maildomains.retryWindowExplain" })
      }
     />
    )}
   />
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
     Net Ban
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Controller
    name="netban_ipv4"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      InputProps={{ startAdornment: "/" }}
      label={intl.formatMessage({ id: "container.maildomains.netbanIpv4" })}
      error={watch("netban_ipv4") < 8 || watch("netban_ipv4") > 32 || !watch("netban_ipv4")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("netban_ipv4") < 8 || watch("netban_ipv4") > 32 || !watch("netban_ipv4")
        ? intl.formatMessage({ id: "container.maildomains.netbanIpv4Error" })
        : intl.formatMessage({ id: "container.maildomains.netbanIpv4Explain" })
      }
     />
    )}
   />
   <Controller
    name="netban_ipv6"
    control={control}
    rules={{
     required: true
    }}
    render={({ field }) => (
     <TextField
      {...field}
      fullWidth={true}
      type="number"
      size="small"
      InputProps={{ startAdornment: "/" }}
      label={intl.formatMessage({ id: "container.maildomains.netbanIpv6" })}
      error={watch("netban_ipv6") < 8 || watch("netban_ipv6") > 128 || !watch("netban_ipv6")}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 2 }}
      autoComplete="off"
      helperText={
       watch("netban_ipv6") < 8 || watch("netban_ipv6") > 128 || !watch("netban_ipv6")
        ? intl.formatMessage({ id: "container.maildomains.netbanIpv6Error" })
        : intl.formatMessage({ id: "container.maildomains.netbanIpv6Explain" })
      }
     />
    )}
   />
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
     Lists
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Stack alignItems="flex-start" pt={1}>
    <Typography variant="caption" fontWeight="bold">
     <FormattedMessage id="container.maildomains.whitelist" />
    </Typography>
    <Stack direction="row" flexWrap="wrap" mb={1}>
     {watch("whitelist").map((ip: string, key: number) => {
      return (
       <Chip
        key={`ip-rule-${key}`}
        size="small"
        label={ip}
        sx={{ mr: 0.5, mt: 0.5 }}
        onClick={() => handleDeleteIp(ip, "whitelist")}
        onDelete={() => handleDeleteIp(ip, "whitelist")}
       />
      );
     })}
    </Stack>
    <TextField
     label={intl.formatMessage({ id: "addons.firewall.insertIps" })}
     variant="standard"
     fullWidth
     value={newIp[0]}
     onChange={(e) => setNewIp([e.target.value, ""])}
     onKeyDown={(e) => handleKeyDown(e, "whitelist")}
     error={error !== ""}
     helperText={error !== "" ? <FormattedMessage id={renderError()} /> : ""}
    />
   </Stack>
   <Stack alignItems="flex-start" pt={2}>
    <Typography variant="caption" fontWeight="bold">
     <FormattedMessage id="container.maildomains.blacklist" />
    </Typography>
    <Stack direction="row" flexWrap="wrap" mb={1}>
     {watch("blacklist").map((ip: string, key: number) => {
      return (
       <Chip
        key={`ip-rule-${key}`}
        size="small"
        label={ip}
        sx={{ mr: 0.5, mt: 0.5 }}
        onClick={() => handleDeleteIp(ip, "blacklist")}
        onDelete={() => handleDeleteIp(ip, "blacklist")}
       />
      );
     })}
    </Stack>
    <TextField
     label={intl.formatMessage({ id: "addons.firewall.insertIps" })}
     variant="standard"
     fullWidth
     value={newIp[1]}
     onChange={(e) => setNewIp(["", e.target.value])}
     onKeyDown={(e) => handleKeyDown(e, "blacklist")}
     error={error !== ""}
     helperText={error !== "" ? <FormattedMessage id={renderError()} /> : ""}
    />
   </Stack>
   <Stack direction="row" spacing={2} pt={2}>
    <Button disabled={loading} onClick={handleOpen}>
     <FormattedMessage id="app.edit" />
    </Button>
    <Button onClick={closeUpdate}>
     <FormattedMessage id="app.back" />
    </Button>
   </Stack>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.updateFail2ban" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading}>
    <FormattedMessage id="container.maildomains.updateFail2banMessage" />
   </AppModal>
  </Stack>
 );
};

export default EditFail2ban;
