import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { externalDatabasesTableData } from "types/global/table";

import DeleteExternalDatabase from "./actions/DeleteExternalDatabase";

const ExternalDatabasesMenu = ({
 rowData
}: {
 rowData: externalDatabasesTableData;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <MenuItem
      onClick={() =>
       window.open(
        `http://${rowData.db_host_phpmyadmin}:9089`,
        "Database",
        "width=1000, height=800"
       )
      }>
      <FormattedMessage id="utility.externalDatabase.openDatabase" />
     </MenuItem>
     <DeleteExternalDatabase id={rowData.server_id_db} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default ExternalDatabasesMenu;
