import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getRepositorys } from "redux/handlers/dockerHandler";
import { getEnviromentVersions, postInstallRedis } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerRepo, IWebAppEnviromentVersion } from "types/api/dockerApiInterface";

const InstallRedis = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [plugins, setPlugins] = useState<IWebAppEnviromentVersion | null>(null);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedVersion, setSelectedVersion] = useState<string>("");
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);

 const handleOpen = async () => {
  setRepository(await dispatch(getRepositorys()));
  setPlugins(await dispatch(getEnviromentVersions("plugin")));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postInstallRedis(
    id,
    repository.find((element) => element.name.includes("redis"))?.name || "",
    selectedVersion
   )
  );
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.installPlugin" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="InstallDesktopIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.installPlugin" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack>
     <FormControl fullWidth>
      <InputLabel>
       <FormattedMessage id="docker.website.redisVersion" />
      </InputLabel>
      <Select
       disabled={loading}
       value={selectedVersion}
       label={<FormattedMessage id="docker.website.redisVersion" />}
       onChange={(e) => setSelectedVersion(e.target.value)}>
       {plugins?.versions
        .filter((plugin) => plugin.enviroment === "redis")
        .map((element, index) => {
         return (
          <MenuItem value={element.version} key={`redis-vers-${index}`}>
           {element.version}
          </MenuItem>
         );
        })}
      </Select>
     </FormControl>
    </Stack>
   </AppModal>
  </>
 );
};

export default InstallRedis;
