import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import AppModal from "components/shared/modal/AppModal";

const ShowSshKey = ({ ssh_key }: { ssh_key: string }): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [open, setOpen] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 return (
  <>
   <Typography
    fontSize={12}
    minWidth={20}
    onClick={handleOpen}
    variant="kxColoredSmall"
    sx={{ cursor: "pointer", textDecoration: "underline" }}>
    <FormattedMessage id="docker.website.showSshKey" />
   </Typography>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.showSshKey" })}
    handleClose={handleClose}
    handleConfirm={handleClose}
    showConfirmButton={false}
    customSize={
     desktopViewPort
      ? {
         minWidth: 900
        }
      : {}
    }>
    <Stack spacing={1}>
     <TextField
      multiline
      maxRows={20}
      autoFocus
      minRows={6}
      fullWidth
      InputLabelProps={{ shrink: true }}
      value={ssh_key}
     />
     <ClickToCopy text={ssh_key} isButton buttonText="Copia" />
    </Stack>
   </AppModal>
  </>
 );
};

export default ShowSshKey;
