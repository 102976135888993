import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getSpamfilterData, postUpdateMailDomain } from "redux/handlers/mailsHandler";

import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamSelector } from "types/api/mailApiInterface";

const MailDetails = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [section, setSection] = useState<string>("");
 const [spamData, setSpamData] = useState<Array<ISpamSelector>>([]);

 useEffect(() => {
  (async () => {
   const spamArray = await getSpamfilterData();
   spamArray.push({ id: 0, policy_id: 0, policy_name: "-spamfilter disabled-" });
   setSpamData(spamArray);
   setIsLoading(false);
  })();
 }, []);

 const handleOpen = (section: string) => {
  setSection(section);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const onSubmit: SubmitHandler<{ spamfilter: number }> = async (data: { spamfilter: number }) => {
  setIsLoading(true);
  await dispatch(
   postUpdateMailDomain(
    maildetails?.id || 0,
    maildetails?.isp_id || 0,
    maildetails?.dkim || false,
    data.spamfilter,
    maildetails?.domain || ""
   )
  );
  setIsLoading(false);
  handleClose();
 };

 const { control, formState, getValues, setValue, handleSubmit } = useForm({
  defaultValues: {
   spamfilter: 0
  }
 });

 const formatDkim = (dkim: string): ReactElement => {
  const dkimSplitted = dkim.split("\n");
  return (
   <Stack sx={{ overflowWrap: "break-word" }}>
    {dkimSplitted.map((line, index) => (
     <Typography key={index}>
      {line}
      {index < dkimSplitted.length - 1 && <br />}
     </Typography>
    ))}
   </Stack>
  );
 };

 const RenderModal = (): ReactElement => {
  const formattedPrivate = maildetails?.dkim_private
   .replaceAll(/(?:\r\n|\r|\n|\s)/g, "")
   .replace("-----BEGINRSAPRIVATEKEY-----", "")
   .replace("-----ENDRSAPRIVATEKEY-----", "");
  const formattedPublic = maildetails?.dkim_public
   .replaceAll(/(?:\r\n|\r|\n|\s)/g, "")
   .replace("-----BEGINPUBLICKEY-----", "")
   .replace("-----ENDPUBLICKEY-----", "");

  switch (section) {
   case "privateDkim":
    return (
     <Stack spacing={1}>
      <Typography>{formatDkim(maildetails?.dkim_private || "")}</Typography>
      <ClickToCopy text={formattedPrivate || ""} isButton buttonText="Copia dkim" />
     </Stack>
    );
   case "publicDkim":
    return (
     <Stack spacing={1}>
      <Typography>{formatDkim(maildetails?.dkim_public || "")}</Typography>
      <ClickToCopy text={formattedPublic || ""} isButton buttonText="Copia dkim" />
     </Stack>
    );
   case "spamfilter":
    return (
     <Stack justifyContent="center" alignContent="center" direction="column">
      <Controller
       name="spamfilter"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) =>
          isNil(value) ? setValue("spamfilter", 0) : setValue("spamfilter", value.id)
         }
         options={spamData.map((spam) => {
          return { label: spam.policy_name, id: spam.policy_id };
         })}
         value={spamData
          .map((spam) => {
           return { label: spam.policy_name, id: spam.policy_id };
          })
          .find((spamfiltered) => getValues("spamfilter") === spamfiltered.id)}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           label={<FormattedMessage id="mails.spamFilter" />}
           error={formState.isDirty && !!formState?.errors?.spamfilter}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const handleSwitchToggle = async (value: boolean, type: string) => {
  switch (type) {
   case "dkim":
    await dispatch(
     postUpdateMailDomain(
      maildetails?.id || 0,
      maildetails?.isp_id || 0,
      value,
      maildetails?.policy_id || 0,
      //maildetails?.policy_id || 0,
      maildetails?.domain || ""
     )
    );
    break;
   default:
    break;
  }
 };

 return (
  <>
   <Stack>
    <Alert severity="info">
     <FormattedMessage id="mails.mailDetailsMessage" />
    </Alert>
    <Stack
     direction="row"
     alignItems="center"
     justifyContent="space-between"
     spacing={2}
     pr={2}
     py={0.5}
     pt={2}>
     <Stack direction="row" alignItems="center" spacing={2}>
      <Typography fontWeight="bold" textAlign="start" variant="subtitle1" px={2} minWidth={150}>
       <FormattedMessage id="mails.spamFilter" />
      </Typography>
      <Typography>
       {spamData.find((spam) => spam.policy_id === maildetails?.policy_id)?.policy_name ||
        "-spamfilter disabled-"}
      </Typography>
     </Stack>
     <Tooltip title={<FormattedMessage id="mails.changeSpamfilter" />}>
      <IconButton onClick={() => handleOpen("spamfilter")}>
       <IconSelector icon="EditIcon" />
      </IconButton>
     </Tooltip>
    </Stack>
    <Divider />
    <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.changeDkim" />
     </Typography>
     <Switch
      defaultChecked={maildetails?.dkim}
      onChange={(event) => handleSwitchToggle(event.target.checked, "dkim")}
     />
    </Stack>
    <Divider />
    <Stack direction="row" alignItems="center" spacing={2} py={0.5} pr={2}>
     <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
      <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
       <FormattedMessage id="mails.dkimSelector" />
      </Typography>
     </Stack>
     {!maildetails?.dkim ? (
      <Typography>
       <FormattedMessage id="mails.dkimSelectorNotAvailable" />
      </Typography>
     ) : (
      <Typography>{`mail._domainkey.${maildetails?.domain}`}</Typography>
     )}
    </Stack>
    <Divider />
    <Stack direction="row" alignItems="center" py={0.5} pr={2}>
     <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
      <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
       <FormattedMessage id="mails.publicDkim" />
      </Typography>
     </Stack>
     {desktopViewPort ? (
      <Button
       variant="kxActionButton"
       disabled={!maildetails?.dkim}
       onClick={() => handleOpen("publicDkim")}>
       <FormattedMessage id="mails.showPublicDkim" />
      </Button>
     ) : (
      <IconButton
       color="kxBlue"
       disabled={!maildetails?.dkim}
       onClick={() => handleOpen("publicDkim")}>
       <IconSelector icon="VisibilityIcon" />
      </IconButton>
     )}
    </Stack>
    <Divider />
    <Stack direction="row" alignItems="center" py={0.5} pr={2}>
     <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
      <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
       <FormattedMessage id="mails.privateDkim" />
      </Typography>
     </Stack>
     {desktopViewPort ? (
      <Button
       variant="kxActionButton"
       disabled={!maildetails?.dkim}
       onClick={() => handleOpen("privateDkim")}>
       <FormattedMessage id="mails.showPrivateDkim" />
      </Button>
     ) : (
      <IconButton
       disabled={!maildetails?.dkim}
       onClick={() => handleOpen("privateDkim")}
       color="kxBlue">
       <IconSelector icon="VisibilityIcon" />
      </IconButton>
     )}
    </Stack>
    <AppModal
     showConfirmButton={section !== "publicDkim" && section !== "privateDkim"}
     open={openModal}
     close={handleClose}
     loading={isLoading}
     title={intl.formatMessage({ id: "mails.operations" })}
     handleClose={handleClose}
     handleConfirm={handleSubmit(onSubmit)}>
     <RenderModal />
    </AppModal>
   </Stack>
  </>
 );
};

export default MailDetails;
