import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const VueIcon = createSvgIcon(
 <svg
  width="800px"
  height="800px"
  viewBox="0 0 64 64"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlSpace="preserve"
  style={{
   fillRule: "evenodd",
   clipRule: "evenodd",
   strokeLinejoin: "round",
   strokeMiterlimit: 2
  }}>
  <rect id="Icons" x="-512" y="-128" width="1280" height="800" style={{ fill: "none" }} />

  <g id="Icons1">
   <g id="Strike"></g>

   <g id="H1"></g>

   <g id="H2"></g>

   <g id="H3"></g>

   <g id="list-ul"></g>

   <g id="hamburger-1"></g>

   <g id="hamburger-2"></g>

   <g id="list-ol"></g>

   <g id="list-task"></g>

   <g id="trash"></g>

   <g id="vertical-menu"></g>

   <g id="horizontal-menu"></g>

   <g id="sidebar-2"></g>

   <g id="Pen"></g>

   <g id="Pen1"></g>

   <g id="clock"></g>

   <g id="external-link"></g>

   <g id="hr"></g>

   <g id="info"></g>

   <g id="warning"></g>

   <g id="plus-circle"></g>

   <g id="minus-circle"></g>

   <g id="vue">
    <path
     d="M17.595,11.204l8.91,0l5.536,9.391l5.591,-9.391l8.831,0l-14.422,25.359l-14.446,-25.359Z"
     style={{ fill: "#435466" }}
    />

    <path
     d="M8.089,11.204l23.952,41.845l24.126,-41.845l-9.704,0l-14.422,25.359l-14.446,-25.359l-9.506,0Z"
     style={{ fill: "#4dba87" }}
    />
   </g>

   <g id="cog"></g>

   <g id="logo"></g>

   <g id="radio-check"></g>

   <g id="eye-slash"></g>

   <g id="eye"></g>

   <g id="toggle-off"></g>

   <g id="shredder"></g>

   <g id="spinner--loading--dots-"></g>

   <g id="react"></g>

   <g id="check-selected"></g>

   <g id="turn-off"></g>

   <g id="code-block"></g>

   <g id="user"></g>

   <g id="coffee-bean"></g>

   <g id="coffee-beans">
    <g id="coffee-bean1"></g>
   </g>

   <g id="coffee-bean-filled"></g>

   <g id="coffee-beans-filled">
    <g id="coffee-bean2"></g>
   </g>

   <g id="clipboard"></g>

   <g id="clipboard-paste"></g>

   <g id="clipboard-copy"></g>

   <g id="Layer1"></g>
  </g>
 </svg>,
 "Vue"
);
