import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { wordpressAppSteps } from "constants/apps";
import { domainValidation } from "constants/regexp";
import { wordpressPhpVersionLimiter } from "constants/webApp";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 getDockerDbTypes,
 getDockerDbVersions,
 getDockerProxy,
 getRepositorys,
 getTypeVersions
} from "redux/handlers/dockerHandler";
import { generatePassword } from "redux/handlers/userHandler";
import { getServerByType } from "redux/handlers/utilityHandler";
import { getEnviromentVersions, postCreateWordpressWebApp } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

import {
 IDockerRepo,
 IDockerTypeVersions,
 IWebAppEnviromentVersion
} from "types/api/dockerApiInterface";
import { IServerByTypeAPI } from "types/api/serversApiInterface";

import WordpressWebAppRecap from "./webAppWizardComponents/WordpressWebAppRecap";

const WordpressWebAppWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [appVers, setAppVers] = useState<IDockerTypeVersions | null>(null);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [dbVersions, setDbVersions] = useState<Array<{ id: number; name: string }>>([]);
 const [dbTypes, setDbTypes] = useState<Array<{ id: number; name: string }>>([]);
 const [showPassword, setShowPassword] = useState<string>("password");
 const [cms, setCms] = useState<IWebAppEnviromentVersion | null>();
 const [proxyNotFound, setProxyNotFound] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setAppVers(await dispatch(getTypeVersions()));
   setRepository(await dispatch(getRepositorys()));
   setServers(await dispatch(getServerByType("container-website")));
   setCms(await dispatch(getEnviromentVersions("cms")));
   setDbTypes(await dispatch(getDockerDbTypes()));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   server: 0,
   site_name: "",
   wp_version: "",
   app_type: "php",
   app_version: "",
   db_name: "",
   db_type: "",
   db_version: "",
   db_user: "",
   db_password: ""
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const selectRepo = (vers: string): string => {
  if (vers.includes("tomcat")) {
   return repository.find((element) => element.name.includes("tomcat"))?.name || "";
  } else if (vers.includes("nginx")) {
   return repository.find((element) => element.name.includes("nginx"))?.name || "";
  } else if (vers.includes("apache")) {
   return repository.find((element) => element.name.includes("apache"))?.name || "";
  } else if (vers.includes("odoo")) {
   return repository.find((element) => element.name.includes("odoo"))?.name || "";
  } else {
   return repository[0].name;
  }
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateWordpressWebApp(
    watch("server"),
    watch("site_name"),
    watch("app_version"),
    selectRepo(watch("app_version")),
    watch("db_name"),
    watch("db_user"),
    watch("db_password"),
    watch("db_type"),
    watch("db_version"),
    watch("wp_version")
   )
  );
  setLoading(false);
  handleClose();
  navigate(`/servers/manage/${watch("server")}/container`);
  handleReset();
 };

 const handleGeneratePassword = async () => {
  setValue("db_password", await generatePassword());
 };

 const handleChangeDatabase = async (type: string) => {
  setDbVersions(await dispatch(getDockerDbVersions(type)));
  setValue("db_type", type);
  setValue("db_version", "");
 };

 const handleChangeServer = async (id: number) => {
  setProxyNotFound(false);
  const containerList = await dispatch(getDockerProxy(id, 0, 10));
  if (containerList.totalCount === 0) setProxyNotFound(true);
  setValue("server", id);
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="server"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         handleChangeServer(value?.id || 0);
        }}
        options={servers.map((server) => {
         return { label: server.server_name, id: server.id };
        })}
        value={servers
         .map((element) => {
          return { label: element.server_name, id: element.id };
         })
         .find((server) => server.id === getValues("server"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="docker.website.add.serverHost" />}
          error={formState.isDirty && !!formState?.errors?.server}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="site_name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "docker.website.add.siteName" })}
        error={formState.isDirty && !!formState?.errors?.site_name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.site_name &&
         intl.formatMessage({ id: "login.passwordMinLength" })
        }
       />
      )}
     />
    );
   case 2:
    return (
     <Controller
      name="wp_version"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <FormControl fullWidth>
        <InputLabel>
         <FormattedMessage id="docker.website.wordpressVersion" />
        </InputLabel>
        <Select
         {...field}
         value={watch("wp_version")}
         label={<FormattedMessage id="docker.website.wordpressVersion" />}
         onChange={(e) => setValue("wp_version", e.target.value)}>
         {cms?.versions
          .filter((cms) => cms.enviroment === "wordpress")
          .map((element, index) => {
           return (
            <MenuItem value={element.version} key={`wp-vers-${index}`}>
             {element.version}
            </MenuItem>
           );
          })}
        </Select>
       </FormControl>
      )}
     />
    );
   case 3:
    return appVers ? (
     <Controller
      name="app_version"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("app_version", value?.label || "");
        }}
        options={appVers?.versions
         .filter(
          (element) =>
           element.name.includes(watch("app_type")) &&
           !element.name.includes("litespeed") &&
           !element.name.includes(
            wordpressPhpVersionLimiter.find((wpvers) => wpvers.wp_version === watch("wp_version"))
             ?.ph_not_wanted || ""
           )
         )
         .map((app) => {
          return { label: app.name, id: app.id };
         })}
        value={appVers?.versions
         .map((element) => {
          return { label: element.name, id: element.id };
         })
         .find((app) => app.label === getValues("app_version"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="docker.website.add.appVersion" />}
          error={formState.isDirty && !!formState?.errors?.app_version}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    ) : (
     <></>
    );
   case 4:
    return (
     <Stack spacing={2}>
      <Controller
       name="db_name"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "docker.website.databaseName" })}
         error={formState.isDirty && !!formState?.errors?.db_name}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.db_name &&
          intl.formatMessage({ id: "login.passwordMinLength" })
         }
        />
       )}
      />
      <Controller
       name="db_type"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <FormControl fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.website.databaseType" />
         </InputLabel>
         <Select
          {...field}
          value={watch("db_type")}
          label={<FormattedMessage id="docker.website.databaseType" />}
          onChange={(e) => handleChangeDatabase(e.target.value)}>
          {dbTypes
           .filter((db) => db.name !== "postgres")
           .map((element, index) => {
            return (
             <MenuItem value={element.name} key={`db-type-${index}`}>
              {element.name}
             </MenuItem>
            );
           })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="db_version"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <FormControl fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.website.databaseVersion" />
         </InputLabel>
         <Select
          {...field}
          disabled={watch("db_type") === ""}
          value={watch("db_version")}
          label={<FormattedMessage id="docker.website.databaseVersion" />}
          onChange={(e) => setValue("db_version", e.target.value)}>
          {dbVersions.map((element, index) => {
           return (
            <MenuItem value={element.name} key={`db-vers-${index}`}>
             {element.name}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
     </Stack>
    );
   case 5:
    return (
     <Stack>
      <Controller
       name="db_user"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "docker.website.databaseUser" })}
         error={formState.isDirty && !!formState?.errors?.db_user}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.db_user &&
          intl.formatMessage({ id: "login.passwordMinLength" })
         }
        />
       )}
      />
      <Stack direction="row" alignItems="center" spacing={2}>
       <Controller
        name="db_password"
        control={control}
        rules={{
         required: true
        }}
        render={({ field }) => (
         <TextField
          {...field}
          fullWidth={true}
          label={intl.formatMessage({ id: "docker.website.databasePassword" })}
          error={formState.isDirty && !!formState?.errors?.db_password}
          InputLabelProps={{ shrink: true }}
          sx={{ my: 2 }}
          autoComplete="new-password"
          type={showPassword}
          helperText={
           formState.isDirty &&
           !!formState?.errors?.db_password &&
           intl.formatMessage({ id: "login.passwordMinLength" })
          }
          InputProps={{
           endAdornment: (
            <InputAdornment position="end">
             <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(showPassword === "password" ? "text" : "password")}
              edge="end">
              <IconSelector
               icon={showPassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
               props={{ fontSize: "small" }}
              />
             </IconButton>
            </InputAdornment>
           )
          }}
         />
        )}
       />
       <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
        <FormattedMessage id="app.generatePassword" />
       </Button>
      </Stack>
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("server") === 0 || proxyNotFound;
   case 1:
    return watch("site_name") === "" || !domainValidation.test(watch("site_name"));
   case 2:
    return watch("wp_version") === "";
   case 3:
    return watch("app_version") === "";
   case 4:
    return watch("db_name") === "" || watch("db_type") === "" || watch("db_version") === "";
   case 5:
    return watch("db_user") === "" || watch("db_password") === "";
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("server") === 0 ||
   proxyNotFound ||
   watch("site_name") === "" ||
   watch("wp_version") === "" ||
   !domainValidation.test(watch("site_name")) ||
   watch("app_version") === "" ||
   watch("db_name") === "" ||
   watch("db_type") === "" ||
   watch("db_version") === "" ||
   watch("db_user") === "" ||
   watch("db_password") === ""
  );
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "app", url: "/app" },
      { name: "sites", url: "/app/sites" },
      { name: "add", url: "/app/sites/add" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <IconSelector icon="WordpressIcon" />
     <Typography>
      <FormattedMessage id={"docker.website.add.createWordpressApp"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {wordpressAppSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 0 && proxyNotFound && (
            <Alert severity="error">
             <Typography>
              <FormattedMessage id="docker.website.noProxyFound" />
             </Typography>
            </Alert>
           )}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== wordpressAppSteps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 5 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="app.wizard.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="app.wizard.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="app.wizard.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        </Step>
       ))}
      </Stepper>
     </Box>
     <WordpressWebAppRecap
      server={servers.find((element) => element.id === watch("server"))?.server_name || ""}
      site={watch("site_name")}
      type={watch("app_type")}
      version={watch("app_version")}
      wp_version={watch("wp_version")}
      db_name={watch("db_name")}
      db_type={watch("db_type")}
      db_version={watch("db_version")}
      db_user={watch("db_user")}
      db_password={watch("db_password")}
     />
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "docker.website.add.createNewApp" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack>
      <FormattedMessage id="docker.website.add.confirmCreation" />
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default WordpressWebAppWizard;
