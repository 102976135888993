import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { maildomainAppSteps } from "constants/apps";
import { domainValidation } from "constants/regexp";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postCreateContainerMailDomain } from "redux/handlers/mailDomainContainerHandler";
import { getServerByType } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerByTypeAPI } from "types/api/serversApiInterface";

import MailDomainContainerRecap from "./mailDomainWizardComponents/MailDomainContainerRecap";

const MailDomainContainerWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getServerByType("container-mail")));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   server: 0,
   name: "",
   comment: "",
   max_aliases: 1,
   max_quota: 100,
   max_users: 1
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateContainerMailDomain(
    watch("server"),
    watch("name"),
    watch("comment"),
    Number(watch("max_users")),
    Number(watch("max_aliases")),
    watch("max_quota")
   )
  );
  setLoading(false);
  handleClose();
  navigate(`/servers/manage/${watch("server")}/container`);
  handleReset();
 };

 const handleChangeServer = async (id: number) => {
  setValue("server", id);
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="server"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         handleChangeServer(value?.id || 0);
        }}
        options={servers.map((server) => {
         return { label: server.server_name, id: server.id };
        })}
        value={servers
         .map((element) => {
          return { label: element.server_name, id: element.id };
         })
         .find((server) => server.id === getValues("server"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="container.maildomains.serverHost" />}
          error={formState.isDirty && !!formState?.errors?.server}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 1:
    return (
     <Stack>
      <Controller
       name="name"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "container.maildomains.name" })}
         error={formState.isDirty && !!formState?.errors?.name}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.name &&
          intl.formatMessage({ id: "login.passwordMinLength" })
         }
        />
       )}
      />
      <Controller
       name="comment"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "container.maildomains.comment" })}
         error={formState.isDirty && !!formState?.errors?.comment}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.comment &&
          intl.formatMessage({ id: "login.passwordMinLength" })
         }
        />
       )}
      />
     </Stack>
    );
   case 2:
    return (
     <Stack>
      <Controller
       name="max_quota"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         type="number"
         label={intl.formatMessage({ id: "container.maildomains.maxQuota" })}
         error={watch("max_quota") < -1 || watch("max_quota") === 0 || !watch("max_quota")}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          (watch("max_quota") < -1 || watch("max_quota") === 0 || !watch("max_quota")) &&
          intl.formatMessage({ id: "container.maildomains.maxQuotaError" })
         }
        />
       )}
      />
      <Controller
       name="max_aliases"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         type="number"
         label={intl.formatMessage({ id: "container.maildomains.maxAliases" })}
         error={watch("max_aliases") < -1 || watch("max_aliases") === 0 || !watch("max_aliases")}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          (watch("max_aliases") < -1 || watch("max_aliases") === 0 || !watch("max_aliases")) &&
          intl.formatMessage({ id: "container.maildomains.maxAliasesError" })
         }
        />
       )}
      />
      <Controller
       name="max_users"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         type="number"
         label={intl.formatMessage({ id: "container.maildomains.maxUsers" })}
         error={watch("max_users") < -1 || watch("max_users") === 0 || !watch("max_users")}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          (watch("max_users") < -1 || watch("max_users") === 0 || !watch("max_users")) &&
          intl.formatMessage({ id: "container.maildomains.maxUsersError" })
         }
        />
       )}
      />
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("server") === 0;
   case 1:
    return watch("name") === "" || !domainValidation.test(watch("name"));
   case 2:
    return (
     watch("max_aliases") < -1 ||
     watch("max_aliases") === 0 ||
     !watch("max_aliases") ||
     watch("max_quota") < -1 ||
     watch("max_quota") === 0 ||
     !watch("max_quota") ||
     watch("max_users") < -1 ||
     watch("max_users") === 0 ||
     !watch("max_users")
    );
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("server") === 0 ||
   watch("name") === "" ||
   !domainValidation.test(watch("name")) ||
   watch("max_aliases") < -1 ||
   watch("max_aliases") === 0 ||
   !watch("max_aliases") ||
   watch("max_quota") < -1 ||
   watch("max_quota") === 0 ||
   !watch("max_quota") ||
   watch("max_users") < -1 ||
   watch("max_users") === 0 ||
   !watch("max_users")
  );
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "app", url: "/app" },
      { name: "mails", url: "/app/mails" },
      { name: "add", url: "/app/mails/add" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <IconSelector icon="DockerIcon" />
     <Typography>
      <FormattedMessage id="container.maildomains.createNewDomain" />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {maildomainAppSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== maildomainAppSteps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 2 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="app.wizard.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="app.wizard.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="app.wizard.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        </Step>
       ))}
      </Stepper>
     </Box>
     <MailDomainContainerRecap
      server={servers.find((element) => element.id === watch("server"))?.server_name || ""}
      name={watch("name")}
      comment={watch("comment")}
      quota={watch("max_quota")}
      aliases={watch("max_aliases")}
      users={watch("max_users")}
     />
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "container.maildomains.createNewDomain" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack>
      <FormattedMessage id="container.maildomains.add.confirmCreation" />
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <Stack direction="row" alignItems="center" justifyContent="center" height="100vh">
   <CircularProgress />
  </Stack>
 );
};

export default MailDomainContainerWizard;
