import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postInstallGithubRunner } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const InstallGitHubRunner = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, handleSubmit, setValue, formState } = useForm({
  defaultValues: {
   url: "",
   token: "",
   hash: false
  }
 });

 const onSubmit: SubmitHandler<{ url: string; token: string; hash: boolean }> = async (data: {
  url: string;
  token: string;
  hash: boolean;
 }) => {
  setLoading(true);
  await dispatch(postInstallGithubRunner(id, data.url, data.token, data.hash));
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return watch("url") === "" || watch("token") === "";
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.installGitHubRunner" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="GitHubIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.installGitHubRunner" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Alert severity="info">
      <FormattedMessage id="docker.website.installGitHubRunnerInfo" />
     </Alert>
     <Controller
      name="url"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "docker.website.githubUrl" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.url}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.url &&
         intl.formatMessage({ id: "docker.website.invalidGitHubUrl" })
        }
       />
      )}
     />
     <Controller
      name="token"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "docker.website.githubToken" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.token}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.token &&
         intl.formatMessage({ id: "docker.website.invalidToken" })
        }
       />
      )}
     />
     <Controller
      name="hash"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          checked={watch("hash")}
          onChange={(e) => {
           setValue("hash", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "docker.website.githubHash" })}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default InstallGitHubRunner;
