import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRepairTable } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RepairDatabaseTable = ({ id, logs }: { id: number; logs: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);
 const [repairActivated, setRepairActivated] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  setLoading(false);
  setOpen(false);
 };

 const handleRepair = async () => {
  setLoading(true);
  await dispatch(postRepairTable(id));
  setRepairActivated(true);
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.service.repairTable" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="RebuildIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    handleClose={handleClose}
    loading={loading}
    handleConfirm={handleConfirm}
    showConfirmButton={false}
    title={intl.formatMessage({ id: "server.service.repairTable" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <FormattedMessage id="docker.website.repairTableMessage" />
     </Alert>
     <Stack maxHeight={200} sx={{ overflowY: "scroll" }}>
      {logs !== "" ? (
       <Typography>{logs}</Typography>
      ) : (
       <Typography variant="caption" fontStyle="italic">
        No logs available
       </Typography>
      )}
     </Stack>
     {repairActivated && loading && <CircularProgress />}
     <Stack width={"20%"}>
      <Button variant="kxActionButton" onClick={handleRepair}>
       <FormattedMessage id="server.service.repairTable" />
      </Button>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default RepairDatabaseTable;
