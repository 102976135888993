import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { websiteContainerCronjobSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postCreateCronjob } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

import CronFile from "./cronjobStepComponents/CronFile";
import CronRecap from "./cronjobStepComponents/CronRecap";
import CronTiming from "./cronjobStepComponents/CronTiming";

type cronType = {
 week: string;
 minutes: string;
 hours: string;
 days: string;
 month: string;
 type: string;
};

const CreateCronjob = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [file, setFile] = useState<string>("");
 const [timingWeek, setTimingWeek] = useState<string>("*");
 const [timingMin, setTimingMin] = useState<string>("*/5");
 const [timingHour, setTimingHour] = useState<string>("*");
 const [timingDay, setTimingDay] = useState<string>("*");
 const [timingMonth, setTimingMonth] = useState<string>("*");
 const [selectedType, setSelectedType] = useState<string>("5min");
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);

 const handleOpen = async () => {
  setGoToStepper(false);
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateCronjob(
    id,
    file,
    `"${timingMin} ${timingHour} ${timingDay} ${timingMonth} ${timingWeek}"`
   )
  );
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setFile("");
  setTimingWeek("*");
  setTimingMin("*");
  setTimingHour("*");
  setTimingDay("*");
  setTimingMonth("*");
  setSelectedType("5min");
  setActiveStep(0);
 };

 const handleSetTiming = (value: cronType) => {
  setTimingWeek(value.week);
  setTimingMin(value.minutes);
  setTimingHour(value.hours);
  setTimingDay(value.days);
  setTimingMonth(value.month);
  setSelectedType(value.type);
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <CronFile
      activeStep={activeStep}
      firstValue={file}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => setFile(value)}
     />
    );
   case 1:
    return (
     <CronTiming
      activeStep={activeStep}
      firstValue={{
       week: timingWeek,
       minutes: timingMin,
       hours: timingHour,
       days: timingDay,
       month: timingMonth,
       type: selectedType
      }}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => handleSetTiming(value)}
     />
    );
   case 2:
    return (
     <CronRecap
      file={file}
      timing={{
       week: timingWeek,
       minutes: timingMin,
       hours: timingHour,
       days: timingDay,
       month: timingMonth
      }}
      handleBack={handleBack}
      handleReset={handleReset}
     />
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.website.createCronjob" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createCronjob" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerCronjobSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplainCronjob" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplainCronjob" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplainCronjob" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector
         icon="CronjobIcon"
         props={{ sx: { fontSize: "200px", color: "lightgray" } }}
        />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerCronjobSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <RenderStepContent />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateCronjob;
