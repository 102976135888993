import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import CreateMailbox from "../actions/mailboxActions/CreateMailbox";

const MailboxNotFound = ({
 id,
 server_id,
 domain
}: {
 id: number;
 server_id: number;
 domain: string;
}): ReactElement => {
 return (
  <Stack spacing={2} p={2}>
   <Alert severity="warning">
    <FormattedMessage id="container.maildomains.noMailboxFoundMessage" />
   </Alert>
   <Stack direction="row">
    <CreateMailbox id={id} server_id={server_id} domain={domain} />
   </Stack>
  </Stack>
 );
};

export default MailboxNotFound;
