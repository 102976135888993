import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

const ShowDkim = ({ dkim, domain }: { dkim: string; domain: string }): ReactElement => {
 const intl = useIntl();

 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const formatDkim = (dkim: string): ReactElement => {
  const dkimSplitted = dkim.split("\n");
  return (
   <Stack sx={{ overflowWrap: "break-word" }}>
    {dkimSplitted.map((line, index) => (
     <Typography key={index}>
      {line}
      {index < dkimSplitted.length - 1 && <br />}
     </Typography>
    ))}
   </Stack>
  );
 };

 return (
  <>
   <Typography
    variant="kxColoredSmall"
    sx={{ cursor: "pointer", textDecoration: "underline" }}
    onClick={handleOpen}>
    <FormattedMessage id="container.maildomains.showDkim" />
   </Typography>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.dkim" })}
    handleClose={handleClose}
    showConfirmButton={false}
    handleConfirm={handleClose}>
    <Stack spacing={1}>
     <Typography variant="caption">
      <FormattedMessage
       id="container.maildomains.dkimExplains"
       values={{ br: <br />, domain: domain }}
      />
     </Typography>
     <Divider />
     {formatDkim(dkim)}
    </Stack>
   </AppModal>
  </>
 );
};

export default ShowDkim;
