import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

import { getServerDetails } from "redux/selectors/serversSelector";

import ContainerMailPage from "./containerMail/ContainerMailPage";
import ContainerWebsitePage from "./containerWebsite/ContainerWebsitePage";
import BaseContainerPage from "./BaseContainerPage";

const DockerContainer = (): ReactElement => {
 const server = useSelector(getServerDetails);

 const RenderContainerByType = (): ReactElement => {
  switch (server.type) {
   case "container-website":
    return <ContainerWebsitePage />;
   case "container-mail":
    return <ContainerMailPage />;
   default:
    return <BaseContainerPage />;
  }
 };

 return <RenderContainerByType />;
};

export default DockerContainer;
