import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getRepositorys } from "redux/handlers/dockerHandler";
import { getEnviromentVersions, postInstallEnviroment } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerRepo, IWebAppEnviromentVersion } from "types/api/dockerApiInterface";

const InstallEnviroment = ({ id, version }: { id: number; version: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [enviroment, setEnviroment] = useState<string>("");
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [enviroments, setEnviroments] = useState<IWebAppEnviromentVersion | null>(null);

 const handleOpen = async () => {
  setRepository(await dispatch(getRepositorys()));
  setEnviroments(await dispatch(getEnviromentVersions("enviroment")));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postInstallEnviroment(
    id,
    repository.find((element) => element.name.includes(enviroment))?.name || "",
    enviroment
   )
  );
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.installEnviroment" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="AddCircleIcon" props={{ color: "success" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.installEnviroment" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack>
     <Typography>
      <FormattedMessage id="docker.website.installEnviromentMessage" />
     </Typography>
     {enviroments && (
      <Autocomplete
       fullWidth={true}
       autoHighlight
       sx={{ my: 2 }}
       onChange={(e, value) => {
        setEnviroment(value?.version || "");
       }}
       options={enviroments?.versions
        .filter((enviroment) => enviroment.enviroment.includes(version))
        .map((version) => {
         return {
          label: `${version.enviroment}-${version.version}`,
          id: version.id,
          version: version.version
         };
        })}
       value={enviroments?.versions
        .map((element) => {
         return {
          label: `${element.enviroment}-${element.version}`,
          id: element.id,
          version: element.version
         };
        })
        .find((version) => version.version === enviroment)}
       renderInput={(params) => (
        <TextField
         {...params}
         label={<FormattedMessage id="docker.website.add.enviromentVersion" />}
         InputLabelProps={{ shrink: true }}
        />
       )}
      />
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default InstallEnviroment;
