/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatCountryCode } from "helpers/server";

import { isNil } from "ramda";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DvrIcon from "@mui/icons-material/Dvr";
import EmailIcon from "@mui/icons-material/Email";
import LanIcon from "@mui/icons-material/Lan";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ReportIcon from "@mui/icons-material/Report";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import RouterIcon from "@mui/icons-material/Router";
import WebIcon from "@mui/icons-material/Web";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ManualRenewServer from "components/servers/actions/ManualRenewServer";
import ManualRestoreServer from "components/servers/actions/ManualRestoreServet";
import ServerPerformance from "components/servers/ServerPerformance";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";
import OsImage from "components/shared/images/OsImage";
import ProviderImage from "components/shared/images/ProviderImage";
import { DockerIcon } from "components/shared/images/svgs/Docker";

import { serverTableData } from "types/global/table";

const ServerTable = ({
 rowData,
 index,
 performance
}: {
 rowData: serverTableData;
 index: number;
 performance: Array<{ token: string; cpu: number; ram: number; disk: number }>;
}): ReactElement => {
 const navigate = useNavigate();

 const handleRedirectToServerDetails = (id: number): void => {
  navigate(`/servers/manage/${id}/specification`);
 };

 const formatStatus = (status: string) => {
  switch (status) {
   case "running":
    return "success";
   case "initializing":
    return "info";
   case "starting":
    return "warning";
   case "stopping":
    return "warning";
   case "off":
    return "error";
   case "deleting":
    return "error";
   case "migrating":
    return "info";
   case "rebuilding":
    return "warning";
   default:
    return "default";
  }
 };

 const RenderStatusMessage = ({ status }: { status: string }) => {
  switch (status) {
   case "running":
    return <FormattedMessage id="server.status.running" />;
   case "initializing":
    return <FormattedMessage id="server.status.initializing" />;
   case "starting":
    return <FormattedMessage id="server.status.starting" />;
   case "stopping":
    return <FormattedMessage id="server.status.stopping" />;
   case "off":
    return <FormattedMessage id="server.status.off" />;
   case "stopped":
    return <FormattedMessage id="server.status.off" />;
   case "deleting":
    return <FormattedMessage id="server.status.deleting" />;
   case "migrating":
    return <FormattedMessage id="server.status.migrating" />;
   case "rebuilding":
    return <FormattedMessage id="server.status.rebuilding" />;
   default:
    return <FormattedMessage id="server.status.unknown" />;
  }
 };

 const RenderStatusIcon = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <Stack direction="row" spacing={1}>
      <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "expiring":
    return (
     <Stack direction="row" spacing={1}>
      <ReportProblemIcon sx={{ color: "#FFB600" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "in renew":
    return (
     <Stack direction="row" spacing={1}>
      <ReportProblemIcon sx={{ color: "blue" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "expired":
    return (
     <Stack direction="row" spacing={1}>
      <ReportIcon sx={{ color: "#FF1800" }} />
      <Typography>{status}</Typography>
     </Stack>
    );
   case "deleted":
    return (
     <Stack direction="row" spacing={1}>
      <ReportIcon sx={{ color: "#FF1800" }} />
      <Typography>expired</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderServerType = ({ type }: { type: string | null }) => {
  switch (type) {
   case "webserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <WebIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "mailserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <AlternateEmailIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "nameserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <LanIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "container-server":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <DockerIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "container-website":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <DockerIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "container-mail":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <DockerIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "gatewayserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <RouterIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "firewall":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="FirewallIcon" />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "voipserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <PermPhoneMsgIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "custom":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <AutoAwesomeIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "webserver-apache":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <img
       width={24}
       height={24}
       src={`${process.env.PUBLIC_URL}/svg/providers/apache2.svg`}
       alt="provider"
      />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "webserver-nginx":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <img
       width={24}
       height={24}
       src={`${process.env.PUBLIC_URL}/svg/providers/nginx.svg`}
       alt="provider"
      />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   case "zimbra":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <EmailIcon />
      <Typography variant="subtitle2">{type}</Typography>
     </Stack>
    );
   default:
    return <FormattedMessage id="server.typeNotPresent" />;
  }
 };

 return (
  <TableRow
   key={`server-list-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => {
    rowData.status_on !== "expired" &&
     rowData.status_on !== "deleted" &&
     handleRedirectToServerDetails(rowData.id);
   }}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" spacing={0.5}>
     <OsImage osType={rowData.os} /> <ProviderImage provider={rowData.provider} />
     <Stack component="span" className={`fi fi-${formatCountryCode(rowData.countryCode)}`} />{" "}
     <Typography variant="small">{rowData.serverName}</Typography>
    </Stack>
    <Stack direction="row" ml={1} mt={1}>
     <Typography
      variant="small"
      fontStyle="italic">{`${rowData.server_type_cores} core | ${rowData.server_type_memory} GB RAM | ${rowData.server_type_disk} GB NVME`}</Typography>
    </Stack>
    <Stack onClick={(event) => event.stopPropagation()} maxWidth="fit-content">
     <AdminLogAsUser userid={rowData.userid} owner={rowData.owner || ""} />
    </Stack>
   </TableCell>
   <TableCell>
    <Stack spacing={1}>
     <Typography variant="extraSmall">{rowData.ipAddress}</Typography>
     {rowData.private_net.length > 0 ? (
      rowData.private_net.map((element, index) => {
       return (
        <Typography variant="extraSmall" key={`ip-index-${index}`}>
         {element.ip}
        </Typography>
       );
      })
     ) : (
      <Typography variant="caption" fontStyle="italic">
       <FormattedMessage id="server.privateIpNull" />
      </Typography>
     )}
    </Stack>
   </TableCell>
   <TableCell>
    <Stack spacing={2}>
     <RenderStatusIcon status={rowData.status_on} />
     <Chip
      color={formatStatus(rowData.status)}
      size="small"
      label={<RenderStatusMessage status={rowData.status} />}
     />
    </Stack>
   </TableCell>
   <TableCell>
    <Stack spacing={2} alignItems={"left"}>
     {rowData?.type ? (
      rowData?.type?.split(" ").map((element, index) => {
       return <RenderServerType type={element} key={`server-type-${index}`} />;
      })
     ) : (
      <RenderServerType type={rowData.type} />
     )}
    </Stack>
   </TableCell>
   <TableCell align="left">
    <Stack direction="row" alignItems="center" spacing={0.5}>
     <DvrIcon fontSize="small" />
     <Typography variant="overline" fontWeight="bold">
      [{rowData.serverMachine}]
     </Typography>
     <Typography variant="small" fontWeight="bold">
      <FormattedNumber
       value={isNil(rowData.price) ? 0 : Number(rowData.price)}
       style="currency"
       currency="EUR"
      />
     </Typography>
    </Stack>
    <Stack justifyContent="flex-end">
     {rowData.autorenew ? (
      <Stack direction="row" alignItems="center">
       <Typography variant="small">
        <FormattedMessage id={"server.renewType.automatic"} />
       </Typography>
       <IconSelector
        icon="ScheduleIcon"
        props={{ fontSize: "small", color: "success", sx: { ml: 1 } }}
       />
      </Stack>
     ) : (
      <Stack direction="row" alignItems="center">
       {rowData.status_on !== "active" && rowData.status_on !== "deleted" ? (
        rowData.status_on === "expired" ? (
         <Stack onClick={(event) => event.stopPropagation()}>
          <ManualRestoreServer server={rowData.serverName} />
         </Stack>
        ) : (
         <Stack onClick={(event) => event.stopPropagation()}>
          <ManualRenewServer server={rowData.serverName} />
         </Stack>
        )
       ) : (
        <Typography variant="small">
         <FormattedMessage id={"server.renewType.manual"} />
        </Typography>
       )}
      </Stack>
     )}
     <Stack>
      <Typography variant="small" color={rowData.status_on === "expired" ? "error" : ""}>
       {rowData?.expiration && (
        <FormattedDate
         value={new Date(rowData.expiration)}
         year="numeric"
         month="long"
         day="2-digit"
        />
       )}
      </Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell align="right" sx={{ width: "25%" }}>
    <ServerPerformance performance={performance} agentoken={rowData.agentoken} />
   </TableCell>
  </TableRow>
 );
};

export default ServerTable;
