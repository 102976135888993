import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import ContainerMailDetailsReducer from "redux/reducers/containerMailDomainReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import {
 IContainerMailboxData,
 IContainerMailDomainAPI,
 IMailDomainSyncAPI
} from "types/api/containerMailDomainApiInterface";

import { ApiService } from "service/ApiService";

export const getContainerMailDomains =
 (server_id: number, page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerMailDomainAPI>(
    `/docker/maildomain/get-maildomains?page=${page}&limit=${limit}&server_id_db=${server_id}&q=${
     q || ""
    }`
   );
   dispatch(ContainerMailDetailsReducer.actions.setMailDomainsDetails(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load mail domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateServerMailSizeLimit =
 (id: number, msg_size_limit: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/container-mail/update-limit", "POST", {
    id,
    msg_size_limit
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing size limit",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit size limit - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateServerMailRateLimit =
 (id: number, msg_rate_limit: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/server/container-mail/update-limit", "POST", {
    id,
    msg_rate_limit
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing rate limit",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit rate limit - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateContainerMailDomain =
 (
  server_id_db: number,
  domain: string,
  description: string,
  mailboxes: number,
  aliases: number,
  maxquota: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/add-maildomain", "POST", {
    server_id_db,
    domain,
    description,
    mailboxes,
    aliases,
    maxquota
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating mail domain",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateMailDomain =
 (
  id: number,
  description: string,
  mailboxes: number,
  aliases: number,
  maxquota: number,
  active: boolean,
  gal: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/update-maildomain", "POST", {
    id,
    description,
    mailboxes,
    aliases,
    maxquota,
    active,
    gal
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing mail domain",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteMailDomain =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/delete-maildomain", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting mail domain",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getSelectedMailDomainData =
 (server_id: number, page: number, limit: number, mail_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerMailDomainAPI>(
    `/docker/maildomain/get-maildomains?page=${page}&limit=${limit}&mail_id=${mail_id}&server_id_db=${server_id}`
   );
   dispatch(ContainerMailDetailsReducer.actions.setMailDomainsDetails(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateContainerMailbox =
 (
  server_id_db: number,
  maildomain_id_db: number,
  local_part: string,
  password: string,
  quota: number,
  imap_access: boolean,
  pop3_access: boolean,
  smtp_access: boolean,
  sieve_access: boolean,
  spam_score: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/mailbox/add-mailbox", "POST", {
    server_id_db,
    maildomain_id_db,
    local_part,
    password,
    quota,
    imap_access,
    pop3_access,
    smtp_access,
    sieve_access,
    spam_score,
    name: ""
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating mailbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllMailboxes =
 (id: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ dataset: Array<IContainerMailboxData>; totalCount: number }>(
    `/docker/mailbox/get-mailboxes?maildomain_id_db=${id}&q=${q || ""}`
   );
   dispatch(ContainerMailDetailsReducer.actions.setReload(false));
   dispatch(ContainerMailDetailsReducer.actions.setMailboxes(data.dataset));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get mailboxes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteMailbox =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/docker/mailbox/delete-mailbox", "POST", {
    id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postEditContainerMailbox =
 (
  id: number,
  password: string,
  name: string,
  quota: number,
  active: boolean,
  imap_access: boolean,
  pop_access: boolean,
  smtp_access: boolean,
  sieve_access: boolean,
  spam_score: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/mailbox/update-mailbox", "POST", {
    id,
    name,
    password,
    quota,
    active,
    imap_access,
    pop_access,
    smtp_access,
    sieve_access,
    spam_score
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing mailbox",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditMailAutoresponder =
 (
  id: number,
  reply_enabled: boolean,
  reply_startdate: string,
  reply_enddate: string,
  reply_subject: string,
  reply_body: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/mailbox/update-mailbox", "POST", {
    id,
    reply_enabled,
    reply_startdate,
    reply_enddate,
    reply_subject,
    reply_body
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing autoresponder",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit autoresponder - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGenerateDkim =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/generate-dkim", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Generating new dkim",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to generate dkim - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postVerifyMaildomain =
 (id: number, endpoint: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(endpoint, "POST", {
    id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to verify maildomain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postUpdateFail2ban =
 (
  id: number,
  ban_time: number,
  ban_time_increment: boolean,
  max_ban_time: number,
  netban_ipv4: number,
  netban_ipv6: number,
  max_attempts: number,
  retry_window: number,
  whitelist: Array<string>,
  blacklist: Array<string>
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/update-fail2ban", "POST", {
    id,
    ban_time,
    ban_time_increment,
    max_ban_time,
    netban_ipv4,
    netban_ipv6,
    max_attempts,
    retry_window,
    whitelist,
    blacklist
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing mail domain",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateContainerAlias =
 (maildomain_id_db: number, address: string, goto: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/alias/add-alias", "POST", {
    maildomain_id_db,
    address,
    goto
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating alias",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditContainerAlias =
 (
  id: number,
  address: string,
  goto: string,
  active: boolean,
  private_comment: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/alias/update-alias", "POST", {
    id,
    address,
    goto,
    active,
    private_comment
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing alias",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteContainerAlias =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/maildomain/alias/delete-alias", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting alias",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete alias - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getContainerMailDomainsSync =
 (
  server_id: number,
  page: number,
  limit: number,
  domain: string,
  q?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IMailDomainSyncAPI>(
    `/docker/syncjob/get-syncjobs?page=${page}&limit=${limit}&server_id_db=${server_id}&mail_domain=${domain}&q=${
     q || ""
    }`
   );
   dispatch(ContainerMailDetailsReducer.actions.setSync(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load mail domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

type syncData = {
 server: number;
 username: string;
 host1: string;
 port1: number;
 user1: string;
 password1: string;
 enc1: string;
 mins_interval: number;
 maxage: number;
 maxbytespersecond: number;
 timeout1: number;
 timeout2: number;
 exclude: string;
 delete2duplicates: boolean;
 delete1: boolean;
 delete2: boolean;
 automap: boolean;
 skipcrossduplicates: boolean;
 subscribeall: boolean;
 subfolder2: string;
};

export const postAddNewMailDomainsSync =
 (props: syncData): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/syncjob/add-syncjob", "POST", {
    server_id_db: props.server,
    username: props.username,
    host1: props.host1,
    port1: props.port1,
    user1: props.user1,
    password1: props.password1,
    enc1: props.enc1,
    mins_interval: props.mins_interval,
    maxage: props.maxage,
    maxbytespersecond: props.maxbytespersecond,
    timeout1: props.timeout1,
    timeout2: props.timeout2,
    exclude: props.exclude,
    delete2duplicates: props.delete2duplicates,
    delete1: props.delete1,
    delete2: props.delete2,
    automap: props.automap,
    skipcrossduplicates: props.skipcrossduplicates,
    subscribeall: props.subscribeall,
    subfolder2: props.subfolder2 || null
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new sync process",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new sync process - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditMailDomainsSync =
 (
  id: number,
  host1: string,
  port1: number,
  user1: string,
  enc1: string,
  mins_interval: number,
  maxage: number,
  maxbytespersecond: number,
  timeout1: number,
  timeout2: number,
  exclude: string,
  delete2duplicates: boolean,
  delete1: boolean,
  delete2: boolean,
  automap: boolean,
  skipcrossduplicates: boolean,
  subscribeall: boolean,
  subfolder2: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/syncjob/add-syncjob", "POST", {
    id,
    host1,
    port1,
    user1,
    enc1,
    mins_interval,
    maxage,
    maxbytespersecond,
    timeout1,
    timeout2,
    exclude,
    delete2duplicates,
    delete1,
    delete2,
    automap,
    skipcrossduplicates,
    subscribeall,
    subfolder2
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing sync process",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit sync process - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteMailDomainSync =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/syncjob/delete-syncjob", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting sync process",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete sync process - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditMailMsgLimits =
 (id: number, rl_value: string, rl_frame: string, endpoint: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>(endpoint, "POST", {
    id,
    rl_value,
    rl_frame
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Editing domain limits",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to edit domain limits - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
