import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import GithubLogin from "components/shared/buttons/GithubLogin";
import GoogleLogin from "components/shared/buttons/GoogleLogin";
import LinkedinLogin from "components/shared/buttons/LinkedinLogin";
import IconSelector from "components/shared/images/IconSelector";

import { postConnectGoogle } from "redux/handlers/appLoginHandler";
import { getPasswordIsSet } from "redux/handlers/userHandler";

import { getUserExternalLogin } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { GoogleSignupResponseAPI } from "types/api/externalLoginApiInterface";

import RevokeAppAccess from "../actions/RevokeAppAccess";

const LinkExternalApp = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("sm"));

 const linkedApp = useSelector(getUserExternalLogin);

 const [passwordIsSet, setPasswordIsSet] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(true);

 const handleGoogleCredentialResponse = async (response: GoogleSignupResponseAPI) => {
  await dispatch(postConnectGoogle(response.credential));
 };

 useEffect(() => {
  (async () => {
   setLoading(true);
   setPasswordIsSet(await dispatch(getPasswordIsSet()));
   setLoading(false);
  })();
 }, []);

 return (
  <Stack p={2}>
   <Typography textAlign="left" variant="h6">
    <FormattedMessage id="profile.security.externalLogin" />
   </Typography>
   <Divider />
   <Stack pt={1}>
    <Stack>
     <Typography textAlign="left" variant="subtitle2">
      <FormattedMessage id="profile.security.externalLoginMessage" />
     </Typography>
    </Stack>
    <Divider />
    {loading ? (
     <Stack direction="row" justifyContent="center" pt={2} alignItems="center">
      <CircularProgress />
     </Stack>
    ) : passwordIsSet ? (
     <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      direction={desktopViewPort ? "row" : "column"}
      pt={2}>
      <Stack
       alignItems="center"
       justifyContent="center"
       spacing={1}
       p={2}
       sx={{
        border: linkedApp.googleId ? "1px solid green" : "1px solid lightgrey",
        borderRadius: 2
       }}>
       {linkedApp.googleId ? (
        <IconSelector icon="GoogleIcon" props={{ color: "success" }} />
       ) : (
        <GoogleLogin
         onSuccess={handleGoogleCredentialResponse}
         onError={() => console.log("error")}
         method="signup_with"
        />
       )}
       {linkedApp.googleId ? (
        <Typography variant="caption" color="success">
         <FormattedMessage id="profile.security.externalConnected" />
        </Typography>
       ) : (
        <Typography variant="caption">{`${intl.formatMessage({
         id: "app.signInWith"
        })} Google`}</Typography>
       )}
       {linkedApp.googleId && <RevokeAppAccess type="google" />}
      </Stack>
      <Stack
       alignItems="center"
       justifyContent="center"
       spacing={1}
       p={2}
       sx={{
        border: linkedApp.linkedinId ? "1px solid green" : "1px solid lightgrey",
        borderRadius: 2
       }}>
       {linkedApp.linkedinId ? (
        <IconSelector icon="LinkedInIcon" props={{ color: "success" }} />
       ) : (
        <LinkedinLogin message="app.signInWith" />
       )}
       {linkedApp.linkedinId ? (
        <Typography variant="caption" color="success">
         <FormattedMessage id="profile.security.externalConnected" />
        </Typography>
       ) : (
        <Typography variant="caption">{`${intl.formatMessage({
         id: "app.signInWith"
        })} LinkedIn`}</Typography>
       )}
       {linkedApp.linkedinId && <RevokeAppAccess type="linkedin" />}
      </Stack>
      <Stack
       alignItems="center"
       justifyContent="center"
       spacing={1}
       p={2}
       sx={{
        border: linkedApp.githubId ? "1px solid green" : "1px solid lightgrey",
        borderRadius: 2
       }}>
       {linkedApp.githubId ? (
        <IconSelector icon="GitHubIcon" props={{ color: "success" }} />
       ) : (
        <GithubLogin message="app.signInWith" />
       )}
       {linkedApp.githubId ? (
        <Typography variant="caption" color="success">
         <FormattedMessage id="profile.security.externalConnected" />
        </Typography>
       ) : (
        <Typography variant="caption">{`${intl.formatMessage({
         id: "app.signInWith"
        })} GitHub`}</Typography>
       )}
       {linkedApp.githubId && <RevokeAppAccess type="github" />}
      </Stack>
     </Stack>
    ) : (
     <Alert severity="error" variant="filled">
      <FormattedMessage id="profile.security.passwordNotSetted" />
     </Alert>
    )}
   </Stack>
  </Stack>
 );
};

export default LinkExternalApp;
