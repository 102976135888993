import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { formatBytes, formatMegaBytes } from "helpers/numberFormatting";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

import CheckDomainConfig from "../actions/CheckDomainConfig";
import EditMailDomain from "../actions/EditMailDomain";
import EditMailDomainLimits from "../actions/EditMailDomainLimits";
import ShowDkim from "../actions/ShowDkim";
import MailPerformance from "../manageComponents/MailPerformance";

const MailDomainDetails = ({ mailData }: { mailData: IContainerMailDomainData }): ReactElement => {
 const intl = useIntl();

 const formatQuota = (quota: number, occupied: number): string => {
  const firstFormat = formatBytes(quota, "MB");
  const secondFormat = formatBytes(occupied, "MB");
  if (firstFormat >= 1024) {
   return `${
    secondFormat > 1024
     ? formatMegaBytes(secondFormat, "GB").toFixed(0)
     : `${secondFormat.toFixed(0)} MB`
   }/${formatMegaBytes(firstFormat, "GB").toFixed(0)} GB`;
  } else {
   return `${secondFormat.toFixed(0)}/${firstFormat.toFixed(0)} MB`;
  }
 };

 const formatSingleQuota = (quota: number): string => {
  const firstFormat = formatBytes(quota, "MB");
  if (firstFormat >= 1024) {
   return `${formatMegaBytes(firstFormat, "GB").toFixed(0)} GB`;
  } else {
   return `${firstFormat.toFixed(0)} MB`;
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="DetailsIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="container.maildomains.details" />
    </Typography>
   </Stack>
   <Stack textAlign="left" p={2} pt={0}>
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
      Data
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.description" />
     </Typography>
     <Typography variant="subtitle2">
      {mailData.description ? mailData.description : "--"}
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.dkim" />
     </Typography>
     {mailData.dkim_txt ? (
      <ShowDkim dkim={mailData.dkim_txt} domain={mailData.domain_name} />
     ) : (
      <Typography variant="subtitle2">Not available</Typography>
     )}
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.gal" />
     </Typography>
     <Chip
      label={mailData.gal ? "Online" : "Offline"}
      size="small"
      color={mailData.gal ? "success" : "error"}
      sx={{ height: "18px", mr: 1 }}
     />
     <Tooltip title={<FormattedMessage id="container.maildomains.galInfo" />}>
      <Stack>
       <IconSelector icon="InfoIcon" props={{ color: "kxBlue" }} />
      </Stack>
     </Tooltip>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.totalMessages" />
     </Typography>
     {mailData.domain_limit.msgs_total ? (
      <Typography variant="subtitle2">{mailData.domain_limit.msgs_total}</Typography>
     ) : (
      <Typography variant="subtitle2">Not available</Typography>
     )}
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
      Limits
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.add.quotaLimit" />
     </Typography>
     {mailData.domain_limit.max_quota_for_domain === 0 ? (
      <Typography variant="subtitle2">
       <FormattedMessage id="sites.quotaUnlimited" />
      </Typography>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center" minWidth={200}>
       <Typography variant="subtitle2" minWidth={100}>
        {formatSingleQuota(mailData.domain_limit.max_quota_for_domain)}
       </Typography>
      </Stack>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.maxQuotaDetails" />
     </Typography>
     {mailData.domain_limit.max_quota_for_domain === 0 ? (
      <Typography variant="subtitle2">
       <FormattedMessage id="sites.quotaUnlimited" />
      </Typography>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center" minWidth={200}>
       <Typography variant="subtitle2" minWidth={100}>
        {formatQuota(
         mailData.domain_limit.max_quota_for_domain,
         Number(mailData.domain_limit.max_quota_occupied)
        )}
       </Typography>
       <MailPerformance
        value={
         (Number(mailData.domain_limit.max_quota_occupied) /
          mailData.domain_limit.max_quota_for_domain) *
         100
        }
       />
      </Stack>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.maxQuotaAllocated" />
     </Typography>
     <Stack direction="row" spacing={1} alignItems="center" minWidth={200}>
      <Typography variant="subtitle2" minWidth={100}>
       {formatQuota(
        mailData.domain_limit.max_quota_for_domain,
        mailData.domain_limit.quota_used_in_domain
       )}
      </Typography>
      <MailPerformance
       value={
        (mailData.domain_limit.quota_used_in_domain / mailData.domain_limit.max_quota_for_domain) *
        100
       }
      />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.maxUsersDetails" />
     </Typography>
     {mailData.domain_limit.max_num_mboxes_for_domain === -1 ? (
      <Typography variant="subtitle2">
       <FormattedMessage id="container.maildomains.unlimited" />
      </Typography>
     ) : (
      <Typography variant="subtitle2">{mailData.domain_limit.max_num_mboxes_for_domain}</Typography>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.maxAliasesDetails" />
     </Typography>
     {mailData.domain_limit.max_num_aliases_for_domain === -1 ? (
      <Typography variant="subtitle2">
       <FormattedMessage id="container.maildomains.unlimited" />
      </Typography>
     ) : (
      <Typography variant="subtitle2">
       {mailData.domain_limit.max_num_aliases_for_domain}
      </Typography>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.msgLimitSecond" />
     </Typography>
     {!mailData.domain_limit.msg_limit_second ? (
      <Typography variant="subtitle2">not selected</Typography>
     ) : (
      <Typography variant="subtitle2">{mailData.domain_limit.msg_limit_second}</Typography>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.msgLimitMinute" />
     </Typography>
     {!mailData.domain_limit.msg_limit_minute ? (
      <Typography variant="subtitle2">not selected</Typography>
     ) : (
      <Typography variant="subtitle2">{mailData.domain_limit.msg_limit_minute}</Typography>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.msgLimitHour" />
     </Typography>
     {!mailData.domain_limit.msg_limit_hour ? (
      <Typography variant="subtitle2">not selected</Typography>
     ) : (
      <Typography variant="subtitle2">{mailData.domain_limit.msg_limit_hour}</Typography>
     )}
    </Stack>
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.msgLimitDay" />
     </Typography>
     {!mailData.domain_limit.msg_limit_day ? (
      <Typography variant="subtitle2">not selected</Typography>
     ) : (
      <Typography variant="subtitle2">{mailData.domain_limit.msg_limit_day}</Typography>
     )}
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
      Config
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <Typography variant="subtitle2">
      <FormattedMessage
       id="container.maildomains.configGuide"
       values={{
        guide: (
         <a
          href={`${process.env.PUBLIC_URL}/documents/guides/server_mail_guide.html`}
          target="_blank"
          rel="noreferrer">
          {intl.formatMessage({ id: "container.maildomains.configGuideLink" })}
         </a>
        )
       }}
      />
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.checkAutodiscover" />
     </Typography>
     {mailData.configuration.check_autodiscover ? (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
       <Typography variant="caption" color="success">
        <FormattedMessage id="app.verified" />
       </Typography>
      </Stack>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="ClearIcon" props={{ color: "error" }} />
       <Typography color="error" variant="caption">
        <FormattedMessage id="app.notVerified" />
       </Typography>
       <CheckDomainConfig id={mailData.id} caller="autodiscover" />
      </Stack>
     )}
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.checkSrv" />
     </Typography>
     {mailData.configuration.check_srv ? (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
       <Typography variant="caption" color="success">
        <FormattedMessage id="app.verified" />
       </Typography>
      </Stack>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="ClearIcon" props={{ color: "error" }} />
       <Typography color="error" variant="caption">
        <FormattedMessage id="app.notVerified" />
       </Typography>
       <CheckDomainConfig id={mailData.id} caller="srv" />
      </Stack>
     )}
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.checkSpf" />
     </Typography>
     {mailData.configuration.check_spf ? (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
       <Typography variant="caption" color="success">
        <FormattedMessage id="app.verified" />
       </Typography>
      </Stack>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="ClearIcon" props={{ color: "error" }} />
       <Typography color="error" variant="caption">
        <FormattedMessage id="app.notVerified" />
       </Typography>
       <CheckDomainConfig id={mailData.id} caller="spf" />
      </Stack>
     )}
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.checkDkim" />
     </Typography>
     {mailData.configuration.check_dkim ? (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
       <Typography variant="caption" color="success">
        <FormattedMessage id="app.verified" />
       </Typography>
      </Stack>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="ClearIcon" props={{ color: "error" }} />
       <Typography color="error" variant="caption">
        <FormattedMessage id="app.notVerified" />
       </Typography>
       <CheckDomainConfig id={mailData.id} caller="dkim" />
      </Stack>
     )}
    </Stack>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
      <FormattedMessage id="container.maildomains.checkDmarc" />
     </Typography>
     {mailData.configuration.check_dmarc ? (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
       <Typography variant="caption" color="success">
        <FormattedMessage id="app.verified" />
       </Typography>
      </Stack>
     ) : (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="ClearIcon" props={{ color: "error" }} />
       <Typography color="error" variant="caption">
        <FormattedMessage id="app.notVerified" />
       </Typography>
       <CheckDomainConfig id={mailData.id} caller="dmarc" />
      </Stack>
     )}
    </Stack>
    <Stack direction="row" alignItems="center" spacing={1} pt={2}>
     <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
      Actions
     </Typography>
     <Stack width="stretch">
      <Divider />
     </Stack>
    </Stack>
    <Stack direction="row">
     <EditMailDomain mailData={mailData} />
     <EditMailDomainLimits mailData={mailData} />
    </Stack>
   </Stack>
  </Paper>
 );
};

export default MailDomainDetails;
