import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import DatabaseNotFound from "../manageComponents/DatabaseNotFound";
import DatabaseSection from "../manageComponents/DatabaseSection";
import DbNavigatorSection from "../manageComponents/DbNavigatorSection";
import FtpUserSection from "../manageComponents/FtpuserSection";

const WebAppDetails = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const checkForActive = (): string => {
  if (siteData.database.adminer_active) {
   return "adminer";
  } else if (siteData.database.phpmyadmin_active) {
   return "phpmyadmin";
  } else if (siteData.database.pgadmin_active) {
   return "pgadmin";
  } else {
   return "none";
  }
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return siteData.container_id_db ? (
     <DatabaseSection
      id={siteData.id}
      container_id={siteData.container_id_db}
      dbOnline={siteData.database.db_status || ""}
     />
    ) : (
     <DatabaseNotFound id={siteData.id} />
    );
   case 1:
    return <FtpUserSection id={siteData.id} />;
   case 2:
    return (
     <DbNavigatorSection
      id={siteData.id}
      dbType={siteData.database.db_type || ""}
      container_id={siteData.container_id_db}
      active={
       siteData.database.phpmyadmin_active ||
       siteData.database.adminer_active ||
       siteData.database.pgadmin_active
      }
      navigator_type={checkForActive()}
     />
    );
   default:
    return siteData.container_id_db ? (
     <DatabaseSection
      id={siteData.id}
      container_id={siteData.container_id_db}
      dbOnline={siteData.database.db_status || ""}
     />
    ) : (
     <DatabaseNotFound id={siteData.id} />
    );
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={desktopViewPort ? "standard" : "scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "docker.website.database" })} />
    <Tab label={intl.formatMessage({ id: "docker.website.ftp" })} />
    <Tab
     disabled={!siteData.container_id_db}
     label={intl.formatMessage({ id: "docker.website.dbmanage" })}
    />
   </Tabs>
   <RenderTabContent />
  </Paper>
 );
};

export default WebAppDetails;
