import React, { ReactElement } from "react";
import { useLocation, useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";

import ExternalDatabasesPage from "./ExternalDatabasesPage";
import MailMigrationPage from "./MailMigrationsPage";

const UtilityPage = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();

 const RenderUtility = (): ReactElement => {
  switch (params.section) {
   case "databases":
    return <ExternalDatabasesPage />;
   case "migration":
    return <MailMigrationPage />;
   default:
    return <ExternalDatabasesPage />;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "utility", url: "/utility" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    <Grid xs={12}>
     <RenderUtility />
    </Grid>
   </Grid>
  </Container>
 );
};

export default UtilityPage;
