import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { IDockerSiteAPI } from "types/api/webAppApiInterface";

type filemanagerUpdate = {
 id: number;
 filemanager_port: number | null;
 filemanager_password: string | null;
 filemanager_active: boolean;
};

const initialState: IDockerSiteAPI = { dataset: [], totalCount: 0 };

const DockerWebsiteReducer = createSlice({
 name: "Dockerwebsite",
 initialState,
 reducers: {
  setDockerWebsites: (state, { payload }: PayloadAction<IDockerSiteAPI>) => {
   return payload;
  },
  resetDockerWebsites: () => {
   return initialState;
  },
  setDockerWebsitesFilemanager: (state, { payload }: PayloadAction<filemanagerUpdate>) => {
   return {
    dataset: state.dataset.map((element) => {
     if (element.id === payload.id) {
      return {
       ...element,
       filemanager_active: payload.filemanager_active,
       filemanager_password: payload.filemanager_password,
       filemanager_port: payload.filemanager_port
      };
     } else {
      return {
       ...element,
       filemanager_active: false,
       filemanager_password: null,
       filemanager_port: null
      };
     }
    }),
    totalCount: state.totalCount
   };
  }
 }
});

export default DockerWebsiteReducer;
