import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { specialCharactersValidation } from "constants/regexp";
import { websiteContainerDatabaseSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";

import { generatePassword } from "redux/handlers/userHandler";

const DbPassword = ({
 activeStep,
 firstValue,
 handleNext,
 handleBack,
 handleReset,
 setPropValue
}: {
 activeStep: number;
 firstValue: string;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropValue: (value: string) => void;
}): ReactElement => {
 const intl = useIntl();

 const [password, setPassword] = useState<string>(firstValue);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleGeneratePassword = async () => {
  setPassword(await generatePassword());
 };

 const goNext = () => {
  setPropValue(password);
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <Stack direction="row" alignItems="center" spacing={2}>
    <TextField
     value={password}
     onChange={({ currentTarget }) => setPassword(currentTarget.value)}
     fullWidth={true}
     autoFocus
     type={showPassword}
     autoComplete="new-password"
     label={intl.formatMessage({ id: "docker.website.databasePassword" })}
     error={!specialCharactersValidation.test(password)}
     helperText={
      !specialCharactersValidation.test(password) && (
       <FormattedMessage id="docker.website.databasePasswordError" />
      )
     }
     InputProps={{
      endAdornment: (
       <InputAdornment position="end">
        <IconButton
         aria-label="toggle password visibility"
         onClick={() => setShowPassword(showPassword === "password" ? "text" : "password")}
         edge="end">
         <IconSelector
          icon={showPassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
          props={{ fontSize: "small" }}
         />
        </IconButton>
       </InputAdornment>
      )
     }}
    />
    <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
     <FormattedMessage id="app.generatePassword" />
    </Button>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={
      activeStep === websiteContainerDatabaseSteps.length - 1 ||
      password === "" ||
      !specialCharactersValidation.test(password)
     }
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DbPassword;
