import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { formatMemLimit, formatMemVisual } from "helpers/addons";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import CheckLogs from "../actions/CheckLogs";
import ClosePorts from "../actions/ClosePorts";
import EditSiteTuning from "../actions/EditSiteTuning";
import GenerateSshKey from "../actions/GenerateSshKey";
import OpenPorts from "../actions/OpenPorts";
import PowerOffSite from "../actions/PowerOffSite";
import PowerOnSite from "../actions/PowerOnSite";
import RebootSite from "../actions/RebootSite";
import RepairDatabaseTable from "../actions/RepairDatabaseTable";
import ShowSshKey from "../actions/ShowSshKey";

const WebAppDbStatistics = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onWebappDbPerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === `db_${siteData.site_name}`) {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
      setNetIo(element?.net_io);
      setBlockIo(element?.block_io);
     }
    }
   }
  }
  !isNil(socket) && socket.on("container_website_performance", onWebappDbPerformance);

  return () => {
   !isNil(socket) && socket.off("container_website_performance", onWebappDbPerformance);
  };
 }, [socket]);

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Stack direction="row" spacing={2} p={1}>
     <IconSelector icon="DatabaseIcon" />
     <Typography fontWeight="bold">
      <FormattedMessage id="docker.website.databaseStatistics" />
      {` db_${siteData.site_name}`}
     </Typography>
    </Stack>
    {!isNil(siteData.container_id_db) ? (
     siteData.database.db_status === "online" ? (
      <Stack>
       <Stack direction="row" alignItems="center" justifyContent="space-around">
        <Stack>
         <SemiCircularProgress value={cpu} maxValue={100} size={100} thickness={8} percent={true} />
         <Typography variant="subtitle2" fontWeight="bold">
          CPU
         </Typography>
        </Stack>
        <Stack>
         <SemiCircularProgress value={ram} maxValue={100} size={100} thickness={8} percent={true} />
         <Typography variant="subtitle2" fontWeight="bold">
          RAM
         </Typography>
        </Stack>
       </Stack>
       <Stack textAlign="left" p={1}>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          MEM limit:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.database.db_mem_limit
           ? formatMemVisual(siteData.database.db_mem_limit)
           : "not selected"}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          CPU core limit:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.database.db_cpu_limit || "not selected"}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          NET I/O:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {netIo}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          BLOCK I/O:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {blockIo}
         </Typography>
        </Stack>
        {!isNil(siteData.open_ports.db.port) && (
         <>
          <Stack direction="row" spacing={2}>
           <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
            SSH Port:
           </Typography>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            {siteData.open_ports.db.port}
           </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
           <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
            SSH Root IP:
           </Typography>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            {siteData.open_ports.db.root}
           </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
           <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
            SSH Password:
           </Typography>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            {siteData.open_ports.db.password}
           </Typography>
          </Stack>
         </>
        )}
        {!isNil(siteData.ssh_data.db.ssh_key) && (
         <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
           SSH Key:
          </Typography>
          <ShowSshKey ssh_key={siteData.ssh_data.db.ssh_key} />
         </Stack>
        )}
       </Stack>
       <Divider />
       <Stack direction="row" spacing={1} p={1}>
        <PowerOffSite id={siteData.id} type="db" />
        <RebootSite id={siteData.id} type="db" />
        <EditSiteTuning
         id={siteData.id}
         type="db"
         defaultMem={formatMemLimit(siteData.database.db_mem_limit || "0g")}
         defaultCpu={siteData.database.db_cpu_limit || 0}
        />
        {isNil(siteData.open_ports.db.port) ? (
         <OpenPorts id={siteData.id} name={`db_${siteData.site_name}`} type="db" />
        ) : (
         <ClosePorts id={siteData.id} name={`db_${siteData.site_name}`} type="db" />
        )}
        <GenerateSshKey id={siteData.id} name={`db_${siteData.site_name}`} type="db" />
        {siteData.database.db_type !== "postgres" && (
         <RepairDatabaseTable id={siteData.id} logs={siteData.repair_log || ""} />
        )}
        <CheckLogs id={siteData.server_id_db} container={`db_${siteData.site_name}`} />
       </Stack>
      </Stack>
     ) : siteData.database.db_status === "restarting" ? (
      <Stack direction="row" spacing={1}>
       <CircularProgress />
       <Typography>
        <FormattedMessage id="docker.website.dbRestarting" />
       </Typography>
      </Stack>
     ) : (
      <Stack spacing={2}>
       <Alert severity="warning">
        <Typography>
         <FormattedMessage id="docker.website.dbIsOffline" />
        </Typography>
       </Alert>
       <Stack direction="row">
        <PowerOnSite id={siteData.id} type="db" />
        <EditSiteTuning
         id={siteData.id}
         type="db"
         defaultMem={formatMemLimit(siteData.database.db_mem_limit || "0g")}
         defaultCpu={siteData.database.db_cpu_limit || 0}
        />
       </Stack>
      </Stack>
     )
    ) : (
     <Stack p={1}>
      <Alert severity="warning">
       <Typography>
        <FormattedMessage id="docker.website.noDbStatistics" />
       </Typography>
      </Alert>
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default WebAppDbStatistics;
