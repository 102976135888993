import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { mailboxContainerSteps } from "constants/apps";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postCreateContainerMailbox } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { mailboxData, mailboxSettings } from "types/global/container";

import MailboxName from "./mailboxStepComponents/MailboxName";
import MailboxPassword from "./mailboxStepComponents/MailboxPassword";
import MailboxRecap from "./mailboxStepComponents/MailboxRecap";
import MailboxSettings from "./mailboxStepComponents/MailboxSettings";
import MailboxSpam from "./mailboxStepComponents/MailboxSpam";

const initialValue = {
 name: "",
 password: "",
 quota: 0,
 imap: false,
 pop: false,
 smtp: false,
 sieve: false,
 threshold1: 8,
 threshold2: 12
};

const CreateMailbox = ({
 id,
 server_id,
 domain
}: {
 id: number;
 server_id: number;
 domain: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);
 const [mailboxData, setMailboxData] = useState<mailboxData>(initialValue);

 const handleOpen = async () => {
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateContainerMailbox(
    server_id,
    id,
    mailboxData.name,
    mailboxData.password,
    mailboxData.quota,
    mailboxData.imap,
    mailboxData.pop,
    mailboxData.smtp,
    mailboxData.sieve,
    `${mailboxData.threshold1}.${mailboxData.threshold2}`
   )
  );
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setMailboxData(initialValue);
  setActiveStep(0);
 };

 const handleChange = (key: keyof mailboxData, value: string) => {
  setMailboxData({ ...mailboxData, [key]: value });
 };

 const handleChangeSettings = (value: mailboxSettings) => {
  setMailboxData({
   ...mailboxData,
   imap: value.imap,
   pop: value.pop,
   smtp: value.smtp,
   sieve: value.sieve,
   quota: value.quota
  });
 };

 const handleChangeSpam = (value: Array<number>) => {
  setMailboxData({
   ...mailboxData,
   threshold1: value[0],
   threshold2: value[1]
  });
 };

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <MailboxName
      activeStep={activeStep}
      firstValue={mailboxData.name}
      mail={domain}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropMailbox={(value) => handleChange("name", value)}
     />
    );
   case 1:
    return (
     <MailboxPassword
      activeStep={activeStep}
      firstValue={mailboxData.password}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropValue={(value) => handleChange("password", value)}
     />
    );
   case 2:
    return (
     <MailboxSettings
      activeStep={activeStep}
      imap={mailboxData.imap}
      pop={mailboxData.pop}
      smtp={mailboxData.smtp}
      sieve={mailboxData.sieve}
      quota={mailboxData.quota}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropSettings={(value: mailboxSettings) => handleChangeSettings(value)}
     />
    );
   case 3:
    return (
     <MailboxSpam
      activeStep={activeStep}
      threshold1={mailboxData.threshold1}
      threshold2={mailboxData.threshold2}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setScore={(value: Array<number>) => handleChangeSpam(value)}
     />
    );
   case 4:
    return (
     <MailboxRecap mailboxData={mailboxData} handleBack={handleBack} handleReset={handleReset} />
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="container.maildomains.createMailbox" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.createMailbox" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === mailboxContainerSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="container.maildomains.firstExplainMailbox" />
        </Typography>
        <Typography>
         <FormattedMessage id="container.maildomains.secondExplainMailbox" />
        </Typography>
        <Typography>
         <FormattedMessage id="container.maildomains.thirdExplainMailbox" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector icon="InboxIcon" props={{ sx: { fontSize: "200px", color: "lightgray" } }} />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="container.maildomains.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {mailboxContainerSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <RenderStepContent />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateMailbox;
