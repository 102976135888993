import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { mailboxData } from "types/global/container";

const MailboxRecap = ({
 mailboxData,
 handleBack,
 handleReset
}: {
 mailboxData: mailboxData;
 handleBack: () => void;
 handleReset: () => void;
}): ReactElement => {
 return (
  <Stack p={2} spacing={2}>
   <Stack spacing={1}>
    <Typography fontWeight="bold">
     <FormattedMessage id="container.maildomains.checkForData" />
    </Typography>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.mailboxName" />:
     </Typography>
     <Typography>{mailboxData.name}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.mailboxPassword" />:
     </Typography>
     <Typography>{mailboxData.password}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.imap" />:
     </Typography>
     <Chip
      color={mailboxData.imap ? "success" : "error"}
      size="small"
      label={mailboxData.imap ? "Enabled" : "Disabled"}
     />
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.pop" />:
     </Typography>
     <Chip
      color={mailboxData.pop ? "success" : "error"}
      size="small"
      label={mailboxData.pop ? "Enabled" : "Disabled"}
     />
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.smtp" />:
     </Typography>
     <Chip
      color={mailboxData.smtp ? "success" : "error"}
      size="small"
      label={mailboxData.smtp ? "Enabled" : "Disabled"}
     />
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.sieve" />:
     </Typography>
     <Chip
      color={mailboxData.sieve ? "success" : "error"}
      size="small"
      label={mailboxData.sieve ? "Enabled" : "Disabled"}
     />
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography minWidth={220}>
      <FormattedMessage id="container.maildomains.threshold" />:
     </Typography>
     <Typography>{`${mailboxData.threshold1} - ${mailboxData.threshold2}`}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button disabled>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default MailboxRecap;
