import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { mailboxContainerSteps } from "constants/apps";

import { mailboxSettings } from "types/global/container";

const MailboxSettings = ({
 activeStep,
 imap,
 pop,
 smtp,
 sieve,
 quota,
 handleNext,
 handleBack,
 handleReset,
 setPropSettings
}: {
 activeStep: number;
 imap: boolean;
 pop: boolean;
 smtp: boolean;
 sieve: boolean;
 quota: number;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropSettings: (value: mailboxSettings) => void;
}): ReactElement => {
 const intl = useIntl();

 const [cImap, setCImap] = useState<boolean>(imap);
 const [cPop, setCPop] = useState<boolean>(pop);
 const [cSmtp, setCSmtp] = useState<boolean>(smtp);
 const [cSieve, setCSieve] = useState<boolean>(sieve);
 const [cQuota, setCQuota] = useState<number>(quota);

 const goNext = () => {
  setPropSettings({ imap: cImap, pop: cPop, smtp: cSmtp, sieve: cSieve, quota: cQuota });
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <FormControlLabel
    control={
     <Checkbox
      checked={cImap}
      onChange={(e) => {
       setCImap(e.target.checked);
      }}
     />
    }
    label={intl.formatMessage({ id: "container.maildomains.enableImap" })}
   />
   <FormControlLabel
    control={
     <Checkbox
      checked={cPop}
      onChange={(e) => {
       setCPop(e.target.checked);
      }}
     />
    }
    label={intl.formatMessage({ id: "container.maildomains.enablePop" })}
   />
   <FormControlLabel
    control={
     <Checkbox
      checked={cSmtp}
      onChange={(e) => {
       setCSmtp(e.target.checked);
      }}
     />
    }
    label={intl.formatMessage({ id: "container.maildomains.enableSmtp" })}
   />
   <FormControlLabel
    control={
     <Checkbox
      checked={cSieve}
      onChange={(e) => {
       setCSieve(e.target.checked);
      }}
     />
    }
    label={intl.formatMessage({ id: "container.maildomains.enableSieve" })}
   />
   <TextField
    fullWidth={true}
    type="number"
    label={intl.formatMessage({ id: "container.maildomains.mailboxQuota" })}
    error={cQuota < 0 || isNil(cQuota)}
    InputLabelProps={{ shrink: true }}
    sx={{ my: 2 }}
    value={cQuota}
    onChange={(e) => setCQuota(Number(e.target.value))}
    autoComplete="off"
    helperText={
     cQuota < 0 || isNil(cQuota)
      ? intl.formatMessage({ id: "container.maildomains.mailboxQuotaError" })
      : intl.formatMessage({ id: "container.maildomains.mailboxQuotaExplain" })
    }
   />
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button disabled={activeStep === mailboxContainerSteps.length - 1} onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default MailboxSettings;
