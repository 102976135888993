import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

import EditFail2ban from "../actions/fail2banActions/EditFail2ban";

const MailDomainFail2ban = ({ mailData }: { mailData: IContainerMailDomainData }): ReactElement => {
 const intl = useIntl();

 const [showUpdate, setShowUpdate] = useState<boolean>(false);

 const openUpdate = () => setShowUpdate(true);
 const closeUpdate = () => setShowUpdate(false);

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="NotInterestedIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="container.maildomains.fail2ban" />
    </Typography>
   </Stack>
   {showUpdate ? (
    <EditFail2ban mailData={mailData} closeUpdate={closeUpdate} />
   ) : (
    <Stack textAlign="left" p={2} pt={0}>
     <Stack direction="row" alignItems="center" spacing={1} pt={2}>
      <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
       Ban time
      </Typography>
      <Stack width="stretch">
       <Divider />
      </Stack>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.banTime" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.ban_time}</Typography>
     </Stack>
     <Stack direction="row" alignItems="center">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.banTimeIncrement" />
      </Typography>
      <Chip
       label={mailData.fail2ban.ban_time_increment ? "Online" : "Offline"}
       size="small"
       color={mailData.fail2ban.ban_time_increment ? "success" : "error"}
       sx={{ height: "18px" }}
      />
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.maxBanTime" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.max_ban_time}</Typography>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.maxAttempts" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.max_attempts}</Typography>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.retryWindow" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.retry_window}</Typography>
     </Stack>
     <Stack direction="row" alignItems="center" spacing={1} pt={2}>
      <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
       Net Ban
      </Typography>
      <Stack width="stretch">
       <Divider />
      </Stack>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.netbanIpv4" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.netban_ipv4}</Typography>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.netbanIpv6" />
      </Typography>
      <Typography variant="subtitle2">{mailData.fail2ban.netban_ipv6}</Typography>
     </Stack>
     <Stack direction="row" alignItems="center" spacing={1} pt={2}>
      <Typography fontWeight="bold" variant="body2" textTransform="uppercase" minWidth={70}>
       Lists
      </Typography>
      <Stack width="stretch">
       <Divider />
      </Stack>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.whitelist" />
      </Typography>
      <Typography variant="subtitle2">
       {mailData.fail2ban.whitelist.length > 0
        ? mailData.fail2ban.whitelist.map((element, index) =>
           index !== mailData.fail2ban.whitelist.length - 1 ? `${element}, ` : element
          )
        : "No element"}
      </Typography>
     </Stack>
     <Stack direction="row">
      <Typography fontWeight="bold" variant="subtitle2" minWidth={180}>
       <FormattedMessage id="container.maildomains.blacklist" />
      </Typography>
      <Typography variant="subtitle2">
       {mailData.fail2ban.blacklist.length > 0
        ? mailData.fail2ban.blacklist.map((element) => `${element} `)
        : "No element"}
      </Typography>
     </Stack>
     <Stack direction="row" alignItems="center" spacing={1} pt={2}>
      <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
       Actions
      </Typography>
      <Stack width="stretch">
       <Divider />
      </Stack>
     </Stack>
     <Stack direction="row">
      <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
       <IconButton onClick={openUpdate}>
        <IconSelector icon="EditIcon" props={{ color: "secondary" }} />
       </IconButton>
      </Tooltip>
     </Stack>
    </Stack>
   )}
  </Paper>
 );
};

export default MailDomainFail2ban;
