import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEnableContinuity } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const EnableBCP = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [accept, setAccept] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postEnableContinuity(id, true));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.add.enableContinuity" })}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="SyncDisabledIcon" props={{ color: "kxOrange" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    handleClose={handleClose}
    disabled={loading || !accept}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "server.add.enableContinuity" })}
    customSize={{ overflowY: "scroll", maxHeight: "100vh" }}>
    <Stack>
     <Alert severity="info">
      <Stack>
       <Typography variant="caption">
        <FormattedMessage id="server.add.enableContinuityMessage" values={{ br: <br /> }} />
       </Typography>
       <Typography variant="caption">
        <FormattedMessage id="server.add.enableContinuityMessage2" />
       </Typography>
       <FormControlLabel
        control={
         <Checkbox
          checked={accept}
          onChange={(e, checked) => {
           setAccept(checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "app.accept" })}
       />
      </Stack>
     </Alert>
    </Stack>
   </AppModal>
  </>
 );
};

export default EnableBCP;
