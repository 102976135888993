import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DomainIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 20 20">
  <path d="M4.21,9.74h5.53V.15c-3.13,1.45-5.44,5.14-5.53,9.58Z" />
  <path d="M9.74,19.85v-9.58h-5.53c.09,4.44,2.4,8.14,5.53,9.58Z" />
  <path d="M3.68,10.26H0c.13,4.99,3.91,9.06,8.76,9.66-2.95-1.75-5-5.41-5.08-9.66Z" />
  <path d="M8.76.07C3.91.67.13,4.75,0,9.74h3.68C3.77,5.48,5.82,1.83,8.76.07Z" />
  <path d="M15.79,10.26h-5.53v9.58c3.13-1.45,5.44-5.14,5.53-9.58Z" />
  <path d="M11.24.07c2.95,1.75,5,5.41,5.08,9.66h3.68C19.87,4.75,16.09.67,11.24.07Z" />
  <path d="M10.26.15v9.58h5.53c-.09-4.44-2.4-8.14-5.53-9.58Z" />
  <path d="M11.24,19.93c4.86-.6,8.63-4.68,8.76-9.66h-3.68c-.08,4.25-2.13,7.91-5.08,9.66Z" />
 </svg>,
 "Domain"
);
