import { isNil } from "ramda";

export const sortTable = (
 caller: string,
 a: any,
 b: any,
 sortBy: any,
 sortDirection: string
): number => {
 const valueA = a[sortBy];
 const valueB = b[sortBy];

 if (!isNil(valueA) && !isNil(valueB) && valueA < valueB) return sortDirection === "asc" ? -1 : 1;
 if (!isNil(valueA) && !isNil(valueB) && valueA > valueB) return sortDirection === "asc" ? 1 : -1;
 return 0;
};
