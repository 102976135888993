import React, { ReactElement } from "react";

import Tooltip from "@mui/material/Tooltip";

import { EServerProvider } from "types/redux/serverInterface";

type Props = {
 provider: string | null;
 width?: number;
 height?: number;
};

const ProviderImage = ({ provider, width = 25, height = 20 }: Props): ReactElement => {
 const image = () => {
  switch (provider?.toLocaleLowerCase()) {
   case EServerProvider.HETZNER:
    return (
     <img
      width={20}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`}
      alt="konsolex"
     />
    );
   case EServerProvider.CONTABO:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/contabo.svg`}
      alt="contabo"
     />
    );
   case EServerProvider.OVH:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/ovh.svg`}
      alt="ovh"
     />
    );
   case EServerProvider.GCLOUD:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/gcloud.svg`}
      alt="gcp"
     />
    );
   case EServerProvider.AWS:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/aws.svg`}
      alt="aws"
     />
    );
   case EServerProvider.HETZNER2:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/hetzner.svg`}
      alt="htz"
     />
    );
   case EServerProvider.KX:
    return (
     <img
      width={20}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`}
      alt="konsolex"
     />
    );
   default:
    return (
     <img
      width={20}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`}
      alt="konsolex"
     />
    );
  }
 };

 return (
  <Tooltip title={provider || ""} placement="top">
   {image()}
  </Tooltip>
 );
};

export default ProviderImage;
