import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { getBase64 } from "helpers/generic";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getUserPreferences, postChangeUserPreferences } from "redux/handlers/teamsHandler";

import { useAppDispatch } from "hooks/reduxHook";

type preferenceType = {
 file: string;
 color: string;
};

const SetTeamsPreferences = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [currentLink, setCurrentLink] = useState<string>("");
 const [instructions, setInstructions] = useState<boolean>(false);
 const [fileError, setFileError] = useState<boolean>(false);

 const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
 });

 const { control, handleSubmit, setValue, watch, formState, reset } = useForm({
  defaultValues: {
   file: "",
   color: ""
  }
 });

 const onSubmit: SubmitHandler<preferenceType> = async (data: preferenceType) => {
  setIsLoading(true);
  await dispatch(postChangeUserPreferences(data.file, data.color));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = async () => {
  reset();
  setInstructions(false);
  const preferences = await dispatch(getUserPreferences());
  if (preferences) {
   setValue("color", preferences?.color);
   setValue("file", preferences?.image);
   setCurrentLink(preferences.composition);
  }
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 const checkDisabled = (): boolean => {
  return watch("file") === "" || watch("color") === "";
 };

 const handleCreateFile = async (files: FileList | null) => {
  if (files && files.length > 0) {
   const fileExtension = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
   if (fileExtension !== "jpg" && fileExtension !== "png" && fileExtension !== "jpeg") {
    setFileError(true);
   } else {
    setFileError(false);
   }
   const size = files[0].size;
   if (size > 64000) setFileError(true);
   const result = await getBase64(files[0]);
   setValue("file", result);
  }
 };

 return (
  <>
   {desktopViewPort ? (
    <Button variant="kxActionButton" onClick={handleOpen}>
     <FormattedMessage id="teams.changePreferences" />
    </Button>
   ) : (
    <IconButton color="kxBlue" onClick={handleOpen}>
     <IconSelector icon="EditIcon" />
    </IconButton>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    showConfirmButton={!instructions}
    disableCancel={instructions}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={isLoading || checkDisabled() || fileError}
    title={intl.formatMessage({ id: "teams.changePreferences" })}>
    {instructions ? (
     <Stack>
      <Typography>
       <FormattedMessage id="teams.instructions.title" />
      </Typography>
      <Typography>
       <FormattedMessage id="teams.instructions1" values={{ br: <br /> }} />
      </Typography>
      <Typography
       sx={{
        fontFamily: "Courier New",
        fontSize: 12,
        backgroundColor: "#e6e6e6",
        p: 1,
        borderRadius: 2
       }}>
       Ex:
       <br />
       Name: Record DNS
       <br />
       Typo: A<br />
       Value:[IP address of apache server]
       <br />
       DNS configuration:
       <br />
       Name Typo Value: A 123.456.78.90 Note: the IP address has to point to the apache server.
      </Typography>
      <Typography>
       <FormattedMessage id="teams.instructions2" values={{ br: <br /> }} />
      </Typography>
      <Typography
       sx={{
        fontFamily: "Courier New",
        fontSize: 12,
        backgroundColor: "#e6e6e6",
        p: 1,
        borderRadius: 2
       }}>
       Codice .htaccess
       <br />
       RewriteEngine On
       <br />
       RewriteRule ^$ /index.html [L]
       <br />
       RewriteRule ^$ /index.html [L]:
       <br />
      </Typography>
      <Typography>
       <FormattedMessage id="teams.instructions3" values={{ br: <br /> }} />
      </Typography>
      <Typography
       sx={{
        fontFamily: "Courier New",
        fontSize: 12,
        backgroundColor: "#e6e6e6",
        p: 1,
        borderRadius: 2
       }}>
       <FormattedMessage id="teams.instructions.html" values={{ br: <br /> }} />
      </Typography>
      <Stack direction="row" mt={2}>
       <Button onClick={() => setInstructions(false)}>
        <FormattedMessage id="app.back" />
       </Button>
      </Stack>
     </Stack>
    ) : (
     <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
       {currentLink ? (
        <Typography>
         <FormattedMessage id="teams.linkToAccess" />
         {`: ${currentLink}`}
        </Typography>
       ) : (
        <Alert severity="warning">
         <FormattedMessage id="teams.linkNotFound" />
        </Alert>
       )}
       {currentLink && (
        <Stack direction="row">
         <Typography>
          <FormattedMessage id="teams.howToDo" />
         </Typography>
         <Typography
          sx={{
           color: "blue",
           textDecoration: "underline",
           cursor: "pointer",
           "&:hover": { color: "steelblue" }
          }}
          onClick={() => setInstructions(true)}>
          <FormattedMessage id="app.clickHere" />
         </Typography>
        </Stack>
       )}
      </Stack>
      <Divider sx={{ mt: 2 }} />
      <Controller
       name="color"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          e.stopPropagation();
         }}
         fullWidth={true}
         label={intl.formatMessage({ id: "teams.color" })}
         error={formState.isDirty && !!formState?.errors?.color}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.color &&
          intl.formatMessage({ id: "teams.colorError" })
         }
        />
       )}
      />
      <Stack direction="row" spacing={2} alignItems="center">
       {watch("file") !== "" ? (
        <img alt="logo" height={40} src={watch("file")} />
       ) : (
        <Alert severity="info">
         <FormattedMessage id="teams.noImageSelected" />
        </Alert>
       )}
       <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}>
        <FormattedMessage id="teams.uploadImage" />
        <VisuallyHiddenInput
         type="file"
         accept=".png, .jpg, .jpeg"
         onChange={(event) => handleCreateFile(event.target.files)}
        />
       </Button>
       <Stack>
        <Typography variant="caption" color="gray">
         <FormattedMessage id="teams.imageSizeSuggestion" />
        </Typography>
        {fileError && (
         <Typography variant="caption" color="#FF1800">
          <FormattedMessage id="teams.imageError" />
         </Typography>
        )}
       </Stack>
      </Stack>
     </form>
    )}
   </AppModal>
  </>
 );
};

export default SetTeamsPreferences;
