import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import DomainsOrdersReducer from "redux/reducers/domainsOrdersReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { Record } from "types/api/appsApiInterface";
import { dnsValues } from "types/api/dnsApiInterface";
import {
 IContactData,
 IContactExtended,
 IDomainsApi,
 IDomainsOrdersApi,
 IHostData,
 IHostNs,
 IISpNs,
 INewDomainData,
 IRegistrarPrice,
 ISimpleDomainData
} from "types/api/domainsApiInterface";

import { ApiService } from "service/ApiService";

import DomainsReducer from "../reducers/domainsReducer";

export const getAllDomains =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDomainsApi>(
    `/domain/alldomains?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${
     q || ""
    }&partner=${userid || ""}`
   );
   dispatch(DomainsReducer.actions.setDomains(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllDomainsNotPaged =
 (): AppAction<Promise<Array<ISimpleDomainData>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ISimpleDomainData>>("/domain/list");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getDomainDetails =
 (currentIndex: number, sizePerPage: number, id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDomainsApi>(
    `/domain/alldomains?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&id=${id}`
   );
   dispatch(DomainsReducer.actions.setDomains(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllDomainsOrders =
 (currentIndex: number, sizePerPage: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDomainsOrdersApi>(
    `/domainorder/getdomainorders?currentIndex=${currentIndex}&sizePerPage=${sizePerPage}&q=${
     q || ""
    }`
   );
   dispatch(DomainsOrdersReducer.actions.setDomainsOrders(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGetAllDomainRecords = async (
 isp_id: number,
 zone_id: number
): Promise<Array<Record>> => {
 const { data } = await ApiService("/dns/getzonebyzoneid", "POST", {
  isp_id: isp_id,
  zone_id: zone_id
 });
 return data.records;
};

export const postManualRenewDomain =
 (domain_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/manualdomainrenew", "POST", {
    domain_id: domain_id
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Domain renewed successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to renew domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetAuthinfo =
 (domain_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/updateauthinfo", "POST", {
    id: domain_id
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting domain authinfo",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset domain authinfo - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRemoveDomainOrder =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/deletetransfer", "POST", {
    id: id
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting transfer...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete transfer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeDomainsAutorenew = (id: number, autorenew: boolean) => {
 console.log(id, autorenew);
};

export const postGetNsProprietari = async (): Promise<Array<IISpNs>> => {
 const { data } = await ApiService("/settings/getISPconfigNS", "POST");
 return data.ispconfig;
};

export const postGetNsHost = async (): Promise<Array<IHostNs>> => {
 const { data } = await ApiService("/server?type=ns");
 return data.servers;
};

export const postSetDomainNs = (newNs: Array<string>) => {
 console.log(newNs);
};

export const postAddDnsRecord = (domainId: number, dnsData: dnsValues) => {
 console.log(domainId, dnsData);
};

export const postUpdateDnsRecord = (domainId: number, id: number, dnsData: dnsValues) => {
 console.log(domainId, id, dnsData);
};

export const postDeleteDnsRecord = (domainId: number, id: number) => {
 console.log(domainId, id);
};

export const postChangeDomainOwners =
 (
  domain_id: number,
  registrant: number,
  technical: number,
  admin: number,
  billing: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/changeowners", "POST", {
    id: domain_id,
    registrant: registrant,
    technical: technical,
    admin: admin,
    billing: billing
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Domain contacts updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update domain contacts - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeDomainNs =
 (
  domain_id: number,
  ns1: string,
  ns2: string,
  ns3?: string,
  ns4?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/changens", "POST", {
    id: domain_id,
    ns1: ns1,
    ns2: ns2,
    ns3: ns3,
    ns4: ns4
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Domain ns updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update domain ns - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryDomainOperation =
 (id: number, action: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domainorder/retry", "POST", {
    id: id,
    action: action
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Retry to execute the operation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to retry the operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postSendEmailVerification =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/ibsemailverification", "POST", {
    id: id
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Verification email sent",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to send email verification - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryDomainAuthinfo =
 (id: number, action: string, authinfo: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domainorder/retry", "POST", {
    id: id,
    action: action,
    authinfo: authinfo
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Retry to execute the operation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to retry the operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryDomainVat =
 (id: number, action: string, vat: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domainorder/retry", "POST", {
    id: id,
    action: action,
    vat: vat
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Retry to execute the operation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to retry the operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRetryDomainDotitregcode =
 (
  id: number,
  action: string,
  contact: { firstname: string; lastname: string; regcode: string }
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domainorder/retry", "POST", {
    id: id,
    action: action,
    contact: contact
   });
   if (data) {
    await dispatch(getAllDomainsOrders(0, 10));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Retry to execute the operation",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to retry the operation - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeDomainLock =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/changelock", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing the domain lock",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change domain lock - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeDomainAutomigration =
 (id: number, automigration: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/services/updateservice", "POST", {
    domain_id_db: id,
    automigration: automigration
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing domain automigration...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change domain automigration - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getContacts = async (): Promise<Array<IContactExtended>> => {
 const { data } = await ApiService<{ dataset: Array<IContactData>; totalCount: number }>(
  "/contact/allcontacts"
 );
 return data.dataset.map((element: IContactData) => {
  return { ...element, label: `${element.firstname} ${element.lastname} - ${element.company}` };
 });
};

export const getContactsList = async (): Promise<Array<IContactData>> => {
 const { data } = await ApiService("/contact/contactslist");
 return data;
};

export const getHostList = async (): Promise<Array<IHostData>> => {
 const { data } = await ApiService("/host/hostslist");
 return data;
};

export const getRegisterList = async () => {
 const { data } = await ApiService("/register/getregisters");
 return data;
};

export const postCheckDomainAvailability = async (domain: string): Promise<string> => {
 const { data } = await ApiService("/domain/checkavailability", "POST", { domain: domain });
 return data;
};

export const postGetDomainPrice = async (
 domain: string,
 register: string,
 status: string
): Promise<IRegistrarPrice> => {
 const { data } = await ApiService("/domain/getdomainprice", "POST", {
  domain: domain,
  register: register,
  status: status
 });
 return data;
};

export const postCreateDomain =
 (dataToSend: INewDomainData): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/domain/adddomain", "POST", {
    data: dataToSend
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message:
       dataToSend.action === "transfer" ? "Domain transfer ongoing" : "Domain creation in progress",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDomainRestorePrice =
 (id: number): AppAction<Promise<number>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<number>("/domain/restoreprice", "POST", {
    id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get restore price - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return 0;
  }
 };

export const postRestoreDomain =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<number>("/domain/restore", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Domain restore in progress..",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restore the domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
