import React, { ReactElement } from "react";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import GithubSignUp from "components/shared/buttons/GithubLogin";
import GoogleLogin from "components/shared/buttons/GoogleLogin";
import LinkedinSignUp from "components/shared/buttons/LinkedinLogin";

import { postLoginViaGoogle } from "redux/handlers/appLoginHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { GoogleSignupResponseAPI } from "types/api/externalLoginApiInterface";

const RegistrationStart = ({ changeRegType }: { changeRegType: () => void }): ReactElement => {
 const dispatch = useAppDispatch();

 const handleGoogleCredentialResponse = async (response: GoogleSignupResponseAPI) => {
  await dispatch(postLoginViaGoogle(response.credential));
 };

 return (
  <Stack spacing={2}>
   <Button variant="kxFilledActionButton" onClick={changeRegType} sx={{ py: 3 }}>
    Registrati con KonsoleX
   </Button>
   <Divider>O</Divider>
   <Stack alignItems="center" justifyContent="center" direction="row" spacing={2}>
    <GoogleLogin
     onSuccess={handleGoogleCredentialResponse}
     onError={() => console.log("error")}
     method="signup_with"
    />
    <LinkedinSignUp message="app.signUpWith" />
    <GithubSignUp message="app.signUpWith" />
   </Stack>
  </Stack>
 );
};

export default RegistrationStart;
