import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getProviderPermissions } from "helpers/server";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";
import OsImage from "components/shared/images/OsImage";
import ProviderImage from "components/shared/images/ProviderImage";

import { getAutoRebootData } from "redux/handlers/serverHandler";

import { getServerPermissions } from "redux/selectors/permissionSelector";
import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerCronjobData } from "types/api/serversApiInterface";
import { IServerPermissionApi } from "types/api/userApiInterface";

import EnableAutoRestart from "../EnableAutoRestart";
import RemoveAutoRestart from "../RemoveAutoRestart";
import ServerVnc from "../ServerVNC";

import DisableBCP from "./monitoring/monitoringActions/DisableBCP";
import DisableManagement from "./monitoring/monitoringActions/DisableManagement";
import EnableBCP from "./monitoring/monitoringActions/EnableBCP";
import LockServer from "./monitoring/monitoringActions/LockServer";
import PowerServer from "./monitoring/monitoringActions/PowerServer";
import RebootServer from "./monitoring/monitoringActions/RebootServer";
import ServerIso from "./monitoring/monitoringActions/ServerIso";

const ServerRecapBox = (): ReactElement => {
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 const server = useSelector(getServerDetails);
 const permissions = useSelector(getServerPermissions);

 const [providerPermission, setProviderPermission] = useState<IServerPermissionApi | null>(null);
 const [autorebootData, setAutorebootData] = useState<IServerCronjobData | null>(null);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   if (server.id) {
    setAutorebootData(await dispatch(getAutoRebootData(server.id || 0)));
   }
   setLoading(false);
  })();
 }, [server, server.auto_reboot]);

 useEffect(() => {
  if (server.provider) {
   setProviderPermission(getProviderPermissions(server.provider, permissions || []));
  }
 }, [server]);

 const RenderServerType = ({ type }: { type: string | null }) => {
  switch (type) {
   case "webserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="WebIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "mailserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="AlternateEmailIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "nameserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="LanIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "container-server":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="DockerIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "container-website":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="DockerIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "container-mail":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="DockerIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "gatewayserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="RouterIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "firewall":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="FirewallIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "voipserver":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="PermPhoneMsgIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "custom":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="AutoAwesomeIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "webserver-apache":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <img
       width={24}
       height={24}
       src={`${process.env.PUBLIC_URL}/svg/providers/apache2.svg`}
       alt="provider"
      />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "webserver-nginx":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <img
       width={24}
       height={24}
       src={`${process.env.PUBLIC_URL}/svg/providers/nginx.svg`}
       alt="provider"
      />
      <Chip label={type} size="small" />
     </Stack>
    );
   case "zimbra":
    return (
     <Stack direction="row" spacing={2} alignItems={"left"}>
      <IconSelector icon="EmailIcon" />
      <Chip label={type} size="small" />
     </Stack>
    );
   default:
    return <FormattedMessage id="server.typeNotPresent" />;
  }
 };

 const formatTime = (data: string): string => {
  const dividedData = data.split(" ");
  return `${Number(dividedData[1]) < 10 ? `0${dividedData[1]}` : dividedData[1]}:${
   Number(dividedData[0].replaceAll('"', "")) < 10
    ? `0${dividedData[0].replaceAll('"', "")}`
    : dividedData[0].replaceAll('"', "")
  }`;
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1} p={2}>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Chip
      size="small"
      color={
       server.server_status === "off" || server.server_status === "stopped" ? "default" : "success"
      }
      sx={{ height: "16px" }}
      label={
       phoneView
        ? server.server_status === "off" || server.server_status === "stopped"
          ? "spento"
          : "acceso"
        : ""
      }
     />
     <Typography fontWeight="bold" fontSize={16} ml={1}>
      {server.serverName} ({server.server_type})
     </Typography>
    </Stack>
    <Grid container spacing={2}>
     <Grid xs={6} p={0}>
      <Stack spacing={1}>
       <Stack direction="row" spacing={1} alignItems="center">
        <OsImage osType={server.os || "debian"} />
        <Typography variant="extraSmall">{server.os}</Typography>
       </Stack>
       <Stack direction="row" spacing={1} alignItems="center">
        <ProviderImage provider={server?.provider || ""} />
        <Typography variant="extraSmall">{server?.provider}</Typography>
       </Stack>
       <Stack direction="row" spacing={1} alignItems="center">
        <Stack component="span" className={`fi fi-${server.countryCode}`} />
        <Typography variant="extraSmall">{server.location}</Typography>
       </Stack>
       <Stack direction="row" spacing={1} alignItems="center">
        <IconSelector icon="EngineeringIcon" props={{ fontSize: "small", color: "success" }} />
        <Typography variant="extraSmall">
         <FormattedMessage id="app.managementEnabled" />
        </Typography>
       </Stack>
       <Stack direction="row" alignItems="center">
        <Typography variant="extraSmall" fontWeight="bold">
         IPv4:
        </Typography>
        <Typography variant="extraSmall" px={0.5}>
         {server.ipAddress}
        </Typography>
        <ClickToCopy text={server.ipAddress || ""} />
       </Stack>
       {autorebootData && server.auto_reboot && (
        <Stack direction="row" alignItems="center">
         <Typography variant="extraSmall" fontWeight="bold">
          <FormattedMessage id="server.autoReboot" />
          {":"}
         </Typography>
         <Typography variant="extraSmall" px={0.5}>
          {formatTime(autorebootData.schedule)}
         </Typography>
        </Stack>
       )}
      </Stack>
     </Grid>
     <Grid xs={6} p={0}>
      <Stack>
       <Stack direction="row">
        <RenderServerType type={server.type || ""} />
       </Stack>
       {phoneView && (
        <Stack>
         <Typography fontWeight="bold" mt={1} textAlign="left" variant="extraSmall">
          <FormattedMessage id="server.securityActions" />
          {":"}
         </Typography>
         <Stack direction="row" spacing={1}>
          {providerPermission?.protection_rebuild && phoneView && (
           <LockServer id={server.id || 0} locked={server.locked || false} />
          )}
          {phoneView &&
           providerPermission?.business_continuity &&
           (server.business_continuity ? (
            <DisableBCP id={server.id || 0} />
           ) : (
            <EnableBCP id={server.id || 0} />
           ))}
          {phoneView && server.server_management_type === "management" && (
           <DisableManagement id={server.id || 0} />
          )}
         </Stack>
        </Stack>
       )}
      </Stack>
     </Grid>
    </Grid>
    <Divider />
    <Stack direction="row" justifyContent="space-between">
     {desktopViewPort && providerPermission?.vnc && (
      <Stack alignItems="center">
       <ServerVnc />
       <Typography variant="extraSmall">VNC</Typography>
      </Stack>
     )}
     {providerPermission?.update_iso && (
      <Stack alignItems="center">
       <ServerIso id={server.id || 0} iso_id={server.iso_id || 0} />
       <Typography variant="extraSmall">ISO</Typography>
      </Stack>
     )}
     {providerPermission?.reboot && (
      <Stack alignItems="center">
       <RebootServer id={server.id || 0} service_status={server.service_status || ""} />
       <Typography variant="extraSmall">
        <FormattedMessage id="server.rebootLabel" />
       </Typography>
      </Stack>
     )}
     {providerPermission?.start && (
      <Stack alignItems="center">
       <PowerServer
        id={server.id || 0}
        status={server.server_status || "off"}
        service_status={server.service_status || ""}
       />
       <Typography variant="extraSmall">
        {server.server_status === "off" || server.server_status === "stopped" ? (
         <FormattedMessage id="server.powerOn" />
        ) : (
         <FormattedMessage id="server.powerOff" />
        )}
       </Typography>
      </Stack>
     )}
     {!loading && server.os !== "windows" && (
      <Stack>
       {autorebootData ? (
        <RemoveAutoRestart id={autorebootData.id} />
       ) : (
        <EnableAutoRestart id={server.id || 0} />
       )}
       <Typography variant="extraSmall">Auto</Typography>
      </Stack>
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default ServerRecapBox;
