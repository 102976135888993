import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { formatBytes } from "helpers/numberFormatting";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUpdateMailDomain } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

type domainType = {
 comment: string;
 max_aliases: number;
 max_quota: number;
 mailboxes: number;
 domain_enabled: boolean;
 gal_enabled: boolean;
};

const EditMailDomain = ({ mailData }: { mailData: IContainerMailDomainData }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, reset, watch, setValue } = useForm({
  defaultValues: {
   comment: mailData.description || "",
   max_aliases: mailData.domain_limit.max_num_aliases_for_domain,
   max_quota: formatBytes(mailData.domain_limit.max_quota_for_domain, "MB"),
   mailboxes: mailData.domain_limit.max_num_mboxes_for_domain,
   domain_enabled: mailData.active,
   gal_enabled: mailData.gal
  }
 });

 const onSubmit: SubmitHandler<domainType> = async (data: domainType) => {
  setLoading(true);
  await dispatch(
   postUpdateMailDomain(
    mailData.id,
    data.comment,
    Number(data.mailboxes),
    Number(data.max_aliases),
    Number(data.max_quota),
    data.domain_enabled,
    data.gal_enabled
   )
  );
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip
    title={intl.formatMessage({ id: "container.maildomains.updateMailDomain" })}
    placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.updateMailDomain" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Alert severity="info">
      <FormattedMessage id="container.maildomains.updateMessage" />
     </Alert>
     <Controller
      name="comment"
      control={control}
      rules={{
       required: false
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "container.maildomains.comment" })}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Controller
      name="max_quota"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        type="number"
        label={intl.formatMessage({ id: "container.maildomains.maxQuota" })}
        error={watch("max_quota") < -1 || watch("max_quota") === 0 || !watch("max_quota")}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         (watch("max_quota") < -1 || watch("max_quota") === 0 || !watch("max_quota")) &&
         intl.formatMessage({ id: "container.maildomains.maxQuotaError" })
        }
       />
      )}
     />
     <Controller
      name="max_aliases"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        type="number"
        label={intl.formatMessage({ id: "container.maildomains.maxAliases" })}
        error={watch("max_aliases") < -1 || watch("max_aliases") === 0 || !watch("max_aliases")}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         (watch("max_aliases") < -1 || watch("max_aliases") === 0 || !watch("max_aliases")) &&
         intl.formatMessage({ id: "container.maildomains.maxAliasesError" })
        }
       />
      )}
     />
     <Controller
      name="mailboxes"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        type="number"
        label={intl.formatMessage({ id: "container.maildomains.maxUsers" })}
        error={watch("mailboxes") < -1 || watch("mailboxes") === 0 || !watch("mailboxes")}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         (watch("mailboxes") < -1 || watch("mailboxes") === 0 || !watch("mailboxes")) &&
         intl.formatMessage({ id: "container.maildomains.maxUsersError" })
        }
       />
      )}
     />
     <Controller
      name="domain_enabled"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        {...field}
        control={
         <Checkbox
          checked={watch("domain_enabled")}
          onChange={(e) => {
           setValue("domain_enabled", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "container.maildomains.domainEnabled" })}
       />
      )}
     />
     <Controller
      name="gal_enabled"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        {...field}
        control={
         <Checkbox
          checked={watch("gal_enabled")}
          onChange={(e) => {
           setValue("gal_enabled", e.target.checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "container.maildomains.galEnabled" })}
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditMailDomain;
