/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ipRegex, specialCharactersValidation } from "constants/regexp";
import { externalDatabaseSteps } from "constants/utility";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postCreateExternalConnection } from "redux/handlers/dockerHandler";
import { getServerByType } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerByTypeAPI } from "types/api/serversApiInterface";

import ExternalDatabaseRecap from "./databaseSections/ExternalDatabaseRecap";

const ExternalDatabaseWizard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);

 useEffect(() => {
  (async () => {
   setServers(await dispatch(getServerByType("container")));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   server: 0,
   upload_limit: 0,
   db_host: "",
   port: 9089,
   db_name: ""
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateExternalConnection(
    watch("server"),
    watch("db_host"),
    watch("port"),
    watch("db_name"),
    watch("upload_limit")
   )
  );
  setLoading(false);
  handleClose();
  handleReset();
  navigate("/utility/databases");
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="server"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("server", value?.id || 0);
        }}
        options={servers
         .filter((element) => !element.db_phpmyadmin_active)
         .map((server) => {
          return { label: server.server_name, id: server.id };
         })}
        value={servers
         .map((element) => {
          return { label: element.server_name, id: element.id };
         })
         .find((server) => server.id === getValues("server"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="utility.externalDatabases.add.server" />}
          error={formState.isDirty && !!formState?.errors?.server}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="upload_limit"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "utility.externalDatabases.add.uploadLimitField" })}
        error={formState.isDirty && !!formState?.errors?.upload_limit}
        InputLabelProps={{ shrink: true }}
        type="number"
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.upload_limit &&
         intl.formatMessage({ id: "login.passwordMinLength" })
        }
       />
      )}
     />
    );
   case 2:
    return (
     <Controller
      name="db_host"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "utility.externalDatabases.add.databaseHost" })}
        error={formState.isDirty && !ipRegex.test(getValues("db_host"))}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !ipRegex.test(getValues("db_host")) &&
         intl.formatMessage({ id: "utility.externalDatabases.add.databaseHostError" })
        }
       />
      )}
     />
    );
   case 3:
    return (
     <Controller
      name="port"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "utility.externalDatabases.add.port" })}
        error={formState.isDirty && (watch("port") < 1024 || watch("port") > 65535)}
        InputLabelProps={{ shrink: true }}
        type="number"
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         (watch("port") < 1024 || watch("port") > 65535) &&
         intl.formatMessage({ id: "utility.externalDatabases.add.portError" })
        }
       />
      )}
     />
    );
   case 4:
    return (
     <Controller
      name="db_name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "utility.externalDatabases.add.databaseName" })}
        error={formState.isDirty && !specialCharactersValidation.test(getValues("db_name"))}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !specialCharactersValidation.test(getValues("db_name")) &&
         intl.formatMessage({ id: "utility.externalDatabases.add.databaseNameSpecialCharacters" })
        }
       />
      )}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("server") === 0;
   case 1:
    return watch("upload_limit") === 0;
   case 2:
    return watch("db_host") === "" || !ipRegex.test(getValues("db_host"));
   case 3:
    return watch("port") < 1024 || watch("port") > 65535;
   case 4:
    return watch("db_name") === "" || !specialCharactersValidation.test(getValues("db_name"));
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("server") === 0 ||
   watch("upload_limit") === 0 ||
   watch("db_host") === "" ||
   watch("port") < 1024 ||
   watch("port") > 65535 ||
   watch("db_name") === "" ||
   !ipRegex.test(getValues("db_host")) ||
   !specialCharactersValidation.test(getValues("db_name"))
  );
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "utility", url: "/utility" },
      { name: "databases", url: "/utility/databases" },
      { name: "add", url: "/utility/databases/add" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <IconSelector icon="DatabaseIcon" />
     <Typography>
      <FormattedMessage id={"utility.externalDatabases.addDatabase"} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {externalDatabaseSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== externalDatabaseSteps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 4 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="app.wizard.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="app.wizard.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="app.wizard.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        </Step>
       ))}
      </Stepper>
     </Box>
     <ExternalDatabaseRecap
      server={servers.find((element) => element.id === watch("server"))?.server_name || ""}
      database={watch("db_name")}
      host={watch("db_host")}
      port={watch("port")}
      uploadLimit={watch("upload_limit")}
     />
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "utility.externalDatabases.add.createNewDatabase" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack>
      <FormattedMessage id="utility.externalDatabases.add.confirmCreation" />
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default ExternalDatabaseWizard;
