import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { formatBytes } from "helpers/numberFormatting";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

const MailDomainContainerRecap = ({
 server,
 name,
 comment,
 quota,
 users,
 aliases
}: {
 server: string;
 name: string;
 comment: string;
 quota: number;
 users: number;
 aliases: number;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const formatQuota = (): string => {
  const tempQuota = quota * 1024 * 1024;
  if (tempQuota > 1048570 && tempQuota < 1073741824) return `${formatBytes(tempQuota, "MB")} MB`;
  else return `${formatBytes(tempQuota, "GB")} GB`;
 };

 return (
  <Paper
   elevation={0}
   sx={{
    m: 5,
    p: 2,
    maxHeight: 400,
    position: "sticky",
    top: 30,
    minWidth: desktopViewPort ? 400 : "auto",
    borderRadius: "10px",
    boxShadow: 0
   }}>
   <Stack mb={2} alignItems="center" direction="row" spacing={1}>
    <IconSelector icon="DockerIcon" props={{ color: "secondary" }} />
    <Typography variant="h5" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.recap" />
    </Typography>
   </Stack>
   <Divider textAlign="left">
    <Typography variant="overline">
     <FormattedMessage id="container.maildomains.add.serverData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.serverName" />:
    </Typography>
    <Typography noWrap>{server}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="container.maildomains.add.domainData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.domainName" />:
    </Typography>
    <Typography noWrap>{name}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.comment" />:
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center">
     <Typography noWrap>{comment || "--"}</Typography>
    </Stack>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="container.maildomains.add.domainLimits" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.quotaLimit" />:
    </Typography>
    <Typography noWrap>{formatQuota()}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.usersLimit" />:
    </Typography>
    <Typography noWrap>{users}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={160} textAlign="start" fontWeight="bold">
     <FormattedMessage id="container.maildomains.add.aliasesLimit" />:
    </Typography>
    <Typography noWrap>{aliases}</Typography>
   </Stack>
  </Paper>
 );
};

export default MailDomainContainerRecap;
