import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppContext } from "AppContext";
import { formatMemLimit, formatMemVisual } from "helpers/addons";

import { isNil } from "ramda";

import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import CheckLogs from "../actions/CheckLogs";
import ClosePorts from "../actions/ClosePorts";
import EditSiteTuning from "../actions/EditSiteTuning";
import GenerateSshKey from "../actions/GenerateSshKey";
import OpenPorts from "../actions/OpenPorts";
import PowerOffSite from "../actions/PowerOffSite";
import RebootSite from "../actions/RebootSite";
import ShowSshKey from "../actions/ShowSshKey";

const WebAppSiteStatistics = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onWebsiteStatisticPerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === siteData.site_name) {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
      setNetIo(element?.net_io);
      setBlockIo(element?.block_io);
     }
    }
   }
  }
  !isNil(socket) && socket.on("container_website_performance", onWebsiteStatisticPerformance);

  return () => {
   !isNil(socket) && socket.off("container_website_performance", onWebsiteStatisticPerformance);
  };
 }, [socket]);

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Stack direction="row" spacing={2} p={1}>
     <IconSelector icon="DockerIcon" />
     <Typography fontWeight="bold">
      <FormattedMessage id="docker.website.siteStatistics" />
      {` ${siteData.site_name}`}
     </Typography>
    </Stack>
    <Stack>
     <Stack direction="row" alignItems="center" justifyContent="space-around">
      <Stack>
       <SemiCircularProgress value={cpu} maxValue={100} size={100} thickness={8} percent={true} />
       <Typography variant="subtitle2" fontWeight="bold">
        CPU
       </Typography>
      </Stack>
      <Stack>
       <SemiCircularProgress value={ram} maxValue={100} size={100} thickness={8} percent={true} />
       <Typography variant="subtitle2" fontWeight="bold">
        RAM
       </Typography>
      </Stack>
     </Stack>
     <Stack textAlign="left" p={1}>
      <Stack direction="row" spacing={2}>
       <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
        MEM limit:
       </Typography>
       <Typography variant="subtitle2" fontSize={12} minWidth={20}>
        {siteData.site_mem_limit ? formatMemVisual(siteData.site_mem_limit) : "not selected"}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
        CPU core limit:
       </Typography>
       <Typography variant="subtitle2" fontSize={12} minWidth={20}>
        {siteData.site_cpu_limit || "not selected"}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
        NET I/O:
       </Typography>
       <Typography variant="subtitle2" fontSize={12} minWidth={20}>
        {netIo}
       </Typography>
      </Stack>
      <Stack direction="row" spacing={2}>
       <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
        BLOCK I/O:
       </Typography>
       <Typography variant="subtitle2" fontSize={12} minWidth={20}>
        {blockIo}
       </Typography>
      </Stack>
      {siteData.system_service.version.includes("odoo") && (
       <>
        <Stack direction="row" alignItems="center" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Admin Password:
         </Typography>
         {siteData.odoo.odoo_admin_password ? (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           {siteData.odoo.odoo_admin_password}
          </Typography>
         ) : (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           Not selected
          </Typography>
         )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          DB Connection:
         </Typography>
         <Chip
          size="small"
          label={siteData.odoo.odoo_db_connected ? "On" : "Off"}
          color={siteData.odoo.odoo_db_connected ? "success" : "error"}
         />
        </Stack>
       </>
      )}
      {!isNil(siteData.open_ports.site.port) && (
       <>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          SSH Port:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.open_ports.site.port}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          SSH Root IP:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.open_ports.site.root}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          SSH Password:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.open_ports.site.password}
         </Typography>
        </Stack>
       </>
      )}
      {!isNil(siteData.ssh_data.site.ssh_key) && (
       <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
         SSH Key:
        </Typography>
        <ShowSshKey ssh_key={siteData.ssh_data.site.ssh_key} />
       </Stack>
      )}
      {siteData.framework.type === "wordpress" && (
       <>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Wordpress User:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.wordpress.wordpress_user}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Wordpress Password:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.wordpress.wordpress_password}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Wordpress Link:
         </Typography>
         {siteData.wordpress.wordpress_url ? (
          <NavLink to={siteData.wordpress.wordpress_url} target={"_blank"}>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            <FormattedMessage id="docker.website.access" />
           </Typography>
          </NavLink>
         ) : (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           not available
          </Typography>
         )}
        </Stack>
       </>
      )}
      {siteData.framework.type === "prestashop" && (
       <>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Prestashop User:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.prestashop.prestashop_user}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Prestashop Password:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.prestashop.prestashop_password || "not available"}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Prestashop Link:
         </Typography>
         {siteData.prestashop.prestashop_url ? (
          <NavLink to={siteData.prestashop.prestashop_url} target={"_blank"}>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            <FormattedMessage id="docker.website.access" />
           </Typography>
          </NavLink>
         ) : (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           not available
          </Typography>
         )}
        </Stack>
       </>
      )}
      {siteData.framework.type === "joomla" && (
       <>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Joomla User:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.joomla.joomla_user}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Joomla Password:
         </Typography>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          {siteData.joomla.joomla_password || "not available"}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
          Joomla Link:
         </Typography>
         {siteData.joomla.joomla_url ? (
          <NavLink to={siteData.joomla.joomla_url} target={"_blank"}>
           <Typography variant="subtitle2" fontSize={12} minWidth={20}>
            <FormattedMessage id="docker.website.access" />
           </Typography>
          </NavLink>
         ) : (
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           not available
          </Typography>
         )}
        </Stack>
       </>
      )}
     </Stack>
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" spacing={1} p={1}>
    <PowerOffSite id={siteData.id} type="site" />
    <RebootSite id={siteData.id} type="site" />
    <EditSiteTuning
     id={siteData.id}
     type="site"
     defaultMem={formatMemLimit(siteData.site_mem_limit)}
     defaultCpu={siteData.site_cpu_limit}
    />
    {isNil(siteData.open_ports.site.port) ? (
     <OpenPorts id={siteData.id} name={siteData.site_name} type="site" />
    ) : (
     <ClosePorts id={siteData.id} name={siteData.site_name} type="site" />
    )}
    <GenerateSshKey id={siteData.id} name={siteData.site_name} type="site" />
    <CheckLogs id={siteData.server_id_db} container={siteData.site_name} />
   </Stack>
  </Paper>
 );
};

export default WebAppSiteStatistics;
