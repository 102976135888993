import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

const SiteFramework = ({ framework }: { framework: string }): ReactElement => {
 const RenderIcon = (): ReactElement => {
  switch (framework) {
   case "react":
    return <IconSelector icon="ReactIcon" />;
   case "vue":
    return <IconSelector icon="VueIcon" />;
   case "wordpress":
    return <IconSelector icon="WordpressIcon" />;
   case "prestashop":
    return <IconSelector icon="PrestashopIcon" />;
   case "joomla":
    return <IconSelector icon="JoomlaIcon" />;
   default:
    return <></>;
  }
 };
 return (
  <Stack justifyContent="center" alignItems="center">
   <RenderIcon />
   <Typography variant="caption">{framework}</Typography>
  </Stack>
 );
};

export default SiteFramework;
