import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const ServerIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <rect x="2.57" y="11.55" width="14.87" height=".75" />
  <rect x="2.57" y="17.96" width="14.87" height="2.04" />
  <rect x="2.57" y="5.13" width="14.87" height=".75" />
  <path d="M19.74,0H.26C.12,0,0,.12,0,.26v4.08C0,4.49.12,4.6.26,4.6h19.47c.15,0,.26-.12.26-.26V.26c0-.15-.12-.26-.26-.26ZM2.43,3.59c0,.07-.06.13-.13.13s-.13-.06-.13-.13V1.02c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM3.72,3.59c0,.07-.06.13-.13.13s-.13-.06-.13-.13V1.02c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM5,3.59c0,.07-.06.13-.13.13s-.13-.06-.13-.13V1.02c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM6.28,3.59c0,.07-.06.13-.13.13s-.13-.06-.13-.13V1.02c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM17.7,2.43h-2.57c-.07,0-.13-.06-.13-.13s.06-.13.13-.13h2.57c.07,0,.13.06.13.13s-.06.13-.13.13Z" />
  <path d="M17.96,6.42H2.04s-1.77,0-1.77,0C.12,6.42,0,6.53,0,6.68v4.08c0,.15.12.26.26.26h19.47c.15,0,.26-.12.26-.26v-4.08c0-.15-.12-.26-.26-.26h-1.77ZM2.43,10c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM3.72,10c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM5,10c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM6.28,10c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM17.7,8.85h-2.57c-.07,0-.13-.06-.13-.13s.06-.13.13-.13h2.57c.07,0,.13.06.13.13s-.06.13-.13.13Z" />
  <path d="M17.7,12.83H.26C.12,12.83,0,12.95,0,13.09v4.08c0,.15.12.26.26.26h19.47c.15,0,.26-.12.26-.26v-4.08c0-.15-.12-.26-.26-.26h-2.04ZM2.43,16.41c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM3.72,16.41c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM5,16.41c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM6.28,16.41c0,.07-.06.13-.13.13s-.13-.06-.13-.13v-2.57c0-.07.06-.13.13-.13s.13.06.13.13v2.57ZM17.7,15.26h-2.57c-.07,0-.13-.06-.13-.13s.06-.13.13-.13h2.57c.07,0,.13.06.13.13s-.06.13-.13.13Z" />
 </svg>,
 "Server"
);
