import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AppContext } from "AppContext";
import dayjs, { Dayjs } from "dayjs";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postVisualizeLogs } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CheckLogs = ({ id, container }: { id: number; container: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const { socket } = useContext(AppContext);

 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [open, setOpen] = useState<boolean>(false);
 const [value, setValue] = useState<Dayjs | null>(dayjs("2025-03-21T2:30"));
 const [loadingLogs, setLoadingLogs] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => {
  setLoadingLogs(false);
  setOpen(false);
 };

 const handleGetLogs = async () => {
  setLoadingLogs(true);
  await dispatch(postVisualizeLogs(id, container, value?.hour() || 0, value?.minute() || 0));
 };

 const handleGetAllLogs = async () => {
  setLoadingLogs(true);
  await dispatch(postVisualizeLogs(id, container));
 };

 useEffect(() => {
  function onLogProgress(value: any) {
   if (value?.completed) {
    setLoadingLogs(false);
   }
  }

  !isNil(socket) && socket.on("command_progress", onLogProgress);

  return () => {
   !isNil(socket) && socket.off("command_progress", onLogProgress);
  };
 }, [socket]);

 return (
  <>
   <Tooltip title={<FormattedMessage id="utility.mailMigration.showLogs" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="SubjectIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "utility.mailMigration.showLogs" })}
    handleClose={handleClose}
    showConfirmButton={false}
    handleConfirm={handleClose}>
    <Stack spacing={2}>
     <Typography variant="subtitle2" fontStyle="italic">
      <FormattedMessage id="docker.website.visualizeLogsMessage" />
     </Typography>
     <Divider />
     <TimePicker
      label="Intervallo di tempo"
      value={value}
      onChange={(newValue) => setValue(newValue)}
     />
     {loadingLogs ? (
      <Stack direction="row" justifyContent="center">
       <CircularProgress />
      </Stack>
     ) : (
      <Stack
       direction={desktopViewPort ? "row" : "column"}
       spacing={2}
       alignItems="center"
       justifyContent="center">
       <Button variant="kxActionButton" onClick={handleGetLogs}>
        <FormattedMessage id="docker.website.visualizeOnInterval" />
       </Button>
       <Divider>O</Divider>
       <Button variant="kxActionButton" onClick={handleGetAllLogs}>
        <FormattedMessage id="docker.website.visualizeCompleteLogs" />
       </Button>
      </Stack>
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default CheckLogs;
