import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { is } from "ramda";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditSiteTuning } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const EditSiteTuning = ({
 id,
 type,
 defaultMem,
 defaultCpu
}: {
 id: number;
 type: string;
 defaultMem: number;
 defaultCpu: number;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, handleSubmit, formState } = useForm({
  defaultValues: {
   size: defaultMem ? defaultMem : type === "db" ? 200 : 50,
   core: defaultCpu || 0.1
  }
 });

 const formatMemory = (mem: number): string => {
  if (mem >= 1024) {
   return `${mem / 1024}g`;
  } else {
   return `${mem}m`;
  }
 };

 const onSubmit: SubmitHandler<{ size: number; core: number }> = async (data: {
  size: number;
  core: number;
 }) => {
  setLoading(true);
  await dispatch(postEditSiteTuning(id, data.core, formatMemory(data.size), type));
  setLoading(false);
  handleClose();
 };

 const checkData = (): boolean => {
  return (
   !is(Number, Number(watch("size"))) ||
   !is(Number, Number(watch("core"))) ||
   watch("size") === 0 ||
   Number(watch("size")) < (type === "db" ? 200 : 50) ||
   Number(watch("size")) > server.ramSize * 1024 ||
   watch("core") === 0 ||
   watch("core") < 0.1 ||
   Number(watch("core")) > server.cpuCore
  );
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.tuningResurces" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="TuneIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.tuningResurces" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkData()}>
    <Stack spacing={2}>
     <Alert severity="info">
      <FormattedMessage id="docker.website.tuningInfo" />
     </Alert>
     <Controller
      name="size"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "docker.website.size" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.size}
        InputProps={{
         startAdornment: <InputAdornment position="start">MB</InputAdornment>
        }}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.size &&
         intl.formatMessage({ id: "docker.website.invalidsize" })
        }
       />
      )}
     />
     <Controller
      name="core"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "docker.website.core" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.core}
        InputProps={{
         startAdornment: <InputAdornment position="start">Core</InputAdornment>
        }}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.core &&
         intl.formatMessage({ id: "docker.website.invalidcore" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditSiteTuning;
