import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const LitespeedIcon = createSvgIcon(
 <svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  x="0px"
  y="0px"
  width="800px"
  height="800px"
  viewBox="0 0 800 800"
  enableBackground="new 0 0 800 800"
  xmlSpace="preserve">
  <g>
   <g>
    <path
     fill="#5E8CDA"
     d="M702.492,398.328L531.625,227.438c-0.715-0.711-1.68-1.109-2.68-1.109c-0.07,0-0.145,0.004-0.223,0.008
           c-1.074,0.063-2.074,0.586-2.746,1.434l-72.828,92.41c-1.188,1.508-1.055,3.66,0.297,5.016l78.461,78.461
           c2.82,2.824,4.367,6.574,4.355,10.578c-0.012,3.961-1.551,7.664-4.34,10.422l-22.676,22.672c-1.277,1.281-1.473,3.281-0.473,4.777
           c5.652,8.457,14.906,22.383,15.77,23.926c2.84,5.684,3.789,20.195-4.59,26.57L341.445,639.703c-0.93,0.719-1.477,1.828-1.477,3
           c0,15.211-0.004,35.758-0.008,56.445c-0.004,20.875-0.012,41.891-0.012,57.695c0,7.895,0.004,14.492,0.008,19.109
           c0,2.313,0,4.133,0.004,5.371c0.004,2.637,0.008,4.234,2.098,5.273c0.523,0.262,1.102,0.402,1.691,0.402
           c1.563,0,2.188-0.617,3.695-2.121l2.984-2.98c2.613-2.605,6.441-6.434,11.313-11.297c9.742-9.73,23.648-23.633,40.344-40.313
           c33.383-33.363,77.887-77.852,122.391-122.336c89-88.961,177.996-177.938,177.996-177.938
           C711.121,421.258,711.117,407.047,702.492,398.328z"
    />
    <path
     fill="#093071"
     d="M475.43,32.695l-0.055-0.078c-0.039-0.047-0.07-0.094-0.109-0.137l-0.098-0.117
           c-0.684-0.816-1.668-1.309-2.734-1.363c-0.059,0-0.117,0-0.176,0c-0.996,0-1.961,0.398-2.676,1.109L113.613,388.121
           c-4.238,4.191-6.578,9.824-6.574,15.84c0.004,6.008,2.344,11.617,6.59,15.801l170.82,170.84c0.707,0.711,1.672,1.109,2.672,1.109
           c0.074,0,0.145,0,0.223-0.008c1.078-0.066,2.074-0.586,2.746-1.434l72.871-92.41c1.184-1.508,1.059-3.656-0.297-5.012
           l-78.539-78.465c-2.781-2.758-4.309-6.457-4.316-10.422c-0.004-3.988,1.527-7.715,4.313-10.492l22.723-22.758
           c1.293-1.301,1.473-3.332,0.43-4.836l-15.512-22.289c-6.266-8.883-4.32-21.492,4.324-28.121L474.66,178.453
           c0.926-0.715,1.48-1.82,1.48-3l0.055-140.477C476.195,34.152,475.93,33.352,475.43,32.695z"
    />
   </g>
   <path
    fill="#F2C913"
    d="M506.875,473.734L309.141,345.316l87.336,125.559c1.883,2.871,1.688,7.781-0.383,10.555L239.66,679.969
       c-2.77,3.594-5.078,7.488-3.125,11.512c1.059,2.109,3.617,3.789,6.086,3.996c3.09-0.031,5.453-1.383,8.707-3.859l251.414-193
       C510.43,492.742,510.223,478.512,506.875,473.734z"
   />
   <path
    fill="#F4D646"
    d="M579.465,126.578c-1.184-2.418-3.816-4.055-6.543-4.055c-2.43,0-4.734,1.051-8.242,3.734L313.16,319.406
       c-7.973,6.098-9.773,17.719-4.02,25.91l197.734,128.418c-2.863-4.195-86.512-125.453-87.355-126.68
       c-1.895-2.68-1.738-7.898,0.324-10.52l156.438-198.621l0.109-0.125C578.93,134.531,581.477,130.711,579.465,126.578z"
   />
  </g>
 </svg>,
 "Litespeed"
);
