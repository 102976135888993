import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { serverJobs } from "constants/tables";

import SortingTable from "components/shared/tables/SortingTable";

import { getServerOrders } from "redux/handlers/hetznerOrders";

import { getServerJobsList, getServerJobsNumber } from "redux/selectors/serverJobQueueSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServerJobQueue = (): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const serverJobList = useSelector(getServerJobsList);
 const serverJobNumber = useSelector(getServerJobsNumber);

 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_jobs", async (value): Promise<void> => {
    if (value?.completed) {
     await dispatch(getServerOrders(currentPage, rowPerPage, searchRef?.current?.value));
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_jobs");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   await dispatch(getServerOrders(0, 10));
   setIsLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    await dispatch(getServerOrders(currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(getServerOrders(0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(getServerOrders(startIndex, rowPerPage, searchRef?.current?.value));
 };

 return (
  <Stack mt={5}>
   <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
    <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
     <FormattedMessage id="server.jobQueue" />
    </Typography>
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack justifyContent="flex-start" alignItems="center" p={2} direction="row">
     <Typography variant="subtitle2">
      {serverJobNumber} <FormattedMessage id="app.totalJobs" />
     </Typography>
     <TextField
      size="small"
      autoComplete="off"
      inputRef={searchRef}
      label={<FormattedMessage id="search.job" />}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" /> }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Divider sx={{ borderColor: "#ff4900" }} />
    <Stack>
     <SortingTable
      tableData={serverJobs}
      maxWidth={650}
      caller="serverJobQueue"
      listToShow={serverJobList || []}
     />
     <TablePagination
      component="div"
      count={serverJobNumber || 0}
      page={currentPage}
      rowsPerPage={rowPerPage}
      onPageChange={handleOnPageChange}
      onRowsPerPageChange={handleOnRowsPerPageChange}
     />
    </Stack>
   </Paper>
  </Stack>
 );
};

export default ServerJobQueue;
