import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { resetPasswordAction } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

interface IFormInput {
 password: string;
 repeatPassword: string;
}

const NewPassword = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const param = useParams();

 const hash = param.hash || "";

 const [isLoading, setIsLoading] = useState<boolean>(false);

 const { control, handleSubmit, formState, getValues } = useForm({
  defaultValues: {
   password: "",
   repeatPassword: ""
  }
 });

 const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
  setIsLoading(true);
  const response = await dispatch(resetPasswordAction(hash, data.password));
  if (response) {
   navigate("/login");
  }
  setIsLoading(false);
 };

 return (
  <Stack>
   <form
    onSubmit={handleSubmit(onSubmit)}
    style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center"
    }}>
    <Controller
     name="password"
     control={control}
     rules={{ required: true }}
     render={({ field }) => (
      <TextField
       {...field}
       sx={{ my: 2 }}
       fullWidth={true}
       type="password"
       label={intl.formatMessage({ id: "login.passwordField" })}
       InputLabelProps={{ shrink: true }}
       error={formState.isDirty && !!formState?.errors?.password}
       autoComplete="new-password"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.password &&
        intl.formatMessage({ id: "login.invalidPassword" })
       }
      />
     )}
    />
    <Controller
     name="repeatPassword"
     control={control}
     rules={{
      required: true,
      validate: () => getValues("password") === getValues("repeatPassword")
     }}
     render={({ field }) => (
      <TextField
       {...field}
       sx={{ my: 2 }}
       fullWidth={true}
       type="password"
       label={intl.formatMessage({ id: "login.repeatField" })}
       InputLabelProps={{ shrink: true }}
       error={formState.isDirty && !!formState?.errors?.repeatPassword}
       autoComplete="new-password"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.repeatPassword &&
        intl.formatMessage({ id: "login.invalidPasswords" })
       }
      />
     )}
    />
    <Button
     disabled={isLoading}
     variant="kxFilledActionButton"
     onClick={handleSubmit(onSubmit)}
     sx={{ mt: 2, py: 3, width: "100%" }}>
     <FormattedMessage id="login.newPassword" />
    </Button>
   </form>
  </Stack>
 );
};

export default NewPassword;
