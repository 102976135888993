import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { resultSynchronization, showNotification } from "helpers/socketNotifications";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import notificationReducer from "redux/reducers/notificationReducer";

import { getAlertsNumber, getLatestAlert } from "redux/selectors/notificationsSelector";

import { useAppDispatch } from "hooks/reduxHook";

const Alerts = (): ReactElement => {
 const alertsNumber: number = useSelector(getAlertsNumber);
 const alertMessage = useSelector(getLatestAlert);
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();

 const [showAlertToast, setShowAlertToast] = useState<boolean>(false);

 const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  if (reason === "clickaway") {
   return;
  }

  setShowAlertToast(false);
  if (alertsNumber === 1) dispatch(notificationReducer.actions.removeAllAlerts());
  else dispatch(notificationReducer.actions.removeFirstAlert());
 };

 useEffect(() => {
  setShowAlertToast(false);
  if (alertsNumber > 0) setShowAlertToast(true);
  else setShowAlertToast(false);
 }, [alertsNumber]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_notification", (value): void => {
    dispatch(showNotification(value?.popupText, value?.status));
    dispatch(resultSynchronization(value?.popupText));
   });
  return () => {
   !isNil(socket) && socket.off("app_notification");
  };
 }, [socket]);

 return (
  <Snackbar
   open={showAlertToast}
   autoHideDuration={3000}
   onClose={handleClose}
   anchorOrigin={{ vertical: "top", horizontal: "right" }}>
   <Alert onClose={handleClose} severity={alertMessage?.type || "info"} sx={{ width: "100%" }}>
    {alertMessage?.message}
   </Alert>
  </Snackbar>
 );
};

export default Alerts;
