import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import { AccountCircle } from "@mui/icons-material";

import { Button, TextField, useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";

import ChangeAvatar from "components/profile/actions/ChangeAvatar";
import ChangePassword from "components/profile/actions/ChangePassword";
import ChangeProfile from "components/profile/actions/ChangeProfile";
import SetPassword from "components/profile/actions/SetPassword";

import { getPasswordIsSet } from "redux/handlers/userHandler";

import { getUserAvatar, getUserProfileData } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IProfile } from "types/redux/userInterfaces";

const ProfilePage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const intl = useIntl();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("sm"));

 const profileData: IProfile = useSelector(getUserProfileData);
 const profileAvatar: string = useSelector(getUserAvatar);

 const [changingData, setChangingData] = useState<boolean>(false);
 const [passwordIsSet, setPasswordIsSet] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setPasswordIsSet(await dispatch(getPasswordIsSet()));
  })();
 }, []);

 const RenderDataLabel = (data: string): ReactElement => {
  switch (data) {
   case "email":
    return <FormattedMessage id="profile.email" />;
   case "phone":
    return <FormattedMessage id="profile.phoneNumber" />;
   case "street":
    return <FormattedMessage id="profile.street" />;
   case "city":
    return <FormattedMessage id="profile.city" />;
   case "country":
    return <FormattedMessage id="profile.country" />;
   case "state":
    return <FormattedMessage id="profile.country" />;
   case "zipCode":
    return <FormattedMessage id="profile.zipCode" />;
   case "vatCode":
    return <FormattedMessage id="profile.vatCode" />;
   case "companyName":
    return <FormattedMessage id="login.companyField" />;
   case "pec":
    return <FormattedMessage id="profile.pec" />;
   case "sdi":
    return <FormattedMessage id="profile.sdi" />;
   default:
    return <FormattedMessage id="profile.name" />;
  }
 };

 const renderDatas = (id: string, value: any, index: number) => {
  if (id === "lastname") return <></>;
  else {
   if (id === "firstname")
    return (
     <ListItem key={`profile-data-${index}`}>
      <TextField
       variant="standard"
       InputProps={{
        readOnly: true
       }}
       label={RenderDataLabel(id)}
       value={
        isNil(value)
         ? intl.formatMessage({ id: "profile.dataNotFinalized" })
         : `${value} ${profileData.lastname}`
       }
      />
     </ListItem>
    );
   else {
    return (
     <ListItem key={`profile-data-${index}`}>
      <TextField
       variant="standard"
       InputProps={{
        readOnly: true
       }}
       label={RenderDataLabel(id)}
       value={isNil(value) ? intl.formatMessage({ id: "profile.dataNotFinalized" }) : value}
      />
     </ListItem>
    );
   }
  }
 };

 return (
  <Paper sx={{ p: 3, borderRadius: "10px", boxShadow: 0 }}>
   <Grid container>
    <Grid xs={desktopViewPort ? 8 : 12}>
     {!changingData ? (
      <Stack direction="row" spacing={2}>
       <List>
        {Object.entries(profileData)
         .filter((element, index) => index < 7)
         .map(([id, value], index) => renderDatas(id, value, index))}
       </List>
       <List>
        {Object.entries(profileData)
         .filter((element, index) => index >= 7)
         .map(([id, value], index) => renderDatas(id, value, index))}
       </List>
      </Stack>
     ) : (
      <ChangeProfile changeUpdate={() => setChangingData(false)} />
     )}
     {!changingData && (
      <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
       <Button onClick={() => setChangingData(true)} variant="kxActionButton">
        <FormattedMessage id="profile.updateProfile" />
       </Button>
       {passwordIsSet ? <ChangePassword /> : <SetPassword />}
      </Stack>
     )}
    </Grid>
    <Grid xs={desktopViewPort ? 4 : 12} mt={desktopViewPort ? 0 : 5}>
     <Stack alignItems={"center"}>
      {isNil(profileAvatar) ? (
       <AccountCircle sx={{ height: 150, width: 150, color: "#d3d3d3" }} />
      ) : (
       <Avatar src={`${profileAvatar}`} sx={{ height: 150, width: 150 }} />
      )}
      <ChangeAvatar />
     </Stack>
    </Grid>
   </Grid>
  </Paper>
 );
};

export default ProfilePage;
