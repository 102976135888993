import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

const typeList = [
 "custom",
 "webserver-apache",
 "webserver-nginx",
 "mailserver",
 "nameserver",
 "gateway",
 "container-server",
 "container-website",
 "container-mail"
];

const ServerTypeBox = ({
 selectedType,
 selectType
}: {
 selectedType: string;
 selectType: (type: string) => void;
}): ReactElement => {
 const searchType = (type: string): ReactElement => {
  switch (type) {
   case "custom":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="AutoAwesomeIcon" />
     </Stack>
    );
   case "webserver-apache":
    return (
     <img
      width={24}
      height={24}
      src={`${process.env.PUBLIC_URL}/svg/providers/apache2.svg`}
      alt="provider"
     />
    );
   case "webserver-nginx":
    return (
     <img
      width={24}
      height={24}
      src={`${process.env.PUBLIC_URL}/svg/providers/nginx.svg`}
      alt="provider"
     />
    );
   case "webserver":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="WebIcon" />
     </Stack>
    );
   case "mailserver":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="AlternateEmailIcon" />
     </Stack>
    );
   case "nameserver":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="LanIcon" />
     </Stack>
    );
   case "gateway":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="RouterIcon" />
     </Stack>
    );
   case "container-server":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="DockerIcon" />
     </Stack>
    );
   case "container-website":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="DockerIcon" />
     </Stack>
    );
   case "container-mail":
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="DockerIcon" />
     </Stack>
    );
   default:
    return (
     <Stack direction="row" justifyContent="center">
      <IconSelector icon="AutoAwesomeIcon" />
     </Stack>
    );
  }
 };
 return (
  <Stack direction="row" flexWrap="wrap">
   {typeList.map((element, index) => {
    return selectedType === element ? (
     <Paper
      sx={{
       backgroundColor: "#c8e6c9",
       px: 4,
       py: 2,
       m: 1
      }}
      onClick={() => selectType(element)}>
      {searchType(element)}
      <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
       <Typography fontWeight="bold" textTransform="uppercase">
        {element}
       </Typography>
      </Stack>
     </Paper>
    ) : (
     <Tooltip
      key={`type-paper-${index}`}
      title={<FormattedMessage id="server.add.selectTypeTitle" />}>
      <Paper
       sx={{
        px: 4,
        py: 2,
        cursor: "pointer",
        m: 1
       }}
       onClick={() => selectType(element)}>
       {searchType(element)}
       <Stack direction="row" justifyContent="center" alignItems="center" mt={1}>
        <Typography fontWeight="bold" textTransform="uppercase">
         {element}
        </Typography>
       </Stack>
      </Paper>
     </Tooltip>
    );
   })}
  </Stack>
 );
};

export default ServerTypeBox;
