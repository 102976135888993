import { IAllServers } from "types/api/serversApiInterface";
import { IUtilityInfo } from "types/api/utilityApiInterface";

export const filterUtilityInfo = (utilityInfo: IUtilityInfo, section: string) => {
 switch (section) {
  case "databases":
   return utilityInfo.external_databases;
  case "migrations":
   return utilityInfo.mail_migrations;
  default:
   return utilityInfo.external_databases;
 }
};

export const getServerNameFromId = (id: number, serverList: Array<IAllServers>): string => {
 const serverFiltered = serverList.filter((server) => {
  if (!server.id || server.id !== id) return false;

  return true;
 });

 if (serverFiltered[0]?.server_name) return serverFiltered[0]?.server_name;
 else return "";
};
