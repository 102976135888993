import React, { ReactElement, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormattedMessage, useIntl } from "react-intl";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getDisable2FA, getEnable2FA, postFirst2FAVerify } from "redux/handlers/userHandler";

import { get2FA } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const TwoFactor = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const doubleAuthentication = useSelector(get2FA);

 const [qrCode, setQrCode] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);
 const [enabling2FA, setEnabling2FA] = useState<boolean>(false);
 const [factorKey, setFactorKey] = useState<string>("");
 const [code, setCode] = useState<string>("");
 const [visualizeTokens, setVisualizeTokens] = useState<boolean>(false);
 const [backupCodes, setBackupCodes] = useState<Array<string>>([]);
 const [open, setOpen] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const enable2fa = async () => {
  setLoading(true);
  const qrData = await dispatch(getEnable2FA());
  setQrCode(qrData?.otpauth_url || "");
  setFactorKey(qrData?.secret || "");
  setEnabling2FA(true);
  setLoading(false);
 };

 const disable2fa = async () => {
  await dispatch(getDisable2FA());
  handleClose();
 };

 const handleVerify = async () => {
  setLoading(true);
  const result = await dispatch(postFirst2FAVerify(code));
  setBackupCodes(result);
  if (result.length > 0) {
   setEnabling2FA(false);
   setVisualizeTokens(true);
  }
  setLoading(false);
 };

 const handleConfirmCode = () => {
  setVisualizeTokens(false);
 };

 const handleDowloadBackupCode = () => {
  // Create a URL for the Blob
  const blob = new Blob(
   backupCodes.map((element) => {
    return `${element}\n`;
   }),
   {
    type: "text/plain;charset=utf-8"
   }
  );
  const url = URL.createObjectURL(blob);

  // Create an anchor tag for downloading
  const a = document.createElement("a");

  // Set the URL and download attribute of the anchor tag
  a.href = url;
  a.download = "backup_codes.txt";

  // Trigger the download by clicking the anchor tag
  a.click();
 };

 return (
  <>
   <Stack p={2}>
    <Typography textAlign="left" variant="h6">
     <FormattedMessage id="profile.security.2fa" />
    </Typography>
    <Divider />
    <Stack pt={1}>
     <Stack>
      <Typography textAlign="left" variant="subtitle2">
       <FormattedMessage id="profile.security.2faMessage" />
      </Typography>
     </Stack>
     <Divider />
     {!enabling2FA && !visualizeTokens && (
      <Stack spacing={1} mt={4}>
       <Stack alignItems="center">
        <IconSelector
         icon={doubleAuthentication ? "CheckCircleIcon" : "WarningIcon"}
         props={{ color: doubleAuthentication ? "success" : "error" }}
        />
       </Stack>
       <Typography fontWeight="bold" sx={{ color: doubleAuthentication ? "green" : "#FF1800" }}>
        <FormattedMessage
         id={doubleAuthentication ? "profile.security.2faActive" : "profile.security.2faOffline"}
        />
       </Typography>
      </Stack>
     )}
     {loading ? (
      <Stack direction="row" justifyContent="center">
       <CircularProgress />
      </Stack>
     ) : (
      <Stack>
       {doubleAuthentication && !visualizeTokens ? (
        <Stack direction="row" justifyContent="center" mt={4}>
         <Button variant="kxRedActionButton" onClick={handleOpen}>
          <FormattedMessage id="profile.security.disable2fa" />
         </Button>
        </Stack>
       ) : (
        <Stack>
         {enabling2FA && (
          <Stack alignItems="center" justifyContent="center" spacing={2} pt={2}>
           <Typography variant="subtitle2">
            <FormattedMessage id="profile.security.2faQrcodeExplain" />
           </Typography>
           <QRCode value={qrCode} />
           <Typography>{factorKey}</Typography>
           <Divider />
           <Stack spacing={2}>
            <TextField
             label={<FormattedMessage id="profile.security.2fafield" />}
             value={code}
             onChange={({ currentTarget }) => setCode(currentTarget.value)}
            />
            <Button disabled={code === ""} onClick={handleVerify}>
             <FormattedMessage id="profile.security.verifyCode" />
            </Button>
           </Stack>
          </Stack>
         )}
         {visualizeTokens && (
          <Stack mt={4} alignItems="center">
           <Stack alignItems="center" mb={1}>
            <IconSelector
             icon={doubleAuthentication ? "CheckCircleIcon" : "WarningIcon"}
             props={{ color: doubleAuthentication ? "success" : "error" }}
            />
           </Stack>
           <Typography variant="subtitle2">
            <FormattedMessage id="profile.security.2faEnabledSuccess" />
           </Typography>
           <Stack
            spacing={1}
            sx={{
             backgroundColor: "#F4F4F4",
             m: 2,
             py: 1,
             borderRadius: 5,
             width: "fit-content",
             px: 4
            }}
            alignItems="center">
            <Stack>
             {backupCodes.map((element, index) => {
              return <Typography key={`code-index-${index}`}>{element}</Typography>;
             })}
            </Stack>
            <Divider />
            <ButtonGroup variant="text" aria-label="Basic button group">
             <CopyToClipboard text={backupCodes.toString()}>
              <IconButton>
               <IconSelector icon="ContentCopyIcon" />
              </IconButton>
             </CopyToClipboard>
             <IconButton onClick={handleDowloadBackupCode}>
              <IconSelector icon="DownloadIcon" />
             </IconButton>
            </ButtonGroup>
           </Stack>
           <Stack direction="row" justifyContent="center">
            <Button onClick={handleConfirmCode}>
             <FormattedMessage id="app.confirm" />
            </Button>
           </Stack>
          </Stack>
         )}
         {!enabling2FA && !visualizeTokens && (
          <Stack direction="row" justifyContent="center" mt={4}>
           <Button variant="kxActionButton" onClick={enable2fa}>
            <FormattedMessage id="profile.security.enable2fa" />
           </Button>
          </Stack>
         )}
        </Stack>
       )}
      </Stack>
     )}
    </Stack>
   </Stack>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "profile.security.disable2fa" })}
    handleClose={handleClose}
    handleConfirm={disable2fa}>
    <Typography>
     <FormattedMessage id="profile.security.disable2faMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default TwoFactor;
