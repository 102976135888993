import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebounce } from "react-use";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { containerMailDomainAliasTable } from "constants/tables";

import IconSelector from "components/shared/images/IconSelector";
import SortingTable from "components/shared/tables/SortingTable";

import {
 IContainerMailDomainData,
 IContainerMailDomainsAlias
} from "types/api/containerMailDomainApiInterface";

import CreateAlias from "../actions/aliasActions/CreateAlias";
import AliasNotFound from "../manageComponents/AliasNotFound";

const MailDomainAlias = ({ mailData }: { mailData: IContainerMailDomainData }): ReactElement => {
 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState<boolean>(false);
 const [searchValue, setSearchValue] = useState<string>("");
 const [aliases, setAliases] = useState<Array<IContainerMailDomainsAlias>>([]);

 useEffect(() => {
  setLoading(true);
  setAliases(mailData.alias);
  setLoading(false);
 }, [mailData]);

 useDebounce(
  async () => {
   if (mailData.alias.find((element) => element.address.includes(searchValue))) {
    setAliases(mailData.alias.filter((element) => element.address.includes(searchValue)));
   } else if (searchValue === "") {
    setAliases(mailData.alias);
   } else {
    setAliases([]);
   }
  },
  1000,
  [searchValue]
 );

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="AlternateEmailIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="container.maildomains.alias" />
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : mailData.alias.length > 0 ? (
    <Stack width="stretch" p={2}>
     <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TextField
       size="small"
       autoComplete="new-password"
       label={<FormattedMessage id="container.maildomains.searchAlias" />}
       inputRef={searchRef}
       InputLabelProps={{ shrink: true }}
       InputProps={{
        startAdornment: <IconSelector icon="SearchIcon" props={{ color: "disabled" }} />,
        autoComplete: "off"
       }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      {mailData.alias.length >= mailData.domain_limit.max_num_aliases_for_domain ? (
       <Tooltip title={<FormattedMessage id="container.maildomains.tooMuchAliases" />}>
        <Stack>
         <IconSelector icon="InfoIcon" props={{ color: "kxBlue" }} />
        </Stack>
       </Tooltip>
      ) : (
       <Stack direction="row" spacing={1}>
        <CreateAlias id={mailData.id} domain={mailData.domain_name} />
       </Stack>
      )}
     </Stack>
     <SortingTable
      tableData={containerMailDomainAliasTable}
      maxWidth={650}
      caller="container_alias_mailbox"
      listToShow={aliases}
     />
    </Stack>
   ) : (
    <AliasNotFound id={mailData.id} domain={mailData.domain_name} />
   )}
  </Paper>
 );
};

export default MailDomainAlias;
