import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { containerMailboxTable } from "constants/tables";

import IconSelector from "components/shared/images/IconSelector";
import SortingTable from "components/shared/tables/SortingTable";

import { getAllMailboxes } from "redux/handlers/mailDomainContainerHandler";

import { getContainerMailboxes, getContainerReload } from "redux/selectors/containerMailSelector";

import { useAppDispatch } from "hooks/reduxHook";

import CreateMailbox from "../actions/mailboxActions/CreateMailbox";
import MailboxNotFound from "../manageComponents/MailboxNotFound";

const MailDomainMailbox = ({
 id,
 server_id,
 domain,
 max_mailboxes
}: {
 id: number;
 server_id: number;
 domain: string;
 max_mailboxes: number;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const searchRef = useRef<HTMLInputElement>(null);
 const reload = useSelector(getContainerReload);
 const mailboxes = useSelector(getContainerMailboxes);

 const [loading, setLoading] = useState<boolean>(false);
 const [searchValue, setSearchValue] = useState<string>("");

 useEffect(() => {
  if (reload) {
   (async () => {
    setLoading(true);
    if (id) {
     await dispatch(getAllMailboxes(id));
    }
    setLoading(false);
   })();
  }
 }, [reload]);

 useDebounce(
  async () => {
   if (!loading && searchValue !== "") {
    await dispatch(getAllMailboxes(id, searchValue));
   }
  },
  1000,
  [searchValue]
 );

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="InboxIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="container.maildomains.mailbox" />
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : mailboxes && mailboxes?.length > 0 ? (
    <Stack width="stretch" p={2}>
     <Stack direction="row" justifyContent="space-between" alignItems="center">
      <TextField
       size="small"
       autoComplete="new-password"
       label={<FormattedMessage id="container.maildomains.searchMailbox" />}
       inputRef={searchRef}
       InputLabelProps={{ shrink: true }}
       InputProps={{
        startAdornment: <IconSelector icon="SearchIcon" props={{ color: "disabled" }} />,
        autoComplete: "off"
       }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      {mailboxes.length >= max_mailboxes ? (
       <Tooltip title={<FormattedMessage id="container.maildomains.tooMuchMailboxes" />}>
        <Stack>
         <IconSelector icon="InfoIcon" props={{ color: "kxBlue" }} />
        </Stack>
       </Tooltip>
      ) : (
       <Stack direction="row" spacing={1}>
        <CreateMailbox id={id} server_id={server_id} domain={domain} />
       </Stack>
      )}
     </Stack>
     <SortingTable
      tableData={containerMailboxTable}
      maxWidth={650}
      caller="container_mailbox"
      listToShow={mailboxes}
     />
    </Stack>
   ) : (
    <MailboxNotFound id={id} server_id={server_id} domain={domain} />
   )}
  </Paper>
 );
};

export default MailDomainMailbox;
