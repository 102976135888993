import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

type Props = {
 children: ReactElement;
 open: boolean;
 close: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined;
 title: string;
 handleClose: () => void;
 handleConfirm: () => void;
 loading?: boolean;
 showConfirmButton?: boolean;
 disableCancel?: boolean;
 disabled?: boolean;
 confirmColor?:
  | "success"
  | "inherit"
  | "secondary"
  | "primary"
  | "error"
  | "info"
  | "warning"
  | undefined;
 cancelButtonText?: string;
 customSize?: {
  height?: number | string;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  maxHeight?: string;
  minHeight?: string;
  overflowY?: string;
 };
 thirdOption?: boolean;
 handleConfirmThird?: () => void;
 thirdText?: string;
 confirmText?: string;
};

const style = {
 position: "absolute" as "absolute",
 top: "50%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 maxWidth: 1200,
 minWidth: 700,
 maxHeight: "100vh",
 bgcolor: "background.paper",
 borderRadius: 6,
 boxShadow: 24,
 p: 4
};

const AppModal = ({
 children,
 open,
 close,
 title,
 handleClose,
 handleConfirm,
 loading,
 showConfirmButton = true,
 disableCancel = false,
 disabled = false,
 confirmColor = "success",
 cancelButtonText = "",
 customSize = {},
 thirdOption = false,
 thirdText = "",
 confirmText = "",
 handleConfirmThird
}: Props): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const heightViewPort = useMediaQuery("(min-height:800px)");
 const widthViewPort = useMediaQuery("(min-width:500px)");

 const modalStyle = desktopViewPort
  ? { ...style, borderRadius: "10px", maxHeight: 800, ...customSize }
  : heightViewPort && widthViewPort
  ? {
     ...style,
     borderRadius: "10px",
     overflowY: "scroll",
     maxHeight: 800,
     minWidth: 600,
     ...customSize
    }
  : {
     ...style,
     borderRadius: "10px",
     overflowY: "scroll",
     maxHeight: 800,
     minWidth: 300,
     ...customSize
    };

 return (
  <Modal open={open} onClose={close}>
   <Box sx={modalStyle}>
    <Typography variant="h5" borderBottom={"2px solid #ff4900"} mb={4}>
     {title}
    </Typography>
    {children}
    <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
     <Button onClick={handleClose} disabled={disableCancel} variant="kxModalDeny">
      <FormattedMessage id={cancelButtonText ? cancelButtonText : "app.back"} />
     </Button>
     {showConfirmButton && (
      <Button
       disabled={loading || disabled}
       variant="kxModalConfirm"
       color={confirmColor}
       onClick={handleConfirm}>
       <FormattedMessage id={confirmText ? confirmText : "app.confirm"} />
      </Button>
     )}
     {thirdOption && (
      <Button
       disabled={loading || disabled}
       variant="kxModalConfirm"
       color={confirmColor}
       onClick={handleConfirmThird}>
       <FormattedMessage id={thirdText} />
      </Button>
     )}
    </Stack>
   </Box>
  </Modal>
 );
};

export default AppModal;
