import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const JoomlaIcon = createSvgIcon(
 <svg
  width="800px"
  height="800px"
  viewBox="-1 0 258 258"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="xMidYMid">
  <g>
   <path
    d="M255.6673,35.5610895 C255.6673,16.9537743 240.583222,1.8696965 221.975907,1.8696965 C204.986272,1.8696965 190.941136,14.4475642 188.627175,30.7986926 C188.411019,30.6841401 188.28551,30.619393 188.28551,30.619393 C160.733136,18.1919377 137.523798,40.2029572 137.523798,40.2029572 L76.132607,101.894973 L100.389852,125.254724 L149.802833,76.7093541 C172.8767,53.6344903 185.440623,69.2524825 185.440623,69.2524825 C202.81077,83.9261634 186.039284,101.296311 186.039284,101.296311 L210.896187,125.254724 C231.193899,103.332358 232.3713,84.1990973 226.201401,68.9785525 C242.812514,66.898677 255.6673,52.736 255.6673,35.5610895"
    fill="#F9AE41"></path>
   <path
    d="M226.514179,190.506833 C226.628732,190.291673 226.693479,190.166163 226.693479,190.166163 C239.12193,162.612794 217.109914,139.404451 217.109914,139.404451 L155.416903,78.0112685 L132.058148,102.26951 L180.603518,151.68249 C203.678381,174.757354 188.060389,187.321276 188.060389,187.321276 C173.386708,204.691424 156.01656,187.919938 156.01656,187.919938 L132.058148,212.775844 C153.979518,233.073556 173.113774,234.250957 188.333323,228.081058 C190.413198,244.693167 204.576872,257.547953 221.751782,257.547953 C240.359097,257.547953 255.442179,242.463875 255.442179,223.85656 C255.442179,206.866926 242.865307,192.82179 226.514179,190.506833"
    fill="#EE4035"></path>
   <path
    d="M155.941852,133.040311 L106.528872,181.584685 C83.4540078,204.660545 70.8900856,189.043549 70.8900856,189.043549 C53.5199377,174.368872 70.2914241,156.99972 70.2914241,156.99972 L45.4355175,133.041307 C25.1378054,154.962677 23.9604047,174.095938 30.1293074,189.316482 C13.5171984,191.396358 0.66340856,205.558039 0.66340856,222.733946 C0.66340856,241.341261 15.7474864,256.424342 34.3548016,256.424342 C51.3444358,256.425339 65.389572,243.848467 67.7035331,227.497339 C67.9196887,227.611891 68.0451984,227.675642 68.0451984,227.675642 C95.5985681,240.105089 118.806911,218.092078 118.806911,218.092078 L180.199097,156.399066 L155.941852,133.040311"
    fill="#4F91CD"></path>
   <path
    d="M75.7271907,106.612545 C52.6523268,83.5376809 68.2683268,70.9737588 68.2683268,70.9737588 C82.944,53.6036109 100.313152,70.3750973 100.313152,70.3750973 L124.271564,45.5201868 C102.350195,25.2224747 83.2169339,24.0450739 67.9973852,30.2139767 C65.9165136,13.6008716 51.7548327,0.747081712 34.57793,0.747081712 C15.9716109,0.747081712 0.888529183,15.8311595 0.887533074,34.4384747 C0.887533074,51.4291051 13.4644047,65.4742412 29.8155331,67.7882023 C29.7009805,68.004358 29.6372296,68.1298677 29.6372296,68.1298677 C17.206786,95.6822412 39.2197977,118.889588 39.2197977,118.889588 L100.913805,180.28277 L124.27256,156.025525 L75.7271907,106.612545"
    fill="#7AC043"></path>
  </g>
 </svg>,
 "Joomla"
);
