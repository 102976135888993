import React, { ReactElement } from "react";
import dayjs from "dayjs";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import StyledBadge from "components/shared/badge/StyledBadge";

const MessageListElement = ({
 name,
 avatar,
 lastMessage,
 date,
 newMessage,
 border,
 creationDate,
 ticketSection
}: {
 name: string;
 avatar: string;
 lastMessage: string;
 date: string;
 newMessage: boolean;
 border: boolean;
 creationDate?: boolean;
 ticketSection?: boolean;
}): ReactElement => {
 const formatDate = (): string => {
  const formattedDate = new Date(date);
  const today = new Date();

  if (formattedDate.getTime() + 86400000 < today.getTime()) {
   return `${formattedDate.getUTCDate()}/${formattedDate.getUTCMonth() + 1}/${
    formattedDate.getUTCFullYear() - 2000
   }`;
  } else if (
   formattedDate.getUTCDate() < today.getUTCDate() ||
   formattedDate.getUTCDate() > today.getUTCDate()
  ) {
   return `${formattedDate.getUTCDate()}/${formattedDate.getUTCMonth() + 1}/${
    formattedDate.getUTCFullYear() - 2000
   }`;
  } else {
   return `${
    formattedDate.getHours() < 10 ? "0" + formattedDate.getHours() : formattedDate.getHours()
   }:${
    formattedDate.getMinutes() < 10 ? "0" + formattedDate.getMinutes() : formattedDate.getMinutes()
   }`;
  }
 };

 return (
  <Stack
   direction="row"
   spacing={2}
   p={1}
   sx={{
    cursor: "pointer",
    "&:hover": {
     backgroundColor: "#F0F0F0"
    }
   }}>
   <Stack justifyContent="center">
    {newMessage === true ? (
     <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot">
      <Avatar src={avatar} sx={{ border: "3px solid #ff4900" }} />
     </StyledBadge>
    ) : border ? (
     <Avatar src={avatar} sx={{ border: "3px solid green" }} />
    ) : ticketSection ? (
     <Avatar src={avatar} sx={{ border: "3px solid #ff4900" }} />
    ) : (
     <Avatar src={avatar} />
    )}
   </Stack>
   <Stack textAlign="left" minWidth={280} maxWidth={280}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography fontWeight="bold" textOverflow="ellipsis" noWrap>
      {name}
     </Typography>
     <Typography variant="caption" color="gray">
      {creationDate ? dayjs(date).format("DD/MM/YYYY") : formatDate()}
     </Typography>
    </Stack>
    <Typography color="gray" variant="caption" textOverflow="ellipsis" noWrap>
     {lastMessage}
    </Typography>
   </Stack>
  </Stack>
 );
};

export default MessageListElement;
