import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postPowerOffServer, postPowerOnServer } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const PowerServer = ({
 id,
 status,
 service_status
}: {
 id: number;
 status: string;
 service_status: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  if (status === "off" || status === "stopped") {
   await dispatch(postPowerOnServer(id));
  } else await dispatch(postPowerOffServer(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip
    title={intl.formatMessage({
     id: status === "off" || status === "stopped" ? "server.powerOn" : "server.powerOff"
    })}>
    <IconButton disabled={service_status === "expired"} onClick={handleOpen}>
     <IconSelector
      icon="PowerIcon"
      props={{ color: service_status === "expired" ? "disabled" : "kxOrange" }}
     />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({
     id: status === "off" || status === "stopped" ? "server.powerOnServer" : "server.powerOffServer"
    })}>
    <FormattedMessage
     id={
      status === "off" || status === "stopped"
       ? "server.powerOnServerMessage"
       : "server.powerOffServerMessage"
     }
    />
   </AppModal>
  </>
 );
};

export default PowerServer;
