import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import {
 IEmailMigrationAPI,
 IExternalDatabaseAPI,
 IUtilityAPI
} from "types/api/utilityApiInterface";

const defaultData = { dataset: [], totalCount: 0 };

const initialState: IUtilityAPI = { external_databases: defaultData, mail_migrations: defaultData };

const UtilityReducer = createSlice({
 name: "utilities",
 initialState,
 reducers: {
  setDatabases: (state, { payload }: PayloadAction<IExternalDatabaseAPI>) => {
   return { ...state, external_databases: payload };
  },
  resetDatabases: (state) => {
   return { ...state, external_databases: defaultData };
  },
  setMigrations: (state, { payload }: PayloadAction<IEmailMigrationAPI>) => {
   return { ...state, mail_migrations: payload };
  },
  resetMigrations: (state) => {
   return { ...state, mail_migrations: defaultData };
  },
  resetUtility: () => {
   return initialState;
  }
 }
});

export default UtilityReducer;
