import React from "react";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AntispamPerformance from "./manageSections/AntispamPerformance";
import PostfixPerformance from "./manageSections/PostfixPerformance";
import WebmailPerformance from "./manageSections/WebmailPerformance";

const MailDomainMonitoring = () => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Grid container spacing={2}>
   <Grid xs={desktopViewPort ? 4 : 12}>
    <WebmailPerformance />
   </Grid>
   <Grid xs={desktopViewPort ? 4 : 12}>
    <PostfixPerformance />
   </Grid>
   <Grid xs={desktopViewPort ? 4 : 12}>
    <AntispamPerformance />
   </Grid>
  </Grid>
 );
};

export default MailDomainMonitoring;
