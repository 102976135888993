import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postCreateContainerAlias } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

type aliasType = {
 address: string;
 goto: string;
};

const defaultAlias = {
 address: "",
 goto: ""
};

const CreateAlias = ({ id, domain }: { id: number; domain: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [newAlias, setNewAlias] = useState<aliasType>(defaultAlias);

 const handleOpen = async () => {
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateContainerAlias(id, `${newAlias.address}@${domain}`, newAlias.goto));
  setLoading(false);
  handleClose();
 };

 const handleReset = () => {
  setNewAlias(defaultAlias);
 };

 const handleChange = (key: keyof aliasType, value: string) => {
  setNewAlias({ ...newAlias, [key]: value });
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="container.maildomains.createAlias" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.createAlias" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <TextField
      autoComplete="off"
      value={newAlias.address}
      autoFocus
      label={intl.formatMessage({ id: "container.maildomains.aliasAddress" })}
      InputLabelProps={{ shrink: true }}
      helperText={<FormattedMessage id="container.maildomains.aliasAddressHelp" />}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => handleChange("address", currentTarget.value)}
      InputProps={{
       endAdornment: <InputAdornment position="end">{`@${domain}`}</InputAdornment>
      }}
     />
     <TextField
      autoComplete="off"
      value={newAlias.goto}
      autoFocus
      label={intl.formatMessage({ id: "container.maildomains.aliasGoto" })}
      InputLabelProps={{ shrink: true }}
      helperText={<FormattedMessage id="container.maildomains.aliasGotoHelp" />}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      onChange={({ currentTarget }) => handleChange("goto", currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CreateAlias;
