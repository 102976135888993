import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { containerSitesLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import DisabledIconButton from "components/shared/buttons/DisabledIconButton";
import IconSelector from "components/shared/images/IconSelector";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { postServerDetails } from "redux/handlers/serverHandler";
import { getServerByType } from "redux/handlers/utilityHandler";
import { getWebAppData } from "redux/handlers/webAppHandler";

import WebAppReducer from "redux/reducers/webAppReducer";

import { getDockerWebsitesList } from "redux/selectors/dockerWebsiteSelector";
import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerByTypeAPI } from "types/api/serversApiInterface";
import { IDockerSiteData } from "types/api/webAppApiInterface";

import ConnectCloudbucketFolder from "./actions/cloudbucketActions/ConnectCloudbucketFolder";
import DisconnectCloudbucketFolder from "./actions/cloudbucketActions/DisconnectCloudbucketFolder";
import InstallModule from "./actions/InstallModule";
import ResetJmPassword from "./actions/joomlaActions/ResetJmPassword";
import ResetJmUser from "./actions/joomlaActions/ResetJmUser";
import ConnectToDatabase from "./actions/odooActions/ConnectToDatabase";
import ResetPassword from "./actions/odooActions/ResetPassword";
import ResetPsPassword from "./actions/prestashopActions/ResetPsPassword";
import ResetPsUser from "./actions/prestashopActions/ResetPsUser";
import ResetPrivileges from "./actions/ResetPrivileges";
import RevokeSslCertificate from "./actions/RevokeSslCertificate";
import RunCustomScript from "./actions/RunCustomScript";
import ResetWpPassword from "./actions/wordpressActions/ResetWpPassword";
import ResetWpUser from "./actions/wordpressActions/ResetWpUser";
import WebAppBackups from "./manageSections/WebAppBackups";
import WebAppCronjobs from "./manageSections/WebAppCronjobs";
import WebAppDetails from "./manageSections/WebAppDetails";
import WebAppFilemanager from "./manageSections/WebAppFilemanager";
import WebAppPlugins from "./manageSections/WebAppPlugins";
import WebAppMonitoring from "./WebAppMonitoring";

const WebAppManage = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const location = useLocation();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const websites = useSelector(getDockerWebsitesList);
 const { cloudbucket_connected } = useSelector(getServerDetails);

 const { socket } = useContext(AppContext);
 const params = useParams<{ server_id: string; site_id: string; section: string }>();

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [siteData, setSiteData] = useState<IDockerSiteData | null>(null);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  function onDockerWebsite(value: any) {
   if (value?.completed && value?.section === "websites") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onDockerWebsite);

  return () => {
   !isNil(socket) && socket.off("docker_data", onDockerWebsite);
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   await dispatch(postServerDetails(Number(params.server_id)));
   await dispatch(getWebAppData(Number(params?.server_id), 0, 10, Number(params?.site_id)));
   setServers(await dispatch(getServerByType("container")));
  })();
 }, []);

 useEffect(() => {
  (async () => {
   setSiteData(websites.find((element) => element.id === Number(params?.site_id)) || null);
   setIsLoading(false);
  })();
 }, [websites]);

 useEffect(() => {
  if (reloadData) {
   (async () => {
    await dispatch(postServerDetails(Number(params.server_id)));
    await dispatch(getWebAppData(Number(params?.server_id), 0, 10, Number(params?.site_id)));
    dispatch(WebAppReducer.actions.setReload(true));
    setReloadData(false);
   })();
  }
 }, [reloadData]);

 useEffect(() => {
  dispatch(WebAppReducer.actions.setReload(true));
 }, [params.section]);

 useEffect(() => {
  if (siteData && siteData?.status !== "online")
   navigate(`/servers/manage/${params?.server_id}/container`);
 }, [siteData]);

 const RenderWebAppSections = (): ReactElement => {
  if (siteData) {
   switch (params.section) {
    case "details":
     return <WebAppDetails siteData={siteData} />;
    case "cronjobs":
     return <WebAppCronjobs id={siteData.id} container_id={siteData.container_id} />;
    case "filemanager":
     return (
      <WebAppFilemanager
       id={siteData.id}
       active={siteData.filemanager.filemanager_active}
       file_password={siteData.filemanager.filemanager_password}
       file_port={siteData.filemanager.filemanager_port}
      />
     );
    case "backup":
     return (
      <WebAppBackups
       id={siteData.id}
       hasDb={siteData.container_id_db ? true : false}
       backupData={siteData.backup_data}
      />
     );
    case "plugins":
     return <WebAppPlugins siteData={siteData} />;
    default:
     return <></>;
   }
  } else {
   return <></>;
  }
 };

 const RenderTypeIcon = (): ReactElement => {
  switch (siteData?.system_service.service) {
   case "php":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="PhpIcon" />
      <Typography variant="subtitle2">PHP Stack</Typography>
     </Stack>
    );
   case "odoo":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="OdooIcon" />
      <Typography variant="subtitle2">Odoo</Typography>
     </Stack>
    );
   case "tomcat":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="TomcatIcon" />
      <Typography variant="subtitle2">Tomcat</Typography>
     </Stack>
    );
   case "litespeed":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="LitespeedIcon" />
      <Typography variant="subtitle2">Litespeed</Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 const RenderFramework = (): ReactElement => {
  switch (siteData?.framework.type) {
   case "react":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="ReactIcon" />
      <Typography variant="subtitle2">React.js</Typography>
     </Stack>
    );
   case "vue":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="VueIcon" />
      <Typography variant="subtitle2">Vue.js</Typography>
     </Stack>
    );
   case "custom":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="AutoAwesomeIcon" props={{ color: "secondary" }} />
      <Typography variant="subtitle2">Custom</Typography>
     </Stack>
    );
   case "wordpress":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="WordpressIcon" props={{ color: "secondary" }} />
      <Typography variant="subtitle2">Wordpress</Typography>
     </Stack>
    );
   case "prestashop":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="PrestashopIcon" />
      <Typography variant="subtitle2">Prestashop</Typography>
     </Stack>
    );
   case "joomla":
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="JoomlaIcon" />
      <Typography variant="subtitle2">Joomla</Typography>
     </Stack>
    );
   default:
    return (
     <Stack direction="row" spacing={1} alignItems="center">
      <IconSelector icon="AutoAwesomeIcon" props={{ color: "secondary" }} />
      <Typography variant="subtitle2">Custom</Typography>
     </Stack>
    );
  }
 };

 const RenderEnviroment = (): ReactElement => {
  if (siteData?.enviroment.type?.includes("nodejs")) {
   return (
    <Stack direction="row" spacing={1} alignItems="center">
     <IconSelector icon="NodejsIcon" />
     <Typography variant="subtitle2">{siteData.enviroment.type}</Typography>
    </Stack>
   );
  } else if (siteData?.enviroment.type?.includes("nextjs")) {
   return (
    <Stack direction="row" spacing={1} alignItems="center">
     <IconSelector icon="NodejsIcon" />
     <Typography variant="subtitle2">{siteData.enviroment.type}</Typography>
    </Stack>
   );
  } else {
   return <></>;
  }
 };

 return !isLoading ? (
  <Container maxWidth={false} sx={{ mx: 2, width: "auto" }}>
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app" },
      { name: "webapp", url: "/app/sites" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container spacing={3}>
    <Grid xs={desktopViewPort ? 3 : 12}>
     <Stack mt={4} spacing={1}>
      <Paper elevation={0} sx={{ mt: 1, pb: 0.7, borderRadius: "10px", boxShadow: 0, p: 2 }}>
       <Stack
        spacing={1}
        alignItems="flex-start"
        pb={1}
        sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
         {siteData?.site_name}
        </Typography>
        <Stack direction="row" spacing={1}>
         <Tooltip title={intl.formatMessage({ id: "sites.details.goToServer" })}>
          <NavLink
           to={`/servers/manage/${servers.find((element) => element.id === siteData?.server_id_db)
            ?.id}/container`}
           style={{
            textDecoration: "none"
           }}>
           <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
            <IconSelector icon="ServerIcon" props={{ fontSize: "small", color: "primary" }} />
            <Typography variant="subtitle2" color="primary">
             {servers.find((element) => element.id === siteData?.server_id_db)?.server_name}
            </Typography>
           </Stack>
          </NavLink>
         </Tooltip>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage
           id={
            siteData?.framework.type && siteData.framework.type !== "custom"
             ? "docker.website.webFramework"
             : "docker.website.webSystem"
           }
          />
          {":"}
         </Typography>
         {siteData?.framework.type && siteData.framework.type !== "custom" ? (
          <RenderFramework />
         ) : (
          <RenderTypeIcon />
         )}
        </Stack>
        {siteData?.enviroment.type && (
         <Stack direction="row" spacing={2} alignItems="center">
          <Typography fontWeight="bold" variant="subtitle2">
           <FormattedMessage id="docker.website.serverEnviroment" />
           {":"}
          </Typography>
          <RenderEnviroment />
         </Stack>
        )}
       </Stack>
       <Divider />
       {siteData && (
        <Stack direction="row" justifyContent="space-around" pt={1}>
         {!siteData?.system_service.version.includes("odoo") &&
          siteData.framework.type !== "wordpress" &&
          siteData.framework.type !== "prestashop" &&
          siteData.framework.type !== "joomla" && (
           <>
            <InstallModule
             id={siteData.id}
             installed_modules={siteData.installed_modules || []}
             version={siteData.system_service.version}
            />
            <RunCustomScript id={siteData.id} />
            <ResetPrivileges id={siteData.id} />
           </>
          )}
         {siteData.system_service.version.includes("odoo") && (
          <>
           {siteData.odoo.odoo_db_connected ? (
            <DisabledIconButton icon="DatabaseConnectIcon" />
           ) : (
            <ConnectToDatabase id={siteData.id} />
           )}
           <ResetPassword id={siteData.id} oldPassword={siteData.odoo.odoo_admin_password || ""} />
          </>
         )}
         {siteData.framework.type === "wordpress" && (
          <>
           <ResetWpUser
            id={siteData.id}
            oldUser={siteData.wordpress.wordpress_user || "admin"}
            password={siteData.wordpress.wordpress_password || ""}
           />
           <ResetWpPassword
            id={siteData.id}
            oldPassword={siteData.wordpress.wordpress_password || ""}
           />
           <ResetPrivileges id={siteData.id} />
          </>
         )}
         {siteData.framework.type === "prestashop" && (
          <>
           <ResetPsUser
            id={siteData.id}
            oldUser={siteData.prestashop.prestashop_user || ""}
            password={siteData.prestashop.prestashop_password || ""}
           />
           <ResetPsPassword
            id={siteData.id}
            oldPassword={siteData.prestashop.prestashop_password || ""}
           />
           <ResetPrivileges id={siteData.id} />
          </>
         )}
         {siteData.framework.type === "joomla" && (
          <>
           <ResetJmUser
            id={siteData.id}
            password={siteData.joomla.joomla_password || ""}
            oldUser={siteData.joomla.joomla_user || ""}
           />
           <ResetJmPassword id={siteData.id} oldPassword={siteData.joomla.joomla_password || ""} />
           <ResetPrivileges id={siteData.id} />
          </>
         )}
         {cloudbucket_connected && (
          <Stack direction="row">
           <ConnectCloudbucketFolder
            id={siteData.id}
            folders={siteData.bucket_folder || []}
            available={siteData.available_folder || []}
           />
           {siteData.bucket_folder.length > 0 && (
            <DisconnectCloudbucketFolder id={siteData.id} folders={siteData.bucket_folder} />
           )}
          </Stack>
         )}
         <RevokeSslCertificate id={siteData.id} sslOn={siteData.ssl} />
        </Stack>
       )}
      </Paper>
      {desktopViewPort ? (
       <SectionMenu
        menuList={containerSitesLinks.map((item) => ({
         name: item.name,
         url: `/app/sites/container/${params?.server_id}/${params?.site_id}/${item.url}`,
         icon: item.icon
        }))}
       />
      ) : (
       <MobileSectionMenu
        links={containerSitesLinks}
        disabled={[]}
        url={`/app/sites/container/${params?.server_id}/${params?.site_id}`}
       />
      )}
     </Stack>
    </Grid>
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     {siteData && <WebAppMonitoring rowData={siteData} />}
     {siteData && <RenderWebAppSections />}
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default WebAppManage;
