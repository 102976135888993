import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatBytes, formatMegaBytes } from "helpers/numberFormatting";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import MailPerformance from "components/apps/containerMailDomain/manageComponents/MailPerformance";
import DeleteMailDomain from "components/docker/containerMail/actions/DeleteMailDomain";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

const ContainerMailDomainTable = ({
 rowData,
 index
}: {
 rowData: IContainerMailDomainData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const handleGoToManage = (id: number, server_id: number) => {
  navigate(`/app/mail/container/${server_id}/${id}/details`);
 };

 const formatQuota = (quota: number, occupied: number): string => {
  const firstFormat = formatBytes(quota, "MB");
  const secondFormat = formatBytes(occupied, "MB");
  if (firstFormat >= 1024) {
   return `${
    secondFormat > 1024
     ? formatMegaBytes(secondFormat, "GB").toFixed(0)
     : `${secondFormat.toFixed(0)} MB`
   }/${formatMegaBytes(firstFormat, "GB").toFixed(0)} GB`;
  } else {
   return `${secondFormat.toFixed(0)}/${firstFormat.toFixed(0)} MB`;
  }
 };

 return (
  <TableRow
   key={`container-mail-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => handleGoToManage(rowData.id, rowData.server_id_db)}>
   <TableCell component="th" scope="row">
    <Chip
     size="small"
     color={rowData.active ? "success" : "error"}
     label={rowData.active ? "Online" : "Offline"}
    />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography variant="subtitle2" fontWeight="bold">
      {rowData.domain_name}
     </Typography>
     <Typography variant="caption" fontStyle="italic">
      {rowData.description || "No description"}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    {rowData.domain_limit.max_quota_for_domain === 0 ? (
     <Typography variant="subtitle2">
      <FormattedMessage id="sites.quotaUnlimited" />
     </Typography>
    ) : (
     <Stack>
      <Typography variant="subtitle2">
       {formatQuota(
        rowData.domain_limit.max_quota_for_domain,
        Number(rowData.domain_limit.max_quota_occupied)
       )}
      </Typography>
      <MailPerformance
       value={
        (Number(rowData.domain_limit.max_quota_occupied) /
         rowData.domain_limit.max_quota_for_domain) *
        100
       }
      />
     </Stack>
    )}
   </TableCell>
   <TableCell align="right" onClick={(e) => e.stopPropagation()}>
    <Stack direction="row" justifyContent="flex-end">
     <DeleteMailDomain id={rowData.id} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default ContainerMailDomainTable;
