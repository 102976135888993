import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { emailValidation } from "constants/regexp";

import IconSelector from "components/shared/images/IconSelector";
import PhoneInput from "components/shared/phoneInput/PhoneInput";

import { postRegistration } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import ConfirmRegistration from "./ConfirmRegistration";
import RegistrationStart from "./RegistrationStart";

interface IFormInput {
 email: string;
 password: string;
 name: string;
 surname: string;
 repeatPassword: string;
 companyName: string;
 telephone: string;
}

const Registration = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [registrationResult, setRegistrationResult] = useState<boolean>(false);
 const [registerViaKx, setRegisterViaKx] = useState<boolean>(false);

 const { control, handleSubmit, setValue, formState, getValues } = useForm({
  defaultValues: {
   email: "",
   password: "",
   name: "",
   surname: "",
   repeatPassword: "",
   companyName: "",
   telephone: ""
  }
 });

 const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
  setIsLoading(true);
  let result = await dispatch(
   postRegistration(
    data.email,
    data.password,
    data.name,
    data.surname,
    data.repeatPassword,
    data.companyName,
    data.telephone
   )
  );
  setIsLoading(false);
  setRegistrationResult(result);
 };

 return registrationResult ? (
  <ConfirmRegistration />
 ) : registerViaKx ? (
  <Stack>
   <Stack direction="row">
    <Button
     onClick={() => setRegisterViaKx(false)}
     variant="kxActionButton"
     startIcon={<IconSelector icon="ArrowBackIcon" />}>
     <FormattedMessage id="app.back" />
    </Button>
   </Stack>
   <form
    onSubmit={handleSubmit(onSubmit)}
    autoComplete="off"
    style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center"
    }}>
    <Controller
     name="name"
     control={control}
     rules={{ required: true }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       size="small"
       label={intl.formatMessage({ id: "login.nameField" })}
       error={formState.isDirty && !!formState?.errors?.name}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.name &&
        intl.formatMessage({ id: "login.invalidName" })
       }
      />
     )}
    />
    <Controller
     name="surname"
     control={control}
     rules={{ required: true }}
     render={({ field }) => (
      <TextField
       {...field}
       size="small"
       fullWidth={true}
       label={intl.formatMessage({ id: "login.surnameField" })}
       error={formState.isDirty && !!formState?.errors?.surname}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.surname &&
        intl.formatMessage({ id: "login.invalidSurname" })
       }
      />
     )}
    />
    <Controller
     name="email"
     control={control}
     rules={{
      required: true,
      pattern: emailValidation
     }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       size="small"
       label={intl.formatMessage({ id: "login.emailField" })}
       error={formState.isDirty && !!formState?.errors?.email}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.email &&
        intl.formatMessage({ id: "login.invalidMail" })
       }
      />
     )}
    />
    <Controller
     name="companyName"
     control={control}
     rules={{
      required: true
     }}
     render={({ field }) => (
      <TextField
       {...field}
       size="small"
       fullWidth={true}
       label={intl.formatMessage({ id: "login.companyNameField" })}
       error={formState.isDirty && !!formState?.errors?.companyName}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       autoComplete="off"
       helperText={
        formState.isDirty &&
        !!formState?.errors?.companyName &&
        intl.formatMessage({ id: "login.invalidCompany" })
       }
      />
     )}
    />
    <Controller
     name="telephone"
     control={control}
     rules={{
      required: true
     }}
     render={({ field }) => (
      <PhoneInput
       placeholder={intl.formatMessage({ id: "profile.phoneNumber" })}
       size="small"
       onChange={(value) => {
        setValue("telephone", value);
       }}
      />
     )}
    />
    <Controller
     name="password"
     control={control}
     rules={{
      required: true,
      minLength: 8
     }}
     render={({ field }) => (
      <Tooltip
       title={intl.formatMessage({ id: "login.passwordMinLength" })}
       placement="bottom-start">
       <TextField
        {...field}
        fullWidth={true}
        type="password"
        size="small"
        label={intl.formatMessage({ id: "login.passwordField" })}
        autoComplete="new-password"
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        error={formState.isDirty && !!formState?.errors?.password}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.password &&
         intl.formatMessage({ id: "login.invalidPasswordFormat" })
        }
       />
      </Tooltip>
     )}
    />
    <Controller
     name="repeatPassword"
     control={control}
     rules={{
      required: true,
      minLength: 8,
      validate: () => getValues("password") === getValues("repeatPassword")
     }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       type="password"
       size="small"
       label={intl.formatMessage({ id: "login.repeatField" })}
       autoComplete="new-password"
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       error={formState.isDirty && !!formState?.errors?.repeatPassword}
       helperText={
        formState.isDirty &&
        !!formState?.errors?.repeatPassword &&
        intl.formatMessage({ id: "login.invalidPasswords" })
       }
      />
     )}
    />
    <Button
     disabled={isLoading}
     variant="kxFilledActionButton"
     onClick={handleSubmit(onSubmit)}
     sx={{ mt: 2, py: 3, width: "100%" }}>
     {intl.formatMessage({ id: "login.registerButton" })}
    </Button>
   </form>
  </Stack>
 ) : (
  <RegistrationStart changeRegType={() => setRegisterViaKx(true)} />
 );
};

export default Registration;
