import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUninstallMemcached, postUninstallRedis } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RemovePlugin = ({ id, plugin }: { id: number; plugin: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  switch (plugin) {
   case "redis":
    await dispatch(postUninstallRedis(id));
    break;
   case "memcached":
    await dispatch(postUninstallMemcached(id));
    break;
   default:
    break;
  }
  setLoading(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.removePlugin" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="RemoveFromQueueIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.removePlugin" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.website.removePluginMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default RemovePlugin;
