import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { webAppWizardContainers } from "constants/webApp";

import IconSelector from "components/shared/images/IconSelector";

import CustomWebAppWizard from "./wizards/CustomWebAppWizard";
import JoomlaWebAppWizard from "./wizards/JoomlaWebAppWizard";
import OdooWebAppWizard from "./wizards/OdooWebAppWizard";
import PrestashopWebAppWizard from "./wizards/PrestashopWebAppWizard";
import SpecificWebAppWizard from "./wizards/SpecificWebAppWizard";
import WordpressWebAppWizard from "./wizards/WordpressWebAppWizard";

const WebAppContainerSelection = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [selectedSection, setSelectedSection] = useState<string>("");

 const RenderSelectedSection = (): ReactElement => {
  switch (selectedSection) {
   case "php":
    return <CustomWebAppWizard type="php" />;
   case "react":
    return <SpecificWebAppWizard type="react" />;
   case "vue":
    return <SpecificWebAppWizard type="vue" />;
   case "odoo":
    return <OdooWebAppWizard />;
   case "tomcat":
    return <CustomWebAppWizard type="tomcat" />;
   case "litespeed":
    return <CustomWebAppWizard type="litespeed" />;
   case "wordpress":
    return <WordpressWebAppWizard />;
   case "prestashop":
    return <PrestashopWebAppWizard />;
   case "joomla":
    return <JoomlaWebAppWizard />;
   default:
    return <></>;
  }
 };

 return selectedSection === "" ? (
  <Stack height={"fit-content"} justifyContent="center" p={4}>
   <Grid container direction="column" spacing={5}>
    <Grid container direction="column" xs={12}>
     <Stack textAlign="left">
      <Typography variant="h6" textTransform="uppercase">
       Default Web App
      </Typography>
     </Stack>
     <Grid container>
      {webAppWizardContainers
       .filter((element) => element.section === "custom")
       .map((element, index) => {
        return (
         <Grid
          justifyContent="flex-start"
          key={`web-app-wizard-${index}`}
          xs={desktopViewPort ? 3 : 12}
          sx={{ display: "flex" }}>
          <Paper
           onClick={() => setSelectedSection(element.type)}
           sx={{
            minHeight: 200,
            width: "stretch",
            px: 2,
            cursor: "pointer",
            "&:hover": {
             backgroundColor: "#c8e6c9"
            }
           }}>
           <Stack justifyContent="center" alignItems="center" height="100%">
            <IconSelector
             icon={element.icon}
             props={
              element.type === "custom"
               ? { sx: { fontSize: 80 }, color: "secondary" }
               : { sx: { fontSize: 80 } }
             }
            />
            <Typography variant="h5">
             <FormattedMessage id={element.title} />
            </Typography>
            <Stack pt={2}>
             <Typography variant="subtitle2">
              <FormattedMessage id={element.subtitle} />
             </Typography>
            </Stack>
           </Stack>
          </Paper>
         </Grid>
        );
       })}
     </Grid>
    </Grid>
    <Grid container direction="column" xs={12}>
     <Stack textAlign="left">
      <Typography variant="h6" textTransform="uppercase">
       CMS Web App
      </Typography>
     </Stack>
     <Grid container>
      {webAppWizardContainers
       .filter((element) => element.section === "cms")
       .map((element, index) => {
        return (
         <Grid
          justifyContent="flex-start"
          key={`web-app-wizard-${index}`}
          xs={desktopViewPort ? 3 : 12}
          sx={{ display: "flex" }}>
          <Paper
           onClick={() => setSelectedSection(element.type)}
           sx={{
            minHeight: 200,
            width: "stretch",
            px: 2,
            cursor: "pointer",
            "&:hover": {
             backgroundColor: "#c8e6c9"
            }
           }}>
           <Stack justifyContent="center" alignItems="center" height="100%">
            <IconSelector
             icon={element.icon}
             props={
              element.type === "custom"
               ? { sx: { fontSize: 80 }, color: "secondary" }
               : { sx: { fontSize: 80 } }
             }
            />
            <Typography variant="h5">
             <FormattedMessage id={element.title} />
            </Typography>
            <Stack pt={2}>
             <Typography variant="subtitle2">
              <FormattedMessage id={element.subtitle} />
             </Typography>
            </Stack>
           </Stack>
          </Paper>
         </Grid>
        );
       })}
     </Grid>
    </Grid>
    <Grid container direction="column" xs={12}>
     <Stack textAlign="left" direction="row" alignItems="center">
      <Typography variant="h6" textTransform="uppercase">
       Web App per sviluppo
      </Typography>
     </Stack>
     <Grid container>
      {webAppWizardContainers
       .filter((element) => element.section === "web")
       .map((element, index) => {
        return (
         <Grid
          justifyContent="flex-start"
          key={`web-app-wizard-${index}`}
          xs={desktopViewPort ? 3 : 12}
          sx={{ display: "flex" }}>
          <Paper
           onClick={() => setSelectedSection(element.type)}
           sx={{
            minHeight: 200,
            width: "stretch",
            px: 2,
            cursor: "pointer",
            "&:hover": {
             backgroundColor: "#c8e6c9"
            }
           }}>
           <Stack justifyContent="center" alignItems="center" height="100%">
            <IconSelector
             icon={element.icon}
             props={
              element.type === "custom"
               ? { sx: { fontSize: 80 }, color: "secondary" }
               : { sx: { fontSize: 80 } }
             }
            />
            <Typography variant="h5">
             <FormattedMessage id={element.title} />
            </Typography>
            <Stack pt={2}>
             <Typography variant="subtitle2">
              <FormattedMessage id={element.subtitle} />
             </Typography>
            </Stack>
           </Stack>
          </Paper>
         </Grid>
        );
       })}
     </Grid>
    </Grid>
   </Grid>
  </Stack>
 ) : (
  <RenderSelectedSection />
 );
};

export default WebAppContainerSelection;
