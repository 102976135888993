import React, { ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReportIcon from "@mui/icons-material/Report";
import UpdateIcon from "@mui/icons-material/Update";
import WarningIcon from "@mui/icons-material/Warning";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getAllDomainsOrders } from "redux/handlers/domainsHandler";

import { getDomainsOrdersList } from "redux/selectors/domainsOrdersSelector";

import { useAppDispatch } from "hooks/reduxHook";

const DomainBox = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const navigate = useNavigate();

 const domainsOrders = useSelector(getDomainsOrdersList);

 const [isLoading, setIsLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllDomainsOrders(0, 10, ""));
   setIsLoading(false);
  })();
 }, []);

 const formatStatus = (status: string): ReactElement => {
  switch (status) {
   case "in order":
    return <UpdateIcon style={{ color: "blue" }} />;
   case "failed":
    return <ReportIcon style={{ color: "#FF1800" }} />;
   default:
    return <WarningIcon style={{ color: "blue" }} />;
  }
 };

 const handleGoTo = () => {
  navigate("/domains");
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, p: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" pb={1} justifyContent="space-between">
    <Stack direction="row">
     <Typography fontWeight="bold" variant="h5" textTransform="uppercase">
      <FormattedMessage id="app.domainsOrders" />
     </Typography>
     <Typography fontWeight="bold" variant="h5" textTransform="uppercase">
      {`(${intl.formatMessage({ id: "app.domains" })})`}
     </Typography>
    </Stack>
    <Tooltip title={intl.formatMessage({ id: "app.goToSection" })} placement="top">
     <IconButton onClick={handleGoTo}>
      <IconSelector icon="ArrowOutwardIcon" props={{ fontSize: "small" }} />
     </IconButton>
    </Tooltip>
   </Stack>
   {isLoading ? (
    <Stack>
     <CircularProgress />
    </Stack>
   ) : domainsOrders.length > 0 ? (
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow sx={{ backgroundColor: "transparent" }}>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="domain.name" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="domain.orderType" />
         </Typography>
        </TableCell>
        <TableCell>
         <Typography variant="kxColoredSmall" fontWeight="bold">
          <FormattedMessage id="domain.creationDate" />
         </Typography>
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {domainsOrders.map((rowData, index) => {
        return (
         <TableRow key={`domain-dashboard-${index}`}>
          <TableCell component="th" scope="row">
           <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
            <Typography variant="extraSmall">{formatStatus(rowData.status)}</Typography>
            <Typography variant="small" fontWeight="bold">
             {rowData.domain}
            </Typography>
           </Stack>
          </TableCell>
          <TableCell component="th" scope="row">
           {rowData.action || "Operation info not found"}
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="subtitle2">
            <FormattedDate value={rowData.createdAt} />
           </Typography>
          </TableCell>
         </TableRow>
        );
       })}
      </TableBody>
     </Table>
    </TableContainer>
   ) : (
    <Alert severity="info">
     <Typography>
      <FormattedMessage id="dashboard.orderNotFound" />
     </Typography>
    </Alert>
   )}
  </Paper>
 );
};

export default DomainBox;
