/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getPriceWithIva } from "helpers/generic";

import HelpIcon from "@mui/icons-material/Help";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { moneyList, paymentMethod } from "constants/recharge";

import { postPayWithTransfer } from "redux/handlers/rechargeHandler";
import { getBillingIsSet, postChangeRidSettings } from "redux/handlers/userHandler";

import {
 getUserBalance,
 getUserBonus,
 getUserCash,
 getUserId,
 getUserTax
} from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import ConfirmSepa from "./actions/ConfirmSepa";
import BankTransferInfo from "./components/BankTransferInfo";
import CardPaymentInfo from "./components/CardPaymentInfo";
import RidTransferInfo from "./components/RidTransferInfo";
import StripeSubscription from "./components/StripeSubscription";

const Recharge = (): ReactElement => {
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const dispatch = useAppDispatch();

 const balance = useSelector(getUserBalance);
 const bonus = useSelector(getUserBonus);
 const cash = useSelector(getUserCash);
 const tax = useSelector(getUserTax);
 const id = useSelector(getUserId);

 const [selectedMoney, setSelectedMoney] = useState<number>(0);
 const [selectedPayment, setSelectedPayment] = useState<string>("");
 const [iban, setIban] = useState<string>("");
 const [ridConfirmed, setRidConfirmed] = useState<boolean>(false);
 const [bank, setBank] = useState<string>("");
 const [dateOfPay, setDateOfPay] = useState<dayjs.Dayjs>(dayjs().add(1, "month"));
 const [dataAvailable, setDataAvailable] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setDataAvailable(await dispatch(getBillingIsSet()));
  })();
 }, []);

 const renderButtonText = (): ReactElement => {
  switch (selectedPayment) {
   case "card":
    return <FormattedMessage id="recharge.card.button" />;
   case "sepa_bank":
    return <FormattedMessage id="recharge.sepa.button" />;
   default:
    return <FormattedMessage id="recharge.bank.button" />;
  }
 };

 const handlePayment = async () => {
  switch (selectedPayment) {
   case "card":
    navigate("/profile/recharge/payment", { state: { amount: selectedMoney } });
    break;
   case "sepa_bank":
    await dispatch(
     postChangeRidSettings(id, iban, selectedMoney, ridConfirmed, dateOfPay.toISOString(), bank)
    );
    navigate("/profile/security");
    break;
   default:
    await dispatch(postPayWithTransfer(selectedMoney));
    navigate("/profile/orders");
    break;
  }
 };

 const checkDisableConfirm = () => {
  switch (selectedPayment) {
   case "sepa_bank":
    return iban === "" || bank === "" || ridConfirmed === false || selectedMoney < 100;
   default:
    return false;
  }
 };

 const setNewData = (
  type: "iban" | "bank" | "confirmed" | "date" | "money",
  value: boolean | string | number | dayjs.Dayjs
 ) => {
  switch (type) {
   case "iban":
    setIban(value as string);
    break;
   case "bank":
    setBank(value as string);
    break;
   case "confirmed":
    setRidConfirmed(value as boolean);
    break;
   case "date":
    setDateOfPay(value as dayjs.Dayjs);
    break;
   case "money":
    setSelectedMoney(value as number);
    break;
   default:
    break;
  }
 };

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
   {dataAvailable ? (
    <Stack spacing={2} p={2}>
     <Stack direction="row" spacing={1}>
      <Typography>
       <FormattedMessage id="app.personalCredit" />:
      </Typography>
      <Typography>
       <FormattedNumber style="currency" currency="EUR" value={balance} />
      </Typography>
      <Typography>
       <FormattedMessage
        id="profile.recharge.balanceMessage"
        values={{ bonus: bonus, cash: cash }}
       />
      </Typography>
      <Tooltip
       title={
        <FormattedMessage
         id="profile.recharge.explaining"
         values={{ bonus: bonus, cash: cash, balance: balance }}
        />
       }>
       <HelpIcon color="disabled" sx={{ cursor: "pointer" }} fontSize="small" />
      </Tooltip>
     </Stack>
     <Divider />
     <Alert severity="info" sx={{ textAlign: "left" }}>
      <Typography mb={2}>
       <FormattedMessage id="recharge.cashbackMessage" />
      </Typography>
      <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mb={2}>
       <Typography>
        <FormattedMessage id="recharge.cashback1" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.cashback2" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.cashback3" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.cashback4" />
       </Typography>
      </Stack>
      <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mb={2}>
       <Typography>
        <FormattedMessage id="recharge.cashback5" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.cashback6" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.cashback7" />
       </Typography>
      </Stack>
      <Typography>
       <FormattedMessage id="recharge.cashbackMessage2" />
      </Typography>
     </Alert>
     <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
       <InputLabel>
        <FormattedMessage id="recharge.method" />
       </InputLabel>
       <Select
        value={selectedPayment}
        label={<FormattedMessage id="recharge.method" />}
        onChange={(e) => {
         setSelectedPayment(e.target.value);
         setSelectedMoney(0);
        }}>
        {paymentMethod.map((payment, key) => {
         return (
          <MenuItem key={`payment-list-${key}`} value={payment.code}>
           <FormattedMessage id={payment.label} />
          </MenuItem>
         );
        })}
       </Select>
      </FormControl>
      {selectedPayment !== "auto_pay" && selectedPayment !== "sepa_bank" && (
       <FormControl fullWidth>
        <InputLabel>
         <FormattedMessage id="recharge.import" />
        </InputLabel>
        <Select
         disabled={selectedPayment === ""}
         value={selectedMoney || ""}
         label={<FormattedMessage id="recharge.import" />}
         onChange={(e) => setSelectedMoney(e.target.value as number)}>
         {moneyList
          .filter((element) => element.available.includes(selectedPayment))
          .map((money, key) => {
           return (
            <MenuItem key={`money-list-${key}`} value={money.value}>
             {money.label}
            </MenuItem>
           );
          })}
        </Select>
       </FormControl>
      )}
     </Stack>
     <Stack spacing={2}>
      {selectedMoney !== 0 && selectedPayment === "bank" && <BankTransferInfo />}
      {selectedMoney !== 0 && selectedPayment === "card" && <CardPaymentInfo />}
      {selectedPayment === "sepa_bank" && (
       <RidTransferInfo
        iban={iban}
        bank={bank}
        ridConfirmed={ridConfirmed}
        date={dateOfPay}
        money={selectedMoney}
        setData={(type, value) => setNewData(type, value)}
       />
      )}
      {selectedPayment === "auto_pay" && <StripeSubscription />}
      {selectedMoney !== 0 && selectedPayment && (
       <Stack spacing={1} alignItems={"flex-start"}>
        <Stack direction={"row"}>
         <Typography pl={1} variant="body2" fontWeight={"bold"}>
          <FormattedMessage id="recharge.totalPlusIva" />
         </Typography>
         <Chip
          color="info"
          size="small"
          label={`${getPriceWithIva(selectedMoney, tax)} €`}
          sx={{ borderRadius: 1, marginLeft: 1 }}
         />
        </Stack>
        {selectedPayment === "sepa_bank" ? (
         <ConfirmSepa
          disabledButton={checkDisableConfirm()}
          buttonText="recharge.sepa.button"
          confirmSelection={handlePayment}
         />
        ) : (
         <Button variant="kxActionButton" disabled={checkDisableConfirm()} onClick={handlePayment}>
          {renderButtonText()}
         </Button>
        )}
       </Stack>
      )}
     </Stack>
    </Stack>
   ) : (
    <Stack p={2}>
     <Alert severity="error" variant="filled">
      <FormattedMessage id="app.dataMissingRecharge" />
     </Alert>
    </Stack>
   )}
  </Paper>
 );
};

export default Recharge;
