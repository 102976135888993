export const appsList = [
 {
  name: "apps.websites",
  link: "/app/sites",
  icon: "WebIcon",
  add: "/app/sites/add",
  internElement: "apps.website.totalWebsites",
  sectionMessage: "apps.website.websiteInfo",
  section: "website",
  addTitle: "apps.add.website"
 },
 {
  name: "apps.maildomains",
  link: "/app/mail",
  icon: "MailIcon",
  add: "/app/mail/add",
  internElement: "apps.maildomain.totalMails",
  sectionMessage: "apps.maildomain.mailInfo",
  section: "maildomain",
  addTitle: "apps.add.maildomain"
 },
 {
  name: "apps.zones",
  link: "/app/zone",
  icon: "ZoneIcon",
  add: "/app/zone/add",
  internElement: "apps.zone.totalZones",
  sectionMessage: "apps.zone.zoneInfo",
  section: "zone",
  addTitle: "apps.add.zone"
 }
];

export const customAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 }
];

export const specificAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.appType",
  description: "docker.website.add.appTypeMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 },
 {
  label: "docker.website.add.appSystem",
  description: "docker.website.add.appSystemMessage"
 },
 {
  label: "docker.website.add.enviromentVersion",
  description: "docker.website.add.enviromentVersionMessage"
 }
];

export const odooAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 },
 {
  label: "docker.website.add.databaseData",
  description: "docker.website.add.databaseDataMessage"
 },
 {
  label: "docker.website.add.databaseUser",
  description: "docker.website.add.databaseUserMessage"
 }
];

export const wordpressAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.wodrpressVersion",
  description: "docker.website.add.wodrpressVersionMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 },
 {
  label: "docker.website.add.databaseData",
  description: "docker.website.add.databaseDataMessage"
 },
 {
  label: "docker.website.add.databaseUser",
  description: "docker.website.add.databaseUserMessage"
 }
];

export const prestashopAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.prestashopVersion",
  description: "docker.website.add.prestashopVersionMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 },
 {
  label: "docker.website.add.databaseData",
  description: "docker.website.add.databaseDataMessage"
 },
 {
  label: "docker.website.add.databaseUser",
  description: "docker.website.add.databaseUserMessage"
 }
];

export const joomlaAppSteps = [
 {
  label: "docker.website.add.selectServer",
  description: "docker.website.add.selectServerMessage"
 },
 {
  label: "docker.website.add.siteName",
  description: "docker.website.add.siteNameMessage"
 },
 {
  label: "docker.website.add.joomlaVersion",
  description: "docker.website.add.joomlaVersionMessage"
 },
 {
  label: "docker.website.add.appVersion",
  description: "docker.website.add.appVersionMessage"
 },
 {
  label: "docker.website.add.databaseData",
  description: "docker.website.add.databaseDataMessage"
 },
 {
  label: "docker.website.add.databaseUser",
  description: "docker.website.add.databaseUserMessage"
 }
];

export const maildomainAppSteps = [
 {
  label: "container.maildomains.add.selectServer",
  description: "container.maildomains.add.selectServerMessage"
 },
 {
  label: "container.maildomains.add.domainName",
  description: "container.maildomains.add.domainNameMessage"
 },
 {
  label: "container.maildomains.add.domainDetails",
  description: "container.maildomains.add.domainDetailsMessage"
 }
];

export const mailboxContainerSteps = [
 {
  label: "container.maildomains.mailboxName",
  description: ""
 },
 {
  label: "container.maildomains.mailboxPassword",
  description: ""
 },
 {
  label: "container.maildomains.mailboxSettings",
  description: ""
 },
 {
  label: "container.maildomains.spamSettings",
  description: ""
 },
 {
  label: "container.maildomains.recap",
  description: ""
 }
];
