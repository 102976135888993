import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postResetPsAdmin } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ResetPsUser = ({
 id,
 password,
 oldUser
}: {
 id: number;
 password: string;
 oldUser: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, formState, watch, reset } = useForm({
  defaultValues: {
   user: oldUser
  }
 });

 const onSubmit: SubmitHandler<{
  user: string;
 }> = async (data: { user: string }) => {
  setLoading(true);
  await dispatch(postResetPsAdmin(id, data.user, password));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.resetAdmin" />}>
    <IconButton onClick={handleOpen} color="kxOrange">
     <IconSelector icon="ManageAccountsIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.resetAdmin" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || watch("user") === "" || watch("user") === oldUser}>
    <Stack spacing={2}>
     <Controller
      name="user"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <TextField
        {...field}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "docker.website.adminUser" })}
        InputLabelProps={{ shrink: true }}
        error={formState.isDirty && !!formState?.errors?.user}
        helperText={
         formState.isDirty &&
         !!formState?.errors?.user &&
         intl.formatMessage({ id: "docker.website.invalidUser" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ResetPsUser;
