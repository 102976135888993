import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type cronType = {
 week: string;
 minutes: string;
 hours: string;
 days: string;
 month: string;
};

const CronRecap = ({
 file,
 timing,
 handleBack,
 handleReset
}: {
 file: string;
 timing: cronType;
 handleBack: () => void;
 handleReset: () => void;
}): ReactElement => {
 return (
  <Stack p={2} spacing={2}>
   <Stack spacing={1}>
    <Typography>
     <FormattedMessage id="docker.website.checkForData" />
    </Typography>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.cronjobFile" />:
     </Typography>
     <Typography>{file}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.cronjobExecution" />:
     </Typography>
     <Typography>{`${timing.minutes} ${timing.hours} ${timing.days} ${timing.month} ${timing.week}`}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button disabled>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default CronRecap;
