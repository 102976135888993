import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const MemcachedIcon = createSvgIcon(
 <svg
  fill="currentColor"
  width="800px"
  height="800px"
  viewBox="0 0 14 14"
  role="img"
  focusable="false"
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg">
  <path d="m 11.343263,0.99999969 c -0.382973,0 -8.7445609,0 -8.7445609,0 0,0 -0.297698,0.04264 -0.297698,0.42561201 l 0,11.1274223 c 0,0 -0.085276,0.446802 0.3615269,0.446802 0.4468024,0 8.723115,0 8.723115,0 0,0 0.319655,0.02145 0.319655,-0.425356 0,-0.446802 0,-11.1488683 0,-11.1488683 0,0 0.02094,-0.42561201 -0.362038,-0.42561201 z M 6.3543948,10.529912 c -0.141189,0 -0.255315,-0.163912 -0.255315,-0.366122 0,-0.2022103 0.114126,-0.3661233 0.255315,-0.3661233 0.14119,0 0.255316,0.163913 0.255316,0.3661233 0,0.20221 -0.114126,0.366122 -0.255316,0.366122 z m 1.285515,0 c -0.14119,0 -0.255316,-0.163912 -0.255316,-0.366122 0,-0.2022103 0.114126,-0.3661233 0.255316,-0.3661233 0.141189,0 0.255315,0.163913 0.255315,0.3661233 0,0.20221 -0.114381,0.366122 -0.255315,0.366122 z m 2.196736,-0.44476 c -0.05336,0.426378 -0.242806,0.44476 -0.242806,0.44476 0,0 -0.271145,-0.02298 -0.779223,-0.0069 -0.507567,0.01583 -0.625013,0.03191 -0.529269,-0.28723 0.09574,-0.3191453 0.562205,-1.5158093 0.526461,-3.5902493 -0.03651,-2.113503 -0.610971,-0.89003 -0.610971,-0.89003 0,0 -0.568843,1.772656 -0.841265,2.67724 -0.359995,1.195132 -0.702118,0.05362 -0.702118,0.05362 -0.7651808,-2.986172 -1.2288341,-3.548122 -1.4841497,-2.877918 -0.2553157,0.670203 0.3191446,4.228538 0.4309728,4.5476823 0.1118283,0.319145 0,0.367144 0,0.367144 l -1.2607487,0 C 4.1290641,10.404552 4.1362129,10.076472 4.1362129,10.076472 c 0,0 -0.01583,-4.9467413 0,-5.6649443 0.01583,-0.718203 0.3671439,-0.941604 0.3671439,-0.941604 l 0.554035,-0.140168 0.562205,0.133019 c 0.1383811,0.127658 0.493525,0.573694 0.754713,1.019476 0.322719,0.551226 0.625268,1.113431 0.625523,1.113431 0.287231,-1.212749 1.301344,-2.125758 1.301344,-2.125758 l 0.538206,-0.156253 0.562971,0.155998 c 0.334974,0.319144 0.271145,0.478717 0.367144,0.941604 0.096,0.462887 0.138636,5.10376 0.06715,5.6738793 z" />
 </svg>,
 "Memcached"
);
