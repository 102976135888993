import React, { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { isValidFqdn } from "helpers/stringHelper";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postRenameServer } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ServerChangeName = ({ id, name }: { id: number; name: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [serverName, setServerName] = useState<string>(name);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  setServerName(name);
 }, [name]);

 const handleOpen = () => {
  setServerName(name);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRenameServer(id, serverName));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.edit" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="EditIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    disabled={
     loading ||
     serverName.length === 0 ||
     !isValidFqdn(serverName) ||
     serverName === name ||
     !serverName.includes(".")
    }
    title={intl.formatMessage({ id: "server.credential.name" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Stack>
     <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
      {intl.formatMessage({ id: "app.description.fqdn" })}
     </Alert>
     <TextField
      fullWidth
      label={intl.formatMessage({ id: "server.credential.name" })}
      placeholder={intl.formatMessage({ id: "server.credential.name" })}
      value={serverName}
      autoComplete="off"
      onChange={(event) => setServerName(event.target.value)}
      error={serverName.length === 0 || !isValidFqdn(serverName) || !serverName.includes(".")}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default ServerChangeName;
