import React, { ReactElement } from "react";
import { useIntl } from "react-intl";
import { generateToken } from "helpers/generic";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { saveLinkedinStateCode } from "redux/handlers/appLoginHandler";

import { useAppDispatch } from "hooks/reduxHook";

import IconSelector from "../images/IconSelector";

const LinkedinLogin = ({ message }: { message: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const code = generateToken(18);
 const encodedUrl = encodeURIComponent("https://konsolex.onthecloud.srl/linkedin");

 const saveCode = () => {
  dispatch(saveLinkedinStateCode(code));
 };

 return (
  <Tooltip title={`${intl.formatMessage({ id: message })} LinkedIn`}>
   <IconButton
    sx={{
     p: 0.9,
     border: "1px solid lightgrey",
     borderRadius: 10,
     backgroundColor: "white",
     textTransform: "none"
    }}
    onClick={saveCode}
    href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77u8uaos581im9&redirect_uri=${encodedUrl}&state=${code}&scope=profile%20email%20openid`}>
    <IconSelector icon="LinkedInIcon" props={{ color: "primary" }} />
   </IconButton>
  </Tooltip>
 );
};

export default LinkedinLogin;
