import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const SyncNotFound = (): ReactElement => {
 const navigate = useNavigate();

 const handleGoToCreate = () => {
  navigate("/app/mail/container/sync/add");
 };

 return (
  <Stack spacing={2} p={2}>
   <Alert severity="warning">
    <FormattedMessage id="container.maildomains.noSyncfound" />
   </Alert>
   <Stack direction="row">
    <Button onClick={handleGoToCreate} variant="kxActionButton">
     <FormattedMessage id="container.maildomains.createNewSync" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default SyncNotFound;
