import { TableData } from "types/global/table";

export const serverTable: TableData = [
 {
  headText: "app.servers",
  headAlign: undefined,
  keys: "serverName"
 },
 {
  headText: "app.ip",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "app.type",
  headAlign: "left",
  keys: "type"
 },
 {
  headText: "app.renewal",
  headAlign: "left",
  keys: "renewDate"
 },
 {
  headText: "server.performance",
  headAlign: "center",
  keys: ""
 }
];

export const baremetalTable: TableData = [
 {
  headText: "app.servers",
  headAlign: undefined,
  keys: "server_name"
 },
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "app.size",
  headAlign: undefined,
  keys: "dim_disk"
 },
 {
  headText: "app.price",
  headAlign: "right",
  keys: "price"
 },
 {
  headText: "app.renewal",
  headAlign: "right",
  keys: "expire"
 },
 {
  headText: "app.actions",
  headAlign: "center",
  keys: ""
 }
];

export const domainsTable: TableData = [
 {
  headText: "domain.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "domain.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "domain.locked",
  headAlign: "center",
  keys: "registrarlock"
 },
 {
  headText: "domain.automigrate",
  headAlign: "left",
  keys: ""
 },
 {
  headText: "domain.renewal",
  headAlign: "left",
  keys: "expirationdate"
 }
];

export const mailsTable: TableData = [
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "mails.name",
  headAlign: undefined,
  keys: "domain"
 },
 {
  headText: "mails.boxes",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "mails.filter",
  headAlign: "center",
  keys: "policy_id"
 },
 {
  headText: "mails.on/off",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.performance",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.actions",
  headAlign: "center",
  keys: ""
 }
];

export const websitesTable: TableData = [
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "app.sites",
  headAlign: undefined,
  keys: "site"
 },
 {
  headText: "app.hostname",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "sites.alias.number",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.php",
  headAlign: "center",
  keys: "php"
 },
 {
  headText: "app.version",
  headAlign: "center",
  keys: "php_version"
 },
 {
  headText: "app.active",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.performance",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.actions",
  headAlign: "center",
  keys: ""
 }
];

export const zonesTable: TableData = [
 {
  headText: "zone.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "zone.name",
  headAlign: undefined,
  keys: "zone"
 },
 {
  headText: "zone.records",
  headAlign: "center",
  keys: "dnsRr"
 },
 {
  headText: "zone.email",
  headAlign: "center",
  keys: "mail"
 },
 {
  headText: "zone.ttl",
  headAlign: "center",
  keys: "ttl"
 },
 {
  headText: "zone.active",
  headAlign: "center",
  keys: ""
 },
 {
  headText: "app.actions",
  headAlign: "center",
  keys: ""
 }
];

export const jobqueueTable: TableData = [
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "app.type",
  headAlign: undefined,
  keys: "type"
 },
 {
  headText: "app.job.commandType",
  headAlign: undefined,
  keys: "action"
 },
 {
  headText: "app.date",
  headAlign: undefined,
  keys: "createdAt"
 },
 {
  headText: "app.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const snapshotsTable: TableData = [
 {
  headText: "server.snapshot.snapshotData",
  headAlign: undefined,
  keys: "description"
 },
 {
  headText: "server.snapshot.snapshotSize",
  headAlign: undefined,
  keys: "size"
 },
 {
  headText: "server.snapshot.snapshotAutorenew",
  headAlign: undefined,
  keys: "last_renew"
 },
 {
  headText: "server.snapshot.snapshotProtection",
  headAlign: undefined,
  keys: "protection"
 },
 {
  headText: "server.snapshot.snapshotActions",
  headAlign: undefined,
  keys: ""
 }
];

export const volumesTable: TableData = [
 {
  headText: "addons.volumes.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.volumes.status",
  headAlign: undefined,
  keys: "service_status"
 },
 {
  headText: "addons.volumes.size",
  headAlign: undefined,
  keys: "size"
 },
 {
  headText: "addons.volumes.protection",
  headAlign: undefined,
  keys: "protection"
 },
 {
  headText: "addons.volumes.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "addons.volumes.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const balancerTable: TableData = [
 {
  headText: "addons.loadBalancer.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.loadBalancer.algorithm",
  headAlign: undefined,
  keys: "algorithm"
 },
 {
  headText: "addons.loadBalancer.private_ip",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "addons.loadBalancer.ip",
  headAlign: undefined,
  keys: "public_net_ipv4_ip"
 },
 {
  headText: "addons.loadBalancer.protection",
  headAlign: undefined,
  keys: "protection_delete"
 },
 {
  headText: "addons.loadBalancer.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "addons.loadBalancer.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const networksTable: TableData = [
 {
  headText: "addons.network.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.network.range",
  headAlign: undefined,
  keys: "ip_range"
 },
 {
  headText: "addons.network.protection",
  headAlign: undefined,
  keys: "protection"
 },
 {
  headText: "addons.network.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "addons.network.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const firewallsTable: TableData = [
 {
  headText: "addons.firewall.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.firewall.rules",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "addons.firewall.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "addons.firewall.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const cloudboxTable: TableData = [
 {
  headText: "addons.cloudbox.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.cloudbox.status",
  headAlign: undefined,
  keys: "service_status"
 },
 {
  headText: "addons.cloudbox.size",
  headAlign: undefined,
  keys: "size"
 },
 {
  headText: "addons.cloudbox.expire",
  headAlign: undefined,
  keys: "expire"
 }
];

export const cloudbucketTable: TableData = [
 {
  headText: "addons.cloudbucket.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.cloudbucket.status",
  headAlign: undefined,
  keys: "service_status"
 },
 {
  headText: "addons.cloudbucket.server",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "addons.cloudbucket.size",
  headAlign: undefined,
  keys: "size"
 },
 {
  headText: "addons.cloudbucket.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const floatingIpTable: TableData = [
 {
  headText: "addons.floatingIp.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "addons.floatingIp.ip",
  headAlign: undefined,
  keys: "ip"
 },
 {
  headText: "addons.floatingIp.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "addons.floatingIp.protection",
  headAlign: undefined,
  keys: "protection"
 },
 {
  headText: "addons.floatingIp.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const hostsTable: TableData = [
 {
  headText: "app.type",
  headAlign: undefined,
  keys: "type"
 },
 {
  headText: "login.nameField",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "app.ip",
  headAlign: undefined,
  keys: "ip"
 },
 {
  headText: "table.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const servicesTable: TableData = [
 {
  headText: "app.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "app.type",
  headAlign: undefined,
  keys: "type"
 },
 {
  headText: "app.name",
  headAlign: undefined,
  keys: "name"
 },
 {
  headText: "app.expire",
  headAlign: undefined,
  keys: "expire"
 },
 {
  headText: "app.price",
  headAlign: undefined,
  keys: "price"
 },
 {
  headText: "app.autorenew",
  headAlign: undefined,
  keys: "autorenew"
 }
];

export const customersTable: TableData = [
 {
  headText: "login.nameField",
  headAlign: undefined,
  keys: "firstname"
 },
 {
  headText: "customer.companyName",
  headAlign: undefined,
  keys: "company"
 },
 {
  headText: "login.emailField",
  headAlign: "right",
  keys: "email"
 },
 {
  headText: "app.telephone",
  headAlign: "right",
  keys: "phonenumber"
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const domainOrdersTable: TableData = [
 {
  headText: "domain.name",
  headAlign: undefined,
  keys: "domain"
 },
 {
  headText: "domain.orderType",
  headAlign: "left",
  keys: "status"
 },
 {
  headText: "domain.serversIp",
  headAlign: "left",
  keys: "web"
 },
 {
  headText: "domain.creationDate",
  headAlign: "left",
  keys: "createdAt"
 },
 {
  headText: "domain.actions",
  headAlign: "left",
  keys: ""
 }
];

export const serverJobs: TableData = [
 {
  headText: "server.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "server.action",
  headAlign: undefined,
  keys: "action"
 },
 {
  headText: "server.response",
  headAlign: undefined,
  keys: "response"
 },
 {
  headText: "server.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const serverOrders: TableData = [
 {
  headText: "server.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "server.name",
  headAlign: undefined,
  keys: "server_name"
 },
 {
  headText: "server.operations",
  headAlign: undefined,
  keys: "server_name"
 },
 {
  headText: "server.creationDate",
  headAlign: undefined,
  keys: "action"
 },
 {
  headText: "server.actions",
  headAlign: undefined,
  keys: ""
 }
];

export const cronjobData: TableData = [
 {
  headText: "sites.cronjob.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "sites.cronjob.command",
  headAlign: undefined,
  keys: "command"
 },
 {
  headText: "server.actions",
  headAlign: "right",
  keys: ""
 }
];

export const websiteBackupData: TableData = [
 {
  headText: "sites.backups.name",
  headAlign: undefined,
  keys: "filename"
 },
 {
  headText: "sites.backups.size",
  headAlign: undefined,
  keys: "filesize"
 },
 {
  headText: "sites.backups.date",
  headAlign: undefined,
  keys: "tstamp"
 },
 {
  headText: "server.actions",
  headAlign: "right",
  keys: ""
 }
];

export const profileOrdersData: TableData = [
 {
  headText: "profile.orders.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "profile.orders.invoice",
  headAlign: undefined,
  keys: "order_nr"
 },
 {
  headText: "profile.orders.request",
  headAlign: undefined,
  keys: "request_amount"
 },
 {
  headText: "profile.orders.paymentDate",
  headAlign: undefined,
  keys: "payment_date"
 },
 {
  headText: "server.actions",
  headAlign: "left",
  keys: ""
 }
];

export const externalDatabasesTable: TableData = [
 {
  headText: "utility.externalDatabases.name",
  headAlign: undefined,
  keys: "db_name_phpmyadmin"
 },
 {
  headText: "utility.externalDatabases.host",
  headAlign: undefined,
  keys: "db_host_phpmyadmin"
 },
 {
  headText: "utility.externalDatabases.port",
  headAlign: undefined,
  keys: "db_port_phpmyadmin"
 },
 {
  headText: "utility.externalDatabases.uploadLimit",
  headAlign: undefined,
  keys: "db_upload_limit_phpmyadmin"
 },
 {
  headText: "table.actions",
  headAlign: "center",
  keys: ""
 }
];

export const emailMigrationsTable: TableData = [
 {
  headText: "utility.mailMigration.status",
  headAlign: undefined,
  keys: "status"
 },
 {
  headText: "utility.mailMigration.name",
  headAlign: undefined,
  keys: "mailbox_email"
 },
 {
  headText: "utility.mailMigration.source",
  headAlign: undefined,
  keys: "source_host"
 },
 {
  headText: "utility.mailMigration.destination",
  headAlign: undefined,
  keys: "destination_host"
 },
 {
  headText: "utility.mailMigration.started",
  headAlign: undefined,
  keys: "createdAt"
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const containerMailDomainTable: TableData = [
 {
  headText: "container.maildomains.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "container.maildomains.name",
  headAlign: undefined,
  keys: "domain_name"
 },
 {
  headText: "container.maildomains.quota",
  headAlign: undefined,
  keys: "domain_limit"
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const containerMailboxTable: TableData = [
 {
  headText: "container.maildomains.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "container.maildomains.name",
  headAlign: undefined,
  keys: "username"
 },
 {
  headText: "container.maildomains.quota",
  headAlign: undefined,
  keys: "quota"
 },
 {
  headText: "container.maildomains.settings",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "container.maildomains.spam",
  headAlign: undefined,
  keys: "spam_score"
 },
 {
  headText: "container.maildomains.limits",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const containerMailDomainAliasTable: TableData = [
 {
  headText: "container.maildomains.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "container.maildomains.aliasAddress",
  headAlign: undefined,
  keys: "address"
 },
 {
  headText: "container.maildomains.aliasGoto",
  headAlign: undefined,
  keys: "goto"
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];

export const containerMailDomainSyncTable: TableData = [
 {
  headText: "container.maildomains.status",
  headAlign: undefined,
  keys: "active"
 },
 {
  headText: "container.maildomains.owner",
  headAlign: undefined,
  keys: "domain_name"
 },
 {
  headText: "container.maildomains.server",
  headAlign: undefined,
  keys: "domain_limit"
 },
 {
  headText: "container.maildomains.result",
  headAlign: undefined,
  keys: ""
 },
 {
  headText: "table.actions",
  headAlign: "right",
  keys: ""
 }
];
