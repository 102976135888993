import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DatabaseFrame from "components/shared/externalApps/DatabaseFrame";

import {
 postGetDatabaseData,
 postInstallAdminer,
 postInstallPgadmin,
 postInstallPhpAdmin
} from "redux/handlers/webAppHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getPhpSession } from "redux/selectors/sessionSelector";
import { getDatabases, getReload } from "redux/selectors/webAppSelector";

import { useAppDispatch } from "hooks/reduxHook";

const defaultDatabase = {
 container_id: "",
 db_name: "",
 db_password: "",
 db_root_password: "",
 db_user: "",
 db_type: "",
 id: 0,
 db_host: "",
 remote: false,
 remote_port: null
};

const DbNavigatorSection = ({
 id,
 dbType,
 container_id,
 active,
 navigator_type
}: {
 id: number;
 dbType: string;
 container_id: string;
 active: boolean;
 navigator_type: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const sessionActive = useSelector(getPhpSession);

 const database = useSelector(getDatabases);
 const reload = useSelector(getReload);

 const [loading, setLoading] = useState<boolean>(false);
 const [selectedNavigator, setSelectedNavigator] = useState<string>("");

 useEffect(() => {
  if (reload) {
   (async () => {
    setLoading(true);
    await dispatch(postGetDatabaseData(container_id));
    setLoading(false);
   })();
  }
 }, [reload]);

 const handleLoadData = async () => {
  setLoading(true);
  if (selectedNavigator === "phpmyadmin") {
   const result = await dispatch(postInstallPhpAdmin(id));
   if (result && container_id) {
    dispatch(postGetDatabaseData(container_id));
   }
  } else if (selectedNavigator === "pgadmin") {
   const result = await dispatch(postInstallPgadmin(id));
   if (result && container_id) {
    await dispatch(postGetDatabaseData(container_id));
   }
  } else {
   const result = await dispatch(postInstallAdminer(id));
   if (result && container_id) {
    await dispatch(postGetDatabaseData(container_id));
   }
  }
  setLoading(false);
 };

 const selectPort = (): string => {
  switch (navigator_type) {
   case "phpmyadmin":
    return "8089";
   case "adminer":
    return "8090";
   case "pgadmin":
    return "8091";
   default:
    return "8089";
  }
 };

 const RenderNavigatorBox = (): ReactElement => {
  switch (dbType) {
   case "postgres":
    return (
     <Stack spacing={2} p={2}>
      <Alert severity="warning">
       <FormattedMessage id="docker.website.navigatorOff" />
      </Alert>
      <FormControl>
       <InputLabel>
        <FormattedMessage id="docker.website.service" />
       </InputLabel>
       <Select
        label={<FormattedMessage id="docker.website.service" />}
        value={selectedNavigator}
        onChange={(e) => setSelectedNavigator(e.target.value)}>
        <MenuItem key="http-key" value="adminer">
         Adminer
        </MenuItem>
        <MenuItem key="https-key" value="pgadmin">
         PgAdmin
        </MenuItem>
       </Select>
      </FormControl>
      <Stack direction="row">
       <Button disabled={selectedNavigator === ""} onClick={handleLoadData}>
        <FormattedMessage id="docker.website.activateNavigator" />
       </Button>
      </Stack>
     </Stack>
    );
   default:
    return (
     <Stack spacing={2} p={2}>
      <Alert severity="warning">
       <FormattedMessage id="docker.website.navigatorOff" />
      </Alert>
      <FormControl>
       <InputLabel>
        <FormattedMessage id="docker.website.service" />
       </InputLabel>
       <Select
        label={<FormattedMessage id="docker.website.service" />}
        value={selectedNavigator}
        onChange={(e) => setSelectedNavigator(e.target.value)}>
        <MenuItem key="http-key" value="adminer">
         Adminer
        </MenuItem>
        <MenuItem key="https-key" value="phpmyadmin">
         PHPMyAdmin
        </MenuItem>
       </Select>
      </FormControl>
      <Stack direction="row">
       <Button disabled={selectedNavigator === ""} onClick={handleLoadData}>
        <FormattedMessage id="docker.website.activateNavigator" />
       </Button>
      </Stack>
     </Stack>
    );
  }
 };

 return (
  <>
   {loading ? (
    <Stack direction="row" alignItems="center" spacing={2} p={2}>
     <CircularProgress />
     <Typography>
      <FormattedMessage id="docker.website.loadingDatabaseData" />
     </Typography>
    </Stack>
   ) : sessionActive === id || active ? (
    <DatabaseFrame
     id={id}
     link={`http://${server.ipv4}:${selectPort()}`}
     database={database[0] || defaultDatabase}
     navigator_type={navigator_type}
    />
   ) : (
    <RenderNavigatorBox />
   )}
  </>
 );
};

export default DbNavigatorSection;
