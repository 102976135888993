import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getAllCronjob } from "redux/handlers/webAppHandler";

import { getCronjobs, getReload } from "redux/selectors/webAppSelector";

import { useAppDispatch } from "hooks/reduxHook";

import CreateCronjob from "../actions/cronjobActions/CreateCronjob";
import DeleteCronjob from "../actions/cronjobActions/DeleteCronjob";
import CronjobNotFound from "../manageComponents/CronjobNotFound";

const WebAppCronjobs = ({
 id,
 container_id
}: {
 id: number;
 container_id: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const reload = useSelector(getReload);
 const cronjobs = useSelector(getCronjobs);

 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  if (reload) {
   (async () => {
    setLoading(true);
    if (container_id) {
     await dispatch(getAllCronjob(container_id));
    }
    setLoading(false);
   })();
  }
 }, [reload]);

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="CronjobIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="docker.website.cronjob" />
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : cronjobs.length > 0 ? (
    <Stack width="stretch">
     <Stack direction="row">
      <CreateCronjob id={id} />
     </Stack>
     <TableContainer component="div">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       {cronjobs.map((element, index) => {
        return (
         <TableRow key={`cron-table-${index}`}>
          <TableCell component="th" scope="row">
           <Chip
            size="small"
            label={element.status}
            color={element.status === "online" ? "success" : "error"}
           />
          </TableCell>
          <TableCell component="th" scope="row">
           {element.file}
          </TableCell>
          <TableCell component="th" scope="row">
           {element.schedule}
          </TableCell>
          <TableCell align="right">
           <DeleteCronjob id={element.id} />
          </TableCell>
         </TableRow>
        );
       })}
      </Table>
     </TableContainer>
    </Stack>
   ) : (
    <CronjobNotFound id={id} />
   )}
  </Paper>
 );
};

export default WebAppCronjobs;
