import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import DeleteSync from "components/apps/containerMailDomain/actions/sync/DeleteSync";
import IconSelector from "components/shared/images/IconSelector";

import { IMailDomainSyncData } from "types/api/containerMailDomainApiInterface";

const ContainerMailDomainSync = ({
 rowData,
 index
}: {
 rowData: IMailDomainSyncData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const handleEdit = () => {
  navigate(`/app/mail/container/sync/edit?q=${rowData.id}`);
 };

 const RenderResult = (): ReactElement => {
  if (rowData.success) {
   return (
    <Stack direction="row" spacing={1} alignItems="center">
     <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
     <Typography variant="extraSmall">
      <FormattedMessage id="container.maildomains.successOperation" />
     </Typography>
    </Stack>
   );
  } else {
   switch (rowData.exit_status) {
    case "EXIT_AUTHENTICATION_FAILURE_USER1":
     return (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CloseIcon" props={{ color: "error" }} />
       <Typography variant="extraSmall">
        <FormattedMessage id="container.maildomains.errorOperationUser1" />
       </Typography>
      </Stack>
     );
    default:
     return (
      <Stack direction="row" spacing={1} alignItems="center">
       <IconSelector icon="CloseIcon" props={{ color: "error" }} />
       <Typography variant="extraSmall">
        <FormattedMessage id="container.maildomains.errorOperationNotKnown" />
       </Typography>
      </Stack>
     );
   }
  }
 };
 return (
  <TableRow key={`cron-table-${index}`}>
   <TableCell component="th" scope="row">
    <Chip
     size="small"
     label={rowData.active ? "Online" : "Offline"}
     color={rowData.active ? "success" : "error"}
    />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography fontWeight="bold" variant="subtitle2">
      {rowData.user1}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="subtitle2" minWidth={95}>
     {rowData.host1}
    </Typography>
   </TableCell>
   <TableCell component="th" scope="row">
    <RenderResult />
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" spacing={1} justifyContent="flex-end">
     <Tooltip title={<FormattedMessage id="container.maildomains.editSync" />}>
      <IconButton onClick={handleEdit}>
       <IconSelector icon="EditIcon" props={{ color: "secondary" }} />
      </IconButton>
     </Tooltip>
     <DeleteSync id={rowData.id} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};
export default ContainerMailDomainSync;
