import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postLinkCloudbucketToWebsite } from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ConnectCloudbucketFolder = ({
 id,
 folders,
 available
}: {
 id: number;
 folders: Array<string>;
 available: Array<string>;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const { bucket_id_db } = useSelector(getServerDetails);

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, handleSubmit, setValue } = useForm({
  defaultValues: {
   folder: ""
  }
 });

 const onSubmit: SubmitHandler<{
  folder: string;
 }> = async (data: { folder: string }) => {
  setLoading(true);
  await dispatch(postLinkCloudbucketToWebsite(id, data.folder, bucket_id_db || 0));
  setLoading(false);
  handleClose();
 };

 const checkForExistingFolder = (): boolean => {
  const existingFolder = available.filter((fold) => !folders.includes(fold)).length > 0;
  return existingFolder;
 };

 const checkDisabled = (): boolean => {
  return watch("folder") === "";
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "docker.website.connectCloudbucket" })} placement="top">
    <IconButton onClick={handleOpen} color="kxOrange">
     <IconSelector icon="FolderIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.connectCloudbucket" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled() || !checkForExistingFolder()}>
    {checkForExistingFolder() ? (
     <Stack spacing={2}>
      <Typography>
       <FormattedMessage id="docker.website.availableFolders" />
      </Typography>
      <Controller
       name="folder"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl>
         <InputLabel>
          <FormattedMessage id="addons.cloudbox.folder" />
         </InputLabel>
         <Select
          {...field}
          label={<FormattedMessage id="addons.cloudbox.folder" />}
          onChange={(e) => setValue("folder", e.target.value)}>
          {available
           .filter((fold) => !folders.includes(fold))
           .map((element, index) => {
            return (
             <MenuItem key={`folder-number-${index}`} value={element}>
              {element}
             </MenuItem>
            );
           })}
         </Select>
        </FormControl>
       )}
      />
     </Stack>
    ) : (
     <Alert severity="warning">
      <FormattedMessage id="docker.website.noFoldersAvailable" />
     </Alert>
    )}
   </AppModal>
  </>
 );
};

export default ConnectCloudbucketFolder;
