import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedTime } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getAgentVersion } from "redux/handlers/serverHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import AgentInstaller from "../AgentInstaller";
import AgentReboot from "../AgentReboot";
import AgentUpdate from "../AgentUpdate";

const ServerAgent = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const theme = useTheme();
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 const server = useSelector(getServerDetails);

 const [serverTime, setServerTime] = useState<string>("");
 const [agentVersion, setAgentVersion] = useState<number>(0);
 const [serverAgentVersion, setServerAgentVersion] = useState<number>(-1);
 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setServerTime(value[i]?.tz || "");
       setServerAgentVersion(Number(value[i].agentVersion));
       if (Number(value[i].agentVersion) === 0) setReloadData(true);
      }
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setAgentVersion(await dispatch(getAgentVersion()));
    setReloadData(false);
    await dispatch(postSyncronizeSocket("server"));
   }
  })();
 }, [reloadData]);

 return (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
   <Stack direction="row" spacing={2} alignItems="center">
    <Stack>
     {server.agentinstall ? (
      <Stack
       direction="row"
       alignItems="center"
       p={1}
       sx={{ borderRadius: 10, border: "1px solid grey" }}>
       <IconSelector
        icon="PhonelinkIcon"
        props={{
         color: agentVersion !== serverAgentVersion ? "warning" : "success",
         fontSize: "small",
         sx: { mr: 1 }
        }}
       />
       {phoneView ? (
        <Typography variant="small" pr={1} fontWeight="bold">
         <FormattedMessage id="server.agent.connected" />
         {` ${serverAgentVersion !== -1 ? `v${serverAgentVersion}` : ""}`}
        </Typography>
       ) : (
        <Typography variant="small" pr={1} fontWeight="bold">
         {`${serverAgentVersion !== -1 ? `v${serverAgentVersion}` : ""}`}
        </Typography>
       )}
      </Stack>
     ) : (
      <Stack
       direction="row"
       alignItems="center"
       p={1}
       sx={{ borderRadius: 10, border: "1px solid grey" }}>
       <IconSelector
        icon="PhonelinkOffIcon"
        props={{ color: "error", fontSize: "small", sx: { mr: 1 } }}
       />
       <Typography variant="small" pr={1} fontWeight="bold">
        <FormattedMessage id="server.agent.notconnected" />
       </Typography>
      </Stack>
     )}
    </Stack>
    <Typography variant="small">
     <FormattedMessage id="server.datetime" />:{" "}
     {serverTime ? (
      <FormattedTime value={new Date(serverTime)} />
     ) : (
      <FormattedMessage id="server.manage.chargingData" />
     )}
    </Typography>
   </Stack>
   <Stack>
    {server.agentinstall ? (
     server.agentoken ? (
      agentVersion !== 0 &&
      agentVersion !== serverAgentVersion &&
      serverAgentVersion !== -1 &&
      server.os !== "windows" ? (
       <AgentUpdate id={server.id || 0} />
      ) : (
       <AgentReboot id={server.id || 0} />
      )
     ) : (
      <AgentInstaller />
     )
    ) : server.agentoken ? (
     agentVersion !== 0 &&
     agentVersion > serverAgentVersion &&
     serverAgentVersion !== -1 &&
     server.os !== "windows" ? (
      <AgentUpdate id={server.id || 0} />
     ) : (
      <AgentReboot id={server.id || 0} />
     )
    ) : (
     <AgentInstaller />
    )}
   </Stack>
  </Stack>
 );
};

export default ServerAgent;
