import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

import { containerMailDomainTable } from "constants/tables";

import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";
import SortingTable from "components/shared/tables/SortingTable";

import { getContainerMailDomains } from "redux/handlers/mailDomainContainerHandler";

import {
 getContainerMailsList,
 getContainerMailsNumber
} from "redux/selectors/containerMailSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ContainerMailList = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const { socket } = useContext(AppContext);

 const searchRef = useRef<HTMLInputElement>(null);
 const mailList = useSelector(getContainerMailsList);
 const totalMails = useSelector(getContainerMailsNumber);

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  function onDockerMailList(value: any) {
   if (value?.completed && value?.section === "maildomains") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onDockerMailList);

  return () => {
   !isNil(socket) && socket.off("docker_data", onDockerMailList);
  };
 }, [socket]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    await dispatch(getContainerMailDomains(id, currentPage, rowPerPage, searchRef?.current?.value));
   }
  },
  1000,
  [searchValue]
 );

 useEffect(() => {
  (async () => {
   if (reloadData) {
    await dispatch(getContainerMailDomains(id, currentPage, rowPerPage, searchRef?.current?.value));
    if (totalMails === 0) setListWasEmpty(true);
    if (isLoading) setIsLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(getContainerMailDomains(id, 0, newRowsPerPage, searchRef?.current?.value));
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(getContainerMailDomains(id, startIndex, rowPerPage, searchRef?.current?.value));
 };

 const handleGoToCreate = () => {
  navigate("/app/mail/add?section=mailapp");
 };

 return isLoading ? (
  <></>
 ) : mailList.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="container.maildomains.searchMail" />}
      inputRef={searchRef}
      InputLabelProps={{ shrink: true }}
      InputProps={{
       startAdornment: <IconSelector icon="SearchIcon" props={{ color: "disabled" }} />,
       autoComplete: "off"
      }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>
     <Button onClick={handleGoToCreate} variant="kxActionButton">
      <FormattedMessage id="container.maildomains.createMailDomain" />
     </Button>
    </Stack>
   </Stack>
   <Stack>
    <SortingTable
     tableData={containerMailDomainTable}
     maxWidth={650}
     caller="container_maildomain"
     listToShow={mailList}
    />
    <TablePagination
     component="div"
     count={totalMails}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
   <Button onClick={handleGoToCreate} variant="kxActionButton">
    <FormattedMessage id="container.maildomains.createMailDomain" />
   </Button>
  </Stack>
 );
};

export default ContainerMailList;
