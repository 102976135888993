import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useNavigate } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppFooter from "components/footer/AppFooter";
import Login from "components/login/Login";
import IconSelector from "components/shared/images/IconSelector";

const LoginPage = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();

 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const handleGoToRegistration = () => {
  navigate("/registration");
 };
 return (
  <Grid container height="100vh">
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
   <Grid
    xs={desktopViewPort ? 8 : 12}
    display="flex"
    justifyContent={"center"}
    alignItems={"center"}
    height="100%"
    padding={2}>
    <Stack justifyContent={"space-between"} height={"100%"}>
     <Stack justifyContent={"center"} height={"80%"}>
      <Stack spacing={4}>
       <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <img
         alt="KonsoleX Logo"
         src={`${process.env.PUBLIC_URL}/svg/loghi/KX_hor_B.svg`}
         style={{ maxWidth: "60%" }}
        />
        <Button
         onClick={handleGoToRegistration}
         variant="kxActionButton"
         endIcon={<IconSelector icon="ArrowForwardIcon" />}>
         <FormattedMessage id="login.signUp" />
        </Button>
       </Stack>
       <Typography variant="h5" textAlign="left">
        {intl.formatMessage({ id: "app.login" })}
       </Typography>
      </Stack>
      <Login />
      <Stack mt={2}>
       <NavLink to="/reset-password" style={{ color: "#00278B" }}>
        {intl.formatMessage({ id: "login.forgottenPassword" })}
       </NavLink>
      </Stack>
     </Stack>
     <AppFooter />
    </Stack>
   </Grid>
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
  </Grid>
 );
};

export default LoginPage;
