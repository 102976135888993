import { cloudboxTypes } from "constants/cloudboxType";
import { ipRegex } from "constants/regexp";

import { IAddonsInfo } from "types/api/addonsApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import { ISnapshotPermissionApi } from "types/api/userApiInterface";
import { serverData } from "types/global/server";

export const getServerFromId = (id: number, serverList: Array<serverData>): string => {
 const serverFiltered = serverList.filter((server) => {
  if (!server.hz_id || server.hz_id !== id) return false;

  return true;
 });

 if (serverFiltered[0]?.serverName) return serverFiltered[0]?.serverName;
 else return "";
};

export const getServerNameFromId = (id: number, serverList: Array<IAllServers>): string => {
 const serverFiltered = serverList.filter((server) => {
  if (!server.server_id || server.server_id !== id) return false;

  return true;
 });

 if (serverFiltered[0]?.server_name) return serverFiltered[0]?.server_name;
 else return "";
};

export const getServersNamesList = (servers: Array<serverData>): Array<string> => {
 return servers.map((server) => {
  if (server.serverName) return server.serverName;
  else return "";
 });
};

export const getCloudboxUpgrades = (
 type: string
): Array<{ type: string; size: string; snapshots: number; price: number }> => {
 const filteredUpgrade = cloudboxTypes.filter((element) => {
  if (element.type === type) return false;
  if (parseInt(element.type[2]) <= parseInt(type[2])) return false;

  return true;
 });

 return filteredUpgrade;
};

export const checkIPFormat = (ip: string): boolean => {
 return ipRegex.test(ip) || ip === "0.0.0.0/0" || ip === "::/0";
};

export const getServersListFromIds = (
 servers: Array<serverData>,
 ids: Array<number>
): Array<{ label: string; hz_id: number }> => {
 const serverFiltered = servers.filter((server) => ids.includes(server?.hz_id || -1));

 return serverFiltered.map((server) => {
  if (server.serverName && server.hz_id) return { label: server.serverName, hz_id: server.hz_id };
  else return { label: "", hz_id: 0 };
 });
};

export const formatNetworkGateway = (ip: string): string => {
 return `${ip.substring(0, ip.length - 1)}1`;
};

export const formatIpRange = (ip: string): string => {
 return ip.substring(0, ip.length - 3);
};

export const checkPort = (port: string): boolean => {
 if (port.includes("-")) {
  const ports = port.split("-");
  let result = false;
  for (let singlePort of ports) {
   if (!parseInt(singlePort)) {
    result = true;
   }
  }
  return result;
 } else if (port === "any") {
  return false;
 } else {
  if (parseInt(port)) {
   return false;
  } else {
   return true;
  }
 }
};

export const findConnectedServer = (
 id: number,
 servers: Array<IAllServers>
): IAllServers | null => {
 return servers.find((element) => element.id === id) || null;
};

export const getAddonsPermissions = (
 provider: string,
 permissions: Array<ISnapshotPermissionApi>
): ISnapshotPermissionApi | null => {
 switch (provider) {
  case "kx":
   return permissions.find((element) => element.provider === "hetzner") || null;
  case "ctb":
   return permissions.find((element) => element.provider === "ctb") || null;
  case "konsolex":
   return permissions.find((element) => element.provider === "hetzner") || null;
  default:
   return null;
 }
};

export const formatMemLimit = (mem: string): number => {
 if (mem) {
  const str = mem.substring(0, mem.length - 1);
  if (mem.includes("m")) {
   return Number(str);
  } else if (mem.includes("g")) {
   return Number(str) * 1024;
  } else {
   return Number(str);
  }
 } else {
  return 0;
 }
};

export const formatMemVisual = (mem: string): string => {
 if (mem) {
  const str = mem.substring(0, mem.length - 1);
  if (mem.includes("m")) {
   if (Number(str) > 1024) {
    return `${Number(str) / 1024} GB`;
   } else {
    return `${str} MB`;
   }
  } else if (mem.includes("g")) {
   if (Number(str) > 1024) {
    return `${Number(str) / 1024} TB`;
   } else {
    return `${str} GB`;
   }
  } else {
   return `${str} GB`;
  }
 } else {
  return "0 GB";
 }
};

export const filterAddonsInfo = (addonsInfo: IAddonsInfo, section: string) => {
 switch (section) {
  case "cloudbox":
   return addonsInfo.cloudbox;
  case "firewall":
   return addonsInfo.firewall;
  case "floatingIp":
   return addonsInfo.floating_ip;
  case "network":
   return addonsInfo.network;
  case "loadBalancer":
   return addonsInfo.load_balancer;
  case "snapshot":
   return addonsInfo.snapshot;
  case "volumes":
   return addonsInfo.volume;
  case "cloudbucket":
   return addonsInfo.bucket;
  default:
 }
};

export const filterProvider = (provider: string): string => {
 switch (provider) {
  case "kx":
   return `${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`;
  case "ctb":
   return `${process.env.PUBLIC_URL}/svg/providers/contabo.svg`;
  case "htz":
   return `${process.env.PUBLIC_URL}/svg/providers/hetzner.svg`;
  case "amz":
   return `${process.env.PUBLIC_URL}/svg/providers/aws.svg`;
  case "gcp":
   return `${process.env.PUBLIC_URL}/svg/providers/gcloud.svg`;
  case "ovh":
   return `${process.env.PUBLIC_URL}/svg/providers/ovh.svg`;
  default:
   return `${process.env.PUBLIC_URL}/svg/loghi/logo.jpg`;
 }
};
