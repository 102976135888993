import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { postDisinstallFilemanager, saveFilemanagerSettings } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const FilemanagerFrame = ({
 id,
 password,
 link,
 setLoad
}: {
 id: number;
 password: string;
 link: string;
 setLoad: (value: boolean) => void;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const handleRemove = async () => {
  setLoad(true);
  await dispatch(postDisinstallFilemanager(id));
  await dispatch(saveFilemanagerSettings(id, false, null, null));
  setLoad(false);
 };
 return (
  <Stack spacing={1} textAlign="left" p={2} pt={0}>
   <Divider />
   <Stack direction="row">
    <Typography fontWeight="bold">
     <FormattedMessage id="docker.website.filemanagerUser" />
    </Typography>
    <Typography>{": admin"}</Typography>
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center">
    <Typography fontWeight="bold">
     <FormattedMessage id="docker.website.filemanagerPassword" />
    </Typography>
    <Typography>{`: ${password}`}</Typography>
   </Stack>
   <Divider />
   <Stack direction="row">
    <Button onClick={handleRemove}>
     <FormattedMessage id="docker.website.removeFilemanager" />
    </Button>
    <Button onClick={() => window.open(link, "Filemanager", "width=1000, height=800")}>
     <FormattedMessage id="docker.website.openFileManager" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default FilemanagerFrame;
