import React, { ReactElement } from "react";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ServerAgent from "../ServerAgent";
import ServerStatistics from "../ServerStatistics";

const ServerMonitoring = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Paper elevation={0} sx={{ mt: desktopViewPort ? 3 : 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={2} spacing={1} pt={1}>
    <ServerAgent />
    <Divider />
    <ServerStatistics />
   </Stack>
  </Paper>
 );
};

export default ServerMonitoring;
