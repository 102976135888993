import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { postVerifyMaildomain } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { EWebmailConfigEndpoints } from "types/redux/appInterfaces";

const CheckDomainConfig = ({ id, caller }: { id: number; caller: string }): ReactElement => {
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);

 const searchEndpoint = (): string => {
  switch (caller) {
   case "autodiscover":
    return EWebmailConfigEndpoints.AUTODISCOVER;
   case "srv":
    return EWebmailConfigEndpoints.SRV;
   case "spf":
    return EWebmailConfigEndpoints.SPF;
   case "dkim":
    return EWebmailConfigEndpoints.DKIM;
   case "dmarc":
    return EWebmailConfigEndpoints.DMARC;
   case "config":
    return EWebmailConfigEndpoints.CONFIG;
   default:
    return EWebmailConfigEndpoints.AUTODISCOVER;
  }
 };

 const handleVerify = async () => {
  setLoading(true);
  await dispatch(postVerifyMaildomain(id, searchEndpoint()));
  setLoading(false);
 };

 return loading ? (
  <CircularProgress size={20} />
 ) : (
  <Button onClick={handleVerify}>
   <FormattedMessage id="app.verify" />
  </Button>
 );
};

export default CheckDomainConfig;
