import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import teamsReducer from "redux/reducers/teamsReducer";

import {
 IContactData,
 ITeamsApi,
 ITeamsDataset,
 ITeamsUserSettings,
 IUserPreferences
} from "types/api/teamsApiInterface";
import { settingsType } from "types/global/user";

import { ApiService } from "service/ApiService";

export const getAllSecondLevelUsers =
 (
  currentIndex: number,
  sizePerPage: number,
  parentid: number,
  q?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ITeamsApi>(
    `/teams/allteams?currentIndex=${currentIndex}?sizePerPage=${sizePerPage}?q=${q}`
   );
   dispatch(teamsReducer.actions.setTeams(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET TEAMS");
  }
 };

export const getFullListSecondLevelUsers = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<ITeamsDataset>>("/teams/teamslist");
  dispatch(teamsReducer.actions.setTeams({ dataset: data, totalCount: data.length }));
 } catch (error) {
  console.warn("FAILED TO GET TEAMS");
 }
};

export const getAllUserContacts =
 (): AppAction<Promise<Array<IContactData>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IContactData>>("/contact/getuserscontactslist", "POST");
   return data;
  } catch (error) {
   console.warn("FAILED TO GET TEAMS");
   return [];
  }
 };

export const postAddTeamsUser =
 (userid: number, email: string, password: string, contact_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/addteams", "POST", {
    email,
    password,
    contact_id
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User created successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateTeamsUser =
 (id: number, email: string, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/teams/updateteams/${id}`, "PUT", {
    email
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateTeamsPassword =
 (id: number, oldPassword: string, newPassword: string, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/teams/updatepassword/${id}`, "PUT", {
    oldPassword,
    newPassword
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteTeamsUser =
 (id: number, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/deleteteams", "POST", {
    id
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User deleted successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getUserPreferences =
 (): AppAction<Promise<IUserPreferences | null>> => async (dispatch) => {
  try {
   const { data } = await ApiService<IUserPreferences>("/teams/getsitepreferences");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get preferences - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postChangeUserPreferences =
 (image: string, color: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/setsitepreferences", "POST", {
    image,
    color: color.includes("#") ? color : `#${color}`
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing user preferences",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change preferences - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getUserSettings =
 (id: number): AppAction<Promise<ITeamsUserSettings | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ITeamsUserSettings>("/teams/getdatas", "POST", {
    teams_id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get user settings - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postChangeUserSettings =
 (id: number, datas: settingsType): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/setpreferences", "POST", {
    teams_id: id,
    domains: datas.domains,
    servers: datas.servers,
    showAuthinfo: datas.showAuthinfo,
    showDns: datas.showDns,
    showOwners: datas.showOwners,
    showNs: datas.showNs,
    editAuthinfo: datas.editAuthinfo,
    editDns: datas.editDns,
    editNs: datas.editNs,
    editRebootServer: datas.editRebootServer,
    editPowerOffOnServer: datas.editPowerOffOnServer,
    sites: datas.sites,
    mails: datas.mails,
    zones: datas.zones,
    showBackupServer: datas.showBackupServer,
    editBackupServer: datas.editBackupServer,
    editRebuildServer: datas.editRebuildServer,
    editPowerOffOnSite: datas.editPowerOffOnSite,
    editPowerOffOnMail: datas.editPowerOffOnMail,
    editPowerOffOnZone: datas.editPowerOffOnZone,
    showZoneRecords: datas.showZoneRecords,
    editZoneRecords: datas.editZoneRecords,
    cloudboxes: datas.cloudboxes,
    networks: datas.networks,
    firewalls: datas.firewalls,
    floating_ips: datas.floating_ips,
    load_balancers: datas.load_balancers,
    snapshots: datas.snapshots,
    volumes: datas.volumes,
    cloudbuckets: datas.cloudbuckets,
    showMailDetails: datas.showMailDetails,
    editMailDetails: datas.editMailDetails,
    showMailbox: datas.showMailbox,
    editMailbox: datas.editMailbox,
    showAutomaticMail: datas.showAutomaticMail,
    editAutomaticMail: datas.editAutomaticMail,
    showWhiteBlackMailList: datas.showWhiteBlackMailList,
    editWhiteBlackMailList: datas.editWhiteBlackMailList,
    showSiteDetails: datas.showSiteDetails,
    editSiteDetails: datas.editSiteDetails,
    showSiteFtpCredentials: datas.showSiteFtpCredentials,
    editSiteFtpCredentials: datas.editSiteFtpCredentials,
    showSiteAlias: datas.showSiteAlias,
    editSiteAlias: datas.editSiteAlias,
    showSiteDatabase: datas.showSiteDatabase,
    editSiteDatabase: datas.editSiteDatabase,
    showSiteBackups: datas.showSiteBackups,
    editSiteBackups: datas.editSiteBackups,
    showSiteCronjobs: datas.showSiteCronjobs,
    editSiteCronjobs: datas.editSiteCronjobs,
    showCloudbox: datas.showCloudbox,
    showFirewall: datas.showFirewall,
    showFloatingIp: datas.showFloatingIp,
    showNetwork: datas.showNetwork,
    showVolume: datas.showVolume,
    showLoadBalancer: datas.showLoadBalancer,
    editFloatingIp: datas.editFloatingIp,
    editFloatingIpRdns: datas.editFloatingIpRdns,
    editFloatingIpServer: datas.editFloatingIpServer,
    editVolume: datas.editVolume,
    editVolumeMount: datas.editVolumeMount,
    editVolumeServer: datas.editVolumeServer,
    editLoadBalancer: datas.editLoadBalancer,
    showCloudboxCommands: datas.showCloudboxCommands,
    showCloudboxDetails: datas.showCloudboxDetails,
    editCloudboxDetails: datas.editCloudboxDetails,
    showCloudboxSettings: datas.showCloudboxSettings,
    editCloudboxSettings: datas.editCloudboxSettings,
    showCloudboxServer: datas.showCloudboxServer,
    editCloudboxServer: datas.editCloudboxServer,
    showCloudboxSnapshot: datas.showCloudboxSnapshot,
    editCloudboxSnapshot: datas.editCloudboxSnapshot,
    showNetworkDetails: datas.showNetworkDetails,
    editNetworkDetails: datas.editNetworkDetails,
    showNetworkSubnet: datas.showNetworkSubnet,
    editNetworkSubnet: datas.editNetworkSubnet,
    showNetworkRoute: datas.showNetworkRoute,
    editNetworkRoute: datas.editNetworkRoute,
    showFirewallDetails: datas.showFirewallDetails,
    editFirewallDetails: datas.editFirewallDetails,
    showFirewallServer: datas.showFirewallServer,
    editFirewallServer: datas.editFirewallServer,
    showFirewallInboundRules: datas.showFirewallInboundRules,
    editFirewallInboundRules: datas.editFirewallInboundRules,
    showFirewallOutboundRules: datas.showFirewallOutboundRules,
    editFirewallOutboundRules: datas.editFirewallOutboundRules,
    showLoadBalancerDetails: datas.showLoadBalancerDetails,
    editLoadBalancerDetails: datas.editLoadBalancerDetails,
    showLoadBalancerGraph: datas.showLoadBalancerGraph,
    showLoadBalancerCertificate: datas.showLoadBalancerCertificate,
    editLoadBalancerCertificate: datas.editLoadBalancerCertificate,
    showLoadBalancerService: datas.showLoadBalancerService,
    showLoadBalancerTarget: datas.showLoadBalancerTarget,
    showLoadBalancerNetwork: datas.showLoadBalancerNetwork,
    editLoadBalancerNetwork: datas.editLoadBalancerNetwork,
    showSnapshot: datas.showSnapshot,
    editSnapshot: datas.editSnapshot,
    showCloudbuckets: datas.showCloudbuckets,
    editConnectCloudbucket: datas.editConnectCloudbucket,
    showRebuildServer: datas.showRebuildServer,
    editVncServer: datas.editVncServer,
    editIsoServer: datas.editIsoServer,
    showCredentialsServer: datas.showCredentialsServer,
    editCredentialsServer: datas.editCredentialsServer,
    showIpRdnsServer: datas.showIpRdnsServer,
    editIpRdnsServer: datas.editIpRdnsServer,
    showServicesServer: datas.showServicesServer,
    editServicesServer: datas.editServicesServer,
    editRebootAgent: datas.editRebootAgent,
    showServerStatistics: datas.showServerStatistics
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing user preferences",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change preferences - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
