import React, { ReactElement, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";

import SkeletonUtility from "components/shared/skeletons/SkeletonUtility";
import ExternalDatabases from "components/utility/externalDatabase/ExternalDatabases";

import { getAllExternalDatabases } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ExternalDatabasesPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   await dispatch(getAllExternalDatabases(0, 10));
   setIsLoading(false);
  })();
 }, []);

 return (
  <Stack>
   {isLoading ? <SkeletonUtility id="utility.externalDatabases" /> : <ExternalDatabases />}
  </Stack>
 );
};

export default ExternalDatabasesPage;
