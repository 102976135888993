import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getRepositorys, getTypeVersions, postCreateWebsite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerRepo, IDockerTypeVersions } from "types/api/dockerApiInterface";

const RecreateSite = ({
 id,
 siteName,
 siteVers
}: {
 id: number;
 siteName: string;
 siteVers: string;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedVers, setSelectedVers] = useState<string>("");
 const [typeVers, setTypeVers] = useState<IDockerTypeVersions | null>(null);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);

 const handleOpen = async () => {
  setTypeVers(await dispatch(getTypeVersions()));
  setRepository(await dispatch(getRepositorys()));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateWebsite(id, siteName, selectedVers, selectRepo(selectedVers)));
  setLoading(false);
  handleClose();
 };

 const selectRepo = (vers: string): string => {
  if (vers.includes("tomcat")) {
   return repository.find((element) => element.name.includes("tomcat"))?.name || "";
  } else if (vers.includes("nginx")) {
   return repository.find((element) => element.name.includes("nginx"))?.name || "";
  } else if (vers.includes("apache")) {
   return repository.find((element) => element.name.includes("apache"))?.name || "";
  } else if (vers.includes("odoo")) {
   return repository.find((element) => element.name.includes("odoo"))?.name || "";
  } else {
   return repository[0].name;
  }
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.recreateSite" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="AddIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.recreateSite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="docker.website.recreateSiteMessage" />
     </Typography>
     <FormControl fullWidth>
      <InputLabel>
       <FormattedMessage id="docker.container.tech" />
      </InputLabel>
      <Select
       value={selectedVers}
       label={<FormattedMessage id="docker.container.tech" />}
       onChange={(e) => setSelectedVers(e.target.value)}>
       {typeVers?.versions
        .filter((element) =>
         siteVers === "php"
          ? element.name.includes(siteVers) && !element.name.includes("litespeed")
          : element.name.includes(siteVers)
        )
        .map((element, index) => {
         return (
          <MenuItem value={element.name} key={`php-vers-${index}`}>
           {element.name}
          </MenuItem>
         );
        })}
      </Select>
     </FormControl>
    </Stack>
   </AppModal>
  </>
 );
};

export default RecreateSite;
