import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { formatBytes } from "helpers/numberFormatting";

import { isNil } from "ramda";

import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import NoLabelProgress from "components/shared/progressBar/NoLabelProgress";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

type serverLogData = {
 cpu: number;
 disk: number;
 inode: number;
 logs: number;
 ram: number;
 rx: number;
 tx: number;
 tz: string;
};

const ServerStatistics = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const phoneView = useMediaQuery(theme.breakpoints.up("sm"));

 const server = useSelector(getServerDetails);

 const [logData, setLogData] = useState<serverLogData>({
  cpu: 0,
  disk: 0,
  inode: 0,
  logs: 0,
  ram: 0,
  rx: 0,
  tx: 0,
  tz: ""
 });

 useEffect(() => {
  !isNil(socket) &&
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setLogData({
        cpu: parseInt(value[i]?.cpu) || 0,
        disk: parseInt(value[i]?.disk) || 0,
        inode: parseInt(value[i]?.inode) || 0,
        logs: parseInt(value[i]?.log_usage) || 0,
        ram: parseInt(value[i]?.ram) || 0,
        rx: parseInt(value[i]?.rx) || 0,
        tx: parseInt(value[i]?.tx) || 0,
        tz: value[i]?.tz || ""
       });
      }
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 return (
  <Grid container spacing={2}>
   <Grid xs={desktopViewPort ? 6 : 12}>
    {phoneView ? (
     <Stack direction="row" alignItems="center" justifyContent="space-around">
      <Stack alignItems="center">
       <SemiCircularProgress value={logData.cpu} maxValue={100} percent={true} />
       <Typography variant="subtitle2" fontWeight="bold">
        {`CPU: ${server.cpuCore} core`}
       </Typography>
      </Stack>
      <Stack alignItems="center">
       <SemiCircularProgress value={logData.ram} maxValue={100} percent={true} />
       <Typography variant="subtitle2" fontWeight="bold">
        {`RAM: ${server.ramSize} GB`}
       </Typography>
      </Stack>
      <Stack alignItems="center">
       <SemiCircularProgress value={logData.disk} maxValue={100} percent={true} />
       <Typography variant="subtitle2" fontWeight="bold">
        {`DISK: ${server.diskSize} GB`}
       </Typography>
      </Stack>
     </Stack>
    ) : (
     <Stack>
      <Stack>
       <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold" variant="subtitle2">
         CPU
        </Typography>
        <Typography variant="subtitle2">{`${logData.cpu}%`}</Typography>
       </Stack>
       <NoLabelProgress value={logData.cpu} />
      </Stack>
      <Stack>
       <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold" variant="subtitle2">
         RAM
        </Typography>
        <Typography variant="subtitle2">{`${logData.ram}%`}</Typography>
       </Stack>
       <NoLabelProgress value={logData.ram} />
      </Stack>
      <Stack>
       <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold" variant="subtitle2">
         Disk
        </Typography>
        <Typography variant="subtitle2">{`${logData.disk}%`}</Typography>
       </Stack>
       <NoLabelProgress value={logData.disk} />
      </Stack>
     </Stack>
    )}
   </Grid>
   <Grid xs={desktopViewPort ? 3 : 12}>
    <Stack pl={1} pb={1}>
     <Typography fontWeight="bold" textAlign="left" variant="subtitle2">
      Network
     </Typography>
     <Stack direction="row" justifyContent="space-between">
      <Typography
       variant="caption"
       alignItems="flex-end"
       display="flex"
       textTransform="uppercase"
       fontWeight="bold"
       mr={1}>
       <FormattedMessage id="server.manage.outgoingTraffic" />
      </Typography>
      <Stack direction="row">
       <Typography fontSize="small" lineHeight={1.43}>
        <FormattedNumber
         value={server.os === "windows" ? formatBytes(logData.tx, "GB") : logData.tx}
         unit="gigabyte"
        />
       </Typography>
       <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
        <FormattedMessage id="server.manage.mb" />
       </Typography>
      </Stack>
     </Stack>
     <Stack direction="row" justifyContent="space-between">
      <Typography
       variant="caption"
       alignItems="flex-end"
       display="flex"
       textTransform="uppercase"
       fontWeight="bold"
       mr={1}>
       <FormattedMessage id="server.manage.incomingTraffic" />
      </Typography>
      <Stack direction="row">
       <Typography fontSize="small" lineHeight={1.43}>
        <FormattedNumber
         value={server.os === "windows" ? formatBytes(logData.rx, "GB") : logData.rx}
         unit="gigabyte"
        />
       </Typography>
       <Typography variant="caption" alignItems="flex-end" display="flex" textTransform="uppercase">
        <FormattedMessage id="server.manage.mb" />
       </Typography>
      </Stack>
     </Stack>
     <Stack direction="row" justifyContent="space-between">
      <Typography
       variant="caption"
       alignItems="flex-end"
       display="flex"
       textTransform="uppercase"
       fontWeight="bold"
       mr={1}>
       <FormattedMessage id="server.manage.lastData" />
      </Typography>
      <Stack direction={"row"} spacing={1}>
       {logData.tz ? (
        <>
         <Typography fontSize="small" lineHeight={1.43}>
          <FormattedDate value={new Date(logData.tz)} />
         </Typography>
         <Typography fontSize="small" lineHeight={1.43}>
          <FormattedTime value={new Date(logData.tz)} />
         </Typography>
        </>
       ) : (
        <Typography fontSize="small" lineHeight={1.43}>
         <FormattedMessage id="server.manage.chargingData" />
        </Typography>
       )}
      </Stack>
     </Stack>
    </Stack>
   </Grid>
   <Grid xs={desktopViewPort ? 3 : 12}>
    <Stack>
     {server.system_family !== "windows" && (
      <>
       <Stack>
        <Typography fontWeight="bold" variant="subtitle2" textAlign="left">
         Status
        </Typography>
        <Stack direction="row" justifyContent="space-between">
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="server.inodes" />
         </Typography>
         <Typography variant="subtitle2">{`${logData.inode}%`}</Typography>
        </Stack>
        <NoLabelProgress value={logData.inode} />
       </Stack>
       <Stack>
        <Stack direction="row" justifyContent="space-between">
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="server.logs" />
         </Typography>
         <Typography variant="subtitle2">{`${logData.logs}%`}</Typography>
        </Stack>
        <NoLabelProgress value={logData.logs} />
       </Stack>
      </>
     )}
    </Stack>
   </Grid>
  </Grid>
 );
};

export default ServerStatistics;
