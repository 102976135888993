import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import AppModal from "components/shared/modal/AppModal";

import { postRevokeId } from "redux/handlers/appLoginHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RevokeAppAccess = ({ type }: { type: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setIsLoading(true);
  await dispatch(postRevokeId(type));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="profile.security.revokeAccess" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit}
    title={intl.formatMessage({ id: "profile.security.revokeAccess" })}>
    <Stack spacing={2}>
     <FormattedMessage id="profile.security.revokeAccessMessage" />
    </Stack>
   </AppModal>
  </>
 );
};

export default RevokeAppAccess;
