import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postDeleteBackup } from "redux/handlers/webAppHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteBackup = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = async () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postDeleteBackup(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.website.deleteBackup" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="DeleteIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.deleteBackup" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Typography>
     <FormattedMessage id="docker.website.deleteBackupMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default DeleteBackup;
