export const webAppWizardContainers = [
 {
  type: "php",
  icon: "PhpIcon",
  title: "docker.website.phpTitle",
  subtitle: "docker.website.phpSubtitle",
  section: "custom"
 },
 {
  type: "react",
  icon: "ReactIcon",
  title: "docker.website.reactTitle",
  subtitle: "docker.website.reactSubtitle",
  section: "web"
 },
 {
  type: "vue",
  icon: "VueIcon",
  title: "docker.website.vueTitle",
  subtitle: "docker.website.vueSubtitle",
  section: "web"
 },
 {
  type: "odoo",
  icon: "OdooIcon",
  title: "docker.website.odooTitle",
  subtitle: "docker.website.odooSubtitle",
  section: "custom"
 },
 {
  type: "wordpress",
  icon: "WordpressIcon",
  title: "docker.website.wordpressTitle",
  subtitle: "docker.website.wordpressSubtitle",
  section: "cms"
 },
 {
  type: "litespeed",
  icon: "LitespeedIcon",
  title: "docker.website.litespeedTitle",
  subtitle: "docker.website.litespeedSubtitle",
  section: "custom"
 },
 {
  type: "tomcat",
  icon: "TomcatIcon",
  title: "docker.website.tomcatTitle",
  subtitle: "docker.website.tomcatSubtitle",
  section: "custom"
 },
 {
  type: "prestashop",
  icon: "PrestashopIcon",
  title: "docker.website.prestashopTitle",
  subtitle: "docker.website.prestashopSubtitle",
  section: "cms"
 },
 {
  type: "joomla",
  icon: "JoomlaIcon",
  title: "docker.website.joomlaTitle",
  subtitle: "docker.website.joomlaSubtitle",
  section: "cms"
 }
];

export const wordpressPhpVersionLimiter = [
 {
  wp_version: "6.7.2",
  ph_not_wanted: "php-5.6"
 }
];

export const prestashopPhpVersionLimiter = [
 {
  ps_version: "8.2.0",
  ph_not_wanted: "php-5.6, php-7.4, php-8.0, php-8.1"
 }
];

export const joomlaPhpVersionLimiter = [
 {
  jm_version: "5.2.4",
  ph_not_wanted: "php-5.6, php-7.4, php-8.0, php-8.1"
 }
];

export const pluginsList = [
 {
  name: "Redis",
  icon: "",
  container_name: "redis"
 },
 {
  name: "Memcache",
  icon: "",
  container_name: "memcached"
 }
];
