import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { AppContext } from "AppContext";
import { checkServerOs } from "helpers/server";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ListOfServices } from "constants/services";

import IconSelector from "components/shared/images/IconSelector";
import SkeletonTable from "components/shared/skeletons/SkeletonTable";

import { saveServiceDatas } from "redux/handlers/serverHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import serverServicesReducer from "redux/reducers/serverServicesReducer";

import { getAllServices, getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import ServiceTableData from "./ServiceTableData";

const ServerServices = ({ sqlLogs }: { sqlLogs: string }): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);
 const services = useSelector(getAllServices);

 const [loadingData, setLoadingData] = useState<boolean>(true);
 const [sshValue, setSshValue] = useState<string>("no");

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       dispatch(
        saveServiceDatas({
         apache2: value[i]?.apache_ver || "no",
         postfix: value[i]?.ser_post || "no",
         dovecot: value[i]?.ser_dove || "no",
         nginx: value[i]?.nginx_ver || "no",
         mysql: value[i]?.sql_ver || "no",
         php: value[i]?.php_ver || "no",
         ftp: value[i]?.ser_ftp || "no",
         memcache: value[i]?.ser_memc || "no",
         redis: value[i]?.ser_redi || "no",
         ssh: value[i]?.ser_ssh || "no",
         apache_log: value[i]?.log_apache || "",
         postfix_log: value[i]?.log_post || "",
         mysql_log: value[i]?.log_mysql || "",
         ftp_log: value[i]?.log_ftp || "",
         redis_log: value[i]?.log_redi || "",
         sshOn: value[i]?.ser_sshen === "yes",
         cpu_apache: parseInt(value[i]?.cpu_apache) || 0,
         cpu_mysql: parseInt(value[i]?.cpu_mysql) || 0,
         cpu_nginx: parseInt(value[i]?.cpu_nginx) || 0,
         cpu_phpengine: parseInt(value[i]?.cpu_phpengine)
        })
       );
       setSshValue(value[i]?.ser_ssh || "no");
       setLoadingData(false);
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 useEffect(() => {
  (async () => {
   setLoadingData(false);
   await dispatch(postSyncronizeSocket("server"));
  })();
 }, []);

 useEffect(() => {
  if (services?.ssh !== sshValue) dispatch(serverServicesReducer.actions.setSshChanging(true));
  else dispatch(serverServicesReducer.actions.setSshChanging(false));
 }, [services?.ssh, sshValue]);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack direction={"column"} p={2}>
     <Stack direction="row" alignItems="center">
      <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
      <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
       <FormattedMessage id="server.services" />
      </Typography>
     </Stack>
     {loadingData ? (
      <SkeletonTable numberOfRows={8} tableHeight={60}></SkeletonTable>
     ) : (
      <TableContainer component="div">
       <Table sx={{ minWidth: desktopViewPort ? 650 : 450 }} aria-label="simple table">
        <TableHead>
         <TableRow>
          <TableCell>
           <FormattedMessage id="server.service" />
          </TableCell>
          {desktopViewPort && (
           <TableCell>
            <FormattedMessage id="server.service.status" />
           </TableCell>
          )}
          {desktopViewPort && (
           <TableCell>
            <FormattedMessage id="server.service.cpu" />
           </TableCell>
          )}
          <TableCell>
           <FormattedMessage id="table.actions" />
          </TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {ListOfServices.map((element, index) => {
          if (element.os === checkServerOs(server.os || "")) {
           return (
            <ServiceTableData
             key={`service-table-element-${index}`}
             service={element.dataToPass}
             name={element.name}
             sqlLogs={sqlLogs}
            />
           );
          } else return <TableRow key={`void-data-${index}`}></TableRow>;
         })}
        </TableBody>
       </Table>
      </TableContainer>
     )}
    </Stack>
   </Paper>
  </>
 );
};

export default ServerServices;
