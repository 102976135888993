import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppFooter from "components/footer/AppFooter";

import { postConnectLinkedin, postLoginViaLinkedin } from "redux/handlers/appLoginHandler";

import { useAppDispatch } from "hooks/reduxHook";

const LinkedinConfirmPage = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const params = new URLSearchParams(document.location.search);

 const [showError, setShowError] = useState<string>("");

 useEffect(() => {
  (async () => {
   if (localStorage.getItem("jwt")) {
    await dispatch(postConnectLinkedin(params.get("code") || "", params.get("state") || ""));
    navigate("/");
   } else {
    if (!params.get("error")) {
     await dispatch(postLoginViaLinkedin(params.get("code") || "", params.get("state") || ""));
     navigate("/login");
    }
    if (params.get("error")) setShowError(params.get("error") || "");
   }
  })();
 }, []);

 return (
  <Grid container height="100vh">
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
   <Grid
    xs={desktopViewPort ? 8 : 12}
    display="flex"
    justifyContent={"center"}
    alignItems={"center"}
    height={desktopViewPort ? "100%" : "auto"}
    padding={2}>
    <Stack justifyContent={"space-between"} height={desktopViewPort ? "100%" : "auto"}>
     <Stack spacing={4} justifyContent={"center"} height="90%">
      <Stack spacing={2} alignItems={"center"}>
       <img
        alt="KonsoleX Logo"
        src={`${process.env.PUBLIC_URL}/svg/loghi/KX_hor_B.svg`}
        style={{ marginBottom: "20px", maxWidth: "60%" }}
       />
       <Typography variant="h5">
        <FormattedMessage id="login.withLinkedin" />
       </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
       {showError !== "" ? (
        <Typography
         color="error"
         variant="subtitle2">{`Error during login with linkedin: ${showError}`}</Typography>
       ) : (
        <CircularProgress />
       )}
      </Stack>
     </Stack>
     <AppFooter />
    </Stack>
   </Grid>
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
  </Grid>
 );
};

export default LinkedinConfirmPage;
