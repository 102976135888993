import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { domainValidation } from "constants/regexp";
import { steps } from "constants/zone";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getAllIspNotPaged } from "redux/handlers/websitesHandler";
import { addNewZone, postCheckZoneName } from "redux/handlers/zoneHandler";

import { getIspList } from "redux/selectors/ispSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ZoneWizard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const ispList = useSelector(getIspList);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [nameChecked, setNameChecked] = useState<boolean>(false);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [firstCheck, setFirstCheck] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("nameserver"));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   site: "",
   isp_id: 0
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setNameChecked(false);
  setFirstCheck(false);
  setActiveStep(0);
 };

 const handleNext = async () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  const dataToSend = getValues();
  await dispatch(
   addNewZone(
    dataToSend.isp_id,
    dataToSend.site,
    ispList.find((element) => element.id === dataToSend.isp_id)?.hostname || ""
   )
  );

  setLoading(false);
  handleClose();
  handleReset();
  navigate("/app/zone");
 };

 const handleCheckSiteName = async () => {
  setStepLoading(true);
  let check = await dispatch(postCheckZoneName(getValues("site"), getValues("isp_id")));
  setNameChecked(check);
  setFirstCheck(true);
  setStepLoading(false);
 };

 const renderSiteChecked = () => {
  if (nameChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="zone.add.nameAvailable" />
    </Alert>
   );
  } else {
   return (
    <Alert severity="error">
     <FormattedMessage id="zone.add.nameNotAvailable" />
    </Alert>
   );
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="isp_id"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("isp_id", value?.id || 0)}
        options={ispList
         .filter((element) => element.id)
         .map((element) => {
          return { label: element.hostname || "", id: element.id };
         })}
        value={ispList
         .filter((element) => element.id)
         .map((element) => {
          return { label: element.hostname || "", id: element.id };
         })
         .find((element) => element.id === getValues("isp_id"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.isp_id}
          label={<FormattedMessage id="sites.isp_id" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="site"
      control={control}
      rules={{
       required: true,
       pattern: domainValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onChange={(e) => {
         setValue("site", e.target.value);
         setNameChecked(false);
         setFirstCheck(false);
        }}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.name" })}
        error={formState.isDirty && !!formState?.errors?.site}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="on"
       />
      )}
     />
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("isp_id") === 0;
   case 1:
    return watch("site").length === 0 || !domainValidation.test(watch("site"));
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return !domainValidation.test(watch("site")) || watch("site") === "" || watch("isp_id") === 0;
 };

 return !isLoading ? (
  <Stack spacing={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <IconSelector icon="ZoneIcon" />
    <Typography>
     <FormattedMessage id={"zone.newZone"} />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel>
         <FormattedMessage id={step.label} />
        </StepLabel>
        {!stepLoading ? (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 1 && firstCheck && renderSiteChecked()}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={() => (index === 1 && !nameChecked ? handleCheckSiteName() : handleNext())}
              sx={{ mt: 1, mr: 1 }}>
              {index === 1 && !nameChecked ? (
               <FormattedMessage id="app.wizard.checkValidation" />
              ) : (
               <FormattedMessage id="app.wizard.continue" />
              )}
             </Button>
            ) : (
             <></>
            )}
            {index === 1 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={() => (!nameChecked ? handleCheckSiteName() : handleOpen())}>
               {!nameChecked ? (
                <FormattedMessage id="app.wizard.checkValidation" />
               ) : (
                <FormattedMessage id="sites.confirmSite" />
               )}
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="sites.siteReset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="sites.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        ) : (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id="server.checkExecution" />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           <CircularProgress />
          </Stack>
         </StepContent>
        )}
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 380,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="zone.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="zone.add.zoneData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.name" />:
      </Typography>
      <Typography noWrap>{watch("site")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="zone.email" />:
      </Typography>
      <Typography noWrap>{watch("site") ? `admin.${watch("site")}` : ""}</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="sites.sitesServer" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.host" />:
      </Typography>
      <Typography noWrap>
       {ispList.find((element) => element.id === watch("isp_id"))?.hostname || ""}
      </Typography>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "zone.newZone" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <FormattedMessage id="zone.confirmZoneCreation" />
   </AppModal>
  </Stack>
 ) : (
  <></>
 );
};

export default ZoneWizard;
