import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const JobqueueIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <path d="M10.96,8.09h0s0,0-.01,0h.01Z" />
  <path d="M8.94,8.09h.01s0,0,0,0c0,0,0,0-.01,0Z" />
  <path d="M18.78,4.22h-3.06v-1.75c-.01-1.35-1.1-2.44-2.44-2.44h-6.99C4.93.03,3.84,1.12,3.84,2.47v1.75H1.12C.5,4.22,0,4.73,0,5.35v12.42c-.01.62.5,1.13,1.12,1.13h11.57c-.72-.78-1.17-1.81-1.17-2.96,0-2.43,1.97-4.41,4.41-4.41,1.77,0,3.28,1.05,3.99,2.56V5.35c0-.62-.51-1.13-1.13-1.13ZM13.62,4.22h-7.68v-1.24c0-.47.38-.86.86-.86h5.96c.47,0,.87.38.87.86v1.24Z" />
  <path d="M14.18,19.58c2.04.96,4.47.08,5.43-1.96.52-1.1.52-2.37,0-3.47-.67-1.43-2.11-2.35-3.69-2.35-2.25,0-4.08,1.83-4.08,4.08,0,1.58.91,3.02,2.34,3.69ZM15.34,14.53c0-.21.17-.39.39-.39s.39.17.39.39v1.55h1.17c.21,0,.39.17.39.39,0,.21-.17.39-.39.39h-1.55c-.21,0-.39-.17-.39-.39v-1.94Z" />
 </svg>,
 "Jobqueue"
);
