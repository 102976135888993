import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import WebsitePerformance from "components/websites/WebsitePerformance";
import WebsitesMenu from "components/websites/WebsitesMenu";

import { updateSiteStatus } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { websitesTableData } from "types/global/table";

const WebsitesTable = ({
 rowData,
 index,
 performance
}: {
 rowData: websitesTableData;
 index: number;
 performance: Array<{ site: string; size: string; logs: string }>;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const ispList = useSelector(getIspList);

 const [check, setCheck] = useState<boolean>(rowData.active === "y");

 useEffect(() => {
  setCheck(rowData.active === "y");
 }, [rowData.active]);

 const isp = ispList.find((isp) => isp.server_id === rowData.server_id);

 const handleRedirectToSiteDetails = (id: number, active: string): void => {
  if (rowData.type === "container-site") {
   navigate(`/app/sites/container/${rowData.server_id}/${rowData.site_id}/details`);
  } else {
   active === "y" && navigate(`/app/sites/${id}/details`);
  }
 };

 const handleSwitchToggle = async (isp_Id: number, domain_id: number) => {
  setCheck(!check);
  await dispatch(updateSiteStatus(isp_Id, domain_id));
 };

 const RenderServerType = () => {
  switch (rowData.type) {
   case "container-site":
    return (
     <Stack direction="row" alignItems="center" spacing={1}>
      <IconSelector icon="DockerIcon" props={{ sx: { fontSize: 15 } }} />
      <Typography variant="caption" fontStyle="italic">
       {rowData.type}
      </Typography>
     </Stack>
    );
   case "webserver":
    return (
     <Stack direction="row" alignItems="center" spacing={1}>
      <IconSelector icon="WebIcon" props={{ sx: { fontSize: 15 } }} />
      <Typography variant="caption" fontStyle="italic">
       {rowData.type}
      </Typography>
     </Stack>
    );
   default:
    return <></>;
  }
 };

 return (
  <TableRow
   key={`sites-list-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => handleRedirectToSiteDetails(rowData.id, rowData.active)}>
   <TableCell>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Typography variant="subtitle2">
      {rowData.active === "y" ? (
       <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
      ) : (
       <IconSelector
        icon="ReportProblemIcon"
        props={{ style: { color: "#FF1800" }, fontSize: "small" }}
       />
      )}
     </Typography>
     <Typography variant="caption" color={rowData.active === "y" ? "green" : "error"}>
      {rowData.active === "y" ? "online" : "offline"}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack justifyContent="left" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.site}</Typography>
     <RenderServerType />
    </Stack>
   </TableCell>
   <TableCell>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{isp ? isp.hostname : "N/A"}</Typography>
    </Stack>
   </TableCell>
   <TableCell align="center">
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.alias || 0}</Typography>
    </Stack>
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.php || "N/A"}</Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.php_version}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row" onClick={(event) => event.stopPropagation()}>
    {rowData.type === "container-site" ? (
     <Stack>
      <Typography variant="caption" fontStyle="italic">
       No action available
      </Typography>
     </Stack>
    ) : (
     <Stack direction="row" justifyContent="center" spacing={0}>
      <Switch
       checked={check}
       onClick={() => handleSwitchToggle(rowData.isp_id, rowData.domain_id)}
      />
     </Stack>
    )}
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.type === "container-site" ? (
     <Stack>
      <Typography variant="caption" fontStyle="italic">
       No performance available
      </Typography>
     </Stack>
    ) : (
     <WebsitePerformance site={rowData.site} performance={performance} />
    )}
   </TableCell>
   <TableCell onClick={(event) => event.stopPropagation()}>
    {rowData.type === "container-site" ? (
     <Stack>
      <Typography variant="caption" fontStyle="italic">
       No action available
      </Typography>
     </Stack>
    ) : (
     <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
      <WebsitesMenu section="websites" isp_id={rowData.isp_id} site={rowData.site} />
     </Stack>
    )}
   </TableCell>
  </TableRow>
 );
};

export default WebsitesTable;
