import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const NotificationIcon = createSvgIcon(
 <svg
  id="Livello_1"
  data-name="Livello 1"
  fill="currentColor"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <path d="M10,20c1.46,0,2.7-1.05,2.95-2.48h-5.9c.25,1.43,1.49,2.48,2.95,2.48Z" />
  <path d="M10,0C6.46.12,3.62,2.96,3.5,6.5c-.35,3.36-.89,6.7-1.63,10h16.26c-.74-3.3-1.29-6.64-1.63-10-.12-3.54-2.96-6.38-6.5-6.5Z" />
 </svg>,
 "Notification"
);
