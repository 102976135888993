import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { containerMailDomainSyncTable } from "constants/tables";

import IconSelector from "components/shared/images/IconSelector";
import SortingTable from "components/shared/tables/SortingTable";

import { getContainerMailDomainsSync } from "redux/handlers/mailDomainContainerHandler";

import {
 getMailDomainsSync,
 getMailDomainsSyncNumber
} from "redux/selectors/containerMailSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

import SyncNotFound from "../manageComponents/SyncNotFound";

const MailDomainSync = ({ mailData }: { mailData: IContainerMailDomainData }): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const { socket } = useContext(AppContext);
 const searchRef = useRef<HTMLInputElement>(null);

 const syncList = useSelector(getMailDomainsSync);
 const syncNumber = useSelector(getMailDomainsSyncNumber);

 const [reloadData, setReloadData] = useState<boolean>(true);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);

 useEffect(() => {
  function onSyncReload(value: any) {
   if (value?.completed && value?.section === "maildomains_sync") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onSyncReload);

  return () => {
   !isNil(socket) && socket.off("docker_data", onSyncReload);
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    await dispatch(
     getContainerMailDomainsSync(
      mailData.server_id_db,
      currentPage,
      rowPerPage,
      mailData.domain_name,
      searchRef?.current?.value
     )
    );
    if (syncNumber === 0) setListWasEmpty(true);
    if (isLoading) setIsLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    await dispatch(
     getContainerMailDomainsSync(
      mailData.server_id_db,
      currentPage,
      rowPerPage,
      mailData.domain_name,
      searchRef?.current?.value
     )
    );
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  await dispatch(
   getContainerMailDomainsSync(
    mailData.server_id_db,
    0,
    newRowsPerPage,
    mailData.domain_name,
    searchRef?.current?.value
   )
  );
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  await dispatch(
   getContainerMailDomainsSync(
    mailData.server_id_db,
    startIndex,
    rowPerPage,
    mailData.domain_name,
    searchRef?.current?.value
   )
  );
 };

 const handleGoToCreate = () => {
  navigate("/app/mail/container/sync/add");
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="SyncAltIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="container.maildomains.sync" />
    </Typography>
   </Stack>
   {isLoading ? (
    <CircularProgress />
   ) : (syncList && syncList?.length > 0) || !listWasEmpty ? (
    <Stack width="stretch" p={2}>
     <Stack direction="row" alignItems="center" justifyContent="space-between">
      <TextField
       size="small"
       autoComplete="new-password"
       label={<FormattedMessage id="container.maildomains.searchSync" />}
       inputRef={searchRef}
       InputLabelProps={{ shrink: true }}
       InputProps={{
        startAdornment: <IconSelector icon="SearchIcon" props={{ color: "disabled" }} />,
        autoComplete: "off"
       }}
       onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
      />
      <Button onClick={handleGoToCreate} variant="kxActionButton">
       <FormattedMessage id="container.maildomains.createNewSync" />
      </Button>
     </Stack>
     <Stack>
      <SortingTable
       tableData={containerMailDomainSyncTable}
       maxWidth={650}
       caller="container_maildomain_sync"
       listToShow={syncList}
      />
      <TablePagination
       component="div"
       count={syncNumber}
       page={currentPage}
       rowsPerPage={rowPerPage}
       onPageChange={handleOnPageChange}
       onRowsPerPageChange={handleOnRowsPerPageChange}
      />
     </Stack>
    </Stack>
   ) : (
    <SyncNotFound />
   )}
  </Paper>
 );
};

export default MailDomainSync;
