import React, { ReactElement } from "react";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const RangeSlider = ({
 max,
 min,
 defaultValue,
 changeValue
}: {
 max: number;
 min: number;
 defaultValue: Array<number>;
 changeValue: (value: Array<number>) => void;
}): ReactElement => {
 const [value, setValue] = React.useState<Array<number>>(defaultValue);

 const handleChange = (event: Event, newValue: number | number[]) => {
  setValue(newValue as number[]);
  changeValue(newValue as number[]);
 };

 return (
  <Box>
   <Slider
    getAriaLabel={() => "Temperature range"}
    value={value}
    max={max}
    min={min}
    onChange={handleChange}
    valueLabelDisplay="auto"
    sx={{
     ".MuiSlider-rail": {
      background: "linear-gradient(to right, green, yellow, red);"
     }
    }}
   />
  </Box>
 );
};

export default RangeSlider;
