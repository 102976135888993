import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import AppFooter from "components/footer/AppFooter";
import ResetOtp from "components/login/ResetOtp";

const TwoFactorPage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 return (
  <Grid container height="100vh">
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
   <Grid
    xs={desktopViewPort ? 8 : 12}
    display="flex"
    justifyContent={"center"}
    alignItems={"center"}
    height="100%"
    padding={2}>
    <Stack justifyContent={"space-between"} height={"100%"}>
     <Stack spacing={4} justifyContent={"center"} height={"80%"}>
      <Stack spacing={2} alignItems={"center"}>
       <img
        alt="KonsoleX Logo"
        src={`${process.env.PUBLIC_URL}/svg/loghi/KX_hor_B.svg`}
        style={{ marginBottom: "20px", maxWidth: "60%" }}
       />
       <Typography variant="h5">{intl.formatMessage({ id: "app.login" })}</Typography>
      </Stack>
      <ResetOtp />
     </Stack>
     <AppFooter />
    </Stack>
   </Grid>
   <Grid xs={desktopViewPort ? 2 : 0}></Grid>
  </Grid>
 );
};

export default TwoFactorPage;
