import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

const CustomWebAppRecap = ({
 server,
 site,
 type,
 version,
 icon
}: {
 server: string;
 site: string;
 type: string;
 version: string;
 icon: string;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const RenderTypeIcon = ({ version }: { version: string }): ReactElement => {
  if (version.includes("php")) {
   return <IconSelector icon={"PhpIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("tomcat")) {
   return <IconSelector icon={"TomcatIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("odoo")) {
   return <IconSelector icon={"OdooIcon"} props={{ fontSize: "large" }} />;
  } else if (version.includes("nodejs")) {
   return <IconSelector icon={"NodejsIcon"} />;
  } else if (version.includes("nextjs")) {
   return <IconSelector icon={"NextjsIcon"} />;
  } else if (version.includes("litespeed")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/litespeed.svg`}
     alt="litespeed"
    />
   );
  } else if (version.includes("apache")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/apache2.svg`}
     alt="apache"
    />
   );
  } else if (version.includes("nginx")) {
   return (
    <img
     width={20}
     height={20}
     src={`${process.env.PUBLIC_URL}/svg/providers/nginx.svg`}
     alt="nginx"
    />
   );
  } else {
   return <></>;
  }
 };

 return (
  <Paper
   elevation={0}
   sx={{
    m: 5,
    p: 2,
    maxHeight: 330,
    position: "sticky",
    top: 30,
    minWidth: desktopViewPort ? 400 : "auto",
    borderRadius: "10px",
    boxShadow: 0
   }}>
   <Stack mb={2} alignItems="center" direction="row" spacing={1}>
    <IconSelector icon={icon} props={{ fontSize: "large" }} />
    <Typography variant="h5" fontWeight="bold">
     <FormattedMessage id="docker.website.add.recap" />
    </Typography>
   </Stack>
   <Divider textAlign="left">
    <Typography variant="overline">
     <FormattedMessage id="docker.website.add.serverData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.serverName" />:
    </Typography>
    <Typography noWrap>{server}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="docker.website.add.siteData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.siteName" />:
    </Typography>
    <Typography noWrap>{site}</Typography>
   </Stack>
   <Stack direction="row" spacing={2} alignItems="center">
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.type" />:
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center">
     <RenderTypeIcon version={type} />
     <Typography noWrap>{type}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.version" />:
    </Typography>
    <Typography noWrap>{version}</Typography>
   </Stack>
  </Paper>
 );
};

export default CustomWebAppRecap;
