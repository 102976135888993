import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import ReportIcon from "@mui/icons-material/Report";
import UpdateIcon from "@mui/icons-material/Update";
import WarningIcon from "@mui/icons-material/Warning";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import DomainsOrderAction from "components/domains/actions/DomainsOrderActions";
import RemoveTransfer from "components/domains/actions/RemoveTransfer";
import AdminWrapper from "components/shared/admin/AdminWrapper";
import IconSelector from "components/shared/images/IconSelector";

import { domainOrdersTableData } from "types/global/table";

const DomainOrdersTable = ({
 rowData,
 index
}: {
 rowData: domainOrdersTableData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const formatStatus = (status: string): ReactElement => {
  switch (status) {
   case "in order":
    return <UpdateIcon style={{ color: "blue" }} />;
   case "failed":
    return <ReportIcon style={{ color: "#FF1800" }} />;
   default:
    return <WarningIcon style={{ color: "blue" }} />;
  }
 };

 const navigateToDns = () => {
  navigate(
   `/domains/dnscheck?domain=${rowData.domain}&server=${rowData.isp_id}&zone=${rowData.zone_id}`
  );
 };

 return (
  <TableRow key={`domains-list-${index}`} sx={{ cursor: "pointer" }}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={1}>
     <Typography variant="extraSmall">{formatStatus(rowData.status)}</Typography>
     <Typography variant="small" fontWeight="bold">
      {rowData.domain}
     </Typography>
    </Stack>
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={0.5} py={1}>
     <AdminWrapper>
      <Typography variant="overline">{rowData.personal_name}</Typography>
     </AdminWrapper>
    </Stack>
   </TableCell>
   <TableCell>{rowData.action || "Operation info not found"}</TableCell>
   <TableCell>
    <Stack>
     <Stack direction={"row"} spacing={2}>
      <Typography fontWeight="bold" variant="subtitle2">
       <FormattedMessage id="domain.serverWeb" />
      </Typography>
      <Typography variant="subtitle2">{rowData.web}</Typography>
     </Stack>
     <Stack direction={"row"} spacing={2}>
      <Typography fontWeight="bold" variant="subtitle2">
       <FormattedMessage id="domain.serverMail" />
      </Typography>
      <Typography variant="subtitle2">{rowData.mail}</Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell>
    <Typography variant="subtitle2">
     <FormattedDate value={rowData.createdAt} />
    </Typography>
   </TableCell>
   <TableCell>
    <Stack direction="row" spacing={1} alignItems="center">
     {rowData.action === "PENDING TRANSFER" ? (
      <RemoveTransfer id={rowData.id} />
     ) : rowData.retry || (!rowData.retry && rowData.response === 0) ? (
      rowData.response === 0 ? (
       <Typography>
        <FormattedMessage id="domain.unknownError" />
       </Typography>
      ) : (
       <DomainsOrderAction error={rowData.response} id={rowData.id} action={rowData.action} />
      )
     ) : (
      <Typography variant="subtitle2">
       <FormattedMessage id="domain.noActions" />
      </Typography>
     )}
     {(rowData.action === "PENDING TRANSFER" || rowData.action === "DOMAIN CREATION") && (
      <Tooltip title={<FormattedMessage id="domain.checkDns" />}>
       <IconButton onClick={navigateToDns}>
        <IconSelector icon="LaunchIcon" props={{ color: "kxBlue" }} />
       </IconButton>
      </Tooltip>
     )}
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default DomainOrdersTable;
