import React, { ReactElement } from "react";

import LinearProgress from "@mui/material/LinearProgress";

const NoLabelProgress = ({ value }: { value: number }): ReactElement => {
 const performanceColor = value < 50 ? "#41C58C" : value < 75 ? "#FF9800" : "#F44336";

 return (
  <LinearProgress
   sx={{
    width: "100%",
    backgroundColor: "#F1F2F7",
    "& .MuiLinearProgress-bar": {
     backgroundColor: performanceColor
    },
    height: 10,
    borderRadius: 0.8
   }}
   variant="determinate"
   value={value}
  />
 );
};

export default NoLabelProgress;
