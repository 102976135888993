import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IDomainsOrders } from "types/api/domainsApiInterface";

export const domainsOrdersApi = (state: AppState) => state.domainsOrders;

export const getDomainsOrdersNumber = createSelector(domainsOrdersApi, (domains) => {
 return domains?.totalCount;
});

export const getDomainsOrdersList = createSelector(domainsOrdersApi, (domains) => {
 return (
  domains?.dataset.map((domain: IDomainsOrders) => {
   return {
    domain: domain.domain,
    id: domain.id,
    isp_id: domain.isp_id || domain.server_id,
    link: domain.link,
    mail: domain.mail,
    personal_name: domain.personal_name,
    response: domain.response || 0,
    retry: domain.retry || false,
    status: domain.status,
    web: domain.web,
    createdAt: domain.createdAt,
    action: domain.action || "",
    zone_id_db: domain.zone_id_db,
    zone_id: domain.zone_id
   };
  }) || []
 );
});
