export const steps = [
 {
  label: "zone.add.host",
  description: "zone.add.hostMessage"
 },
 {
  label: "zone.add.selectName",
  description: "zone.add.selectNameMessage"
 }
];
