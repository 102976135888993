import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getAllMigrationLogs } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

const CheckLogs = ({ id }: { id: number }) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [logs, setLogs] = useState<string>("");

 const handleOpen = async () => {
  setLogs(await dispatch(getAllMigrationLogs(id)));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 return (
  <>
   <Tooltip title={<FormattedMessage id="utility.mailMigration.showLogs" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="SubjectIcon" props={{ color: "kxBlue" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "utility.mailMigration.showLogs" })}
    handleClose={handleClose}
    showConfirmButton={false}
    handleConfirm={handleClose}>
    <TextField multiline maxRows={20} fullWidth minRows={4} value={logs} />
   </AppModal>
  </>
 );
};

export default CheckLogs;
