import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

const JoomlaWebAppRecap = ({
 server,
 site,
 type,
 version,
 jm_version,
 db_name,
 db_type,
 db_version,
 db_user,
 db_password
}: {
 server: string;
 site: string;
 type: string;
 version: string;
 jm_version: string;
 db_name: string;
 db_type: string;
 db_version: string;
 db_user: string;
 db_password: string;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Paper
   elevation={0}
   sx={{
    m: 5,
    p: 2,
    maxHeight: 430,
    position: "sticky",
    top: 30,
    minWidth: desktopViewPort ? 400 : "auto",
    borderRadius: "10px",
    boxShadow: 0
   }}>
   <Stack mb={2} alignItems="center" direction="row" spacing={1}>
    <IconSelector icon={"JoomlaIcon"} props={{ fontSize: "large" }} />
    <Typography variant="h5" fontWeight="bold">
     <FormattedMessage id="docker.website.add.recap" />
    </Typography>
   </Stack>
   <Divider textAlign="left">
    <Typography variant="overline">
     <FormattedMessage id="docker.website.add.serverData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.serverName" />:
    </Typography>
    <Typography noWrap>{server}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="docker.website.add.siteData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.siteName" />:
    </Typography>
    <Typography noWrap>{site}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.wpVersion" />:
    </Typography>
    <Typography noWrap>{jm_version}</Typography>
   </Stack>
   <Stack direction="row" spacing={2} alignItems="center">
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.type" />:
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center">
     <IconSelector icon={"PhpIcon"} props={{ fontSize: "large" }} />
     <Typography noWrap>{type}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.version" />:
    </Typography>
    <Typography noWrap>{version}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="docker.website.add.databaseData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.databaseName" />:
    </Typography>
    <Typography noWrap>{db_name}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.databaseType" />:
    </Typography>
    <Typography noWrap>{db_type}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.databaseVersion" />:
    </Typography>
    <Typography noWrap>{db_version}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.databaseUsername" />:
    </Typography>
    <Typography noWrap>{db_user}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="docker.website.add.databasePassword" />:
    </Typography>
    <Typography noWrap>{db_password}</Typography>
   </Stack>
  </Paper>
 );
};

export default JoomlaWebAppRecap;
