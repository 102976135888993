import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import {
 IBackupData,
 ICronjobData,
 IDatabaseData,
 IFtpData,
 IWebAppAPI
} from "types/api/dockerApiInterface";

//const defaultData = { dataset: [], totalCount: 0 };

const initialState: IWebAppAPI = {
 backupData: [],
 cronjobData: [],
 databaseData: [],
 ftpData: [],
 reload: true
};

const WebAppReducer = createSlice({
 name: "webapp",
 initialState,
 reducers: {
  setBackups: (state, { payload }: PayloadAction<Array<IBackupData>>) => {
   return { ...state, backupData: payload };
  },
  setReload: (state, { payload }: PayloadAction<boolean>) => {
   return { ...state, reload: payload };
  },
  resetBackups: (state) => {
   return { ...state, backupData: [] };
  },
  setCronjobs: (state, { payload }: PayloadAction<Array<ICronjobData>>) => {
   return { ...state, cronjobData: payload };
  },
  resetCronjobs: (state) => {
   return { ...state, cronjobData: [] };
  },
  setDatabases: (state, { payload }: PayloadAction<Array<IDatabaseData>>) => {
   return { ...state, databaseData: payload };
  },
  resetDatabases: (state) => {
   return { ...state, databaseData: [] };
  },
  setFtps: (state, { payload }: PayloadAction<Array<IFtpData>>) => {
   return { ...state, ftpData: payload };
  },
  resetFtps: (state) => {
   return { ...state, ftpData: [] };
  },
  resetUtility: () => {
   return initialState;
  }
 }
});

export default WebAppReducer;
