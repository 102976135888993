import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { getWeekday } from "helpers/generic";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ChatDateBubble = ({ date }: { date: string }): ReactElement => {
 const checkToday = () => {
  const formattedDate = new Date(date);
  const today = new Date();

  if (formattedDate.getUTCDate() === today.getUTCDate()) {
   return "app.today";
  } else if (today.getTime() - formattedDate.getTime() < 604800000) {
   return getWeekday(formattedDate.getDay());
  } else if (formattedDate.getTime() + 86400000 < today.getTime()) {
   return `${formattedDate.getUTCDate()}/${formattedDate.getUTCMonth() + 1}/${
    formattedDate.getUTCFullYear() - 2000
   }`;
  } else if (
   formattedDate.getUTCDate() < today.getUTCDate() ||
   formattedDate.getUTCDate() > today.getUTCDate()
  ) {
   return `${formattedDate.getUTCDate()}/${formattedDate.getUTCMonth() + 1}/${
    formattedDate.getUTCFullYear() - 2000
   }`;
  } else {
   return "app.today";
  }
 };

 return (
  <Stack direction="row" justifyContent="center" alignItems="center">
   <Stack p={1} sx={{ borderRadius: 1, backgroundColor: "#00278B", color: "white" }}>
    <Typography textTransform="uppercase" variant="subtitle2">
     <FormattedMessage id={checkToday()} />
    </Typography>
   </Stack>
  </Stack>
 );
};

export default ChatDateBubble;
