import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import DockerWebsiteReducer from "redux/reducers/dockerWebsiteReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import SessionReducer from "redux/reducers/sessionReducer";
import WebAppReducer from "redux/reducers/webAppReducer";

import {
 IBackupData,
 ICronjobData,
 IDatabaseData,
 IFilemanagerData,
 IFtpData,
 IWebAppEnviromentVersion
} from "types/api/dockerApiInterface";
import { IDockerSiteAPI } from "types/api/webAppApiInterface";

import { ApiService } from "service/ApiService";

export const getWebAppData =
 (server_id: number, page: number, limit: number, site_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerSiteAPI>(
    `/docker/site/getsites?page=${page}&limit=${limit}&site_id=${site_id}&server_id_db=${server_id}`
   );
   dispatch(DockerWebsiteReducer.actions.setDockerWebsites(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateSpecificWebApp =
 (
  id: number,
  name: string,
  php_version: string,
  repository: string,
  framework: string,
  enviroment_version: string,
  enviroment_repo: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/installfullsite", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy",
    framework,
    enviroment_version,
    enviroment_repo
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: `Creating ${framework} web app`,
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create ${framework} web app - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getEnviromentVersions =
 (type: string): AppAction<Promise<IWebAppEnviromentVersion | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IWebAppEnviromentVersion>(
    `/docker/enviroment/getversions?type=${type}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get repository - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postInstallFilemanager =
 (id: number): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/activefilemanager", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "filemanager", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install filemanager - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const saveFilemanagerSettings =
 (
  id: number,
  filemanager_active: boolean,
  filemanager_password: string | null,
  filemanager_port: number | null
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  dispatch(
   DockerWebsiteReducer.actions.setDockerWebsitesFilemanager({
    id,
    filemanager_port,
    filemanager_password,
    filemanager_active
   })
  );
 };

export const postDisinstallFilemanager =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService<boolean>("/docker/site/deactivatefilemanager", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "filemanager", value: 0 }));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install filemanager - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllCronjob =
 (container_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<ICronjobData>>("/docker/site/cronjob/list", "POST", {
    container_id
   });
   dispatch(WebAppReducer.actions.setReload(false));
   dispatch(WebAppReducer.actions.setCronjobs(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateCronjob =
 (id: number, file: string, schedule: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/cronjob/add", "POST", {
    id: id,
    file: file,
    schedule: schedule
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating cronjob",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteCronjob =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/cronjob/delete", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting cronjob",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete cronjob - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllBackups =
 (id: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ dataset: Array<IBackupData>; totalCount: number }>(
    `/docker/backup/getbackups?site_id=${id}&q=${q || ""}`
   );
   dispatch(WebAppReducer.actions.setReload(false));
   dispatch(WebAppReducer.actions.setBackups(data.dataset || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get ftp users - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateNewBackup =
 (id: number, type: string, directory?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/backup/createbackup", "POST", {
    id,
    type,
    directory
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating new backup",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create new backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteBackup =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/backup/deletebackup", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting backup",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRestoreBackup =
 (id: number, delete_data: boolean): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/backup/restorebackup", "POST", {
    id,
    delete_data
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Restoring from backup",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to restore from backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postScheduleBackup =
 (
  id: number,
  type: string,
  backup_status: boolean,
  schedule: string,
  backup_retention: number,
  backup_directory?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/backup/create-scheduled-backup", "POST", {
    id,
    type,
    backup_status,
    schedule,
    backup_retention,
    backup_directory
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Scheduling backup",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to schedule backup - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGetDatabaseData =
 (container_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IDatabaseData>>("/docker/site/db/list", "POST", {
    container_id: container_id
   });
   dispatch(WebAppReducer.actions.setReload(false));
   dispatch(WebAppReducer.actions.setDatabases(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get database data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallDatabase =
 (
  id: number,
  db_name: string,
  db_user: string,
  db_password: string,
  type: string,
  db_version: string
 ): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/installdb", "POST", {
    id,
    db_name,
    db_user,
    db_password,
    type,
    db_version
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postUninstallDatabase =
 (id: number): AppAction<Promise<IFilemanagerData | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IFilemanagerData>("/docker/site/disinstalldb", "POST", {
    id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall database - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postInstallPhpAdmin =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/installphpmyadmin", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install PHP admin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postDisinstallPhpAdmin =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService<boolean>("/docker/site/db/disinstallphpmyadmin", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: 0 }));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall PHP admin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallAdminer =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/installadminer", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install adminer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postUninstallAdminer =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/disinstalladminer", "POST", {
    id: id
   });
   dispatch(SessionReducer.actions.setSession({ name: "php", value: 0 }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall adminer - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const getAllFtpUsers =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IFtpData>>("/docker/site/ftp/list", "POST", {
    id
   });
   dispatch(WebAppReducer.actions.setReload(false));
   dispatch(WebAppReducer.actions.setFtps(data));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get ftp users - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateFtpUser =
 (id: number, ftp_user: string, ftp_password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/create", "POST", {
    id: id,
    ftp_user: ftp_user,
    ftp_password: ftp_password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating ftp user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableFtp =
 (server_id_db: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/deactivate", "POST", {
    server_id_db: server_id_db
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Disabling ftp",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable ftp - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteFtpUser =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/ftp/delete", "POST", {
    id_ftp: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting ftp user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete ftp user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetLitespeedPassword =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/docker/site/resetlitespeedpwd", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting litespeed password...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset litespeed password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallEnviroment =
 (id: number, enviroment_repo: string, enviroment_version: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/install-nodejs-compiler", "POST", {
    id,
    enviroment_repo,
    enviroment_version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing enviroment",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install enviroment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUninstallEnviroment =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/remove-nodejs-compiler", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Uninstalling enviroment",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall enviroment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallGithubRunner =
 (
  id: number,
  repositoryGithubUrl: string,
  token_github: string,
  hash_check: boolean
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/install-runner-github", "POST", {
    id,
    repositoryGithubUrl,
    token_github,
    hash_check
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing GitHub runner",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install GitHub runner - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postOpenContainerPorts =
 (id: number, container_name: string, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/open-ssh-port", "POST", {
    id,
    container_name,
    type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: `Opening ${container_name} port`,
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to open container port - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCloseContainerPorts =
 (id: number, container_name: string, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/close-ssh-port", "POST", {
    id,
    container_name,
    type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: `Opening ${container_name} port`,
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to open container port - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postGenerateSshKey =
 (id: number, container_name: string, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/generate-ssh-key", "POST", {
    id,
    container_name,
    type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: `Generating key for ${container_name}`,
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to generate key - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallPgadmin =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/installpgadmin", "POST", {
    id
   });
   dispatch(SessionReducer.actions.setSession({ name: "pgadmin", value: id }));
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install pgadmin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postUninstallPgadmin =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/db/disinstallpgadmin", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Uninstalling PgAdmin",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to uninstall pgadmin - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateOdooWebApp =
 (
  id: number,
  name: string,
  php_version: string,
  repository: string,
  db_name: string,
  db_user: string,
  db_password: string,
  type: string,
  db_version: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/odoo/install", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy",
    db_name,
    db_user,
    db_password,
    type,
    db_version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateWordpressWebApp =
 (
  id: number,
  name: string,
  php_version: string,
  repository: string,
  db_name: string,
  db_user: string,
  db_password: string,
  type: string,
  db_version: string,
  wordpress_version: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/wordpress/install", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy",
    db_name,
    db_user,
    db_password,
    type,
    db_version,
    wordpress_version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetWpAdmin =
 (id: number, user: string, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/wordpress/setpassword", "POST", {
    id,
    user,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetWpAdminPassword =
 (id: number, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/wordpress/setpassword", "POST", {
    id,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreatePrestashopWebApp =
 (
  id: number,
  name: string,
  php_version: string,
  repository: string,
  db_name: string,
  db_user: string,
  db_password: string,
  type: string,
  db_version: string,
  prestashop_version: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/prestashop/install", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy",
    db_name,
    db_user,
    db_password,
    type,
    db_version,
    prestashop_version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetPsAdmin =
 (id: number, user: string, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/prestashop/setpassword", "POST", {
    id,
    user,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetPsAdminPassword =
 (id: number, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/prestashop/setpassword", "POST", {
    id,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateJoomlaWebApp =
 (
  id: number,
  name: string,
  php_version: string,
  repository: string,
  db_name: string,
  db_user: string,
  db_password: string,
  type: string,
  db_version: string,
  joomla_version: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/joomla/install", "POST", {
    server_id_db: id,
    domain_name: name,
    site_name: name,
    container_php_name: name,
    php_version: php_version,
    repo_name: repository,
    container_nginx_certbot_name: "nginx_certbot",
    network_name: "nginx-proxy",
    db_name,
    db_user,
    db_password,
    type,
    db_version,
    joomla_version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetJmAdmin =
 (id: number, user: string, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/joomla/setpassword", "POST", {
    id,
    user,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin user",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postResetJmAdminPassword =
 (id: number, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/joomla/setpassword", "POST", {
    id,
    password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Resetting admin password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to reset admin password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postVisualizeLogs =
 (
  server_id_db: number,
  container_name: string,
  hours?: number,
  minutes?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/logs", "POST", {
    server_id_db,
    container_name,
    hours,
    minutes
   });
   console.log(data);
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get container logs - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallRedis =
 (id: number, repo_name: string, version: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/installredis", "POST", {
    id,
    repo_name,
    version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing redis...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install redis - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postInstallMemcached =
 (id: number, repo_name: string, version: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/installmemcached", "POST", {
    id,
    repo_name,
    version
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing memcache...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install memcache - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUninstallRedis =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/removeredis", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Removing redis...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to remove redis - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
export const postUninstallMemcached =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/removememcached", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Removing memcache...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to remove memcache - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
