import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { formatCountryCode } from "helpers/server";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import {
 getServerProvider,
 getServersLocations,
 postRetryLocationNotFoundServer
} from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ILocations } from "types/api/serversApiInterface";
import { serverOrdersTableData } from "types/global/table";

const RetryLocationNotFound = ({
 id,
 order
}: {
 id: number;
 order: serverOrdersTableData;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [locationList, setLocationList] = useState<Array<ILocations>>([]);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [selectedLocation, setSelectedLocation] = useState<string>("");

 const handleOpen = async () => {
  const providers = await dispatch(getServerProvider());
  if (providers) {
   setLocationList(
    await dispatch(
     getServersLocations(providers?.find((element) => element.name === order.provider)?.id || 1)
    )
   );
  }
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRetryLocationNotFoundServer(id, selectedLocation));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <Typography>
     <FormattedMessage id={"server.retryOrder"} />
    </Typography>
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.retryOrder" })}
    handleClose={handleClose}
    disabled={loading || selectedLocation === ""}
    handleConfirm={handleConfirm}>
    <FormControl>
     <InputLabel>
      <FormattedMessage id="server.retryOrder.locations" />
     </InputLabel>
     <Select
      value={selectedLocation}
      label={<FormattedMessage id="server.retryOrder.locations" />}
      onChange={(e) => setSelectedLocation(e.target.value)}>
      {locationList.map((element, index) => {
       return (
        <MenuItem key={`graph-value-${index}`} value={element.name}>
         <Stack direction="row" spacing={1} alignItems="center">
          <Stack component="span" className={`fi fi-${formatCountryCode(element.country)}`} />
          <Typography> {element.city}</Typography>
         </Stack>
        </MenuItem>
       );
      })}
     </Select>
    </FormControl>
   </AppModal>
  </>
 );
};

export default RetryLocationNotFound;
