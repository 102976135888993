import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postChangeServerLock } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const LockServer = ({ id, locked }: { id: number; locked: boolean }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postChangeServerLock(id));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: locked ? "server.unlock" : "server.lock" })}>
    <IconButton onClick={handleOpen}>
     {locked ? (
      <IconSelector icon="LockIcon" props={{ color: "kxOrange" }} />
     ) : (
      <IconSelector icon="LockOpenIcon" props={{ color: "kxOrange" }} />
     )}
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}
    title={intl.formatMessage({ id: "server.lockServer" })}>
    <FormattedMessage id={"server.lockServerMessage"} />
   </AppModal>
  </>
 );
};

export default LockServer;
