import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import AutomaticPayment from "./sections/AutomaticPayment";
import ChangePasswordPage from "./sections/ChangePasswordPage";
import LinkExternalApp from "./sections/LinkExternalApp";
import TwoFactor from "./sections/TwoFactor";

const Security = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <ChangePasswordPage />;
   case 1:
    return <Autenthication />;
   case 2:
    return <AutomaticPayment />;
   default:
    return <ChangePasswordPage />;
  }
 };

 const Autenthication = (): ReactElement => {
  return (
   <Stack>
    <LinkExternalApp />
    <TwoFactor />
   </Stack>
  );
 };

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0, pb: 2 }}>
   <Tabs
    value={section}
    onChange={handleChangeTab}
    variant={desktopViewPort ? "standard" : "scrollable"}
    scrollButtons={true}
    allowScrollButtonsMobile>
    <Tab label={intl.formatMessage({ id: "profile.security.password" })} />
    <Tab label={intl.formatMessage({ id: "profile.security.2factor" })} />
    <Tab label={intl.formatMessage({ id: "profile.security.autoPayment" })} />
   </Tabs>
   <RenderTabContent />
  </Paper>
 );
};

export default Security;
