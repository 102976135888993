import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { emailValidation } from "constants/regexp";

import { resetAction } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

interface IFormInput {
 email: string;
}

const Reset = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [resetError, setResetError] = useState<boolean>(false);
 const [resetSuccess, setResetSuccess] = useState<boolean>(false);

 const { control, handleSubmit, formState } = useForm({
  defaultValues: {
   email: ""
  }
 });

 const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
  setIsLoading(true);
  const status = await dispatch(resetAction(data.email));
  if (status !== 200) {
   setResetError(true);
   setResetSuccess(false);
   setIsLoading(false);
  } else {
   setIsLoading(false);
   setResetError(false);
   setResetSuccess(true);
  }
 };

 return (
  <Stack>
   <form
    onSubmit={handleSubmit(onSubmit)}
    style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center"
    }}>
    <Controller
     name="email"
     control={control}
     rules={{
      required: true,
      pattern: emailValidation
     }}
     render={({ field }) => (
      <TextField
       {...field}
       fullWidth={true}
       label={intl.formatMessage({ id: "login.emailField" })}
       error={formState.isDirty && !!formState?.errors?.email}
       InputLabelProps={{ shrink: true }}
       sx={{ my: 2 }}
       helperText={
        formState.isDirty &&
        !!formState?.errors?.email &&
        intl.formatMessage({ id: "login.invalidMail" })
       }
      />
     )}
    />
    {isLoading && <div>Loading...</div>}
    {resetError && !isLoading && (
     <Typography color="error">
      <FormattedMessage id="login.wrongEmail" />
     </Typography>
    )}
    {resetSuccess && !isLoading && (
     <Typography sx={{ color: "green" }}>
      <FormattedMessage id="login.mailSent" />
     </Typography>
    )}
    <Button
     disabled={isLoading || resetSuccess}
     variant="kxFilledActionButton"
     onClick={handleSubmit(onSubmit)}
     sx={{ mt: 2, py: 3, width: "100%" }}>
     <FormattedMessage id="login.sendLink" />
    </Button>
   </form>
  </Stack>
 );
};

export default Reset;
