import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { containerMailsLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getSelectedMailDomainData } from "redux/handlers/mailDomainContainerHandler";
import { postServerDetails } from "redux/handlers/serverHandler";
import { getServerByType } from "redux/handlers/utilityHandler";

import ContainerMailDetailsReducer from "redux/reducers/containerMailDomainReducer";

import { getContainerMailsList } from "redux/selectors/containerMailSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";
import { IServerByTypeAPI } from "types/api/serversApiInterface";

import MailDomainAlias from "./manageSections/MailDomainAlias";
import MailDomainDetails from "./manageSections/MailDomainDetails";
import MailDomainFail2ban from "./manageSections/MailDomainFail2ban";
import MailDomainMailbox from "./manageSections/MailDomainMailbox";
import MailDomainSync from "./manageSections/MailDomainSync";
import MailDomainMonitoring from "./MailDomainMonitoring";

const MailDomainManage = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 //const navigate = useNavigate();
 const location = useLocation();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const mails = useSelector(getContainerMailsList);

 const { socket } = useContext(AppContext);
 const params = useParams<{ server_id: string; mail_id: string; section: string }>();

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [mailData, setMailData] = useState<IContainerMailDomainData | null>(null);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  function onDockerMaildomains(value: any) {
   if (value?.completed && value?.section === "maildomains") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onDockerMaildomains);
  return () => {
   !isNil(socket) && socket.off("docker_data", onDockerMaildomains);
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   await dispatch(postServerDetails(Number(params.server_id)));
   await dispatch(
    getSelectedMailDomainData(Number(params?.server_id), 0, 10, Number(params?.mail_id))
   );
   setServers(await dispatch(getServerByType("container")));
  })();
 }, []);

 useEffect(() => {
  (async () => {
   setMailData(mails.find((element) => element.id === Number(params?.mail_id)) || null);
   setIsLoading(false);
  })();
 }, [mails]);

 useEffect(() => {
  if (reloadData) {
   (async () => {
    await dispatch(postServerDetails(Number(params.server_id)));
    await dispatch(
     getSelectedMailDomainData(Number(params?.server_id), 0, 10, Number(params?.mail_id))
    );
    dispatch(ContainerMailDetailsReducer.actions.setReload(true));
    setReloadData(false);
   })();
  }
 }, [reloadData]);

 /*useEffect(() => {
  if (!mailData) navigate(`/servers/manage/${params?.server_id}/container`);
 }, [mailData]);*/

 useEffect(() => {
  dispatch(ContainerMailDetailsReducer.actions.setReload(true));
 }, [params.section]);

 const RenderMailSection = (): ReactElement => {
  if (mailData) {
   switch (params.section) {
    case "details":
     return <MailDomainDetails mailData={mailData} />;
    case "mailbox":
     return (
      <MailDomainMailbox
       id={mailData.id}
       server_id={mailData.server_id_db}
       domain={mailData.domain_name}
       max_mailboxes={mailData.domain_limit.max_num_mboxes_for_domain}
      />
     );
    case "fail2ban":
     return <MailDomainFail2ban mailData={mailData} />;
    case "alias":
     return <MailDomainAlias mailData={mailData} />;
    case "sync":
     return <MailDomainSync mailData={mailData} />;
    default:
     return <></>;
   }
  } else {
   return <></>;
  }
 };

 return !isLoading ? (
  <Container maxWidth={false} sx={{ mx: 2, width: "auto" }}>
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app" },
      { name: "mails", url: "/app/mail" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container spacing={3}>
    <Grid xs={desktopViewPort ? 3 : 12}>
     <Stack mt={4} spacing={1}>
      <Paper elevation={0} sx={{ mt: 1, pb: 0.7, borderRadius: "10px", boxShadow: 0, p: 2 }}>
       <Stack
        spacing={1}
        alignItems="flex-start"
        pb={1}
        sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
        <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
         {mailData?.domain_name}
        </Typography>
        <Stack direction="row" spacing={1}>
         <Tooltip title={intl.formatMessage({ id: "sites.details.goToServer" })}>
          <NavLink
           to={`/servers/manage/${servers.find((element) => element.id === mailData?.server_id_db)
            ?.id}/container`}
           style={{
            textDecoration: "none"
           }}>
           <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
            <IconSelector icon="ServerIcon" props={{ fontSize: "small", color: "primary" }} />
            <Typography variant="subtitle2" color="primary">
             {servers.find((element) => element.id === mailData?.server_id_db)?.server_name}
            </Typography>
           </Stack>
          </NavLink>
         </Tooltip>
        </Stack>
       </Stack>
      </Paper>
      {desktopViewPort ? (
       <SectionMenu
        menuList={containerMailsLinks.map((item) => ({
         name: item.name,
         url: `/app/mail/container/${params?.server_id}/${params?.mail_id}/${item.url}`,
         icon: item.icon
        }))}
       />
      ) : (
       <MobileSectionMenu
        links={containerMailsLinks}
        disabled={[]}
        url={`/app/mail/container/${params?.server_id}/${params?.mail_id}`}
       />
      )}
     </Stack>
    </Grid>
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <MailDomainMonitoring />
     {mailData && <RenderMailSection />}
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default MailDomainManage;
