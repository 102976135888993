import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isNil } from "ramda";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditMailMsgLimits } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailDomainData } from "types/api/containerMailDomainApiInterface";

const EditMailDomainLimits = ({
 mailData
}: {
 mailData: IContainerMailDomainData;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, reset, watch, setValue } = useForm({
  defaultValues: {
   type: "",
   limit: 0
  }
 });

 const onSubmit: SubmitHandler<{ type: string; limit: number }> = async (data: {
  type: string;
  limit: number;
 }) => {
  setLoading(true);
  await dispatch(
   postEditMailMsgLimits(
    mailData.id,
    data.limit.toString(),
    data.type,
    "/docker/maildomain/update-msg-limit"
   )
  );
  setLoading(false);
  handleClose();
 };

 useEffect(() => {
  switch (watch("type")) {
   case "s":
    setValue("limit", mailData.domain_limit.msg_limit_second || 0);
    break;
   case "m":
    setValue("limit", mailData.domain_limit.msg_limit_minute || 0);
    break;
   case "h":
    setValue("limit", mailData.domain_limit.msg_limit_hour || 0);
    break;
   case "d":
    setValue("limit", mailData.domain_limit.msg_limit_day || 0);
    break;
   default:
    break;
  }
 }, [watch("type")]);

 const checkDisabled = (): boolean => {
  return watch("type") === "" || watch("limit") === 0 || isNil(watch("limit"));
 };

 return (
  <>
   <Tooltip
    title={intl.formatMessage({ id: "container.maildomains.updateMailDomainLimits" })}
    placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="TuneIcon" props={{ color: "secondary" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.updateMailDomainLimits" })}
    handleClose={handleClose}
    disabled={loading || checkDisabled()}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Controller
      name="type"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <FormControl>
        <InputLabel>
         <FormattedMessage id="container.maildomains.limitType" />
        </InputLabel>
        <Select
         {...field}
         label={<FormattedMessage id="container.maildomains.limitType" />}
         onChange={(e) => setValue("type", e.target.value)}>
         <MenuItem key="s-key" value="s">
          <FormattedMessage id="app.seconds" />
         </MenuItem>
         <MenuItem key="m-key" value="m">
          <FormattedMessage id="app.minutes" />
         </MenuItem>
         <MenuItem key="h-key" value="h">
          <FormattedMessage id="app.hour" />
         </MenuItem>
         <MenuItem key="d-key" value="d">
          <FormattedMessage id="app.day" />
         </MenuItem>
        </Select>
       </FormControl>
      )}
     />
     <Controller
      name="limit"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        type="number"
        label={intl.formatMessage({ id: "container.maildomains.maxLimit" })}
        error={watch("limit") < -1 || watch("limit") === 0 || !watch("limit")}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         (watch("limit") < -1 || watch("limit") === 0 || !watch("limit")) &&
         intl.formatMessage({ id: "container.maildomains.maxLimitError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditMailDomainLimits;
