import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { filterUtilityInfo } from "helpers/utility";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { utilityList } from "constants/utility";

import IconSelector from "components/shared/images/IconSelector";

import { postObtainUtilityRecap } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IUtilityInfo } from "types/api/utilityApiInterface";

const UtilityDashboard = (): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [utilityInfo, setUtilityInfo] = useState<IUtilityInfo | null>(null);

 useEffect(() => {
  (async () => {
   setUtilityInfo(await dispatch(postObtainUtilityRecap()));
  })();
 }, []);

 const handleNavigate = (link: string) => {
  navigate(link);
 };

 const RenderTypeIcon = ({ type }: { type: string }): ReactElement => {
  if (type.includes("container")) {
   return <IconSelector icon="DockerIcon" />;
  } else {
   return <Typography variant="caption">--</Typography>;
  }
 };

 return (
  <Stack px={desktopViewPort ? 20 : 5} pt={10}>
   <Grid container spacing={2}>
    {utilityList.map((element, index) => {
     return (
      <Grid key={`addons-dash-${index}`} xs={desktopViewPort ? "auto" : 12}>
       <Card sx={{ minWidth: desktopViewPort ? 500 : "auto", minHeight: 300 }}>
        <CardHeader
         avatar={
          <IconSelector icon={element.icon} props={{ sx: { fontSize: 40 }, color: "kxOrange" }} />
         }
         action={
          <Stack direction="row" spacing={1}>
           <Tooltip title={<FormattedMessage id={element.addTitle} />}>
            <IconButton aria-label="settings" onClick={() => handleNavigate(element.add)}>
             <IconSelector icon="AddIcon" props={{ color: "kxOrange" }} />
            </IconButton>
           </Tooltip>
           <Tooltip title={<FormattedMessage id="utility.goToSection" />}>
            <IconButton aria-label="settings" onClick={() => handleNavigate(element.link)}>
             <IconSelector icon="ArrowOutwardIcon" props={{ color: "kxOrange" }} />
            </IconButton>
           </Tooltip>
          </Stack>
         }
         title={
          <Typography textTransform="uppercase" fontWeight="bold">
           <FormattedMessage id={element.name} />
          </Typography>
         }
         subheader={`${
          utilityInfo ? filterUtilityInfo(utilityInfo, element.section)?.totalCount : "0"
         } ${intl.formatMessage({ id: element.internElement })}`}
         sx={{ textAlign: "left" }}
        />
        <Divider sx={{ borderColor: "#f86e06", mx: 1 }} />
        <Stack
         px={2}
         py={1}
         maxWidth={desktopViewPort ? 470 : "auto"}
         textAlign="left"
         sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
         <Typography variant="caption">
          <FormattedMessage id={element.sectionMessage} />
         </Typography>
         <Divider />
         {utilityInfo && filterUtilityInfo(utilityInfo, element.section)?.totalCount === 0 ? (
          <Stack justifyContent="center" alignItems="center" mt={4}>
           <IconSelector icon="WarningIcon" props={{ color: "warning" }} />
           <Typography textAlign="center" variant="caption" fontStyle="italic">
            <FormattedMessage id="utility.elementNotFound" />
           </Typography>
          </Stack>
         ) : (
          <Stack pt={1} color="rgba(0, 0, 0, 0.6)">
           <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
            <Typography variant="caption">
             <FormattedMessage id="utility.availableTypes" />
            </Typography>
            <Stack direction="row" spacing={1}>
             {utilityInfo ? (
              <RenderTypeIcon type={filterUtilityInfo(utilityInfo, element.section)?.type || ""} />
             ) : (
              "--"
             )}
            </Stack>
           </Stack>
          </Stack>
         )}
        </Stack>
       </Card>
      </Grid>
     );
    })}
   </Grid>
  </Stack>
 );
};

export default UtilityDashboard;
