import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { formatBytes, formatMegaBytes } from "helpers/numberFormatting";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import MailboxActions from "components/apps/containerMailDomain/actions/mailboxActions/MailboxActions";
import MailPerformance from "components/apps/containerMailDomain/manageComponents/MailPerformance";

import { IContainerMailboxData } from "types/api/containerMailDomainApiInterface";

const ContainerMailboxTable = ({
 rowData,
 index
}: {
 rowData: IContainerMailboxData;
 index: number;
}): ReactElement => {
 const formatQuota = (quota: number, occupied: number): string => {
  const firstFormat = formatBytes(quota, "MB");
  const secondFormat = formatBytes(occupied, "MB");
  if (firstFormat >= 1024) {
   return `${
    secondFormat > 1024
     ? formatMegaBytes(secondFormat, "GB").toFixed(1)
     : `${secondFormat.toFixed(0)} MB`
   }/${formatMegaBytes(firstFormat, "GB").toFixed(0)} GB`;
  } else {
   return `${secondFormat.toFixed(0)}/${firstFormat.toFixed(0)} MB`;
  }
 };

 return (
  <TableRow key={`cron-table-${index}`}>
   <TableCell component="th" scope="row">
    <Chip
     size="small"
     label={rowData.active ? "Online" : "Offline"}
     color={rowData.active ? "success" : "error"}
    />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography fontWeight="bold" variant="subtitle2">
      {rowData.username}
     </Typography>
     <Typography variant="caption">{rowData.name ? rowData.name : "No description"}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography variant="subtitle2" minWidth={95}>
      {rowData.quota === 0 ? (
       <FormattedMessage id="container.maildomains.unlimited" />
      ) : (
       formatQuota(rowData.quota, rowData.quota_used)
      )}
     </Typography>
     {rowData.quota !== 0 && <MailPerformance value={(rowData.quota_used / rowData.quota) * 100} />}
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" spacing={1}>
     <Stack spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography fontWeight="bold" variant="caption" minWidth={30}>
        <FormattedMessage id="container.maildomains.imap" />
       </Typography>
       <Chip
        label={rowData.protocols.imap_access ? "Enabled" : "Disabled"}
        size="small"
        color={rowData.protocols.imap_access ? "success" : "error"}
       />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography fontWeight="bold" variant="caption" minWidth={30}>
        <FormattedMessage id="container.maildomains.pop" />
       </Typography>
       <Chip
        label={rowData.protocols.pop3_access ? "Enabled" : "Disabled"}
        size="small"
        color={rowData.protocols.pop3_access ? "success" : "error"}
       />
      </Stack>
     </Stack>
     <Stack spacing={1}>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography fontWeight="bold" variant="caption" minWidth={30}>
        <FormattedMessage id="container.maildomains.smtp" />
       </Typography>
       <Chip
        label={rowData.protocols.smtp_access ? "Enabled" : "Disabled"}
        size="small"
        color={rowData.protocols.smtp_access ? "success" : "error"}
       />
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography fontWeight="bold" variant="caption" minWidth={30}>
        <FormattedMessage id="container.maildomains.sieve" />
       </Typography>
       <Chip
        label={rowData.protocols.sieve_access ? "Enabled" : "Disabled"}
        size="small"
        color={rowData.protocols.sieve_access ? "success" : "error"}
       />
      </Stack>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack spacing={1}>
     <Stack direction="row" spacing={2}>
      <Typography variant="caption">{`${Math.trunc(rowData.spam_score)} - ${Number(
       (rowData.spam_score % 1) * 100 > 15
        ? ((rowData.spam_score % 1) * 10).toFixed(0)
        : ((rowData.spam_score % 1) * 100).toFixed(0)
      )}`}</Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       M/s
      </Typography>
      <Typography variant="caption">{rowData.mailbox_limits.msg_limit_second || "--"}</Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       M/m
      </Typography>
      <Typography variant="caption">{rowData.mailbox_limits.msg_limit_minute || "--"}</Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       M/h
      </Typography>
      <Typography variant="caption">{rowData.mailbox_limits.msg_limit_hour || "--"}</Typography>
     </Stack>
     <Stack direction="row" spacing={1}>
      <Typography variant="caption" fontWeight="bold">
       M/d
      </Typography>
      <Typography variant="caption">{rowData.mailbox_limits.msg_limit_day || "--"}</Typography>
     </Stack>
    </Stack>
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" spacing={1} justifyContent="flex-end">
     <MailboxActions rowData={rowData} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default ContainerMailboxTable;
