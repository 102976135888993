import React, { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { postGetDatabaseData } from "redux/handlers/webAppHandler";

import { getDatabases, getReload } from "redux/selectors/webAppSelector";

import { useAppDispatch } from "hooks/reduxHook";

import DisableRemoteDb from "../actions/databaseActions/DisableRemoteDb";
import EnableRemoteDb from "../actions/databaseActions/EnableRemoteDb";
import RemoveDatabase from "../actions/databaseActions/RemoveDatabase";
import PowerOnSite from "../actions/PowerOnSite";

const DatabaseSection = ({
 id,
 container_id,
 dbOnline
}: {
 id: number;
 container_id: string;
 dbOnline: string;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const reload = useSelector(getReload);
 const databaseList = useSelector(getDatabases);

 useEffect(() => {
  if (reload) {
   (async () => {
    if (container_id) {
     await dispatch(postGetDatabaseData(container_id));
    }
   })();
  }
 }, [reload]);

 const RenderDatabaseIcon = (): ReactElement => {
  switch (databaseList[0]?.db_type) {
   case "mysql":
    return <IconSelector icon="MysqlIcon" />;
   case "mariadb":
    return <IconSelector icon="MariadbIcon" />;
   case "postgres":
    return <IconSelector icon="PostgresqlIcon" />;
   default:
    return <></>;
  }
 };

 return dbOnline === "online" ? (
  <Stack textAlign="left" p={2}>
   <Stack direction="row" alignItems="center" spacing={1}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
     Database
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.databaseName" />
    </Typography>
    <Typography variant="subtitle2">{`${databaseList[0]?.db_name}`}</Typography>
   </Stack>
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.databaseHost" />
    </Typography>
    <Typography variant="subtitle2">{`${databaseList[0]?.db_host}`}</Typography>
   </Stack>
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
     Access
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.databaseUser" />
    </Typography>
    <Typography variant="subtitle2">{`${databaseList[0]?.db_user}`}</Typography>
   </Stack>
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.databasePassword" />
    </Typography>
    <Typography variant="subtitle2">{`${databaseList[0]?.db_password}`}</Typography>
   </Stack>
   {databaseList[0]?.db_type === "postgres" ? (
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databasePasswordPostgres" />
     </Typography>
     <Typography variant="subtitle2">{`${databaseList[0]?.db_password}`}</Typography>
    </Stack>
   ) : (
    <Stack direction="row">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.databasePasswordRoot" />
     </Typography>
     <Typography variant="subtitle2">{`${databaseList[0]?.db_root_password}`}</Typography>
    </Stack>
   )}
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
     Type
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.databaseType" />
    </Typography>
    <Stack direction="row" spacing={1} alignItems="center">
     <RenderDatabaseIcon />
     <Typography variant="subtitle2">{`${databaseList[0]?.db_type || "Not available"}`}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
     Remote
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   {databaseList[0]?.remote ? (
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.remoteAccess" />
     </Typography>
     <Chip label="Online" color="success" size="small" />
    </Stack>
   ) : (
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
      <FormattedMessage id="docker.website.remoteAccess" />
     </Typography>
     <Chip label="Offline" color="error" size="small" />
    </Stack>
   )}
   <Stack direction="row">
    <Typography fontWeight="bold" variant="subtitle2" minWidth={140}>
     <FormattedMessage id="docker.website.remoteAccessPort" />
    </Typography>
    <Typography variant="subtitle2">{`${
     databaseList[0]?.remote ? databaseList[0]?.remote_port : "Not available"
    }`}</Typography>
   </Stack>
   <Stack direction="row" alignItems="center" spacing={1} pt={2}>
    <Typography fontWeight="bold" variant="body2" textTransform="uppercase">
     Actions
    </Typography>
    <Stack width="stretch">
     <Divider />
    </Stack>
   </Stack>
   <Stack direction="row">
    <RemoveDatabase id={id} />
    {databaseList[0]?.remote ? <DisableRemoteDb id={id} /> : <EnableRemoteDb id={id} />}
   </Stack>
  </Stack>
 ) : dbOnline === "restarting" ? (
  <Stack direction="row" spacing={1}>
   <CircularProgress />
   <Typography>
    <FormattedMessage id="docker.website.dbRestarting" />
   </Typography>
  </Stack>
 ) : (
  <Stack spacing={2} p={2}>
   <Alert severity="warning">
    <Typography>
     <FormattedMessage id="docker.website.dbIsOffline" />
    </Typography>
   </Alert>
   <Stack textAlign="left">
    <Typography fontWeight="bold">
     <FormattedMessage id="docker.website.dbOperations" />
    </Typography>
    <Stack direction="row">
     <PowerOnSite id={id} type="db" />
    </Stack>
   </Stack>
  </Stack>
 );
};

export default DatabaseSection;
