import React, { Fragment, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import useDebounce from "react-use/lib/useDebounce";
import { AppContext } from "AppContext";
import { formatPort, separatePorts } from "helpers/docker";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";

import { getDockerProxy, getServerSites } from "redux/handlers/dockerHandler";

import { getDockerWebsiteNumber } from "redux/selectors/dockerWebsiteSelector";
import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerData } from "types/api/dockerApiInterface";

import CreateProxy from "../containerWebsite/actions/CreateProxy";
import DisableFtpService from "../containerWebsite/actions/DisableFtpService";

import AddProxyContainer from "./actions/AddProxyContainer";
import DeleteProxyContainer from "./actions/DeleteProxyContainer";
import ShowProxyInfo from "./actions/ShowProxyInfo";

const DockerProxy = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);
 const websiteNumber = useSelector(getDockerWebsiteNumber);

 const searchRef = useRef<HTMLInputElement>(null);

 const [container, setContainers] = useState<Array<IContainerData>>([]);
 const [containerNumber, setContainersNumber] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [sitesNumber, setSitesNumber] = useState<number>(0);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   const containerList = await dispatch(getDockerProxy(id, 0, 10));
   if (containerList.dataset.length === 0) setListWasEmpty(true);
   setContainers(containerList.dataset);
   setContainersNumber(containerList.totalCount);
   await dispatch(getServerSites(id, 0, 10));
   setSitesNumber(websiteNumber);
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  function onDockerProxy(value: any) {
   if (value?.completed && value?.section === "proxy") {
    setReloadData(true);
   }
  }
  !isNil(socket) && socket.on("docker_data", onDockerProxy);
  return () => {
   !isNil(socket) && socket.off("docker_data", onDockerProxy);
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const containerList = await dispatch(
     getDockerProxy(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setContainers(containerList.dataset);
    setContainersNumber(containerList.totalCount);
   }
  })();
 }, [reloadData]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    const containerList = await dispatch(
     getDockerProxy(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setContainers(containerList.dataset);
    setContainersNumber(containerList.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const containerList = await dispatch(
   getDockerProxy(id, 0, newRowsPerPage, searchRef?.current?.value)
  );
  setContainers(containerList.dataset);
  setContainersNumber(containerList.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const containerList = await dispatch(
   getDockerProxy(id, startIndex, rowPerPage, searchRef?.current?.value)
  );
  setContainers(containerList.dataset);
  setContainersNumber(containerList.totalCount);
 };

 return isLoading ? (
  <></>
 ) : container.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="caption">
      {containerNumber} <FormattedMessage id="docker.container.totalContainers" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="docker.container.searchContainer" />}
      inputRef={searchRef}
      sx={{ ml: 2 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack direction="row" spacing={2} alignItems="center">
     <ShowProxyInfo />
     {server.type === "container-website" ? (
      container.find((element) => element.name === "nginx_certbot") ? (
       <></>
      ) : (
       <CreateProxy id={id} />
      )
     ) : (
      <AddProxyContainer id={id} />
     )}
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="docker.container.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.image" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.ports" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {container.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.name}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Chip size="small" label={row.status} color="success" />
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.image}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Stack direction="row" spacing={1}>
           {separatePorts(row.ports).map((element, key) => {
            return (
             <Chip
              key={`docker-port-${key}`}
              label={formatPort(element)}
              size="small"
              color="info"
             />
            );
           })}
          </Stack>
         </TableCell>
         <TableCell
          align="right"
          onClick={(e) => {
           e.stopPropagation();
          }}>
          <Stack direction="row" justifyContent="flex-end">
           {sitesNumber === 0 && (
            <DeleteProxyContainer
             id={id}
             name={row.name}
             proxy_id={row.container_id}
             type={row.type}
            />
           )}
           <DisableFtpService id={server.id || 0} active={row.container_id_ftp ? true : false} />
          </Stack>
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={containerNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
   {server.type === "container-website" ? <CreateProxy id={id} /> : <AddProxyContainer id={id} />}
  </Stack>
 );
};

export default DockerProxy;
