import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

export const getWebApps = (state: AppState) => state.webapp;

export const getBackups = createSelector(getWebApps, (app) => {
 return app?.backupData || [];
});

export const getCronjobs = createSelector(getWebApps, (app) => {
 return app?.cronjobData || [];
});

export const getDatabases = createSelector(getWebApps, (app) => {
 return app?.databaseData || [];
});

export const getFtps = createSelector(getWebApps, (app) => {
 return app?.ftpData || [];
});

export const getReload = createSelector(getWebApps, (app) => {
 return app?.reload;
});
