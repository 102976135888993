import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import dayjs, { Dayjs } from "dayjs";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postSetAutoReboot } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

const EnableAutoRestart = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [value, setValue] = useState(dayjs());

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postSetAutoReboot(id, `"${value.minute()} ${value.hour()} * * *"`));
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "server.enableAutoRestart" })}>
    <IconButton color="kxOrange" onClick={handleOpen}>
     <IconSelector icon="UpdateDisabledIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.enableAutoRestart" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Stack>
     <Alert severity="info">
      <FormattedMessage id="server.enableAutoRestartMessage" />
     </Alert>
     <StaticTimePicker
      orientation="landscape"
      ampm={false}
      value={value}
      onChange={(newValue: Dayjs | null) => setValue(newValue || dayjs())}
      slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
     />
     <Typography
      variant="h2"
      fontFamily="Roboto, Helvetica, Arial, sans-serif"
      color="rgb(77, 77, 77)">{`${Number(value.hour()) < 10 ? `0${value.hour()}` : value.hour()}:${
      Number(value.minute()) < 10 ? `0${value.minute()}` : value.minute()
     }`}</Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default EnableAutoRestart;
