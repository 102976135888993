import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCompleteCloudboxCreation } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

const CompleteCreation = ({
 id,
 closeMenu
}: {
 id: number;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [cloudId, setCloudId] = useState<number>(0);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCompleteCloudboxCreation(id, cloudId));
  handleClose();
  setLoading(false);
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="addons.cloudbox.completeCreation" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.cloudbox.completeCreation" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Stack>
     <Typography>
      <FormattedMessage id="addons.cloudbox.completeCreationMessage" />
     </Typography>
     <TextField
      value={cloudId}
      type="number"
      label={intl.formatMessage({ id: "addons.cloudbox.completeCreationId" })}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setCloudId(Number(e.target.value))}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default CompleteCreation;
