import React, { ReactElement } from "react";
import { FormattedDate } from "react-intl";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import CheckLogs from "components/utility/mailMigration/actions/CheckLogs";

import { mailMigrationTableData } from "types/global/table";

const EmailMigrationTable = ({
 rowData,
 index
}: {
 rowData: mailMigrationTableData;
 index: number;
}): ReactElement => {
 const RenderStatus = (): ReactElement => {
  switch (rowData.status) {
   case "success":
    return <Chip label={rowData.status} color="success" size="small" />;
   case "started":
    return <Chip label={rowData.status} color="info" size="small" />;
   case "failed":
    return <Chip label={rowData.status} color="error" size="small" />;
   default:
    return <></>;
  }
 };
 return (
  <TableRow key={`snapshot-list-${index}`}>
   <TableCell component="th" scope="row">
    <RenderStatus />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography variant="subtitle2" fontWeight="bold">
      {rowData.mailbox_email}
     </Typography>
     <Stack
      onClick={(event) => event.stopPropagation()}
      maxWidth="fit-content"
      sx={{ cursor: "pointer" }}>
      <AdminLogAsUser userid={rowData.userid} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack>
     <Typography variant="caption">{rowData.source_host}</Typography>
     <Typography variant="caption">{rowData.source_user}</Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack>
     <Typography variant="caption">{rowData.destination_host}</Typography>
     <Typography variant="caption">{rowData.destination_user}</Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Typography variant="inherit">
     <FormattedDate value={rowData.createdAt} />
    </Typography>
   </TableCell>
   <TableCell align="right">
    <CheckLogs id={rowData.order_id} />
   </TableCell>
  </TableRow>
 );
};

export default EmailMigrationTable;
