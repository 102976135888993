import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatMegaBytes } from "helpers/numberFormatting";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

const CloudboxMonitoring = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const CircularMonitor = ({ value, label }: { value: number; label: string }): ReactElement => {
  const circularColor = (): string => {
   if (value <= 50) {
    return "green";
   } else if (value > 50 && value <= 80) {
    return "#FFB600";
   } else {
    return "#FF1800";
   }
  };

  return (
   <Box
    display="flex"
    minWidth={80}
    justifyContent="center"
    alignItems="center"
    sx={{ position: "relative", textAlign: "center", height: 80 }}>
    <CircularProgress
     value={100}
     size="5rem"
     sx={{ color: "#dcdada", position: "absolute" }}
     variant="determinate"
    />
    <CircularProgress
     variant="determinate"
     size="5rem"
     sx={{ color: circularColor(), animationDuration: "550ms", position: "absolute" }}
     value={value}
    />
    <Stack position="absolute" alignItems="center">
     <IconSelector icon="DiscIcon" props={{ fontSize: "small" }} />
     <Typography variant="extraSmall" fontWeight="bold" textTransform="uppercase" fontSize={10}>
      {label}:{value}%
     </Typography>
    </Stack>
   </Box>
  );
 };

 return cloudboxData ? (
  <Paper
   elevation={0}
   sx={{ pt: 2, mt: desktopViewPort ? 1 : 2, pb: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Grid container>
    <Grid xs={12}>
     <Stack direction="row" spacing={3} pl={2}>
      <Stack alignItems="center" justifyContent="center">
       <CircularMonitor
        value={Number(((cloudboxData?.disk_usage / cloudboxData?.disk_quota) * 100).toFixed(0))}
        label={intl.formatMessage({ id: "server.disk" })}
       />
       {desktopViewPort && (
        <>
         <Stack justifyContent="center" alignItems="center">
          <Typography variant="extraSmall" mt={0.5} textTransform="uppercase">
           <FormattedMessage id="server.disk" />:
           {formatMegaBytes(cloudboxData.disk_quota, "TB").toFixed(2)}
           <FormattedMessage id="app.tb" />
          </Typography>
         </Stack>
        </>
       )}
      </Stack>
      <Stack alignItems="center" justifyContent="center">
       <CircularMonitor
        value={Number(
         ((cloudboxData?.disk_usage_data / cloudboxData?.disk_quota) * 100).toFixed(0)
        )}
        label={intl.formatMessage({ id: "server.disk" })}
       />
       {desktopViewPort && (
        <>
         <Stack justifyContent="center" alignItems="center">
          <Typography variant="extraSmall" mt={0.5} textTransform="uppercase">
           <FormattedMessage id="app.data" />
          </Typography>
         </Stack>
        </>
       )}
      </Stack>
      <Stack alignItems="center" justifyContent="center">
       <CircularMonitor
        value={Number(
         ((cloudboxData?.disk_usage_snapshots / cloudboxData?.disk_quota) * 100).toFixed(0)
        )}
        label={intl.formatMessage({ id: "server.disk" })}
       />
       {desktopViewPort && (
        <>
         <Stack justifyContent="center" alignItems="center">
          <Typography variant="extraSmall" mt={0.5} textTransform="uppercase">
           <FormattedMessage id="app.snapshot" />
          </Typography>
         </Stack>
        </>
       )}
      </Stack>
     </Stack>
    </Grid>
   </Grid>
  </Paper>
 ) : (
  <></>
 );
};

export default CloudboxMonitoring;
