import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getConfirmEmail } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ConfirmedEmail = (): ReactElement => {
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  setLoading(true);
  const token = new URLSearchParams(window.location.search).get("token");

  if (!token) return;

  dispatch(getConfirmEmail(token));
  setLoading(false);
 }, []);

 const handleReturn = () => {
  navigate("/login");
 };

 return loading ? (
  <Stack direction="row" alignItems="center" justifyContent="center">
   <CircularProgress />
  </Stack>
 ) : (
  <Stack>
   <Typography>
    <FormattedMessage id="login.confirmedEmail" />
   </Typography>
   <Button variant="kxFilledActionButton" onClick={handleReturn} sx={{ py: 3, mt: 2 }}>
    Torna al login
   </Button>
  </Stack>
 );
};

export default ConfirmedEmail;
