import React, { ReactElement } from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import DeleteAlias from "components/apps/containerMailDomain/actions/aliasActions/DeleteAlias";
import EditAlias from "components/apps/containerMailDomain/actions/aliasActions/EditAlias";

import { IContainerMailDomainsAlias } from "types/api/containerMailDomainApiInterface";

const ContainerMailDomainAliasTable = ({
 rowData,
 index
}: {
 rowData: IContainerMailDomainsAlias;
 index: number;
}): ReactElement => {
 return (
  <TableRow key={`cron-table-${index}`}>
   <TableCell component="th" scope="row">
    <Chip
     size="small"
     label={rowData.active ? "Online" : "Offline"}
     color={rowData.active ? "success" : "error"}
    />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <Typography fontWeight="bold" variant="subtitle2">
      {rowData.address}
     </Typography>
     <Typography variant="caption">
      {rowData.private_comment ? rowData.private_comment : "No description"}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="subtitle2" minWidth={95}>
     {rowData.goto}
    </Typography>
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" spacing={1} justifyContent="flex-end">
     <EditAlias aliasData={rowData} />
     <DeleteAlias id={rowData.id} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};
export default ContainerMailDomainAliasTable;
