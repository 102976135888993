import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generateSpecialPassword } from "helpers/generic";
import { formatBytes } from "helpers/numberFormatting";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";
import RangeSlider from "components/shared/sliders/RangeSlider";

import { postEditContainerMailbox } from "redux/handlers/mailDomainContainerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerMailboxData } from "types/api/containerMailDomainApiInterface";

type mailboxType = {
 name: string;
 password: string;
 quota: number;
 enabled: boolean;
 enable_imap: boolean;
 enable_pop: boolean;
 enable_smtp: boolean;
 enable_sieve: boolean;
 spam_threshold1: number;
 spam_threshold2: number;
};

const EditMailbox = ({
 mailboxData,
 closeMenu
}: {
 mailboxData: IContainerMailboxData;
 closeMenu: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleGeneratePassword = () => {
  setValue("password", generateSpecialPassword());
 };

 const handleOpen = () => {
  reset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, handleSubmit, reset, watch, setValue } = useForm({
  defaultValues: {
   name: mailboxData.name || "",
   password: mailboxData.password,
   quota: formatBytes(mailboxData.quota, "MB"),
   enabled: mailboxData.active,
   enable_imap: mailboxData.protocols.imap_access,
   enable_pop: mailboxData.protocols.pop3_access,
   enable_smtp: mailboxData.protocols.smtp_access,
   enable_sieve: mailboxData.protocols.sieve_access,
   spam_threshold1: Math.trunc(mailboxData.spam_score),
   spam_threshold2: Number(
    (mailboxData.spam_score % 1) * 100 > 15
     ? ((mailboxData.spam_score % 1) * 10).toFixed(0)
     : ((mailboxData.spam_score % 1) * 100).toFixed(0)
   )
  }
 });

 const onSubmit: SubmitHandler<mailboxType> = async (data: mailboxType) => {
  setLoading(true);
  await dispatch(
   postEditContainerMailbox(
    mailboxData.id,
    data.password,
    data.name,
    Number(data.quota),
    data.enabled,
    data.enable_imap,
    data.enable_pop,
    data.enable_smtp,
    data.enable_sieve,
    `${data.spam_threshold1}.${data.spam_threshold2}`
   )
  );
  setLoading(false);
  handleClose();
  closeMenu();
 };

 const checkDisabled = () => {
  return (
   watch("spam_threshold1") < 0 ||
   watch("spam_threshold2") > 15 ||
   watch("password").length < 8 ||
   !watch("password") ||
   watch("quota") < 0 ||
   isNil(watch("quota"))
  );
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="container.maildomains.editMailbox" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "container.maildomains.editMailbox" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    <Stack>
     <Controller
      name="name"
      control={control}
      rules={{
       required: false
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "container.maildomains.comment" })}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
       />
      )}
     />
     <Stack direction="row" alignItems="center" spacing={2}>
      <Controller
       name="password"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         type={showPassword}
         autoComplete="new-password"
         label={intl.formatMessage({ id: "container.maildomains.mailboxPassword" })}
         error={watch("password").length < 8}
         InputProps={{
          endAdornment: (
           <InputAdornment position="end">
            <IconButton
             aria-label="toggle password visibility"
             onClick={() => setShowPassword(showPassword === "password" ? "text" : "password")}
             edge="end">
             <IconSelector
              icon={showPassword === "text" ? "VisibilityOffIcon" : "VisibilityIcon"}
              props={{ fontSize: "small" }}
             />
            </IconButton>
           </InputAdornment>
          )
         }}
        />
       )}
      />
      <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
       <FormattedMessage id="app.generatePassword" />
      </Button>
     </Stack>
     <Controller
      name="quota"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        type="number"
        label={intl.formatMessage({ id: "container.maildomains.mailboxQuota" })}
        error={watch("quota") < 0 || isNil(watch("quota"))}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         watch("quota") < 0 || isNil(watch("quota"))
          ? intl.formatMessage({ id: "container.maildomains.mailboxQuotaError" })
          : intl.formatMessage({ id: "container.maildomains.mailboxQuotaExplain" })
        }
       />
      )}
     />
     <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
       <Typography variant="subtitle2">
        <FormattedMessage id="container.maildomains.threshold" />
       </Typography>
       <Typography variant="kxColored" fontWeight="bold">{`${watch("spam_threshold1")} - ${watch(
        "spam_threshold2"
       )}`}</Typography>
      </Stack>
      <RangeSlider
       max={15}
       min={0}
       defaultValue={[watch("spam_threshold1"), watch("spam_threshold2")]}
       changeValue={(value) => {
        setValue("spam_threshold1", value[0]);
        setValue("spam_threshold2", value[1]);
       }}
      />
      <Typography variant="extraSmall">
       <FormattedMessage id="container.maildomains.thresholdHelp" />
      </Typography>
     </Stack>
     <Stack direction="row" justifyContent="space-between">
      <Stack>
       <Controller
        name="enabled"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          {...field}
          control={
           <Checkbox
            checked={watch("enabled")}
            onChange={(e) => {
             setValue("enabled", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "container.maildomains.enableMailbox" })}
         />
        )}
       />
       <Controller
        name="enable_imap"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          {...field}
          control={
           <Checkbox
            checked={watch("enable_imap")}
            onChange={(e) => {
             setValue("enable_imap", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "container.maildomains.enableImap" })}
         />
        )}
       />
       <Controller
        name="enable_pop"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          {...field}
          control={
           <Checkbox
            checked={watch("enable_pop")}
            onChange={(e) => {
             setValue("enable_pop", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "container.maildomains.enablePop" })}
         />
        )}
       />
      </Stack>
      <Stack>
       <Controller
        name="enable_smtp"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          {...field}
          control={
           <Checkbox
            checked={watch("enable_smtp")}
            onChange={(e) => {
             setValue("enable_smtp", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "container.maildomains.enableSmtp" })}
         />
        )}
       />
       <Controller
        name="enable_sieve"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          {...field}
          control={
           <Checkbox
            checked={watch("enable_sieve")}
            onChange={(e) => {
             setValue("enable_sieve", e.target.checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "container.maildomains.enableSieve" })}
         />
        )}
       />
      </Stack>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default EditMailbox;
