import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "../images/IconSelector";

const GithubLogin = ({ message }: { message: string }): ReactElement => {
 const intl = useIntl();
 const encodedUrl = encodeURIComponent("https://konsolex.onthecloud.srl/github");

 return (
  <Tooltip title={`${intl.formatMessage({ id: message })} GitHub`}>
   <IconButton
    sx={{
     p: 0.9,
     border: "1px solid lightgrey",
     borderRadius: 10,
     backgroundColor: "white",
     textTransform: "none",
     textAlign: "center",
     color: "black"
    }}
    href={`https://github.com/login/oauth/authorize?client_id=Ov23lixdnzxjy2nIvBZn&redirect_uri=${encodedUrl}`}>
    <IconSelector icon="GitHubIcon" />
   </IconButton>
  </Tooltip>
 );
};

export default GithubLogin;
