import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { customAppSteps } from "constants/apps";
import { domainValidation } from "constants/regexp";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 getDockerProxy,
 getRepositorys,
 getTypeVersions,
 postCreateWebsite
} from "redux/handlers/dockerHandler";
import { getServerByType } from "redux/handlers/utilityHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IDockerRepo, IDockerTypeVersions } from "types/api/dockerApiInterface";
import { IServerByTypeAPI } from "types/api/serversApiInterface";

import CustomWebAppRecap from "./webAppWizardComponents/CustomWebAppRecap";

const CustomWebAppWizard = ({ type }: { type: string }): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);
 const [servers, setServers] = useState<Array<IServerByTypeAPI>>([]);
 const [appVers, setAppVers] = useState<IDockerTypeVersions | null>(null);
 const [repository, setRepository] = useState<Array<IDockerRepo>>([]);
 const [proxyNotFound, setProxyNotFound] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setAppVers(await dispatch(getTypeVersions()));
   setRepository(await dispatch(getRepositorys()));
   setServers(await dispatch(getServerByType("container-website")));
   setValue("app_type", type);
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   server: 0,
   site_name: "",
   app_type: "",
   app_version: ""
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const selectRepo = (vers: string): string => {
  if (vers.includes("tomcat")) {
   return repository.find((element) => element.name.includes("tomcat"))?.name || "";
  } else if (vers.includes("nginx")) {
   return repository.find((element) => element.name.includes("nginx"))?.name || "";
  } else if (vers.includes("apache")) {
   return repository.find((element) => element.name.includes("apache"))?.name || "";
  } else if (vers.includes("odoo")) {
   return repository.find((element) => element.name.includes("odoo"))?.name || "";
  } else {
   return repository[0].name;
  }
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postCreateWebsite(
    watch("server"),
    watch("site_name"),
    watch("app_version"),
    selectRepo(watch("app_version"))
   )
  );
  setLoading(false);
  handleClose();
  navigate(`/servers/manage/${watch("server")}/container`);
  handleReset();
 };

 const handleChangeServer = async (id: number) => {
  setProxyNotFound(false);
  const containerList = await dispatch(getDockerProxy(id, 0, 10));
  if (containerList.totalCount === 0) setProxyNotFound(true);
  setValue("server", id);
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="server"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         handleChangeServer(value?.id || 0);
        }}
        options={servers.map((server) => {
         return { label: server.server_name, id: server.id };
        })}
        value={servers
         .map((element) => {
          return { label: element.server_name, id: element.id };
         })
         .find((server) => server.id === getValues("server"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="docker.website.add.serverHost" />}
          error={formState.isDirty && !!formState?.errors?.server}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="site_name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "docker.website.add.siteName" })}
        error={formState.isDirty && !!formState?.errors?.site_name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.site_name &&
         intl.formatMessage({ id: "login.passwordMinLength" })
        }
       />
      )}
     />
    );
   case 2:
    return appVers ? (
     <Controller
      name="app_version"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("app_version", value?.label || "");
        }}
        options={appVers?.versions
         .filter((element) =>
          watch("app_type") === "php"
           ? element.name.includes(watch("app_type")) && !element.name.includes("litespeed")
           : element.name.includes(watch("app_type"))
         )
         .map((app) => {
          return { label: app.name, id: app.id };
         })}
        value={appVers?.versions
         .map((element) => {
          return { label: element.name, id: element.id };
         })
         .find((app) => app.label === getValues("app_version"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          label={<FormattedMessage id="docker.website.add.appVersion" />}
          error={formState.isDirty && !!formState?.errors?.app_version}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    ) : (
     <></>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return watch("server") === 0 || proxyNotFound;
   case 1:
    return watch("site_name") === "" || !domainValidation.test(watch("site_name"));
   case 2:
    return watch("app_type") === "";
   case 3:
    return watch("app_version") === "";
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("server") === 0 ||
   proxyNotFound ||
   watch("site_name") === "" ||
   !domainValidation.test(watch("site_name")) ||
   watch("app_type") === "" ||
   watch("app_version") === ""
  );
 };

 const switchText = (): string => {
  switch (type) {
   case "php":
    return "docker.website.add.createPhpApp";
   case "tomcat":
    return "docker.website.add.createTomcatApp";
   case "litespeed":
    return "docker.website.add.createLitespeedApp";
   default:
    return "docker.website.add.createCustomApp";
  }
 };

 const switchIcon = (): string => {
  switch (type) {
   case "php":
    return "PhpIcon";
   case "tomcat":
    return "TomcatIcon";
   case "litespeed":
    return "LitespeedIcon";
   default:
    return "AutoAwesomeIcon";
  }
 };

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "app", url: "/app" },
      { name: "sites", url: "/app/sites" },
      { name: "add", url: "/app/sites/add" }
     ]}
    />
   </Stack>
   <Stack spacing={2}>
    <Stack alignItems="center" mt={5} p={3}>
     <IconSelector icon={switchIcon()} props={{ fontSize: "large" }} />
     <Typography>
      <FormattedMessage id={switchText()} />
     </Typography>
    </Stack>
    <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
     <Box
      sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
      m={10}>
      <Stepper activeStep={activeStep} orientation="vertical">
       {customAppSteps.map((step, index) => (
        <Step key={step.label}>
         <StepLabel>
          <FormattedMessage id={step.label} />
         </StepLabel>
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 0 && proxyNotFound && (
            <Alert severity="error">
             <Typography>
              <FormattedMessage id="docker.website.noProxyFound" />
             </Typography>
            </Alert>
           )}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== customAppSteps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 1, mr: 1 }}>
              <FormattedMessage id="app.wizard.continue" />
             </Button>
            ) : (
             <></>
            )}
            {index === 2 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="app.wizard.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="app.wizard.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="app.wizard.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        </Step>
       ))}
      </Stepper>
     </Box>
     <CustomWebAppRecap
      server={servers.find((element) => element.id === watch("server"))?.server_name || ""}
      site={watch("site_name")}
      type={watch("app_type")}
      version={watch("app_version")}
      icon={switchIcon()}
     />
    </Stack>
    <AppModal
     open={openModal}
     close={handleClose}
     title={intl.formatMessage({ id: "docker.website.add.createNewApp" })}
     handleClose={handleClose}
     disabled={loading}
     handleConfirm={handleConfirm}>
     <Stack>
      <FormattedMessage id="docker.website.add.confirmCreation" />
     </Stack>
    </AppModal>
   </Stack>
  </Container>
 ) : (
  <></>
 );
};

export default CustomWebAppWizard;
