import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import CheckLogs from "../actions/CheckLogs";
import ClosePorts from "../actions/ClosePorts";
import InstallEnviroment from "../actions/enviromentActions/InstallEnviroment";
import UninstallEnviroment from "../actions/enviromentActions/UninstallEnviroment";
import GenerateSshKey from "../actions/GenerateSshKey";
import InstallGitHubRunner from "../actions/githubActions/InstallGitHubRunner";
import OpenPorts from "../actions/OpenPorts";
import ShowSshKey from "../actions/ShowSshKey";

const WebAppEnvStatistics = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onWebsiteEnvPerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === `nodejs_${siteData.site_name}`) {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
      setNetIo(element?.net_io);
      setBlockIo(element?.block_io);
     }
    }
   }
  }
  !isNil(socket) && socket.on("container_website_performance", onWebsiteEnvPerformance);
  return () => {
   !isNil(socket) && socket.off("container_website_performance", onWebsiteEnvPerformance);
  };
 }, [socket]);

 const formatVersion = (): string => {
  if (siteData.enviroment.type?.includes("nodejs")) {
   return "nodejs";
  } else {
   return "nodejs";
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1}>
    <Stack direction="row" spacing={2} p={1}>
     <IconSelector icon="CodeIcon" />
     <Typography fontWeight="bold">
      <FormattedMessage id="docker.website.enviromentStatistics" />
     </Typography>
    </Stack>
    {!isNil(siteData.enviroment.type) ? (
     <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-around">
       <Stack>
        <SemiCircularProgress value={cpu} maxValue={100} size={100} thickness={8} percent={true} />
        <Typography variant="subtitle2" fontWeight="bold">
         CPU
        </Typography>
       </Stack>
       <Stack>
        <SemiCircularProgress value={ram} maxValue={100} size={100} thickness={8} percent={true} />
        <Typography variant="subtitle2" fontWeight="bold">
         RAM
        </Typography>
       </Stack>
      </Stack>
      <Stack textAlign="left" p={1}>
       <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
         NET I/O:
        </Typography>
        <Typography variant="subtitle2" fontSize={12} minWidth={20}>
         {netIo}
        </Typography>
       </Stack>
       <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
         BLOCK I/O:
        </Typography>
        <Typography variant="subtitle2" fontSize={12} minWidth={20}>
         {blockIo}
        </Typography>
       </Stack>
       {siteData.github.github_runner && (
        <>
         <Stack justifyContent="space-between" alignItems="center" direction="row">
          <Stack
           direction="row"
           spacing={2}
           sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
           <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
            GitHub URL:
           </Typography>
           <Typography
            variant="subtitle2"
            fontSize={12}
            minWidth={20}
            sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {siteData.github.repositoryGithubUrl}
           </Typography>
          </Stack>
          <ClickToCopy text={siteData.github.repositoryGithubUrl || ""} />
         </Stack>
         <Stack justifyContent="space-between" alignItems="center" direction="row">
          <Stack
           direction="row"
           spacing={2}
           sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
           <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
            GitHub Token:
           </Typography>
           <Typography
            variant="subtitle2"
            fontSize={12}
            minWidth={20}
            sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
            {siteData.github.token_github}
           </Typography>
          </Stack>
          <ClickToCopy text={siteData.github.token_github || ""} />
         </Stack>
        </>
       )}
       {!isNil(siteData.open_ports.node.port) && (
        <>
         <Stack direction="row" spacing={2}>
          <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
           SSH Port:
          </Typography>
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           {siteData.open_ports.node.port}
          </Typography>
         </Stack>
         <Stack direction="row" spacing={2}>
          <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
           SSH Root IP:
          </Typography>
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           {siteData.open_ports.node.root}
          </Typography>
         </Stack>
         <Stack direction="row" spacing={2}>
          <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
           SSH Password:
          </Typography>
          <Typography variant="subtitle2" fontSize={12} minWidth={20}>
           {siteData.open_ports.node.password}
          </Typography>
         </Stack>
        </>
       )}
       {!isNil(siteData.ssh_data.node.ssh_key) && (
        <Stack direction="row" spacing={2} alignItems="center">
         <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
          SSH Key:
         </Typography>
         <ShowSshKey ssh_key={siteData.ssh_data.node.ssh_key} />
        </Stack>
       )}
       <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={100}>
         GitHub Guide:
        </Typography>
        <NavLink to={`${process.env.PUBLIC_URL}/documents/guides/guida.html`} target={"_blank"}>
         <Typography variant="subtitle2" fontSize={12} minWidth={20}>
          <FormattedMessage id="docker.website.gitGuide" />
         </Typography>
        </NavLink>
       </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} p={1}>
       <UninstallEnviroment id={siteData.id} />
       {isNil(siteData.open_ports.node.port) ? (
        <OpenPorts id={siteData.id} name={`nodejs_${siteData.site_name}`} type="node" />
       ) : (
        <ClosePorts id={siteData.id} name={`nodejs_${siteData.site_name}`} type="node" />
       )}
       <GenerateSshKey id={siteData.id} name={`nodejs_${siteData.site_name}`} type="node" />
       {!siteData.github.github_runner && <InstallGitHubRunner id={siteData.id} />}
       <CheckLogs id={siteData.server_id_db} container={`nodejs_${siteData.site_name}`} />
      </Stack>
     </Stack>
    ) : (
     <Stack p={1} spacing={1}>
      <Alert severity="warning">
       <Typography>
        <FormattedMessage id="docker.website.noEnviromentInstalled" />
       </Typography>
      </Alert>
      <Divider />
      <Stack direction="row">
       <InstallEnviroment id={siteData.id} version={formatVersion()} />
      </Stack>
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default WebAppEnvStatistics;
