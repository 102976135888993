import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";

import GithubLogin from "components/shared/buttons/GithubLogin";
import GoogleLogin from "components/shared/buttons/GoogleLogin";
import LinkedinLogin from "components/shared/buttons/LinkedinLogin";

import { postLoginViaGoogle } from "redux/handlers/appLoginHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { GoogleSignupResponseAPI } from "types/api/externalLoginApiInterface";

const LoginExternalButtons = (): ReactElement => {
 const dispatch = useAppDispatch();

 const handleGoogleCredentialResponse = async (response: GoogleSignupResponseAPI) => {
  await dispatch(postLoginViaGoogle(response.credential));
 };

 return (
  <Stack alignItems="center" justifyContent="center" spacing={2} direction="row">
   <GoogleLogin
    onSuccess={handleGoogleCredentialResponse}
    onError={() => console.log("error")}
    method="signup_with"
   />
   <LinkedinLogin message="app.signInWith" />
   <GithubLogin message="app.signInWith" />
  </Stack>
 );
};

export default LoginExternalButtons;
