import React, { ReactElement } from "react";

import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IDockerSiteData } from "types/api/webAppApiInterface";

import MemcachedStatistics from "../manageComponents/plugins/MemcachedStatistics";
import RedisStatistics from "../manageComponents/plugins/RedisStatistics";

const WebAppPlugins = ({ siteData }: { siteData: IDockerSiteData }): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 /*useEffect(() => {
  (async () => {
   setLoading(true);
   setPlugins(await dispatch(getEnviromentVersions("plugin")));
   setLoading(false);
  })();
 }, []);*/

 /**
  * TODO: inserire modal installazione per entrambi
  * inserire traduzioni
  * chiedere endpoint per spegnere e disinstallare
  */

 return (
  <Grid container spacing={2}>
   <Grid xs={desktopViewPort ? 4 : 12}>
    <RedisStatistics siteData={siteData} />
   </Grid>
   <Grid xs={desktopViewPort ? 4 : 12}>
    <MemcachedStatistics siteData={siteData} />
   </Grid>
  </Grid>
 );
};

export default WebAppPlugins;
