import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postRetryDomainVat } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const RetryVat = ({ id, action }: { id: number; action: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [vat, setVat] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  setVat("");
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);
 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRetryDomainVat(id, action, vat));
  setLoading(false);
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="domain.retry" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "domain.retry" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="domain.error.confirmRetry" />
     </Typography>
     <TextField
      value={vat}
      label={intl.formatMessage({ id: "domain.vat" })}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => setVat(e.target.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default RetryVat;
