import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const UtilityIcon = createSvgIcon(
 <svg
  id="Livello_1"
  fill="currentColor"
  data-name="Livello 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20">
  <path d="M6,.49v4.97c0,.27-.22.49-.49.49H.49c-.27,0-.49-.22-.49-.49V.49c0-.27.22-.49.49-.49h5.01c.27,0,.49.22.49.49Z" />
  <path d="M13,.49v4.97c0,.27-.22.49-.49.49h-5.01c-.27,0-.49-.22-.49-.49V.49c0-.27.22-.49.49-.49h5.01c.27,0,.49.22.49.49Z" />
  <path d="M20,.49v4.97c0,.27-.22.49-.49.49h-5.01c-.27,0-.49-.22-.49-.49V.49c0-.27.22-.49.49-.49h5.01c.27,0,.49.22.49.49Z" />
  <path d="M6,7.51v4.97c0,.27-.22.49-.49.49H.49c-.27,0-.49-.22-.49-.49v-4.97c0-.27.22-.49.49-.49h5.01c.27,0,.49.22.49.49Z" />
  <path d="M6,14.54v4.97c0,.27-.22.49-.49.49H.49c-.27,0-.49-.22-.49-.49v-4.97c0-.27.22-.49.49-.49h5.01c.27,0,.49.22.49.49Z" />
  <path d="M7.09,19.93c.09.09.22.11.32.05l4.18-1.85s.06-.03.09-.05t.01-.01l5.34-5.34,1.15-1.16,1.46-1.46c.46-.46.46-1.21,0-1.67l-1.07-1.07c-.46-.46-1.21-.46-1.67,0l-2.63,2.63-5.34,5.34s-.04.05-.05.09l-1.86,4.18c-.04.11-.02.25.06.33ZM17.97,10.95l-1.9-1.9,1.26-1.25c.23-.24.6-.24.83,0l1.08,1.07c.23.24.23.6,0,.84l-1.26,1.25Z" />
  <path d="M13,7.51c0-.27-.22-.49-.49-.49h-5.01c-.27,0-.49.22-.49.49v4.97c0,.27.22.49.49.49h3.44l2.06-2.06v-3.4Z" />
 </svg>,
 "Utility"
);
