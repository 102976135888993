import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import FilemanagerFrame from "components/shared/externalApps/FilemanagerFrame";
import IconSelector from "components/shared/images/IconSelector";

import { postInstallFilemanager, saveFilemanagerSettings } from "redux/handlers/webAppHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getFilemanagerSession } from "redux/selectors/sessionSelector";

import { useAppDispatch } from "hooks/reduxHook";

const WebAppFilemanager = ({
 id,
 active,
 file_port,
 file_password
}: {
 id: number;
 active: boolean;
 file_port: number | null;
 file_password: string | null;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const sessionActive = useSelector(getFilemanagerSession);

 const [loading, setLoading] = useState<boolean>(false);
 const [password, setPassword] = useState<string>(file_password || "");
 const [port, setPort] = useState<number>(file_port || 0);

 const handleLoadData = async () => {
  setLoading(true);
  const result = await dispatch(postInstallFilemanager(id));
  if (result) {
   setPassword(result.password);
   setPort(result.port);
   await dispatch(saveFilemanagerSettings(id, true, result?.password, result?.port));
  }
  setLoading(false);
 };

 return (
  <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="CronjobIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     <FormattedMessage id="docker.website.filemanager" />
    </Typography>
   </Stack>
   {loading ? (
    <Stack direction="row" alignItems="center" spacing={2} p={2}>
     <CircularProgress />
     <Typography>
      <FormattedMessage id="docker.website.loadingFilemanagerData" />
     </Typography>
    </Stack>
   ) : active || sessionActive === id ? (
    <FilemanagerFrame
     id={id}
     password={password}
     link={`http://${server.ipv4}:${port}`}
     setLoad={(value) => setLoading(value)}
    />
   ) : (
    <Stack p={2} spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="docker.website.filemanagerOff" />
     </Alert>
     <Stack direction="row">
      <Button onClick={handleLoadData}>
       <FormattedMessage id="docker.website.activateFilemanager" />
      </Button>
     </Stack>
    </Stack>
   )}
  </Paper>
 );
};

export default WebAppFilemanager;
