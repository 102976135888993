import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatDateColor } from "helpers/app";
import { formatMegaBytes } from "helpers/numberFormatting";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { locationList } from "constants/locations";

import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { cloudboxTableData } from "types/global/table";

const CloudboxTable = ({
 rowData,
 index
}: {
 rowData: cloudboxTableData;
 index: number;
}): ReactElement => {
 const intl = useIntl();
 const navigate = useNavigate();

 const RenderLocation = (location: string) => {
  const filteredLocation = locationList.filter((data) => location === data.tag);

  return filteredLocation.map((element, index) => (
   <Stack key={`location-item-${index}`} direction="row" spacing={1}>
    <Stack component="span" className={`fi fi-${element.flag}`} />
    <Typography variant="inherit">
     <FormattedMessage id={element.location} />
    </Typography>
   </Stack>
  ));
 };

 const handleNavigate = (id: number) => {
  rowData.service_status !== "expired" &&
   rowData.status === "active" &&
   navigate(`/addons/cloudbox/manage/${id}/details`, { state: { cloudbox: rowData.name } });
 };

 const RenderStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   case "expiring":
    return (
     <IconSelector icon="ReportIcon" props={{ style: { color: "yellow" }, fontSize: "small" }} />
    );
   case "expired":
    return (
     <IconSelector
      icon="ReportProblemIcon"
      props={{ style: { color: "#FF1800" }, fontSize: "small" }}
     />
    );
   default:
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
  }
 };

 const RenderSystemStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return <Chip color={"success"} size="small" label={status} />;
   case "creating":
    return <Chip color={"warning"} size="small" label={status} />;
   case "upgrading":
    return <Chip color={"warning"} size="small" label={status} />;
   default:
    return <Chip color={"error"} size="small" label={status} />;
  }
 };

 return (
  <TableRow
   sx={{ cursor: rowData.service_status === "expired" ? "not-allowed" : "pointer" }}
   key={`volumes-list-${index}`}
   onClick={() => handleNavigate(rowData.id)}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" alignItems="center" spacing={1}>
      <RenderStatus status={rowData.service_status} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.name}
      </Typography>
     </Stack>
     {RenderLocation(rowData.location.toLowerCase())}
     <Stack onClick={(event) => event.stopPropagation()} maxWidth="fit-content">
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <RenderSystemStatus status={rowData.status} />
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.status !== "creating" ? (
     <Typography variant="inherit">{`${formatMegaBytes(rowData.disk_quota, "TB").toFixed(
      2
     )} ${intl.formatMessage({
      id: "app.tb"
     })}`}</Typography>
    ) : (
     <Typography variant="inherit">{rowData.status}</Typography>
    )}
   </TableCell>
   <TableCell>
    <Stack>
     <Typography variant="caption" color={formatDateColor(rowData.service_status)}>
      <FormattedDate value={rowData.expire} />
     </Typography>
     {rowData.service_status === "expired" && (
      <Typography variant="caption" color="error">
       {rowData.service_status}
      </Typography>
     )}
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default CloudboxTable;
