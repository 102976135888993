import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import ZoneRecords from "components/zone/ZoneRecords";

import { postGetAllDomainRecords } from "redux/handlers/domainsHandler";

import { Record } from "types/api/appsApiInterface";

const DomainDnsCheck = (): ReactElement => {
 const navigate = useNavigate();
 const query = new URLSearchParams(window.location.search);

 const { socket } = useContext(AppContext);

 const [records, setRecords] = useState<Array<Record>>([]);
 const [loading, setLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(true);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("dns_data", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("dns_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setLoading(true);
    if (!isNil(query.get("server")) && !isNil(query.get("zone")))
     setRecords(
      await postGetAllDomainRecords(Number(query.get("server")), Number(query.get("zone")))
     );
    setLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const returnToDomain = () => {
  navigate("/domains");
 };

 return (
  <Stack p={3} spacing={1}>
   <Stack direction="row">
    <Button
     variant="kxActionButton"
     startIcon={<IconSelector icon="ArrowBackIcon" />}
     onClick={returnToDomain}>
     <FormattedMessage id="app.back" />
    </Button>
   </Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack>
     <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
      <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
      <Typography variant="h6" component="h2" ml={1}>
       <FormattedMessage id="domains.dns" />{" "}
      </Typography>
     </Stack>
     <Stack p={2} spacing={2}>
      {loading ? (
       <CircularProgress />
      ) : (
       <ZoneRecords
        ispId={Number(query.get("server"))}
        zoneId={Number(query.get("zone"))}
        data={records}
        domain={query.get("domain") || ""}
       />
      )}
     </Stack>
    </Stack>
   </Paper>
  </Stack>
 );
};

export default DomainDnsCheck;
