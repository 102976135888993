import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

const ExternalDatabaseRecap = ({
 server,
 database,
 port,
 uploadLimit,
 host
}: {
 server: string;
 database: string;
 port: number;
 uploadLimit: number;
 host: string;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 return (
  <Paper
   elevation={0}
   sx={{
    m: 5,
    p: 2,
    maxHeight: 330,
    position: "sticky",
    top: 30,
    minWidth: desktopViewPort ? 400 : "auto",
    borderRadius: "10px",
    boxShadow: 0
   }}>
   <Stack mb={2}>
    <Typography variant="h5" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.recap" />
    </Typography>
   </Stack>
   <Divider textAlign="left">
    <Typography variant="overline">
     <FormattedMessage id="utility.externalDatabases.add.serverData" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.serverName" />:
    </Typography>
    <Typography noWrap>{server}</Typography>
   </Stack>
   <Divider textAlign="left" sx={{ mt: 2 }}>
    <Typography variant="overline">
     <FormattedMessage id="utility.externalDatabases.add.databaseSpecs" />
    </Typography>
   </Divider>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.databaseName" />:
    </Typography>
    <Typography noWrap>{database}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.port" />:
    </Typography>
    <Typography noWrap>{port}</Typography>
   </Stack>
   <Stack direction="row" spacing={2}>
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.uploadLimit" />:
    </Typography>
    <Typography noWrap>{`${uploadLimit} MB`}</Typography>
   </Stack>
   <Stack direction="row" spacing={2} alignItems="center">
    <Typography minWidth={120} textAlign="start" fontWeight="bold">
     <FormattedMessage id="utility.externalDatabases.add.databaseHost" />
    </Typography>
    <Typography noWrap>{host}</Typography>
   </Stack>
  </Paper>
 );
};

export default ExternalDatabaseRecap;
