import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import SemiCircularProgress from "components/shared/progressBar/SemiCircularProgress";

import { getServerDetails } from "redux/selectors/serversSelector";

const PostfixPerformance = (): ReactElement => {
 const { socket } = useContext(AppContext);
 const { agentoken } = useSelector(getServerDetails);

 const [netIo, setNetIo] = useState<string>("not available");
 const [blockIo, setBlockIo] = useState<string>("not available");
 const [cpu, setCpu] = useState<number>(0);
 const [ram, setRam] = useState<number>(0);

 useEffect(() => {
  function onPostfixPerformance(value: any) {
   if (value?.length > 0) {
    for (let element of value) {
     if (element.token === agentoken && element.name_ct === "kx-mail-postfix-mailcow-1") {
      setCpu(Number(element?.cpu_ct.substring(0, element?.cpu_ct.length - 1)));
      setRam(Number(element?.mem_usage_ct_pc.substring(0, element?.mem_usage_ct_pc.length - 1)));
      setNetIo(element?.net_io);
      setBlockIo(element?.block_io);
     }
    }
   }
  }

  !isNil(socket) && socket.on("container_website_performance", onPostfixPerformance);
  return () => {
   !isNil(socket) && socket.off("container_website_performance", onPostfixPerformance);
  };
 }, [socket]);

 return (
  <Paper elevation={0} sx={{ mt: 1, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
   <Stack p={1} direction="row" spacing={1} alignItems="center">
    <IconSelector icon="DockerIcon" props={{ color: "kxBlue" }} />
    <Typography fontWeight="bold" variant="h6">
     container-postfix
    </Typography>
   </Stack>
   <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-around">
     <Stack>
      <SemiCircularProgress value={cpu} maxValue={100} size={100} thickness={8} percent={true} />
      <Typography variant="subtitle2" fontWeight="bold">
       CPU
      </Typography>
     </Stack>
     <Stack>
      <SemiCircularProgress value={ram} maxValue={100} size={100} thickness={8} percent={true} />
      <Typography variant="subtitle2" fontWeight="bold">
       RAM
      </Typography>
     </Stack>
    </Stack>
    <Stack textAlign="left" p={1}>
     <Stack direction="row" spacing={2}>
      <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
       NET I/O:
      </Typography>
      <Typography variant="subtitle2" fontSize={12} minWidth={20}>
       {netIo}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography variant="subtitle2" fontWeight="bold" fontSize={12} minWidth={130}>
       BLOCK I/O:
      </Typography>
      <Typography variant="subtitle2" fontSize={12} minWidth={20}>
       {blockIo}
      </Typography>
     </Stack>
    </Stack>
   </Stack>
  </Paper>
 );
};

export default PostfixPerformance;
