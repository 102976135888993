import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import UtilityReducer from "redux/reducers/utilityReducer";

import { IServerByTypeAPI } from "types/api/serversApiInterface";
import { IEmailMigrationAPI, IUtilityInfo } from "types/api/utilityApiInterface";

import { ApiService } from "service/ApiService";

export const postObtainUtilityRecap =
 (): AppAction<Promise<IUtilityInfo | null>> => async (dispatch) => {
  try {
   const { data } = await ApiService<IUtilityInfo>("/docker/utility/getutilitys");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get utility info - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const getServerByType =
 (type: string): AppAction<Promise<Array<IServerByTypeAPI>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IServerByTypeAPI>>("/server/getbytype", "POST", {
    type
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get servers by type - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getAllExternalDatabases =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(
    `/docker/externaldb/list?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }&id=${id || 0}`
   );
   dispatch(UtilityReducer.actions.setDatabases(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get external databases - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllEmailMigrations =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IEmailMigrationAPI>(
    `/docker/mailbox/get-migrations?page=${currentIndex}&limit=${sizePerPage}&q=${
     q || ""
    }&partner=${userid || ""}&id=${id || 0}`
   );
   dispatch(UtilityReducer.actions.setMigrations(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get migrations - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllServerMailbox =
 (server_id_db: number): AppAction<Promise<Array<{ username: string; id: number }>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<{ username: string; id: number }>>(
    `/docker/mailbox/list?server_id_db=${server_id_db}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get mailboxes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postStartMigration =
 (
  id: number,
  server_id_db: number,
  source_host: string,
  source_user: string,
  source_password: string,
  destination_host: string,
  destination_user: string,
  destination_password: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/docker/mailbox/start-migration", "POST", {
    id,
    server_id_db,
    source_host,
    source_user,
    source_password,
    destination_host,
    destination_user,
    destination_password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Starting migration...",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get mailboxes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getAllMigrationLogs =
 (id: number): AppAction<Promise<string>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ log: string }>("/docker/order/get-log", "POST", { id });
   return data.log;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get logs - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return "";
  }
 };
